<template>
  <div v-if="enabled">
    <apexchart
      type="area"
      height="400"
      :options="chartOptions"
      :series="series"
      v-if="measurementsData"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import MeasurementsFactory from "@/utils/measurementsfactory"

export default {
  name: "HighPressureTime",
  components: {
    apexchart: VueApexCharts
  },
  props: ["machine", "dates", "measurementsData"],
  data() {
    return {
      enabled: false
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          fontFamily: "Roboto, sans-serif",
          animations: {
            enabled: false
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: true,
              pan: false,
              reset: "<i class='v-icon mdi mdi-overscan'></i>"
            }
          }
        },
        colors: ["#F86624"], // palette6, column 2 from https://apexcharts.com/docs/options/theme/
        noData: {
          text: this.$i18n.t("$vuetify.noDataText")
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "stepline",
          width: 4
        },
        title: {
          text: this.$t("machines.measurements.highPressureTime"),
          align: "left"
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 0
        },
        xaxis: {
          type: "datetime",
          // Add 24h prior to and 24h after the selected time range,
          // so that all data points are  properly displayed.
          min: new Date(this.dates[0]).getTime() - 24 * 3600 * 1000,
          max: new Date(this.dates[1]).getTime() + 24 * 3600 * 1000
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy HH:mm:ss"
          }
        },
        yaxis: {
          title: {
            text: this.$t("chart.legend.hours")
          }
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    },
    series() {
      let engineHours = MeasurementsFactory.makeAreaSeries(
        this.measurementsData,
        [{ name: this.$t("machines.measurements.highPressureTime"), parameterId: "C5", data: [] }],
        rawValue => {
          return Number(rawValue / 3600).toFixed(1)
        }
      )

      return [
        {
          name: this.$t("machines.measurements.highPressureTime"),
          data: engineHours[0].data
        }
      ]
    }
  },
  mounted() {
    this.enabled = true
  },
  watch: {
    dates() {
      // Hack to fix issue with xaxis min max not updating on date change
      this.enabled = false
      setTimeout(() => {
        this.enabled = true
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
