<template>
  <v-card>
    <v-progress-linear
      :active="refreshLoading"
      :indeterminate="refreshLoading"
      absolute
      top
    ></v-progress-linear>

    <v-container fluid>
      <v-row class="pt-3">
        <v-col xs="auto" class="mr-0">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            :label="$t('common.filters.filter')"
            hide-details
            class="ma-0 pa-0"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="mx-0 px-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="refreshItems"
                :loading="refreshLoading"
                :disabled="refreshLoading"
              >
                <v-icon>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="refreshLoader">
                    <v-icon>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>{{ $t("common.actions.refresh") }}</span>
          </v-tooltip>
        </v-col>

        <v-col v-if="canImportItem" cols="auto" class="mx-0 px-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="grab-import-machines"
                v-on="on"
                icon
                @click="importMachine"
                :disabled="refreshLoading"
              >
                <v-icon>mdi-application-import</v-icon>
                <template v-slot:loader>
                  <span class="refreshLoader">
                    <v-icon>mdi-application-import</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>{{ $t("machines.actions.importMachine") }}</span>
          </v-tooltip>
        </v-col>

        <v-col v-if="canAddItem" cols="auto" class="mx-0 pl-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="grab-add-machine"
                v-on="on"
                icon
                @click="addMachine"
                :disabled="refreshLoading"
              >
                <v-icon>mdi-plus-circle</v-icon>
                <template v-slot:loader>
                  <span class="refreshLoader">
                    <v-icon>mdi-plus-circle</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>{{ $t("machines.actions.addMachine") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <MachineListFilter
            :availableProducts="availableProducts"
            :selectedProducts.sync="selectedProducts"
            :availableConnectTypes="availableConnectTypes"
            :selectedConnectTypes.sync="selectedConnectTypes"
            :availableStatuses="availableStatuses"
            :selectedStatuses.sync="selectedStatuses"
            :availableHoursRange="availableHoursRange"
            :selectedHoursRange.sync="selectedHoursRange"
            @filterChanged="onFilterChanged"
            :disabled="refreshLoading"
            :isFiltered.sync="isFiltered"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-card flat tile>
      <v-card-text class="px-0 pb-0">
        <v-data-table
          :headers="headers"
          :items="machines"
          :search="search"
          :custom-filter="searchFilter"
          item-key="serialNo"
          class="elevation-1 mt-2 machines-data-table"
          :class="{ disabled: isLoading }"
          :custom-sort="machinesCustomSort"
          sort-by="status"
          sort-desc
          @click:row="viewItem"
          :footer-props="{
            'items-per-page-options': [20, 50, 100, -1]
          }"
          :items-per-page="50"
        >
          <template v-slot:[`item.productName`]="{ item }">
            {{ checkProductName(item) }}
          </template>
          <template v-slot:[`item.productImage`]="{ item }">
            <div>
              <v-avatar class="ma-1">
                <v-img
                  :src="machineImageSrc(item)"
                  onerror="this.onerror=null;this.src='/products/jpg/default.jpg';this.title='Missing product image'"
                  :alt="checkProductName(item)"
                />
              </v-avatar>
              <!-- Visible on small devices only -->
              <span class="d-md-none">
                <span class="px-3">{{ checkProductName(item) }}</span>
                <v-icon :color="healthColor(item)"
                  >{{ healthIcon(item) }}
                </v-icon>
                <span class="px-3">{{
                  item.engineSeconds | engineHourFilter
                }}</span>
              </span>
            </div>
          </template>

          <!-- --------------------------------------------------------------------- -->

          <template v-slot:[`item.brokkConnect`]="{ item }">
            <v-tooltip top class="align-center">
              <template v-slot:activator="{ on }">
                <v-icon :color="connectColor(item)" v-on="on" left class="ml-2">
                  {{ connectIcon(item) }}
                </v-icon>
              </template>
              <span class="text-capitalize">{{ connectTooltip(item) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip top class="align-center grab-machine-status-tooltip">
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="healthColor(item)"
                  v-on="on"
                  left
                  class="ml-2"
                  >{{ healthIcon(item) }}</v-icon
                >
              </template>
              <span class="text-capitalize">{{ healthTooltip(item) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.engineSeconds`]="{ item }">{{
            item.engineSeconds | engineHourFilter
          }}</template>

          <template v-slot:[`item.lastSeen`]="{ item }">{{
            formatLastSeen(item)
          }}</template>

          <template v-slot:[`item.action`]="{ item }">
            <v-menu left>
              <template v-slot:activator="{ on: menu }">
                <v-btn icon color="primary" v-on="menu">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="viewItem(item)">
                  <v-list-item-title class="body-2">
                    <v-icon small>mdi-information</v-icon>
                    {{ $t("common.actions.view") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canEditItem" @click="editItem(item)">
                  <v-list-item-title class="body-2">
                    <v-icon small>mdi-pencil</v-icon>
                    {{ $t("common.actions.edit") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canEditItem" @click="transferMachine(item)">
                  <v-list-item-title class="body-2">
                    <v-icon small>mdi-transfer</v-icon> Transfer
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template
            v-if="canReadServiceAgreements"
            v-slot:[`item.serviceAgreement`]="{ item }"
          >
            <v-tooltip top class="align-center grab-machine-status-tooltip">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on" left class="ml-2">{{
                  serviceAgreementIcon(item)
                }}</v-icon>
              </template>
              <span class="text-capitalize">{{
                serviceAgreementTooltip(item)
              }}</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-overlay
          v-if="showMachinesOverlay"
          absolute
          color="#fff"
          opacity=".9"
          class="text--primary"
        >
          <v-col cols="12" class="justify-self-center">
            <div class="text-center">
              <h1 class="title d-flex justify-center mb-5">
                <v-icon color="primary" class="mr-2"
                  >mdi-information-outline</v-icon
                >
                {{ $t("machines.labels.noMachines.title") }}
              </h1>
              <span
                v-if="canAddItem"
                v-html="$t('machines.labels.noMachines.descriptionHasAccess')"
              >
              </span>
              <span
                v-else
                v-html="$t('machines.labels.noMachines.descriptionNoAccess')"
              >
              </span>
            </div>
            <div v-if="canAddItem" class="text-center pt-8">
              <v-btn @click="addMachine" color="secondary accent--text">
                <v-icon left>mdi-plus-circle</v-icon>
                {{ $t("machines.actions.addMachine") }}
              </v-btn>
            </div>
          </v-col>
        </v-overlay>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          $t("common.actions.close")
        </v-btn>
      </template>
    </v-snackbar>

    <EditMachineDialog
      :machine="editedItem"
      :show="showEditDialog"
      @save="onEditSave"
      @cancel="onEditCancel"
    />

    <GuidedMachineTransfer
      :machine="editedItem"
      :show="showTransferDialog"
      @cancel="showTransferDialog = false"
    />

    <GuidedMachineImport
      :tenantUid="tenantUid"
      :show="showImportDialog"
      @cancel="onImportCancel"
    />

    <GuidedMachineAdd
      :tenantUid="tenantUid"
      :show="showAddDialog"
      @cancel="onAddCancel"
    />
  </v-card>
</template>

<script>
import EditMachineDialog from "@/dialogs/EditMachineDialog"
import GuidedMachineTransfer from "@/dialogs/GuidedMachineTransfer"
import GuidedMachineImport from "@/dialogs/GuidedMachineImport"
import GuidedMachineAdd from "@/dialogs/GuidedMachineAdd"
import Formatter from "@/utils/formatter"
import ServiceSeverity from "@/utils/serviceSeverity"
import MachineListFilter from "@/components/filters/MachineListFilter"

export default {
  name: "MachinesView",
  props: ["tenantUid"],

  components: {
    EditMachineDialog,
    GuidedMachineTransfer,
    GuidedMachineImport,
    GuidedMachineAdd,
    MachineListFilter
  },

  data() {
    return {
      editedItem: {},
      isLoading: false,
      search: "",
      showEditDialog: false,
      showTransferDialog: false,
      showImportDialog: false,
      showAddDialog: false,
      snackbar: false,
      snackbarText: "",
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1]
      },

      selectedProducts: [],
      selectedConnectTypes: [],
      selectedStatuses: [],
      selectedHoursRange: [-1, 0],
      isFiltered: false
    }
  },
  created() {
    if (this.$route.query.add_machine === null) {
      this.showAddDialog = true
    }
    this.loadMachinesFilter()
  },
  computed: {
    headerTitle() {
      return this.$store.getters.appTitle
    },
    getHeaderText(text) {
      return this.$root.$i18n.t(text)
    },
    headers() {
      let hdrs = [
        {
          sortable: false,
          text: "",
          value: "productImage",
          width: 20
        },
        {
          text: this.$t("machines.dataTable.headers.product"),
          value: "productName",
          hide: "xs"
        },
        {
          text: this.headerTitle,
          value: "brokkConnect",
          hide: "xs"
        },
        {
          text: this.$t("machines.dataTable.headers.serviceStatus"),
          value: "status",
          hide: "xs"
        },
        {
          text: this.$t("machines.dataTable.headers.name"),
          value: "name",
          hide: "xs"
        },
        {
          text: this.$t("machines.dataTable.headers.engineHours"),
          value: "engineSeconds",
          hide: "xs"
        },
        {
          text: this.$t("machines.dataTable.headers.lastSeen"),
          value: "lastSeen",
          hide: "xs"
        },
        {
          text: this.$t("machines.dataTable.headers.tenantName"),
          value: "tenantName",
          hide: "xs"
        }
      ]

      if (this.canReadServiceAgreements) {
        hdrs.splice(4, 0, {
          text: this.$t("machines.dataTable.headers.serviceAgreement"),
          value: "serviceAgreement",
          hide: "xs"
        })
      }

      if (this.canAddItem || this.canEditItem) {
        hdrs.push({
          align: "center",
          hide: "xs",
          sortable: false,
          text: this.$t("common.dataTable.headers.actions"),
          value: "action"
        })
      }
      return hdrs.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },
    refreshLoading() {
      return this.$store.getters.loadingMachines
    },
    availableProducts() {
      let machines = this.$store.getters.machinesFilteredByTenants(
        this.$store.getters.selectedTenantArray
      )
      if (machines?.length > 0) {
        const groupedMachines = machines.reduce((groupedMachines, machine) => {
          const group = groupedMachines[machine.productName] || []
          group.push(machine)
          groupedMachines[machine.productName] = group
          return groupedMachines
        }, {})

        const productKeys = Object.keys(groupedMachines)
        const products = []

        for (let ix = 0; ix < productKeys.length; ix++) {
          const prods = groupedMachines[productKeys[ix]]
          const productCount = prods.length

          products.push({
            id: prods[0].productId,
            label: prods[0].productName,
            value: prods[0].productName,
            count: productCount
          })
        }
        return products
      }
      return []
    },
    availableConnectTypes() {
      let machines = this.$store.getters.machinesFilteredByTenants(
        this.$store.getters.selectedTenantArray
      )
      if (machines?.length > 0) {
        const unprov = machines.filter(m => m.unprovisioned)
        const products = [
          {
            id: 0,
            label: this.$t("machines.actions.unprovisioned"),
            value: true,
            count: unprov.length
          },
          {
            id: 1,
            label: this.$t("machines.actions.provisioned"),
            value: false,
            count: machines.length - unprov.length
          }
        ]
        return products
      }
      return []
    },
    availableStatuses() {
      let machines = this.$store.getters.machinesFilteredByTenants(
        this.$store.getters.selectedTenantArray
      )
      if (machines?.length > 0) {
        const groupedMachines = machines.reduce((groupedMachines, machine) => {
          const group = groupedMachines[machine.maxSeverity] || []
          group.push(machine)
          groupedMachines[machine.maxSeverity] = group
          return groupedMachines
        }, {})

        const levelKeys = Object.keys(groupedMachines)
        const levels = []

        for (let ix = 0; ix < levelKeys.length; ix++) {
          const levs = groupedMachines[levelKeys[ix]]
          const levelCount = levs.length

          levels.push({
            id: levs[0].maxSeverity,
            label: ServiceSeverity.label(levs[0].maxSeverity, this.$i18n),
            value: levs[0].maxSeverity,
            count: levelCount
          })
        }
        return levels
      }
      return []
    },
    availableHoursRange() {
      let machines = this.$store.getters.machinesFilteredByTenants(
        this.$store.getters.selectedTenantArray
      )
      if (machines?.length > 0) {
        let range = [Number.MAX_SAFE_INTEGER, 0]
        for (let ix = 0; ix < machines.length; ix++) {
          const machine = machines[ix]

          if (machine.engineSeconds < range[0]) {
            range[0] = machine.engineSeconds
          }
          if (machine.engineSeconds > range[1]) {
            range[1] = machine.engineSeconds
          }
        }
        // Convert engine seconds to engine hours
        range = [Math.floor(range[0] / 3600.0), Math.ceil(range[1] / 3600.0)]
        return range
      }
      return [-1, 0]
    },
    machines() {
      let machines = this.$store.getters.machinesFilteredByTenants(
        this.$store.getters.selectedTenantArray
      )

      if (machines !== undefined && machines !== null) {
        if (this.selectedProducts.length > 0) {
          machines = machines.filter(machine => {
            return this.selectedProducts.find(sf => {
              return machine.productName === sf.value
            })
          })
        }
        if (this.selectedConnectTypes.length > 0) {
          machines = machines.filter(machine => {
            return this.selectedConnectTypes.find(sf => {
              return machine.unprovisioned === sf.value
            })
          })
        }
        if (this.selectedStatuses.length > 0) {
          machines = machines.filter(machine => {
            return this.selectedStatuses.find(sf => {
              return machine.maxSeverity === sf.value
            })
          })
        }
        if (this.selectedHoursRange[0] > -1) {
          machines = machines.filter(
            machine =>
              machine.engineSeconds >= this.selectedHoursRange[0] * 3600 &&
              machine.engineSeconds <= this.selectedHoursRange[1] * 3600
          )
        }
        return machines
      }
      return []
    },
    canAddItem() {
      return this.$store.getters.canAddMachines || this.$store.getters.isService
    },
    canEditItem() {
      return this.$store.getters.canEditMachines
    },
    canImportItem() {
      return this.$store.getters.canEditMachines
    },
    canReadServiceAgreements() {
      return this.$store.getters.canReadServiceAgreements
    },
    showMachinesOverlay() {
      return (
        this.machines.length === 0 && !this.refreshLoading && !this.isFiltered
      )
    }
  },
  methods: {
    checkProductName(item) {
      if (item?.properties === null) {
        return item?.productName
      }
      if (item?.properties !== null) {
        if (item?.properties.tags?.includes("smart_power_plus")) {
          return `${item?.productName}⁺`
        } else {
          return item?.productName
        }
      }
      return item?.productName
    },
    machineImageSrc(item) {
      if (item.properties !== null && item.properties.tags !== null) {
        if (item.properties.tags.includes("smart_power_plus")) {
          return `/products/jpg/${item.productId}_SPP.jpg`
        }
      }

      return `/products/jpg/${item.productId}.jpg`
    },
    viewItem(item) {
      this.$router.push({
        name: "dashboard",
        params: {
          tenantUid: this.tenantUid,
          serialNo: item.serialNo,
          sourceView: "machines"
        }
      })
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showEditDialog = true
    },
    onEditSave(info) {
      this.showEditDialog = false
      this.snackbarText = info.wasAdded
        ? this.$t("machines.messages.machineAdded")
        : this.$t("machines.messages.machineUpdated")
      this.snackbar = true
      this.refreshItems()
    },
    onEditCancel() {
      this.showEditDialog = false
    },
    async refreshItems() {
      await this.$store.dispatch(
        "fetchMachines",
        this.$store.getters.tenantRoot.uid
      )
      this.loadMachinesFilter()
    },
    transferMachine(item) {
      this.editedItem = Object.assign({}, item)
      this.showTransferDialog = true
    },
    formatLastSeen(machine) {
      return Formatter(this.$i18n).formatLastSeen(machine)
    },
    connectColor(/*machine*/) {
      "primary"
    },
    connectIcon(machine) {
      if (machine.unprovisioned) {
        return "mdi-minus"
      }
      if (machine.networkType === "Wi-Fi") {
        return "mdi-wifi"
      }
      return "mdi-signal-cellular-3"
    },
    connectTooltip(machine) {
      if (machine.unprovisioned) {
        return this.$t("machines.dataTable.tooltips.unprovisioned")
      }
      if (machine.networkType === "Wi-Fi") {
        return this.$t("machines.dataTable.tooltips.wifi")
      }
      return this.$t("machines.dataTable.tooltips.cellular")
    },
    healthColor(machine) {
      return ServiceSeverity.color(machine, this.$i18n)
    },
    healthIcon(machine) {
      return ServiceSeverity.icon(machine, this.$i18n)
    },
    healthTooltip(machine) {
      return ServiceSeverity.tooltip(machine, this.$i18n)
    },
    importMachine() {
      this.showImportDialog = true
    },

    onImportCancel() {
      this.showImportDialog = false
      this.refreshItems()
    },
    addMachine() {
      this.showAddDialog = true
    },
    onAddCancel() {
      this.showAddDialog = false
      this.refreshItems()
    },

    serviceAgreementIcon(machine) {
      if (machine.serviceAgreement) {
        return "mdi-tools"
      }
      return "mdi-minus"
    },

    serviceAgreementTooltip(machine) {
      if (machine.serviceAgreement) {
        return this.$t("machines.dataTable.tooltips.serviceAgreementPresent")
      }
      return this.$t("machines.dataTable.tooltips.serviceAgreementMissing")
    },

    machinesCustomSort(items, sortBy, sortDesc) {
      if (sortBy[0] === undefined) {
        return items
      }

      return items.sort((a, b) => {
        let aVal = a[sortBy[0]]
        let bVal = b[sortBy[0]]

        // Get severity level and sort by that if we're sorting on status
        if (sortBy[0] === "status") {
          // Unprovisioned machines do not have status
          if (a.unprovisioned) {
            aVal = 99
          } else {
            aVal = ServiceSeverity.level(a)
          }

          if (b.unprovisioned) {
            bVal = 99
          } else {
            bVal = ServiceSeverity.level(b)
          }
        }

        if (sortBy[0] === "brokkConnect") {
          aVal = a.unprovisioned
          bVal = b.unprovisioned
        }

        // Sort on serial number if what we're sorting on is equal
        if (aVal === bVal) {
          return a.serialNo < b.serialNo
        }

        const diff = aVal < bVal ? 1 : -1
        return sortDesc[0] ? diff : -diff
      })
    },
    searchFilter(value, search, item) {
      const ss = search.toLowerCase()
      //allows searching for "ok" to show "upcoming" and upcoming to not show anything
      var sv
      const onlyOk = "upcoming"
      if ("ok".toLowerCase().includes(ss)) {
        sv = "upcoming"
      } //allows unprovisioned to be shown for unprovisioned and unknown
      if (
        "unprovisioned".toLowerCase().includes(ss) ||
        "unknown".toLowerCase().includes(ss)
      ) {
        sv = true
      }
      return (
        item.serialNo.toLowerCase().includes(ss) ||
        item.name?.toLowerCase().includes(ss) ||
        item.description?.toLowerCase().includes(ss) ||
        item.productId?.toLowerCase().includes(ss) ||
        item.status?.toLowerCase().includes(ss) ||
        (!item.serviceStatus
          .at(0)
          ?.toLowerCase()
          .includes(onlyOk) &&
          item.serviceStatus
            .at(0)
            ?.toLowerCase()
            .includes(ss)) ||
        //searching for "ok" will show "upcoming"
        (item.serviceStatus
          .at(0)
          ?.toLowerCase()
          .includes(sv) &&
          item.unprovisioned === false) ||
        item.tenantName?.toLowerCase().includes(ss) ||
        item.brokkConnect?.toLowerCase().includes(ss) ||
        item.unprovisioned === sv
      )
    },
    onFilterChanged() {
      this.saveMachinesFilter()
    },
    loadMachinesFilter() {
      const filterString = localStorage.getItem("machinesFilter")

      if (filterString) {
        const filterItem = JSON.parse(filterString)
        this.selectedProducts = filterItem?.selectedProducts || []
        this.selectedConnectTypes = filterItem?.selectedConnectTypes || []
        this.selectedStatuses = filterItem?.selectedStatuses || []
        this.selectedHoursRange = filterItem?.selectedHoursRange || [-1, 0]
      }
    },
    saveMachinesFilter() {
      const filterItem = {
        selectedProducts: this.selectedProducts,
        selectedConnectTypes: this.selectedConnectTypes,
        selectedStatuses: this.selectedStatuses,
        selectedHoursRange: this.selectedHoursRange
      }
      localStorage.setItem("machinesFilter", JSON.stringify(filterItem))
    }
  }
}
</script>

<style type="scss">
.machines-data-table .v-data-table__mobile-row {
  justify-content: end;
}
.statusText {
  display: inline-block;
}
.v-data-table.disabled .v-data-table__wrapper {
  opacity: 0.5;
  transition: opacity 500ms;
}
.refreshLoader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
