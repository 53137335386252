<template>
  <v-card flat outlined :disabled="disabled">
    <v-card-title>
      {{ $t("tenant.customer.labels.deliveryAddresses") }}
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            @click="addAddressClick"
            class="grab-add-address"
          >
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tenant.customer.actions.addDeliveryAddress") }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle>
      {{ addressDescription }}
    </v-card-subtitle>
    <v-card-text>
      <v-data-table :headers="headers" :items="customer.addresses">
        <template v-slot:[`item.addressLine`]="{ item }">
          {{ getAddressLine(item) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon small class="mr-2" @click="onEditAddress(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="onRemoveAddress(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <AddressDialog
        :address="addressItem"
        :show="showAddDialog"
        @save="onSaveAddress"
        @cancel="showAddDialog = false"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import AddressDialog from "@/dialogs/AddressDialog"

const MAX_NUMBER_OF_ADDRESSES = 10

export default {
  props: ["customer", "disabled"],
  components: { AddressDialog },
  data() {
    return {
      showAddDialog: false,
      headers: [
        {
          text: "Address",
          align: "start",
          sortable: true,
          value: "addressLine",
          width: "50%"
        },
        {
          text: "Freight contact",
          align: "start",
          sortable: true,
          value: "freightContact"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action",
          width: "13%"
        }
      ],
      addressItem: {}
    }
  },
  computed: {
    addressDescription() {
      return this.$t("tenant.customer.labels.deliveryAddressDescription", {
        maxRecords: MAX_NUMBER_OF_ADDRESSES
      })
    }
  },
  methods: {
    addAddressClick() {
      this.addressItem = {
        isNew: true,
        line1: "",
        line2: "",
        line3: "",
        line4: "",
        freightContact: ""
      }
      if (this.customer.addresses.length < MAX_NUMBER_OF_ADDRESSES) {
        this.showAddDialog = true
      } else {
        // Max number of addresses have been added.
        var dialog = {
          title: this.$t("tenant.customer.actions.addDeliveryAddress"),
          message: this.$t("tenant.customer.labels.deliveryAddressInfo"),
          buttons: [
            {
              label: this.$t("common.actions.ok"),
              action: () => {
                return false
              }
            }
          ]
        }
        this.$store.dispatch("setGenericDialog", dialog)
      }
    },
    getAddressLine(item) {
      let line = item.line1
      if (item.line2.length > 0) {
        line += `, ${item.line2}`
      }
      if (item.line3.length > 0) {
        line += `, ${item.line3}`
      }
      if (item.line4.length > 0) {
        line += `, ${item.line4}`
      }
      return line
    },
    onEditAddress(item) {
      // Make a copy of the item to edit
      this.addressItem = Object.assign({}, item)
      this.addressItem.isNew = false
      this.addressItem.updateIndex = this.customer.addresses.indexOf(item)
      this.showAddDialog = true
    },
    onSaveAddress(item) {
      this.showAddDialog = false
      if (item.isNew) {
        this.customer.addresses.push(item)
      } else {
        this.customer.addresses[item.updateIndex] = item
        // Recreate the array to update the table view
        this.customer.addresses = [...this.customer.addresses]
      }
    },
    onRemoveAddress(item) {
      const ix = this.customer.addresses.indexOf(item)
      if (ix > -1) {
        this.customer.addresses.splice(ix, 1)
      }
    }
  }
}
</script>

<style></style>
