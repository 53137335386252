<template>
  <v-card flat>
    <v-card-subtitle class="pb-2 black--text">{{
      $t("machines.dialogs.commissioning.steps.segment.description")
    }}</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="selectedSegment"
                  :items="segments"
                  item-text="name"
                  :label="
                    $t('machines.dialogs.commissioning.steps.segment.name')
                  "
                  @input="onSelectedSegment"
                  return-object
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="selectedCategory"
                  :disabled="selectedSegment === null"
                  :items="categories"
                  item-text="name"
                  :label="
                    $t('machines.dialogs.commissioning.steps.category.name')
                  "
                  @input="onSelectedCategory"
                  return-object
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="canHaveOtherCategory">
              <v-col cols="12">
                <v-text-field
                  :label="
                    $t(
                      'machines.dialogs.commissioning.steps.category.otherCategoryHint'
                    )
                  "
                  v-model="enteredCategory"
                  @input="onEnteredCategoryInput"
                  :placeholder="
                    $t(
                      'machines.dialogs.commissioning.steps.category.otherCategoryPlaceholder'
                    )
                  "
                  :rules="[validationRules.maxLimit, validationRules.name]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="applications !== null">
              <v-col cols="12">
                <v-select
                  v-model="selectedApplications"
                  :disabled="selectedCategory === null"
                  :items="applications"
                  :label="
                    $t('machines.dialogs.commissioning.steps.applications.name')
                  "
                  @input="onSelectedApplications"
                  return-object
                  :hint="
                    $t('machines.dialogs.commissioning.steps.applications.hint')
                  "
                  persistent-hint
                  multiple
                  chips
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ValidationRules from "@/utils/validationrules"

let validationRules = null

export default {
  props: ["segment", "category", "apps", "otherCategory"],
  data: () => {
    return {
      selectedSegment: null,
      selectedCategory: null,
      selectedApplications: null,
      enteredCategory: "",
      validationRules: validationRules
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  watch: {
    segment(val) {
      this.selectedSegment = val
    },
    category(val) {
      this.selectedCategory = val
    },
    apps(val) {
      this.selectedApplications = val
    },
    otherCategory(val) {
      this.enteredCategory = val
    }
  },
  async created() {
    await this.$store.dispatch("loadSegmentMetadata", "en")
  },
  computed: {
    segments() {
      return this.$store.getters.segmentMetadata
    },
    categories() {
      if (this.selectedSegment) {
        return this.selectedSegment.categories
      }
      return []
    },
    applications() {
      if (this.selectedCategory) {
        return this.selectedCategory.applications
      }
      return null
    },
    canHaveOtherCategory() {
      if (this.selectedCategory) {
        return this.selectedCategory.canHaveOtherCategory
      }
      return false
    }
  },
  methods: {
    onSelectedSegment(inpSelection) {
      this.selectedSegment = inpSelection
      this.$emit("update:segment", inpSelection)
      // Reset category and apps
      this.$emit("update:category", null)
      this.$emit("update:apps", null)
      this.$emit("update:otherCategory", null)
    },
    onSelectedCategory(inpSelection) {
      this.selectedCategory = inpSelection
      this.$emit("update:category", inpSelection)
      // Reset apps
      this.$emit("update:apps", null)
      this.$emit("update:otherCategory", null)
    },
    onSelectedApplications(inpSelection) {
      this.selectedApplications = inpSelection
      this.$emit("update:apps", inpSelection)
    },
    onEnteredCategoryInput(txtInput) {
      this.enteredCategory = txtInput
      this.$emit("update:otherCategory", txtInput)
    }
  }
}
</script>

<style></style>
