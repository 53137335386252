<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title class="d-flex align-center">
          <v-icon class="accent--text mr-2">mdi-cogs</v-icon>
          <span
            class="title accent--text text-uppercase d-flex justify-center"
            >{{
              `${parameterDialog.item.parameterId} - ${parameterDialog.item.description}`
            }}</span
          ></v-toolbar-title
        >
        <v-spacer />
        <v-btn icon @click="onClose">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6 pb-0 subtitle-1">
        <div class="ml-auto mr-auto">
          <v-form class="white pa-3 pb-0">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    disabled
                    :label="$t('machines.parameters.dialogs.editParameter.parameterNo')"
                    :value="parameterDialog.item.oldParameter"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    disabled
                    :label="$t('machines.parameters.dialogs.editParameter.category')"
                    :value="parameterDialog.item.category"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :disabled="isDisabled"
                    v-model="parameterDialog.item.updateInterval"
                    :items="freqOptions"
                    :label="$t('machines.parameters.dialogs.editParameter.updateinterval')"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions class="pl-6 pr-6">
        <v-container class="pa-3 pt-0">
          <v-row class="d-flex align-center">
            <v-col class="pl-6 pr-6" cols="9">
              <v-label class="pa-3" v-if="comment">
                {{ comment }}
              </v-label>
            </v-col>
            <v-col class="d-flex justify-end">
              <span
                v-for="button in parameterDialog.buttons"
                :key="button.label"
              >
                <v-btn
                  @click.stop="
                    onClose()
                    button.action()
                  "
                  class="ma-2"
                >
                  {{ button.label }}
                </v-btn>
              </span>
              <v-btn
                v-if="parameterDialog.buttons.length === 0"
                class="mr-2 mb-2"
                @click.stop="onClose"
              >
                {{$t("common.actions.ok")}}
              </v-btn></v-col
            >
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditParameterDialog",
  props: ["parameterDialog"],
  data() {
    return {
      freqOptions: ["1m", "5m", "10m", "15m", "30m", "60m"],
      dialog: true
    }
  },
  computed: {
    isDisabled() {
      return this.parameterDialog.item.editMode === "never"
    },
    comment() {
      switch (this.parameterDialog.item.editMode) {
        case "never":
          return this.$t("machines.parameters.dialogs.editParameter.messages.cantBeEdited")
        case "operatorConfirm":
          return this.$t("machines.parameters.dialogs.editParameter.messages.changesNeedApproval")
        case "specialMode":
        default:
          return ""
      }
    }
  },
  methods: {
    onClose() {
      this.dialog = false
    }
  },
  watch: {
    parameterDialog() {
      this.dialog = true
    }
  }
}
</script>
<style>
.v-select__selection--disabled,
.v-text-field__suffix,
.v-input--is-disabled input {
  color: black !important;
}
.v-label {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
