<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-mail</v-icon>
          <span class="title accent--text">
            {{ title }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="onCancel">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-subtitle class="pt-4 pb-0">
        <div class="body-1">
          {{ $t("tenant.customer.labels.addressDialogDescription") }}
        </div>
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <v-form
          v-if="address !== undefined"
          ref="editAddressForm"
          autocomplete="off"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                autofocus
                v-model="address.line1"
                :label="`${$t('tenant.customer.labels.addressLine')} 1*`"
                :placeholder="$t('tenant.customer.placeholders.address')"
                :rules="[validationRules.maxLimit, validationRules.name]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="address.line2"
                :label="`${$t('tenant.customer.labels.addressLine')} 2`"
                :placeholder="$t('tenant.customer.placeholders.address')"
                :rules="[validationRules.maxLimit]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="address.line3"
                :label="`${$t('tenant.customer.labels.addressLine')} 3`"
                :placeholder="$t('tenant.customer.placeholders.address')"
                :rules="[validationRules.maxLimit]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="address.line4"
                :label="`${$t('tenant.customer.labels.addressLine')} 4`"
                :placeholder="$t('tenant.customer.placeholders.address')"
                :rules="[validationRules.maxLimit]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="address.freightContact"
                :label="`${$t('tenant.customer.labels.freightContact')}`"
                :placeholder="$t('tenant.customer.placeholders.freightContact')"
                :rules="[validationRules.maxLimit]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="pr-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="mr-2" :disabled="!valid" @click.stop="onSave">{{
          $t("common.actions.ok")
        }}</v-btn>
        <v-btn @click.stop="onCancel">
          {{ $t("common.actions.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationRules from "@/utils/validationrules"

let validationRules = null

export default {
  props: ["address", "show"],

  data() {
    return {
      valid: false,
      validationRules: validationRules
    }
  },
  watch: {
    show(value) {
      if (value && this.$refs.editAddressForm) {
        // Reset input validation errors when the dialog is shown
        this.$refs.editAddressForm.resetValidation()
      }
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    title() {
      if (!this.address?.isNew) {
        return this.$t("tenant.customer.actions.editDeliveryAddress")
      }
      return this.$t("tenant.customer.actions.addDeliveryAddress")
    }
  },
  methods: {
    async onSave() {
      if (!this.$refs.editAddressForm.validate()) {
        return
      }
      this.$emit("save", this.address)
    },
    onCancel() {
      this.$emit("cancel", {})
    }
  }
}
</script>
