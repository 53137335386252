<template>
  <div class="dpf-icon-wrapper">
    <div :class="iconClass">
      <v-icon :class="statusIconClass">
        {{ statusIcon }}
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: ["status", "size"],
  data: () => ({}),
  computed: {
    iconClass() {
      return `${this.statusColor} ${this.iconFontClass} dpf-icon-container`
    },
    iconFontClass() {
      if (this.size && this.size === "sm") {
        return "dpf-icon-sm"
      }
      return "dpf-icon-lg"
    },
    statusIcon() {
      switch (this.status) {
        case "ok":
          return "mdi-check-circle"
        case "warning":
          return "mdi-alert"
        case "error":
          return "mdi-alert-octagon"
      }
      return "mdi-help-circle-outline"
    },
    statusIconClass() {
      return `${this.statusColor} ${this.statusIconSizeClass}`
    },
    statusIconSizeClass() {
      switch (this.size) {
        case "sm":
          return "dpf-icon-status-sm"
        case "lg":
          return "dpf-icon-status-lg"
      }
      return "dpf-icon-status-lg"
    },
    statusColor() {
      switch (this.status) {
        case "ok":
          return "success--text"
        case "warning":
          return "warning--text"
        case "error":
          return "error--text"
      }
      return ""
    }
  }
}
</script>

<style>
.dpf-icon-lg::before {
  font-family: "MyBrokkDpf";
  line-height: 1;
  content: "\e900";
  font-weight: 900;
  font-size: 100px;
}
.dpf-icon-sm::before {
  font-family: "MyBrokkDpf";
  line-height: 1;
  content: "\e900";
  font-weight: 900;
  font-size: 64px;
}
.dpf-icon-wrapper {
  display: inline-block;
}
.dpf-icon-container {
  display: flex;
}
.dpf-icon-status-lg {
  position: relative;
  align-self: flex-start;
  margin-top: 0.3em;
  margin-left: -1.1em;
}
.dpf-icon-status-sm {
  position: relative;
  align-self: flex-start;
  margin-top: 0.1em;
  margin-left: -0.8em;
}
</style>
