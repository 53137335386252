<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title class="d-flex align-center">
        <v-icon class="accent--text mr-2">mdi-transfer</v-icon>
        <span class="title accent--text"
          >{{ $t("machines.dialogs.commissioning.title") }}
          {{ machine.serialNo }}
        </span>
      </v-toolbar-title>
    </v-toolbar>

    <v-stepper v-model="stepNo">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="stepNo > 1">
          {{ $t("machines.dialogs.commissioning.steps.commissioning.name") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="stepNo > 2">
          {{ $t("machines.dialogs.commissioning.steps.segment.name") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="stepNo > 3">
          {{ $t("machines.dialogs.commissioning.steps.confirm.name") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-text class="pa-0">
              <MachineCommissionStep
                :commissioningDate.sync="commissioningDate"
                :deliveryDate="order.deliveryDate"
                :show="show"
              />
            </v-card-text>
            <v-card-actions class="pr-4">
              <v-spacer></v-spacer>
              <v-btn
                :disabled="commissioningDate === null"
                @click="stepNo = 2"
                >{{ $t("common.actions.next") }}</v-btn
              >
              <v-btn @click="onCancel">{{ $t("common.actions.cancel") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card>
            <v-card-text class="pa-0">
              <MachineSegmentStep
                :segment.sync="selectedSegment"
                :category.sync="selectedCategory"
                :apps.sync="selectedApplications"
                :otherCategory.sync="otherCategory"
              />
            </v-card-text>
            <v-card-actions class="pr-4">
              <v-btn @click="stepNo = 1">{{
                $t("common.actions.previous")
              }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="stepNo = 3"
                :disabled="
                  selectedSegment === null || selectedCategory === null
                "
                >{{ $t("common.actions.next") }}</v-btn
              >
              <v-btn @click="onCancel">{{ $t("common.actions.cancel") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card>
            <v-card-text class="pa-0">
              <v-card flat>
                <v-card-subtitle class="black--text">
                  {{
                    $t(
                      "machines.dialogs.commissioning.steps.confirm.description"
                    )
                  }}
                </v-card-subtitle>
                <v-card-text class="primary--text">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        :label="$t('machines.info.order.commissioningDate')"
                        :value="commissioningDate"
                        persistent-hint
                        readonly
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :label="machineUsageLabel"
                        :value="machineUsage"
                        persistent-hint
                        readonly
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="subtitle-1 black--text">
                      {{
                        $t(
                          "machines.dialogs.commissioning.steps.confirm.proceedInfo"
                        )
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="error">
                    <v-col cols="12">
                      <v-alert
                        v-if="error"
                        :type="error.type"
                        class="mt-4 mb-4"
                        elevation="2"
                        border="left"
                        colored-border
                        dense
                      >
                        <p>{{ error.message }}</p>
                        <p v-if="error.causedBy">{{ error.causedBy }}</p>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions class="pr-4">
              <v-btn :disabled="isSaving" @click="stepNo = 2">{{
                $t("common.actions.previous")
              }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn :loading="isSaving" @click="onSaveCommissioning">{{
                $t("common.actions.save")
              }}</v-btn>
              <v-btn :disabled="isSaving" @click="onCancel">{{
                $t("common.actions.cancel")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import MachineCommissionStep from "@/components/machines/MachineCommissionStep"
import MachineSegmentStep from "@/components/machines/MachineSegmentStep"

export default {
  props: ["show", "order", "warranty", "machine"],
  components: { MachineCommissionStep, MachineSegmentStep },
  data() {
    return {
      stepNo: 0,
      error: null,

      isSaving: false,
      commissioningDate: null,
      selectedSegment: null,
      selectedCategory: null,
      selectedApplications: null,
      otherCategory: null
    }
  },
  created() {
    this.commissioningDate = null
  },
  computed: {
    machineUsage() {
      return `${this.selectedSegment?.name} / ${this.selectedCategory?.name}`
    },
    machineUsageLabel() {
      return `${this.$t(
        "machines.dialogs.commissioning.steps.segment.name"
      )} / ${this.$t("machines.dialogs.commissioning.steps.category.name")}`
    }
  },
  watch: {
    show(value) {
      if (value) {
        this.stepNo = 1
        this.error = null
        this.commissioningDate = new Date().toISOString().substring(0, 10)
        this.selectedSegment = null
        this.selectedCategory = null
        this.selectedApplications = null
        this.otherCategory = null
        this.showSnackbar = false
      }
    }
  },
  methods: {
    async onSaveCommissioning() {
      this.isSaving = true
      this.error = null

      const req = {
        machine: this.machine,
        commissioning: {
          commissioningDate: this.commissioningDate,
          segmentId: this.selectedSegment.id,
          categoryId: this.selectedCategory.id,
          applications: this.selectedApplications,
          otherCategory: this.otherCategory
        }
      }

      try {
        await this.$store.dispatch("commissionMachine", req)
        this.$emit("save", {})
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.isSaving = false
      }
    },
    onCancel() {
      this.error = null
      this.$emit("cancel", {})
    }
  }
}
</script>
