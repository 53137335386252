<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-home-city</v-icon>
          <span class="title accent--text">{{ title }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="onCancel">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-1 pb-0">
        <v-form ref="tenantForm" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="tenant.name"
                  required
                  :label="`${$t('tenant.dialogs.edit.name')}*`"
                  :disabled="isUpdating"
                  :placeholder="$t('tenant.dialogs.edit.namePlaceholder')"
                  :rules="[validationRules.name]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="tenant.description"
                  :label="$t('tenant.dialogs.edit.description')"
                  :disabled="isUpdating"
                  :placeholder="
                    $t('tenant.dialogs.edit.descriptionPlaceholder')
                  "
                  :rules="[validationRules.maxLimit]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-autocomplete
                  v-model="tenant.country"
                  :label="$t('tenant.dialogs.edit.country')"
                  :disabled="isUpdating"
                  :placeholder="$t('tenant.dialogs.edit.countryPlaceholder')"
                  :rules="[validationRules.optional]"
                  prepend-icon="mdi-map-marker"
                  :items="countries"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model="tenant.city"
                  :label="$t('tenant.dialogs.edit.city')"
                  :disabled="isUpdating"
                  :placeholder="$t('tenant.dialogs.edit.cityPlaceholder')"
                  :rules="[validationRules.optional]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-checkbox
                  :disabled="isUpdating"
                  v-model="enableTenantNotification"
                >
                  <template v-slot:label>
                    <div>
                      {{
                        $t("tenant.dialogs.edit.sendMaintanenceNotifications")
                      }}
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="enableTenantNotification">
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="tenant.notificationEmail"
                  :rules="[validationRules.email, emailCheck]"
                  :label="$t('tenant.dialogs.edit.enterEmailAddress')"
                  :disabled="isUpdating || !enableTenantNotification"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="enableTenantNotification">
              <v-col cols="12">
                <v-select
                  :items="maintenanceThresholds"
                  v-model="tenant.notificationThreshold"
                  :label="
                    $t('tenant.dialogs.edit.engineHoursBeforeNextService')
                  "
                  :disabled="isUpdating || !enableTenantNotification"
                  :rules="[thresholdCheck]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-alert
                  v-if="error"
                  :type="error.type"
                  class="ma-0"
                  elevation="2"
                  border="left"
                  colored-border
                  dense
                  dismissible
                >
                  <div>{{ error.message }}</div>
                  <div v-if="error.causedBy" class="subtitle">
                    {{ error.causedBy }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pr-6 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2 mb-2"
          :disabled="!valid || isUpdating"
          :loading="isUpdating"
          @click.stop="onSave"
          >{{ $t("common.actions.save") }}</v-btn
        >
        <v-btn
          class="mb-2 grab-cancel-edit-tenant"
          :disabled="isUpdating"
          @click.stop="onCancel"
          >{{ $t("common.actions.cancel") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import fetchClient from "@/utils/fetchClient"
import countryList from "country-list"

let validationRules = null

export default {
  name: "EditTenantDialog",
  props: ["parent", "tenant", "show"],

  data() {
    return {
      valid: false,
      validationRules: validationRules,
      isUpdating: false,
      error: null,
      enableTenantNotification: false,
      emailCheck: value => {
        return (
          !this.enableTenantNotification ||
          (value !== undefined && value !== null && value.length > 0)
        )
      },
      thresholdCheck: value => {
        return (
          !this.enableTenantNotification ||
          (value !== undefined && value !== null)
        )
      }
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    isEditing() {
      return this.tenant.uid !== undefined
    },
    title() {
      return this.isEditing
        ? this.$t("tenant.dialogs.edit.editTitle")
        : this.$t("tenant.dialogs.edit.addTitle")
    },
    countries() {
      return countryList.getNames()
    },
    maintenanceThresholds() {
      return this.$store.getters.maintenanceThresholds
    }
  },
  watch: {
    show(value) {
      if (
        this.tenant.notificationThreshold !== undefined &&
        this.tenant.notificationThreshold !== null
      ) {
        this.enableTenantNotification = true
      } else {
        // Set a default threshold
        this.tenant.notificationThreshold = 20
        this.enableTenantNotification = false
      }

      if (value && this.$refs.tenantForm) {
        // Reset input validation errors when the dialog is shown
        this.$refs.tenantForm.resetValidation()
      }
    }
  },
  methods: {
    async onSave() {
      if (!this.$refs.tenantForm.validate()) {
        return
      }

      // Default if no threshold is set is to remove it.
      let threshold = -1

      if (
        this.tenant.notificationThreshold !== undefined &&
        this.tenant.notificationThreshold !== null &&
        this.enableTenantNotification
      ) {
        threshold = this.tenant.notificationThreshold
      }

      const country = this.tenant.country || ""
      const countryCode = countryList.getCode(country) || ""

      const item = {
        name: this.tenant.name,
        description: this.tenant.description,
        country,
        countryCode,
        city: this.tenant.city,
        notificationThreshold: threshold,
        notificationEmail: this.tenant.notificationEmail
      }

      if (threshold === -1) {
        // Don't care about notification email when threshold is disabled.
        delete item.notificationEmail
      }

      let url = ""

      if (this.isEditing) {
        url = `/api/v1/tenants/${this.tenant.uid}`
      } else {
        url = "/api/v1/tenants"

        if (threshold === -1) {
          // Don't include notification if no threshold was set.
          delete item.notificationThreshold
          delete item.notificationEmail
        }
        const maxLevelNumberChild = this.parent.children?.reduce((prev, next) =>
          prev.level > next.level ? prev : next
        )

        const maxLevelNumber = maxLevelNumberChild
          ? Number(
              maxLevelNumberChild.level.replace(this.parent.level + ",", "")
            )
          : 0

        const childNo = String(Number(maxLevelNumber + 1))

        // Zero-pad the level, e.g. '012' instead of '12'
        item.level = this.parent.level + "," + childNo.padStart(3, "0")

        item.parentUid = this.parent.uid
        item.depth = this.parent.depth + 1
      }

      this.error = null

      this.isUpdating = true

      try {
        if (this.isEditing) {
          await fetchClient(this.$i18n).putOrThrow(url, item)
        } else {
          await fetchClient(this.$i18n).postOrThrow(url, item)
        }
        this.$emit("save", { wasAdded: !this.isEditing })
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    },
    onCancel() {
      this.error = null
      this.$emit("cancel", {})
    }
  }
}
</script>
