const isToday = someDate => {
  const today = new Date()
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  )
}

export default value => {
  if (value !== undefined && value != null) {
    const inputDate = new Date(value)

    if (isToday(inputDate)) {
      return `today ${inputDate.toLocaleTimeString()}`
    }

    // Check if the date is yesterday.
    // It'll become today if we add 1 day to it
    const nextDay = new Date(value)
    nextDay.setDate(inputDate.getDate() + 1)

    if (isToday(nextDay)) {
      return `yesterday ${inputDate.toLocaleTimeString()}`
    }

    return inputDate.toLocaleString()
  }
  return "-"
}
