<template>
  <v-card rounded="0" class="ma-4 pa-0 grey lighten-2">
    <v-row class="ma-0 pa-0 d-none d-md-flex">
      <v-col cols="6">
        <v-row>
          <v-col>
            <div class="font-weight-bold text-h5 ml-2 mb-2">
              {{ $t("messages.title") }}
            </div>
            <v-divider class="mx-2"></v-divider>
          </v-col>
        </v-row>
        <v-row v-for="(msg, index) in messages" :key="index">
          <v-col class="ma-0 pa-0 px-4">
            <Message
              :message="msg"
              :isRead="hasMessageBeenRead(msg)"
              :isSelected="msg.id === selectedId"
              @msgClicked="onMessageClicked"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="white">
        <MessageDetails :message="selectedMsg" />
      </v-col>
    </v-row>
    <v-container
      fluid
      class="d-flex d-md-none ml-6 font-weight-bold text-h5 text-left"
      >{{ $t("messages.title") }}</v-container
    >
    <v-expansion-panels class="d-flex d-md-none">
      <v-expansion-panel v-for="(msg, index) in messages" :key="index">
        <MessageHeader
          :message="msg"
          :isRead="hasMessageBeenRead(msg)"
          :isSelected="msg.id === selectedId"
          @msgClicked="onMessageClicked"
        />
        <v-expansion-panel-content>
          <div class="ml-6 mt-3 black--text" v-html="msg.description"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapActions } from "vuex"
import fetchClient from "@/utils/fetchClient"
import Message from "@/views/messages/Message"
import MessageHeader from "@/views/messages/MessageHeader"
import MessageDetails from "@/views/messages/MessageDetails"

export default {
  name: "MessagesView",
  components: { Message, MessageDetails, MessageHeader },
  data() {
    return {
      selectedId: 0, // Determines row selection in the master pane. Not set until user clicks an item.
      selectedMsg: {},
      readMessageIDs: new Set() // Distinct set of read message Ids
    }
  },
  async created() {
    if (this.messages && this.messages.length > 0) {
      this.selectedMsg = this.messages[0]
      await this.fetchReadMessages()
      this.checkUnreadMessages()
    }
  },
  methods: {
    ...mapActions(["setError"]),

    async fetchReadMessages() {
      const loggedInUser = this.$store.getters.loggedInUser

      try {
        await this.$store.dispatch("fetchUserDetails", {
          tenantUid: loggedInUser.tenantUid,
          uid: loggedInUser.uid
        })
        const res = this.$store.getters.userDetails

        if (res && res.notifications && res.notifications.readMessages) {
          this.readMessageIDs = new Set()
          res.notifications.readMessages.forEach(id => {
            this.readMessageIDs.add(id)
          })
        }
      } catch (error) {
        this.setError(error)
      }
    },
    hasMessageBeenRead(msg) {
      return this.readMessageIDs.has(parseInt(msg.id, 10))
    },
    checkUnreadMessages() {
      const allMessages = this.$store.getters.messages

      let numUnread = 0

      allMessages.forEach(msg => {
        const isRead = this.hasMessageBeenRead(msg)
        if (!isRead) {
          numUnread += 1
        }
      })

      this.$emit("unreadMessageCount", numUnread)
    },
    onMessageClicked(msg) {
      const id = parseInt(msg.id, 10)

      if (!this.readMessageIDs.has(id)) {
        this.readMessageIDs.add(id)
        this.saveReadMessages()
      }

      // Reset any previous selection.
      this.messages.forEach(mess => {
        mess.isSelected = false
      })
      this.selectedMsg = msg
      this.selectedMsg.isSelected = true
      this.selectedId = msg.id

      this.checkUnreadMessages()
    },
    async saveReadMessages() {
      const loggedInUser = this.$store.getters.loggedInUser
      const url = `/api/v1/tenants/${loggedInUser.tenantUid}/users/${loggedInUser.uid}/messages`

      try {
        await fetchClient(this.$i18n).putOrThrow(url, {
          readMessages: Array.from(this.readMessageIDs)
        })
      } catch (error) {
        this.setError(error)
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.loggedInUser
    },
    messages() {
      return this.$store.getters.messages
    }
  },
  watch: {
    async messages() {
      if (this.messages && this.messages.length > 0) {
        this.selectedMsg = this.messages[0]
        await this.fetchReadMessages()
        this.checkUnreadMessages()
      }
    }
  }
}
</script>

<style type="scss"></style>
