<template>
  <v-dialog v-model="visible" max-width="400" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-delete</v-icon>
          <span class="title accent--text"
            >{{$t("common.dialogs.confirmRemove.title", [ itemName ])}}</span
          ></v-toolbar-title
        >
        <v-spacer />
        <v-btn icon @click="onCancel">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-6">
        <p class="subtitle-1 accent--text">
          {{$t("common.dialogs.confirmRemove.confirm", [itemName])}}
        </p>
        <p class="caption accent--text">{{$t("common.dialogs.confirmRemove.note")}}</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="confirmRemoveEvent !== undefined"
          class="mr-2 mb-2"
          @click.stop="
            $emit(confirmRemoveEvent)
            visible = false
          "
          >{{$t("common.actions.yes")}}</v-btn
        >
        <v-btn v-else class="mr-2 mb-2" @click.stop="onRemove">{{$t("common.actions.yes")}}</v-btn>
        <v-btn class="mr-2 mb-2" @click.stop="onCancel">{{$t("common.actions.no")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["itemName", "item", "removeAction", "confirmRemoveEvent", "show"],
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(value) {
        if (!value) {
          this.$emit("close")
        }
      }
    }
  },
  methods: {
    onRemove() {
      this.$store.dispatch(this.removeAction, this.item)
      this.visible = false
    },
    onCancel() {
      this.visible = false
    }
  }
}
</script>
