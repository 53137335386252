<template>
  <v-card flat outlined>
    <v-card-title v-if="this.supported">{{
      $t("machines.maintenance.service.reset.title")
    }}</v-card-title>

    <v-card-subtitle>
      <v-container v-if="!this.supported || !this.userCanServiceReset">
        <v-overlay absolute color="#fff" opacity=".9" class="text--primary">
          <v-row>
            <v-col cols="10" offset="1">
              <div class="text-center">
                <v-icon color="grey darken-3">mdi-key-variant</v-icon>
              </div>
              <h1 class="title d-flex mb-5" v-if="!this.userCanServiceReset">
                <v-icon color="primary" class="mr-2"
                  >mdi-information-outline</v-icon
                >
                {{
                  $t(
                    "machines.maintenance.service.reset.availableForServiceUsersOnly"
                  )
                }}
              </h1>
              <div v-else-if="!this.supported">
                <h1 class="title d-flex mb-5">
                  <v-icon color="primary" class="mr-2"
                    >mdi-information-outline</v-icon
                  >
                  {{ $t("machines.maintenance.service.reset.disabled") }}
                </h1>
                <p class="pl-8">
                  {{ $t("machines.messages.contactToLearnMore") }}
                  <a :href="mailToLink">{{ brokkEmail }}</a>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-overlay>
      </v-container>

      <p>
        {{ $t("machines.maintenance.service.reset.description") }}
      </p>

      <v-btn v-if="checkError" class="mb-4" small @click="checkAvailability">
        {{ $t("common.actions.retry") }}
      </v-btn>

      <v-textarea
        class="mb-4"
        :label="$t('machines.maintenance.service.reset.comment')"
        :placeholder="
          $t('machines.maintenance.service.reset.commentPlaceholder')
        "
        :hint="$t('machines.maintenance.service.reset.commentHint')"
        :disabled="disabled"
        v-model="comment"
        rows="2"
        auto-grow
        persistent-hint
      />

      <v-btn
        class="pa-4"
        :disabled="disabled"
        :loading="loading"
        @click.stop="onReset"
      >
        <v-icon left>mdi-progress-upload</v-icon>
        {{ $t("machines.maintenance.service.reset.actions.send") }}
      </v-btn>

      <v-alert
        v-if="error"
        :type="error.type"
        class="mt-4 mb-4"
        elevation="2"
        border="left"
        colored-border
        dense
      >
        {{ error.message }}
      </v-alert>
    </v-card-subtitle>

    <ServiceResetConfirmDialog ref="confirmDialog" />
    <ServiceResetSuccessDialog ref="successDialog" />
  </v-card>
</template>

<script>
import fetchClient from "@/utils/fetchClient"
import ServiceResetConfirmDialog from "@/dialogs/ServiceResetConfirmDialog"
import ServiceResetSuccessDialog from "@/dialogs/ServiceResetSuccessDialog"

export default {
  components: { ServiceResetSuccessDialog, ServiceResetConfirmDialog },
  props: ["machine"],

  data: () => ({
    loading: true,
    supported: false,
    waitingForYearly: false,
    available: true,
    online: true,
    checkError: null,
    resetError: null,
    comment: ""
  }),
  computed: {
    brokkEmail() {
      return this.$store.getters.brokkEmail
    },
    mailToLink() {
      return `mailto:${this.brokkEmail}?subject=Brokk Connect: Service reset for ${this.machine.productName} with serial number ${this.machine.serialNo}`
    },
    disabled() {
      return (
        this.loading ||
        !this.supported ||
        !this.online ||
        !this.available ||
        this.checkError
      )
    },
    userCanServiceReset() {
      return this.$store.getters.isService || this.$store.getters.isAdmin
    },
    error() {
      if (this.loading) {
        return null
      }

      let type = "warning"
      let message = null

      if (!this.supported) {
        message = this.$i18n.t("machines.maintenance.service.reset.disabled")
      } else if (this.waitingForYearly) {
        type = "info"
        message = this.$t(
          "machines.maintenance.service.reset.errors.waitingForYearly"
        )
      } else if (!this.available) {
        type = "info"
        message = this.$i18n.t(
          "machines.maintenance.service.reset.errors.notAvailable"
        )
      } else if (!this.online) {
        message = this.$t("machines.maintenance.service.reset.errors.offline")
      } else if (this.resetError) {
        type = "error"
        message = this.resetError
      } else if (this.checkError) {
        type = "error"
        message = this.checkError
      }

      if (message != null) {
        return { type, message }
      }

      return null
    }
  },

  mounted() {
    this.checkAvailability()
  },

  methods: {
    async checkAvailability() {
      this.checkError = null
      this.loading = true

      try {
        const data = await this.callApi()

        this.online = data.isOnline
        this.supported = data.serviceResetSupported
        this.available = data.serviceResetAvailable
        this.waitingForYearly = data.serviceResetWaitingForYearly
        this.comment = data.comment

        if (data.isResetting) {
          await this.waitForReset(data.resetId)
        }
      } catch (error) {
        this.checkError = error.toString()
      }

      this.loading = false
    },

    async onReset() {
      const confirm = await this.$refs.confirmDialog.open(this.machine.serialNo)
      if (!confirm) {
        return
      }

      this.resetError = null
      this.loading = true

      try {
        const data = await this.callApi("POST", null, {
          comment: this.comment
        })
        await this.waitForReset(data.resetId)
      } catch (error) {
        this.resetError = error.toString()
      }

      this.loading = false
    },

    async waitForReset(resetId) {
      try {
        await this.callApi("GET", resetId)
        await this.$refs.successDialog.open(this.machine.serialNo)
        this.comment = ""
        this.$emit("reset")
      } catch (error) {
        this.resetError = error.toString()
      }
    },

    async callApi(method = "GET", resetId = null, data = null) {
      let url = `/api/v1/tenants/${this.machine.tenantUid}/machines/${this.machine.serialNo}/maintenance/servicereset`
      if (resetId != null) {
        url += `/${resetId}`
      }

      let errorMessage = null
      let responseData = null

      try {
        const res = await fetchClient(this.$i18n).getResponse(method, url, data)

        if (res.status === 200) {
          const txt = await res.text()
          if (txt.length > 0) {
            responseData = JSON.parse(txt)
          }
        } else {
          const error = await fetchClient(this.$i18n).getError(res)
          errorMessage = error.toString()
        }
      } catch (error) {
        errorMessage = error.toString()
      }

      if (errorMessage !== null) {
        throw new Error(errorMessage)
      }

      return responseData
    }
  }
}
</script>
