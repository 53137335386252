import Formatter from "@/utils/formatter"

export default (value, filterFunction) => {
  if (value !== undefined && value != null) {
    if (filterFunction !== undefined && filterFunction !== null) {
      switch (filterFunction) {
        case "toInteger":
          return Number(value).toFixed(0)
        case "hoursMinutesSeconds":
          return Formatter().toHoursMinutesSeconds(value)
        default:
          return "Invalid usage"
      }
    }
    return value
  }
  return "-"
}
