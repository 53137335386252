<template>
  <v-container fluid v-if="machineDashboard">
    <v-row>
      <v-col cols="12" sm="6" lg="3" v-if="this.canReadMeasurements">
        <ValueWidget
          :title="$t('machines.labels.engineHours')"
          :valuesObject="machineEngineHoursSummary"
          :valuesObjectSelectionList="machineEngineHoursSummarySelectionList"
          @lazyLoad="fetchEngineHoursSummary"
          :valueFilter="engineHourFilterInner"
          :loading="loadingMachineEngineHoursSummary"
          :text="
            formatLastUpdated(machineDashboard.timing.engineTime.lastUpdated)
          "
          backgroundIcon="mdi-engine"
          :backgroundIconColor="engineRunningColor"
          actionIcon="mdi-chart-line"
          actionPath="measurements"
          actionDetailsLink="engine-hours"
          disable="true"
          :hideActions="machine.unprovisioned"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="6" v-if="this.canReadMaintenance">
        <NextServiceWidget
          :upcoming="machineDashboard.maintenance.upcoming"
          action-link="maintenance"
          action-icon="mdi-tools"
          :disabled="machine.unprovisioned"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
        v-if="this.canReadMeasurements && !this.hasDieselEngine"
      >
        <SolidGauge
          v-if="machineDashboard.temp.engine"
          :title="$t('machines.labels.elMotorTemp')"
          :text="formatLastUpdated(machineDashboard.temp.engine.lastUpdated)"
          :value="
            machineDashboard.temp.engine.currentVal | dataFilter('toInteger')
          "
          :valueAvg="
            machineDashboard.temp.engine.averageVal | dataFilter('toInteger')
          "
          :valueMax="
            machineDashboard.temp.engine.maxVal | dataFilter('toInteger')
          "
          gaugeMin="0"
          gaugeMax="205"
          :metrics="temperatureName"
          :metricsLabel="temperatureSymbol"
          actionIcon="mdi-chart-line"
          actionPath="measurements"
          actionDetailsLink="temperatures"
          :disabled="machine.unprovisioned"
        />

        <!-- <SolidGauge
          v-else
          :title="$t('machines.labels.elMotorTemp')"
        /> -->
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <ValueWidget
          :title="$t('machines.labels.activeAlarms')"
          :value="activeAlarms.length"
          :valueColor="activeAlarms.length > 0 ? 'accent--text' : 'white--text'"
          :text="lastAlarmReset"
          :backgroundIcon="
            activeAlarms.length > 0 ? 'mdi-bell-ring' : 'mdi-bell'
          "
          :backgroundIconColor="alarmColor"
          actionIcon="mdi-bell-ring-outline"
          actionPath="alarms"
          actionDetailsLink=""
          :disabled="machine.unprovisioned"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="6" v-if="this.canReadMeasurements">
        <Pie
          :title="$t('machines.labels.machineActivity')"
          :metricsData="activityData"
          :text="
            machineDashboard.activities.lastUpdated
              | timeFilter('daysFromNow', $i18n)
          "
          :textTooltip="
            machineDashboard.activities.lastUpdated
              | timeFilter('localeString', $i18n)
          "
          :disabled="machine.unprovisioned"
          formatHours
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
        v-if="this.canReadMeasurements && this.hasDieselEngine"
      >
        <SolidGauge
          v-if="dieselFuel"
          :title="$t('machines.labels.fuelLevel')"
          :text="formatLastUpdated(dieselFuel.lastUpdated)"
          :value="
            ((dieselFuel.currentLevel / dieselFuel.maxLevel) * 100)
              | dataFilter('toInteger')
          "
          :gaugeMin="0"
          :gaugeMax="100"
          metricsLabel="%"
          :invertedColorLimits="true"
          :disabled="machine.unprovisioned"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3" v-if="this.canReadMeasurements">
        <SolidGauge
          v-if="machineDashboard.temp.hydraulics"
          :title="$t('machines.labels.hydraulicFluidTemp')"
          :text="
            formatLastUpdated(machineDashboard.temp.hydraulics.lastUpdated)
          "
          :value="
            machineDashboard.temp.hydraulics.currentVal
              | temperatureFilter(this.units)
              | dataFilter('toInteger')
          "
          :valueAvg="
            machineDashboard.temp.hydraulics.averageVal
              | temperatureFilter(this.units)
              | dataFilter('toInteger')
          "
          :valueMax="
            machineDashboard.temp.hydraulics.maxVal
              | temperatureFilter(this.units)
              | dataFilter('toInteger')
          "
          gaugeMin="0"
          gaugeMax="100"
          :metrics="temperatureName"
          :metricsLabel="temperatureSymbol"
          actionIcon="mdi-chart-line"
          actionPath="measurements"
          actionDetailsLink="temperatures"
          :disabled="machine.unprovisioned"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3" v-if="this.canReadHighTime">
        <ValueWidget
          :title="$t('machines.labels.highPressureTime')"
          :value="
            machineDashboard.timing.toolHighPressureTime.data | engineHourFilter
          "
          :text="
            formatLastUpdated(
              machineDashboard.timing.toolHighPressureTime.lastUpdated
            )
          "
          backgroundIcon="mdi-hammer-wrench"
          actionIcon="mdi-chart-line"
          actionPath="measurements"
          actionDetailsLink="high-pressure-time"
          :disabled="machine.unprovisioned"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3" v-if="this.canReadHighTime">
        <ValueWidget
          :title="$t('machines.labels.highTempTime')"
          :value="
            machineDashboard.timing.hydraulicsHighTempTime.data
              | engineHourFilter
          "
          :text="
            formatLastUpdated(
              machineDashboard.timing.hydraulicsHighTempTime.lastUpdated
            )
          "
          backgroundIcon="mdi-thermometer"
          actionIcon="mdi-chart-line"
          actionPath="measurements"
          actionDetailsLink="high-temperature-time"
          :disabled="machine.unprovisioned"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3" v-if="dieselFilterStatus">
        <DieselParticulateFilterWidget :dpfStatus="dieselFilterStatus" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"

import formatter from "@/utils/formatter"
import temperatureUtils from "@/utils/temperature"
import ValueWidget from "@/components/widgets/ValueWidget"

import SolidGauge from "@/components/widgets/SolidGauge"
import Pie from "@/components/widgets/Pie"

import localeDateFilter from "@/filters/localedatefilter"
import engineHourFilter from "@/filters/enginehourfilter"
import NextServiceWidget from "@/components/widgets/NextServiceWidget"
import DieselParticulateFilterWidget from "@/components/widgets/DieselParticulateFilterWidget"

let Formatter = null

export default {
  name: "MachineDashboard",
  props: ["machine"],
  components: {
    NextServiceWidget,
    ValueWidget,
    SolidGauge,
    Pie,
    DieselParticulateFilterWidget
  },
  data() {
    return { Formatter: Formatter }
  },
  beforeCreate() {
    Formatter = formatter(this.$i18n)
  },
  created() {
    this.fetchDashboardData()
  },
  computed: {
    ...mapGetters([
      "canReadMaintenance",
      "canReadMeasurements",
      "machineDashboard",
      "machineEngineHoursSummary",
      "loadingMachineEngineHoursSummary"
    ]),

    temperatureSymbol() {
      return temperatureUtils.getSymbol(this.units)
    },
    temperatureName() {
      return temperatureUtils.getName(this.units)
    },
    units() {
      return this.$store.getters.userDetails?.preferences?.units || "metric"
    },
    isRunning() {
      return this.machine !== null && this.machine.status === "running"
    },
    engineRunningColor() {
      if (this.isRunning) {
        return "success--text" // Pre-defined material design color (green-ish)
      }
      return null
    },
    lastAlarmReset() {
      if (this.machineDashboard.timing.lastAlarmResetTime.data > 0) {
        return `Last reset at ${engineHourFilter(
          this.machineDashboard.timing.lastAlarmResetTime.data
        )}`
      }
      return ""
    },
    alarmColor() {
      let alarmColor = "success--text"

      if (this.activeAlarms.length > 0) {
        alarmColor = "warning--text"
      }
      if (this.activeAlarms.length > 3) {
        alarmColor = "error--text"
      }
      return alarmColor
    },
    canReadHighTime() {
      return false
    },
    activeAlarms() {
      return this.machine.activeEvents
    },
    hasDieselEngine() {
      return this.machine.hasDieselEngine
    },
    hasParticulateFilter() {
      return this.machineDashboard.diesel?.filter
    },
    activeAlarmsLastRefresh() {
      if (this.machine.activeEvents[0] !== undefined) {
        return `${Formatter.formatDaysFromNow(
          this.machine.activeEvents[this.machine.activeEvents.length - 1]
            .receiveTime
        )}`
      }
      return this.lastSeenDays
    },
    lastSeenDays() {
      return Formatter.formatDays(0)
    },
    activityData() {
      const hasData =
        this.machineDashboard.activities.otherTime > 0 ||
        this.machineDashboard.activities.toolActiveTime > 0 ||
        this.machineDashboard.activities.beltActiveTime > 0

      let dataSet = []

      if (hasData) {
        dataSet = [
          this.machineDashboard.activities.otherTime,
          this.machineDashboard.activities.toolActiveTime,
          this.machineDashboard.activities.beltActiveTime
        ]
      }

      const metricsData = {
        labels: [
          this.$t("machines.activities.otherTime"),
          this.$t("machines.activities.toolActiveTime"),
          this.$t("machines.activities.beltActiveTime")
        ],
        dataSet: dataSet
      }

      return metricsData
    },
    dieselFilterStatus() {
      // Diesel Particulate Filter
      if (this.hasParticulateFilter) {
        return {
          status: `${this.machineDashboard.diesel.filter.status}`,
          ashLevel: `${this.machineDashboard.diesel.filter.ashLevel} %`,
          sootLevel: `${this.machineDashboard.diesel.filter.sootLevel} %`
        }
      }
      return null
    },
    dieselFuel() {
      if (this.machineDashboard.diesel?.fuel) {
        return this.machineDashboard.diesel.fuel
      }
      return null
    },
    machineEngineHoursSummarySelectionList() {
      return [
        {
          value: "total",
          text: this.$t("machines.engineHoursSelectionList.total")
        },
        {
          value: "lastDay",
          text: this.$t("machines.engineHoursSelectionList.lastDay")
        },
        {
          value: "lastWeek",
          text: this.$t("machines.engineHoursSelectionList.lastWeek")
        },
        {
          value: "lastMonth",
          text: this.$t("machines.engineHoursSelectionList.lastMonth")
        }
      ]
    }
  },
  methods: {
    fetchDashboardData() {
      this.$store.dispatch("fetchMachineDashboard", this.machine)
    },
    fetchEngineHoursSummary() {
      this.$store.dispatch("fetchMachineEngineHoursSummary", this.machine)
    },
    formatLastUpdated(lastUpdated) {
      if (lastUpdated !== undefined && lastUpdated !== null) {
        return this.$t("machines.updated", {
          TIME: localeDateFilter(lastUpdated)
        })
      }
      return this.$t("$vuetify.noDataText")
    },
    engineHourFilterInner(engineHour) {
      return engineHourFilter(engineHour)
    }
  }
}
</script>
<style>
.widget-box {
  border: 2px solid #737373;
  border-radius: 5px;
  min-height: 250px;
  position: relative;
  padding-top: 24px;
  overflow: hidden;
  height: 100%;
}
.widget-box .widget-label {
  position: absolute;
  top: 0;
  width: 100%;
  background: #484848;
  color: #eee;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 25px 5px;
}
.widget-box .widget-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #666;
}
.widget-box .measurements-link {
  position: absolute;
  top: 30px;
  right: 5px;
  opacity: 0;
  z-index: 100;
}
.widget-box:hover .measurements-link {
  opacity: 1;
}
.highcharts-credits {
  display: none;
}
</style>
