export default (text, maxLength) => {
    if (text.length <= maxLength) {
        return text
    }

    const shortened = text.substring(0, maxLength)
    const trimmed = shortened.replace(/[\s.,!?&-]+$/, "")
    if (trimmed.length !== 0) {
        return `${trimmed}…`
    }

    return `${shortened}…`
}