import { render, staticRenderFns } from "./RunningReducedTime.vue?vue&type=template&id=6c058786&scoped=true"
import script from "./RunningReducedTime.vue?vue&type=script&lang=js"
export * from "./RunningReducedTime.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c058786",
  null
  
)

export default component.exports