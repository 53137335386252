<template>
  <iframe :src="helpUrl" width="100%" height="100%"></iframe>
</template>

<script>
export default {
  data() {
    return {
      html: ""
    }
  },
  async created() {},
  computed: {
    helpUrl() {
      return this.$store.getters.apiURL + "/api/v1/help"
    }
  },
  methods: {}
}
</script>

<style></style>
