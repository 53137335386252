<template>
  <v-row>
    <v-col sm="6" cols="12">
      <v-select
        :disabled="!canEdit"
        :items="timeRangeItems"
        item-value="value"
        item-text="text"
        v-model="selectedTimeRange"
        :label="$t('common.filters.timeRange')"
      ></v-select>
    </v-col>
    <v-col sm="6" cols="12">
      <v-menu
        ref="datePickerMenu"
        v-model="datePickerMenu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="dateRangeText"
            readonly
            :placeholder="$t('common.placeholders.selectDates')"
            :disabled="selectedTimeRange !== 'custom' || !canEdit"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dates" range>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="datePickerMenu = false">{{
            $t("common.actions.cancel")
          }}</v-btn>
          <v-btn
            text
            color="primary"
            :disabled="dates[0] === undefined || dates[1] === undefined"
            @click="
              $refs.datePickerMenu.save(dates)
              onSavePickedDate()
            "
            >{{ $t("common.actions.ok") }}</v-btn
          >
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["canEdit", "return-value"],
  data() {
    return {
      dates: [],
      datePickerMenu: false,
      selectedTimeRange: "today",
      dateRangeText: ""
    }
  },
  created() {
    this.setDateRange()
  },
  computed: {
    timeRangeItems() {
      return [
        { text: this.$t("common.enums.day.today"), value: "today" },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            7
          )}`,
          value: "7 days"
        },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            30
          )}`,
          value: "30 days"
        },
        { text: this.$t("common.filters.customRange"), value: "custom" }
      ]
    }
  },
  watch: {
    selectedTimeRange(newRange) {
      if (newRange !== "custom") {
        this.setDateRange()
      } else {
        // Display date picker. OK callback will trigger fetch
        this.datePickerMenu = true
      }
    },
    dates(newDates) {
      // Always set earliest date as start date
      if (newDates[0] > newDates[1]) {
        newDates.reverse()
      }
      this.dateRangeText = newDates.join(" ~ ")
      this.$emit("update:return-value", this.dates)
    }
  },
  methods: {
    setDateRange() {
      let startDate = new Date()
      let offset = 0

      if (this.selectedTimeRange === "today") {
        offset = 0
      } else if (this.selectedTimeRange === "7 days") {
        offset = 6 // Becomes 7 since today will be included
      } else if (this.selectedTimeRange === "30 days") {
        offset = 29 // Becomes 30 since today will be included
      }

      startDate.setDate(startDate.getDate() - offset)
      this.dates = [
        startDate.toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10)
      ]
    },
    onSavePickedDate() {
      this.datePickerMenu = false
      this.dateRangeText = this.dates.join(" ~ ")
    }
  }
}
</script>
<style></style>
