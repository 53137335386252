<template>
  <v-container fluid fill-height class="justify-center">
    <v-card elevation="3" outlined raised>
      <v-card-title class="grey darken-3">
        <v-row>
          <v-col cols="6" class="font-weight-bold text-h5 white--text">
            {{ $store.getters.appTitle }}
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-img
              max-height="32"
              max-width="170"
              contain
              :src="$store.getters.logoWhite"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="firstLoginForm" autocomplete="off" v-model="valid">
          <v-container>
            <v-row v-if="passwordChanged">
              <v-col cols="12" class="text-center">
                <h1 class="title mb-3">
                  Your password has been changed
                </h1>
                <a @click.stop="onSignInClick" href="/" class="signIn">
                  Sign In
                </a>
              </v-col>
            </v-row>
            <v-row v-if="!passwordChanged">
              <v-col cols="12">
                <v-text-field
                  :counter="50"
                  v-model="newpassword"
                  prepend-icon="mdi-lock"
                  type="password"
                  required
                  label="New Password*"
                  v-on:keyup.enter="handleSubmit"
                  :rules="[
                    validationRules.awsPasswordRequirements(this.newpassword)
                  ]"
                  class="mr-4"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="!passwordChanged">
              <v-col cols="12">
                <v-text-field
                  :counter="50"
                  v-model="confirmpassword"
                  prepend-icon="mdi-lock"
                  type="password"
                  required
                  label="Confirm new password*"
                  v-on:keyup.enter="handleSubmit"
                  :rules="[
                    validationRules.mustMatch(
                      this.confirmpassword,
                      this.newpassword
                    )
                  ]"
                  class="mr-4"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <p>Password strength</p>
                <password v-model="newpassword" :strength-meter-only="true" />
              </v-col>
            </v-row>
            <v-row v-if="!passwordChanged">
              <v-col cols="12" class="text-center">
                <v-btn
                  type="button"
                  :disabled="!this.valid || loading"
                  rounded
                  class="secondary accent--text"
                  min-width="150"
                  @click.stop="handleSubmit"
                  :loading="loading"
                  >Change Password</v-btn
                >
              </v-col>
              <v-col cols="12" class="text-center">
                <a @click.stop="onResendCode" href="/" class="forgotPassword">
                  Resend Code
                </a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-progress-linear
                  :active="loading"
                  :indeterminate="loading"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row v-if="error">
              <v-col cols="12">
                <app-alert
                  @dismissed="dismissAlert"
                  :errItem="error"
                ></app-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Password from "vue-password-strength-meter"
import ValidationRules from "@/utils/validationrules"
let validationRules = null

export default {
  components: {
    Password
  },
  data() {
    return {
      valid: false,
      newpassword: "",
      confirmpassword: "",
      validationRules: validationRules,
      passwordChanged: false
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    },
    error() {
      return this.$store.getters.error
    }
  },
  watch: {
    newpassword() {
      this.dismissAlert()
    },
    confirmpassword() {
      this.dismissAlert()
    }
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault()
      if (!this.$refs.firstLoginForm.validate()) {
        return
      }
      if (this.valid) {
        const responseData = await this.$store.dispatch("setNewPassword", {
          newpassword: this.newpassword,
          username: decodeURIComponent(this.$route.params.username),
          code: decodeURIComponent(this.$route.params.code)
        })
        if (responseData) {
          this.passwordChanged = true
        }
      }
    },
    onSignInClick(e) {
      e.preventDefault()
      this.$router.push("/login").catch()
    },
    onResendCode(e) {
      e.preventDefault()
      this.$router.push("/forgot_password").catch()
    },
    dismissAlert() {
      this.$store.dispatch("clearError")
    }
  }
}
</script>

<style scoped></style>
