const temperatureUnit = {
  celcius: { symbol: "°C", code: "dgr C", name: "celcius" },
  fahrenheit: { symbol: "°F", code: "dgr F", name: "fahrenheit" },
}

function getTemperatureUnitProperty(propertyName, units) {
  switch(units){
    case "imperial":
      return temperatureUnit.fahrenheit[propertyName]
    case "metric":
        return temperatureUnit.celcius[propertyName]
    default:
      return temperatureUnit.celcius[propertyName]
  }
}
const celciusToFahrenheit = (value) => value * 1.8000 + 32.00
const temperatureUtils = {
  getSymbol: (units) => getTemperatureUnitProperty("symbol", units),
  getCode: (units) => getTemperatureUnitProperty("code", units),
  getName: (units) => getTemperatureUnitProperty("name", units),
  convertFromCelcius(value, units){
    switch(units){
      case "imperial":
        return celciusToFahrenheit(value)
      case "metric":
          return value
      default:
        return value
    }
  }
}
Object.freeze(temperatureUtils)

export default temperatureUtils
