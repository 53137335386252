<template>
  <v-card tile>
    <v-card-text>
      <v-row>
        <v-col cols="auto" class="d-flex align-center">
          <v-btn
            outlined
            small
            text
            class="tenants-btn"
            @click.stop="onShowTenants()"
          >
            <v-icon left>mdi-home-city</v-icon>
            {{ showHideText }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-breadcrumbs
            class="pt-2 pl-0 pb-0"
            :items="breadCrumItems"
          ></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showTenants: false
    }
  },
  computed: {
    showHideText() {
      if (this.showTenants) {
        return `${this.$t("common.actions.hide")} ${this.$t(
          "tenant.menuItems.tenants"
        )}`
      }
      return `${this.$t("common.actions.show")} ${this.$t(
        "tenant.menuItems.tenants"
      )}`
    },
    breadCrumItems() {
      let items = []

      if (this.selectedTenant !== null) {
        const tenants = this.$store.getters.tenantArray

        items.push({ text: this.selectedTenant.name })

        let parent = tenants.find(element => {
          return element.uid === this.selectedTenant.parentUid
        })

        // Counter to stop at certain random depth
        let cn = 0
        while (cn < 10 && parent !== undefined) {
          items.push({ text: parent.name })

          if (parent.parentUid === "0") {
            // Reached root node, no more parents
            break
          }
          parent = tenants.find(element => {
            return element.uid === parent.parentUid
          })
          cn += 1
        }

        items.reverse()

        const ix = this.$route.path.lastIndexOf("/")

        if (ix > 0) {
          let subViewName = this.$route.path.substring(ix + 1)
          if (subViewName.length > 0) {
            subViewName = subViewName.substring(0, 1) + subViewName.substring(1)

            // Check for digits, 70, 110, 120D
            var translationKey
            const digits = subViewName.match(/\d+/g)

            if (digits != null) {
              translationKey =
                this.$t("tenant.menuItems.manualsProductId") + subViewName
            } else {
              translationKey = "tenant.menuItems." + subViewName.toLowerCase()
            }

            items.push({ text: this.$t(translationKey) })
          }
        }
      }

      return items
    },
    selectedTenant() {
      return this.$store.getters.selectedTenant
    }
  },
  methods: {
    expandedLink(tenantUid, link) {
      return `/tenants/${tenantUid}/${link}`
    },
    onShowTenants() {
      this.showTenants = !this.showTenants
      this.$emit("showTenants", this.showTenants)
    }
  }
}
</script>
<style>
.tenant-home-toolbar .v-toolbar__content {
  padding-left: 0;
  padding-right: 0;
}
.tenants-btn {
  opacity: 1;
}
.tenants-btn.hidden {
  opacity: 0;
}
</style>
