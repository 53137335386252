<template>
  <v-container fluid pt-0 pt-md-3>
    <v-card class="mx-auto">
      <v-row no-gutters>
        <v-col sm="3" lg="2" cols="12" class="border-right user-column-left">
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn
                @click.stop="onHomeClicked"
                absolute
                top
                text
                class="user-back-button"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card flat>
                <v-card-title class="justify-center">
                  <v-avatar color="secondary" size="128">
                    <v-icon color="black" class="customer-avatar-icon">
                      mdi-home-city
                    </v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-subtitle>
                  <div class="text-center overline mb-0">
                    {{ $t("tenant.itemName") }}
                  </div>
                  <div class="text-center text-h6 primary--text">
                    {{ tenant.name }}
                  </div>
                </v-card-subtitle>
              </v-card>
              <v-card-text class="pa-0">
                <v-divider></v-divider>
                <v-list class="pa-0 user-view-list-nav">
                  <v-list-item
                    v-for="item in menuItems"
                    :key="item.title"
                    router
                    dense
                    active-class="secondary user-view-active-item"
                    :to="`${item.link}`"
                  >
                    <v-list-item-icon class="mr-5">
                      <v-badge
                        v-if="item.badge"
                        v-bind="item.badge"
                        bordered
                        overlap
                      >
                        <v-icon color="accent">{{ item.icon }}</v-icon>
                      </v-badge>
                      <v-icon v-else color="accent">{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text-capitalize">{{
                        item.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>

        <v-col sm="9" lg="10" cols="12">
          <router-view> </router-view>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false
    }
  },
  computed: {
    tenant() {
      return this.$store.getters.tenant
    },
    menuItems() {
      let items = []

      items.push({
        icon: "mdi-card-bulleted",
        title: this.$t("tenant.itemName"),
        link: "profile"
      })
      items.push({
        icon: "mdi-home-account",
        title: this.$t("tenant.customer.title"),
        link: "customer"
      })
      items.push({
        icon: "mdi-card-bulleted",
        title: this.$t("tenant.contacts.title"),
        link: "contacts"
      })

      return items
    }
  },
  mounted() {
    this.$store.dispatch("fetchTenant", {
      tenantUid: this.$route.params.tenantUid
    })
  },
  methods: {
    onHomeClicked() {
      window.location = "/"
    }
  }
}
</script>

<style type="scss">
.customer-avatar-icon::before {
  font-size: 5rem;
}
</style>
