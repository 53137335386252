const pressureUtils = {
  pressureSymbol: {
    psi: "PSI",
    bar: "BAR",
  },
  barToPsi: (value) => value * 14.5038,
  pascalToPsi: (value) => value * 0.00014503773,
  getCode: (units) => pressureUtils.getSymbol(units).toLowerCase(),
  getSymbol: (units) => {
    switch(units){
      case "imperial":
        return pressureUtils.pressureSymbol.psi
      case "metric":
          return pressureUtils.pressureSymbol.bar
      default:
        return pressureUtils.pressureSymbol.bar
    }
  },
  convertFromBar(value, units){
    switch(units){
      case "imperial":
        return pressureUtils.barToPsi(value)
      case "metric":
          return value
      default:
        return value
    }
  }
}
Object.freeze(pressureUtils)

export default pressureUtils
