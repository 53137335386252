const links = new Map()
links.set("us", "https://www.brokk.com/us")
links.set("ca", "https://www.brokk.com/us/")
links.set("cn", "https://www.brokk.com/china")
links.set("sg", "https://www.brokk.com/sgp")
links.set("ae", "https://www.brokk.com/")
links.set("no", "https://www.brokk.com/no")
links.set("gb", "https://www.brokk.com/uk")
links.set("se", "https://www.brokk.com/sv/brokk-connect")
links.set("ch", "https://www.brokk.com/ch/brokk-connect")
links.set("it", "https://www.brokk.com/it/brokk-connect")
links.set("de", "https://www.brokk.com/de")
links.set("be", "https://www.brokk.com/")
links.set("nl", "https://www.brokk.com/")
links.set("lu", "https://www.brokk.com/")
links.set("fr", "https://www.brokk.fr/")
links.set("au", "https://www.brokk.com/au")

export default {
  countries: links,
  default: "https://www.brokk.com/"
}
