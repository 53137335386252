<template>
  <v-card>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
    ></v-progress-linear>

    <v-container fluid>
      <v-row class="pt-3">
        <v-col xs="auto" class="mr-0">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            v-bind:label="$t('common.filters.filter')"
            hide-details
            class="ma-0 pa-0"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="ml-0 pl-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="fetchReports"
                :loading="loading"
                :disabled="loading"
              >
                <v-icon>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="refreshLoader">
                    <v-icon>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>{{ $t("common.actions.refresh") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      class="reports elevation-1 mt-2"
      :no-results-text="$t('reports.dataTable.noResultsText')"
      :headers="headers"
      :items="reports"
      :search="search"
      item-key="key"
      :class="{ disabled: loading }"
      sort-by="modified"
      sort-desc
    >
      <template v-slot:[`item.modified`]="{ value }">
        <span class="grab-reports-item-date">{{ value }}</span>
      </template>

      <template v-slot:[`item.icon`]="{ value }">
        <v-icon>{{ value }}</v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-progress-circular
          v-if="item.downloading"
          :size="20"
          :width="2"
          indeterminate
        />

        <template v-else-if="$vuetify.breakpoint['xs']">
          <v-btn
            v-if="canReadReports"
            @click="downloadItem(item)"
            depressed
            small
          >
            {{ $t("common.actions.download") }}
          </v-btn>
          <v-btn
            v-if="canRemoveReports"
            @click="removableFile = item"
            class="ml-2"
            depressed
            small
          >
            {{ $t("common.actions.remove") }}
          </v-btn>
        </template>

        <v-menu v-else left>
          <template v-slot:activator="{ on: menu }">
            <v-btn
              icon
              color="primary"
              v-on="menu"
              class="grab-reports-action-menu-btn"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="canReadReports" @click="downloadItem(item)">
              <v-list-item-title class="body-2">
                <v-icon small>mdi-download</v-icon>
                {{ $t("common.actions.download") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canRemoveReports" @click="removableFile = item">
              <v-list-item-title class="body-2">
                <v-icon small>mdi-delete</v-icon>
                {{ $t("common.actions.remove") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <confirm-remove-dialog
      :item-name="$t('reports.itemName')"
      confirm-remove-event="confirm"
      :show="removableFile !== null"
      @close="removableFile = null"
      @confirm="confirmRemove"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import fetchClient from "@/utils/fetchClient"
import formatter from "@/utils/formatter"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"

export default {
  components: { ConfirmRemoveDialog },
  props: ["tenantUid"],

  data: () => ({
    files: [],
    filesLoading: [],
    removableFile: null,
    loading: false,
    search: ""
  }),
  computed: {
    ...mapGetters(["tenantArray", "canReadReports", "canRemoveReports"]),

    headers() {
      let hdrs = [
        { text: "", value: "icon", hide: "xs", width: "56px", sortable: false },
        { text: this.$t("reports.dataTable.headers.name"), value: "name" },
        {
          text: this.$t("reports.dataTable.headers.tenant"),
          value: "tenantName"
        },
        {
          text: this.$t("reports.dataTable.headers.modified"),
          value: "modified"
        }
      ]

      if (this.canReadReports || this.canRemoveReports) {
        hdrs.push({
          text: this.$t("common.dataTable.headers.actions"),
          value: "action",
          align: "center",
          sortable: false
        })
      }

      return hdrs.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },

    reports() {
      return this.files.map(file => {
        const tenant = this.tenantArray.find(t => t.uid === file.tenantUid)
        const fileKey = `${file.tenantUid}-${file.name}`

        return {
          icon: "mdi-file-table-outline",
          key: fileKey,
          name: file.name,
          tenantName: tenant ? tenant.name : "-",
          tenantUid: file.tenantUid,
          modified: this.formatDate(file.modified),
          downloading: this.filesLoading.includes(fileKey)
        }
      })
    }
  },

  watch: {
    tenantUid() {
      this.files = []
      this.search = ""
      this.fetchReports()
    }
  },

  mounted() {
    this.fetchReports()
  },

  methods: {
    ...mapActions(["setError"]),

    async fetchReports() {
      const url = `/api/v1/tenants/${this.tenantUid}/reports`
      this.loading = true

      try {
        this.files = await fetchClient(this.$i18n).getDataOrThrow(url)
      } catch (error) {
        this.setError(error)
      }

      this.loading = false
    },

    async downloadItem(file) {
      this.filesLoading.push(file.key)

      try {
        const url = `/api/v1/tenants/${file.tenantUid}/reports/${file.name}`
        const blob = await fetchClient(this.$i18n).getBlobOrThorw(url)

        let link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = file.name
        link.click()
      } catch (error) {
        this.setError(error)
      }

      const index = this.filesLoading.indexOf(file.key)
      if (index !== -1) {
        this.filesLoading.splice(index, 1)
      }
    },

    async confirmRemove() {
      const file = this.removableFile
      this.filesLoading.push(file.key)

      try {
        const url = `/api/v1/tenants/${file.tenantUid}/reports/${file.name}`
        await fetchClient(this.$i18n).deleteOrThrow(url)

        const fileIndex = this.files.findIndex(f => {
          const fileKey = `${f.tenantUid}-${f.name}`
          return fileKey === file.key
        })

        if (fileIndex !== -1) {
          this.files.splice(fileIndex, 1)
        }
      } catch (error) {
        this.setError(error)
      }

      const index = this.filesLoading.indexOf(file.key)
      if (index !== -1) {
        this.filesLoading.splice(index, 1)
      }
    },

    formatDate(date) {
      return formatter(this.$i18n).formatDate(date)
    }
  }
}
</script>

<style lang="scss">
.reports .v-data-table__wrapper {
  tr {
    cursor: auto;
  }

  .v-data-table__mobile-row {
    height: auto;
    padding: 6px 16px;
    min-height: auto;
  }

  .v-data-table__mobile-row__header {
    padding-right: 10px;
  }
}
</style>
