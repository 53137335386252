var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"justify-center",attrs:{"fluid":"","fill-height":""}},[_c('v-card',{attrs:{"elevation":"3","outlined":"","raised":""}},[_c('v-card-title',{staticClass:"grey darken-3"},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold text-h5 white--text",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$store.getters.appTitle)+" ")]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-img',{attrs:{"max-height":"32","max-width":"170","contain":"","src":_vm.$store.getters.logoWhite}})],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"firstLoginForm",attrs:{"autocomplete":"off"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[(_vm.passwordChanged)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"title mb-3"},[_vm._v(" Your password has been changed ")]),_c('a',{staticClass:"signIn",attrs:{"href":"/"},on:{"click":function($event){$event.stopPropagation();return _vm.onSignInClick.apply(null, arguments)}}},[_vm._v(" Sign In ")])])],1):_vm._e(),(!_vm.passwordChanged)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"counter":50,"prepend-icon":"mdi-lock","type":"password","required":"","label":"New Password*","rules":[
                  _vm.validationRules.awsPasswordRequirements(this.newpassword)
                ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.newpassword),callback:function ($$v) {_vm.newpassword=$$v},expression:"newpassword"}})],1)],1):_vm._e(),(!_vm.passwordChanged)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"counter":50,"prepend-icon":"mdi-lock","type":"password","required":"","label":"Confirm new password*","rules":[
                  _vm.validationRules.mustMatch(
                    this.confirmpassword,
                    this.newpassword
                  )
                ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',[_vm._v("Password strength")]),_c('password',{attrs:{"strength-meter-only":true},model:{value:(_vm.newpassword),callback:function ($$v) {_vm.newpassword=$$v},expression:"newpassword"}})],1)],1),(!_vm.passwordChanged)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"secondary accent--text",attrs:{"type":"button","disabled":!this.valid || _vm.loading,"rounded":"","min-width":"150","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("Change Password")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('a',{staticClass:"forgotPassword",attrs:{"href":"/"},on:{"click":function($event){$event.stopPropagation();return _vm.onResendCode.apply(null, arguments)}}},[_vm._v(" Resend Code ")])])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading}})],1)],1),(_vm.error)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('app-alert',{attrs:{"errItem":_vm.error},on:{"dismissed":_vm.dismissAlert}})],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }