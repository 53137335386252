<template>
  <v-card class="pa-0 pb-4 ma-4" color="white">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
    ></v-progress-linear>

    <v-card-title class="text-uppercase">
      {{ $t("common.actions.download") }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t("downloads.subtitle") }}
    </v-card-subtitle>
    <v-card-text>
      {{ $t("downloads.description") }}
    </v-card-text>

    <v-data-table
      class="mt-2"
      :no-results-text="$t('reports.dataTable.noResultsText')"
      :headers="headers"
      :items="downloads"
      :search="search"
      item-key="key"
      :class="{ disabled: loading }"
      sort-by="modified"
      sort-desc
    >
      <template v-slot:[`item.modified`]="{ value }">
        <span class="grab-downloads-item-date">{{ value }}</span>
      </template>

      <template v-slot:[`item.icon`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="downloadItem(item)">{{
              item.icon
            }}</v-icon>
          </template>
          <span>{{ $t("common.actions.download") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-progress-circular
          v-if="item.downloading"
          :size="20"
          :width="2"
          indeterminate
        />

        <template v-else-if="$vuetify.breakpoint['xs']">
          <v-btn
            v-if="canReadDownloads"
            @click="downloadItem(item)"
            depressed
            small
          >
            {{ $t("common.actions.download") }}
          </v-btn>
          <v-btn
            v-if="canRemoveDownloads"
            @click="removableFile = item"
            class="ml-2"
            depressed
            small
          >
            {{ $t("common.actions.remove") }}
          </v-btn>
        </template>

        <v-menu v-else left>
          <template v-slot:activator="{ on: menu }">
            <v-btn
              icon
              color="primary"
              v-on="menu"
              class="grab-downloads-action-menu-btn"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="canReadDownloads" @click="downloadItem(item)">
              <v-list-item-title class="body-2">
                <v-icon small>mdi-download</v-icon>
                {{ $t("common.actions.download") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canRemoveDownloads"
              @click="removableFile = item"
            >
              <v-list-item-title class="body-2">
                <v-icon small>mdi-delete</v-icon>
                {{ $t("common.actions.remove") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <ConfirmRemoveDialog
      :item-name="$t('downloads.itemName')"
      confirm-remove-event="confirm"
      :show="removableFile !== null"
      @close="removableFile = null"
      @confirm="confirmRemove"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import fetchClient from "@/utils/fetchClient"
import formatter from "@/utils/formatter"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"

export default {
  components: { ConfirmRemoveDialog },
  data() {
    return {
      files: [],
      filesLoading: [],
      removableFile: null,
      loading: false,
      search: ""
    }
  },
  created() {
    this.files = []
    this.search = ""
    this.fetchDownloads()
  },
  computed: {
    ...mapGetters(["canReadDownloads", "canRemoveDownloads"]),

    headers() {
      let hdrs = [
        { text: "", value: "icon", hide: "xs", width: "56px", sortable: false },
        { text: this.$t("reports.dataTable.headers.name"), value: "name" },
        {
          text: this.$t("downloads.size"),
          value: "size"
        },
        {
          text: this.$t("reports.dataTable.headers.modified"),
          value: "modified"
        }
      ]

      if (this.canReadDownloads || this.canRemoveDownloads) {
        hdrs.push({
          text: this.$t("common.dataTable.headers.actions"),
          value: "action",
          align: "center",
          sortable: false
        })
      }

      return hdrs.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },
    downloads() {
      return this.files.map(file => {
        return {
          icon: "mdi-archive-arrow-down-outline", //"mdi-file-table-outline",
          key: file.key,
          name: file.name,
          size: `${Math.floor(file.size / 1024)} kb`,
          modified: this.formatDate(file.modified),
          downloading: this.filesLoading.includes(file.key)
        }
      })
    }
  },

  methods: {
    ...mapActions(["setError"]),

    async fetchDownloads() {
      const url = "/api/v1/documents/software"
      this.loading = true

      try {
        const response = await fetchClient(this.$i18n).getDataOrThrow(url)
        this.files = response.files
      } catch (error) {
        this.setError(error)
      } finally {
        this.loading = false
      }
    },

    async downloadItem(file) {
      this.filesLoading.push(file.key)

      try {
        const url = `/api/v1/downloads/${file.key}`
        const blob = await fetchClient(this.$i18n).getBlobOrThorw(url)

        let link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = file.name
        link.click()
      } catch (error) {
        this.setError(error)
      }

      const index = this.filesLoading.indexOf(file.key)
      if (index !== -1) {
        this.filesLoading.splice(index, 1)
      }
    },

    async confirmRemove() {
      const file = this.removableFile
      this.filesLoading.push(file.key)

      try {
        const url = `/api/v1/tenants/${file.tenantUid}/reports/${file.name}`
        await fetchClient(this.$i18n).deleteOrThrow(url)

        const fileIndex = this.files.findIndex(f => {
          const fileKey = `${f.tenantUid}-${f.name}`
          return fileKey === file.key
        })

        if (fileIndex !== -1) {
          this.files.splice(fileIndex, 1)
        }
      } catch (error) {
        this.setError(error)
      }

      const index = this.filesLoading.indexOf(file.key)
      if (index !== -1) {
        this.filesLoading.splice(index, 1)
      }
    },

    formatDate(date) {
      return formatter(this.$i18n).formatDate(date)
    }
  }
}
</script>
