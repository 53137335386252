<template>
  <v-card flat>
    <v-card-subtitle class="pb-2 black--text">
      {{ $t("machines.dialogs.commissioning.steps.commissioning.description") }}
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-menu
            ref="datePickerMenu"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            :return-value.sync="selectedDate"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :label="$t('machines.info.order.commissioningDate')"
                :value="selectedDate"
                readonly
                prepend-icon="mdi-calendar-check"
                v-on="on"
                persistent-hint
                :hint="$t('machines.info.order.commissioningHint')"
              ></v-text-field>
            </template>
            <v-date-picker v-model="selectedDate" :min="minDate" :max="maxDate">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datePickerMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="primary"
                :disabled="selectedDate === undefined"
                @click="
                  $refs.datePickerMenu.save(selectedDate)
                  onSavePickedDate()
                "
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined>
            <v-card-subtitle class="black--text text-subtitle-1 pb-0">{{
              $t("machines.info.warranty.label")
            }}</v-card-subtitle>
            <v-row no-gutters>
              <v-col cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ warrantyStart }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      $t("machines.info.warranty.startDate")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        $tc("common.units.month", warrantyLength)
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      $t("machines.info.warranty.length")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ warrantyEnd }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      $t("machines.info.warranty.endDate")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import formatter from "@/utils/formatter"

export default {
  props: ["show", "commissioningDate", "deliveryDate"],
  data: () => ({
    datePickerMenu: false,
    selectedDate: null
  }),
  created() {
    this.setSelectedDate()
  },
  computed: {
    minDate() {
      return this.deliveryDate
    },
    maxDate() {
      var end = new Date(this.deliveryDate)
      // Add 6 months to the delivery date
      end.setMonth(end.getMonth() + 6)

      var today = new Date()

      // Ensure it is not past today
      if (end.getTime() > today.getTime()) {
        end.setTime(today.getTime())
      }
      return formatter(this.$i18n).formatDate(end)
    },
    validRange() {
      return `${this.minDate} ~ ${this.maxDate}`
    },
    machineInfo() {
      return this.$store.getters.machineInfo
    },
    warrantyLength() {
      return this.machineInfo.warrantyLength
    },
    warrantyStart() {
      return this.selectedDate
    },
    warrantyEnd() {
      let end = new Date(this.warrantyStart)
      end.setMonth(end.getMonth() + this.warrantyLength)
      return formatter(this.$i18n).formatDate(end)
    }
  },
  methods: {
    onSavePickedDate() {
      this.datePickerMenu = false
      this.$emit("update:commissioningDate", this.selectedDate)
    },
    setSelectedDate() {
      var selDate = new Date()
      var maxDate = new Date(this.maxDate)

      if (selDate.getTime() > maxDate.getTime()) {
        selDate.setTime(maxDate.getTime())
      }
      this.selectedDate = formatter(this.$i18n).formatDate(selDate)
      this.$emit("update:commissioningDate", this.selectedDate)
    }
  },
  watch: {
    show(value) {
      if (value) {
        this.setSelectedDate()
      }
    }
  }
}
</script>

<style></style>
