import MeasurementMeta from "@/filters/measurementmeta"
import unitUtils from "@/utils/units"

function csvExport(i18n, units) {
  function triggerFileDownload(href, filename) {
    const downloadLink = document.createElement("a")

    downloadLink.href = href
    downloadLink.download = filename

    document.body.appendChild(downloadLink)
    downloadLink.click()

    document.body.removeChild(downloadLink)
  }

  function exportCsv(header, rows, filename) {
    const headerData = header.join(",")
    const rowsData = rows.map(row => {
      return row
        .map(col => {
          if (typeof col === "string") {
            // eslint-disable-next-line
            return `"${col.replace(/"/g, '""')}"`
          }

          return `${col}`
        })
        .join(",")
    })
    const result = [headerData, ...rowsData]
    const data = `data:text/csv;charset=utf-8,${result.join("\n")}`

    triggerFileDownload(encodeURI(data), filename)
  }

  function isSelectedId(selectedIds, parameterId) {
    for (let ix = 0; ix < selectedIds.length; ix++) {
      if (selectedIds[ix].localeCompare(parameterId) === 0) {
        // Parameter IDs are the same
        return true
      }
    }
    return false
  }

  function zeroPad(val) {
    if (val < 10) {
      return "0" + val
    }
    return val
  }

  function getCurrentDateTime() {
    const d = new Date()

    var s = `${d.getFullYear()}`
    s += zeroPad(d.getMonth() + 1) // zero-based value
    s += zeroPad(d.getDate())
    s += "_"
    s += zeroPad(d.getHours())
    s += zeroPad(d.getMinutes())
    s += zeroPad(d.getSeconds())
    return s
  }

  function exportMeasurements(machine, measurements, selectedIds) {
    let header = [
      i18n.t("machines.measurements.export.csv.columns.timestamp"),
      i18n.t("machines.measurements.export.csv.columns.parameterId"),
      i18n.t("machines.measurements.export.csv.columns.category"),
      i18n.t("machines.measurements.export.csv.columns.description"),
      i18n.t("machines.measurements.export.csv.columns.value"),
      i18n.t("machines.measurements.export.csv.columns.unit")
    ]

    const rows = []
    measurements.forEach(m => {
      if (isSelectedId(selectedIds, m.parameterId)) {
        let meta = MeasurementMeta(m.parameterId)
        rows.push([
          m.receiveTime,
          m.parameterId,
          meta.category,
          meta.description,
          unitUtils.convert(meta.unit, m.rawValue, units),
          unitUtils.getCode(meta.unit, units)
        ])
      }
    })

    // ex: B70-200001-20200612060352.csv
    const filename = `B${machine.productId}-${
      machine.serialNo
    }-${getCurrentDateTime()}.csv`

    exportCsv(header, rows, filename)

    return rows.length
  }

  function exportServiceSchedule(machine, schedule) {
    let header = []
    if (machine.unprovisioned === true) {
      header = [
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.status"
        ),
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.service"
        ),
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.loggedHours"
        ),
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.comment"
        ),
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.serviceDate"
        )
      ]
    } else {
      header = [
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.status"
        ),
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.service"
        ),
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.scheduled"
        ),
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.loggedHours"
        ),
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.comment"
        ),
        i18n.t(
          "machines.maintenance.service.schedule.dataTable.headers.serviceDate"
        )
      ]
    }

    const rows = schedule.map(service => {
      let date = "-"
      let engineHours = "-"
      let comment = "-"

      if (service.serviceDate !== undefined) {
        date = service.serviceDate
      }

      if (service.status === "completed") {
        engineHours = service.engineHours

        if (service.comment !== null) {
          comment = service.comment
        }
      }

      if (machine.unprovisioned === true) {
        return [
          service.status.toUpperCase(),
          service.name,
          engineHours,
          comment,
          date
        ]
      } else {
        return [
          service.status.toUpperCase(),
          service.name,
          service.scheduled,
          engineHours,
          comment,
          date
        ]
      }
    })

    // ex: B70-200001-20200612060352.csv
    const filename = `Service-History-B${machine.productId}-${
      machine.serialNo
    }-${getCurrentDateTime()}.csv`

    exportCsv(header, rows, filename)

    return rows.length
  }

  return {
    exportMeasurements,
    exportServiceSchedule
  }
}

export default csvExport
