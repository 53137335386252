<template>
  <v-card>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
    ></v-progress-linear>

    <v-container fluid>
      <v-row class="pt-3">
        <v-col xs="auto" class="mr-0">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            :label="$t('common.filters.filter')"
            hide-details
            class="ma-0 pa-0"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="mx-0 px-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="refreshItems"
                :loading="refreshLoading"
                :disabled="refreshLoading"
              >
                <v-icon>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="refreshLoader">
                    <v-icon>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>{{ $t("common.actions.refresh") }}</span>
          </v-tooltip>
        </v-col>

        <v-col v-if="canExport" cols="auto" class="mx-0 px-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="exportUser"
                :disabled="refreshLoading"
              >
                <v-icon>mdi-application-export</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("users.actions.export") }}</span>
          </v-tooltip>
        </v-col>

        <v-col v-if="canAddItem" cols="auto" class="ml-0 pl-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="addItem"
                :disabled="refreshLoading"
                class="grab-add-user"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("users.actions.add") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :expanded.sync="expanded"
      item-key="uid"
      show-expand
      single-expand
      class="elevation-1 mt-2 grab-users-data-table"
      :class="{ disabled: refreshLoading }"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <UserDetails :user="item" />
        </td>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <template v-if="$vuetify.breakpoint['xs']">
          <v-btn v-if="canEditItem" @click="editItem(item)" depressed small>
            {{ $t("common.actions.edit") }}
          </v-btn>
          <v-btn
            v-if="canRemoveItem"
            @click="removeItem(item)"
            class="ml-2"
            depressed
            small
          >
            {{ $t("common.actions.remove") }}
          </v-btn>
        </template>
        <v-menu v-else left>
          <template v-slot:activator="{ on: menu }">
            <v-btn icon color="primary" v-on="menu">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="canEditItem" @click="editItem(item)">
              <v-list-item-title class="body-2">
                <v-icon small>mdi-pencil</v-icon>
                {{ $t("common.actions.edit") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canRemoveItem" @click="removeItem(item)">
              <v-list-item-title class="body-2">
                <v-icon small>mdi-delete</v-icon
                >{{ $t("common.actions.remove") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Dialog to add a new or edit an existing user -->
    <UserDialog
      :user="userItem"
      :show="showUserDialog"
      @save="onSave"
      @cancel="onCancel"
    />

    <ConfirmRemoveDialog
      :itemName="$t('users.itemName')"
      :item="userItem"
      removeAction="removeUser"
      :show="showRemoveDialog"
      @close="showRemoveDialog = false"
    />
  </v-card>
</template>

<script>
import UserDialog from "@/dialogs/UserDialog"
import UserDetails from "@/components/UserDetails"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"
import fetchClient from "@/utils/fetchClient"

export default {
  name: "UsersView",
  props: ["tenantUid"],
  components: {
    UserDialog,
    UserDetails,
    ConfirmRemoveDialog
  },
  data() {
    return {
      refreshLoading: false,
      search: "",
      expanded: [],
      userItem: {},
      showUserDialog: false,
      showRemoveDialog: false,
      snackbar: false,
      snackbarText: ""
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    headers() {
      return [
        { text: this.$t("users.itemAttributes.email"), value: "email" },
        { text: this.$t("users.itemAttributes.role"), value: "roleName" },
        { text: this.$t("users.itemAttributes.tenant"), value: "tenantName" },
        {
          text: this.$t("common.dataTable.headers.actions"),
          value: "action",
          sortable: false
        }
      ]
    },
    loading() {
      return this.$store.getters.loading
    },
    users() {
      let u = this.$store.getters.users
      if (u !== undefined && u !== null) {
        return u
      }
      return []
    },
    currentUser() {
      return this.$store.getters.loggedInUser
    },
    canAddItem() {
      return this.$store.getters.canAddUsers
    },
    canExport() {
      return this.$store.getters.isAdmin
    }
  },
  watch: {
    $route: "fetchData"
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("fetchUsers", this.tenantUid)
    },
    canEditItem(item) {
      let isGranted = this.$store.getters.canEditUsers

      // low role Id means high authority.
      // Can only edit or remove users that have same or higher role,
      // e.g. a local admin user cannot edit or remove an admin user.
      let isAuthorized = this.currentUser.roleId <= item.roleId

      return isGranted && isAuthorized
    },
    canRemoveItem(item) {
      let isGranted = this.$store.getters.canRemoveUsers
      let isAuthorized = this.currentUser.roleId <= item.roleId

      // Can't remove the currently logged in user.
      let isCurrentUser = this.currentUser.uid === item.uid

      return isGranted && isAuthorized && !isCurrentUser
    },
    addItem() {
      this.userItem = {
        tenantUid: this.tenantUid,
        email: "",
        firstName: "",
        lastName: "",
        description: "",
        roleId: 500 // Default to user role
      }
      this.showUserDialog = true
    },
    editItem(item) {
      this.userItem = Object.assign({}, item)
      this.showUserDialog = true
    },
    removeItem(item) {
      this.userItem = Object.assign({}, item)
      this.showRemoveDialog = true
    },
    onSave(info) {
      this.showUserDialog = false
      this.snackbarText = info.wasAdded
        ? this.$t("users.dialogs.edit.messages.userAdded")
        : this.$t("users.dialogs.edit.messages.userUpdated")
      this.snackbar = true
      this.refreshItems()
    },
    onCancel() {
      this.showUserDialog = false
    },
    async exportUser() {
      const url = "/api/v1/export/users/webshop"
      this.refreshLoading = true

      try {
        await fetchClient(this.$i18n).postOrThrow(url, {})

        this.snackbarText = this.$t("users.dialogs.edit.messages.userExport")
        this.snackbar = true
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.refreshLoading = false
      }
    },
    async refreshItems() {
      this.refreshLoading = true
      await this.$store.dispatch("fetchUsers", this.tenantUid)
      this.refreshLoading = false
    }
  }
}
</script>
