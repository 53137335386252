import fetchClient from "@/utils/fetchClient"

const settingState = {
  DEFAULT: "default",
  APPLIED: "applied",
  PENDING: "pending",
  MODIFIED: "modified",
  ERROR: "error"
}

const isEqualToDefault = (change, settings) => {
  return settings.find(
    x =>
      x.parameterId === change.parameterId &&
      x.updateInterval === change.updateInterval
  )
    ? true
    : false
}

const getUpdatedMachineSettings = (defaultSettings, changed) => {
  // Add new attribute to keep track of the default update interval
  defaultSettings.forEach(cs => {
    cs.defaultInterval = cs.updateInterval
  })

  const changedSettings = defaultSettings.filter(x =>
    changed.find(y => y.parameterId === x.parameterId)
  )

  const changedSettingsWithChangesApplied = changedSettings.map(x => ({
    ...x,
    updateInterval: changed.find(y => y.parameterId === x.parameterId)
      .updateInterval,
    status: changed.find(y => y.parameterId === x.parameterId).status,
    statusMessage: changed.find(y => y.parameterId === x.parameterId)
      .statusMessage
  }))

  return [
    ...new Map(
      [...defaultSettings, ...changedSettingsWithChangesApplied].map(item => [
        item.parameterId,
        item
      ])
    ).values()
  ]
}

export default {
  state: {
    machineSettings: [],
    stashedChanges: [],
    loadingSettings: false
  },
  mutations: {
    setMachineSettings(state, payload) {
      state.machineSettings = payload
    },
    setStashedChanges(state, payload) {
      state.stashedChanges = payload
    },
    setLoadingSettings(state, payload) {
      state.loadingSettings = payload
    }
  },
  actions: {
    clearStashedChanges(context) {
      context.commit("setStashedChanges", [])
    },
    async pushStashedChanges(context, machineInfo) {
      context.commit("setLoadingSettings", true)

      var allChanges = context.state.machineSettings.filter(
        x => x.status == settingState.PENDING
      )

      // AWS shadow requires both pending and stashed changes
      context.state.stashedChanges.forEach(change => {
        const i = allChanges.findIndex(
          x => x.parameterId === change.parameterId
        )
        if (i > -1) {
          allChanges[i] = change
        } else {
          allChanges.push(change)
        }
      })

      fetchClient(this.state.$i18n)
        .put(`/api/v1/tenants/${machineInfo.tenantUid}/machines/${machineInfo.serialNo}/measurements/settings`,
          allChanges.map(x => ({
            serialNo: machineInfo.serialNo,
            parameterId: x.parameterId,
            updateInterval: x.updateInterval
          }))
        )
        .then((data) => {
          const newSettings = getUpdatedMachineSettings(context.state.machineSettings, data)
          context.commit("setMachineSettings", newSettings)
          context.commit("setStashedChanges", [])
        })
        .catch((error) => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoadingSettings", false)
        })
    },
    updateStashedChanges(context, change) {
      const settings = [...context.state.machineSettings]
      const stashedChanges = [...context.state.stashedChanges]
      const index = stashedChanges.findIndex(
        x => x.parameterId === change.parameterId
      )

      if (isEqualToDefault(change, settings)) {
        if (index >= 0) {
          stashedChanges.splice(index, 1)
        }

        context.commit("setStashedChanges", stashedChanges)
      } else {
        index >= 0
          ? stashedChanges.splice(index, settingState.APPLIED, {
            ...change,
            status: settingState.MODIFIED
          })
          : stashedChanges.push({ ...change, status: settingState.MODIFIED })

        context.commit("setStashedChanges", stashedChanges)
      }
    },

    async fetchMachineSettings(context, machineInfo) {
      context.commit("setLoadingSettings", true)
      try {
        const defaultSettings = await fetchClient(this.state.$i18n).getDataOrThrow(`/api/v1/metadata/measurements/${machineInfo.langCode}`)
        const changedSettings = await fetchClient(this.state.$i18n).getDataOrThrow(`/api/v1/tenants/${machineInfo.tenantUid}/machines/${machineInfo.serialNo}/measurements/settings`)
        const machineSettings = getUpdatedMachineSettings(defaultSettings.measurements, changedSettings)
        context.commit("setMachineSettings", machineSettings)
      } catch (error) {
        context.commit("setError", error)
      } finally {
        context.commit("setLoadingSettings", false)
      }
    }
  },
  getters: {
    machineSettings(state) {
      return state.machineSettings
    },
    stashedChanges(state) {
      return state.stashedChanges
    },
    isLoadingSettings(state) {
      return state.loadingSettings
    }
  }
}
