<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title class="d-flex align-center">
          <v-icon class="accent--text mr-2">{{ genericDialog.icon }}</v-icon>
          <span class="title accent--text">{{
            genericDialog.title
          }}</span></v-toolbar-title
        >
        <v-spacer />
        <v-btn icon @click="onClose">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6 subtitle-1">
        {{ genericDialog.message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <span v-for="button in genericDialog.buttons" :key="button.label">
          <v-btn
            @click.stop="
              onClose()
              button.action()
            "
            class="ma-2"
          >
            {{ button.label }}
          </v-btn>
        </span>
        <v-btn
          v-if="genericDialog.buttons.length === 0"
          class="mr-2 mb-2"
          @click.stop="onClose">
            {{ $t("common.actions.ok")}}
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GenericDialog",
  props: ["genericDialog"],
  data() {
    return {
      dialog: true
    }
  },
  computed: {},
  methods: {
    onClose() {
      this.dialog = false
    }
  },
  watch: {
    genericDialog() {
      this.dialog = true
    }
  }
}
</script>
