import Vue from "vue"
import VueRouter from "vue-router"

import { routes } from "./routes"
import authCheck from "./authcheck"

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

router.beforeEach(authCheck)
export default router
