<template>
  <v-card flat tile class="pa-0 pb-4 ma-4" color="white">
    <v-card-title>
      <div class="text-md-h4 home-title">
        {{ $t("home.greeting") }}
      </div>
    </v-card-title>
    <v-card-text>
      <v-row class="d-none d-md-block">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <HomeMachines />
        </v-col>
        <v-col cols="12" md="6">
          <HomeMetrics />
        </v-col>
      </v-row>
      <v-row class="d-none d-md-block">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <HomeDocuments />
        </v-col>
        <v-col cols="12" md="6">
          <HomeWebshop />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import HomeMachines from "@/components/home/HomeMachines"
import HomeMetrics from "@/components/home/HomeMetrics"
import HomeDocuments from "@/components/home/HomeDocuments"
import HomeWebshop from "@/components/home/HomeWebshop"

export default {
  components: {
    HomeMachines,
    HomeMetrics,
    HomeDocuments,
    HomeWebshop
  },
  data() {
    return {
      title: "Welcome to My Brokk"
    }
  },
  computed: {},
  created() {},
  methods: {},
  watch: {}
}
</script>

<style>
.home-title {
  padding-left: 1.8rem;
}

.home-title::before {
  background: #ffc107;
  content: "";
  height: 2.2rem;
  left: 1.4rem;
  position: absolute;
  top: 0.8rem;
  transform: skew(-18deg, 0deg);
  width: 10px;
}

.homepage-link:link {
  text-decoration: none;
}

.homepage-link:visited {
  text-decoration: none;
}

.homepage-link:hover {
  text-decoration: underline;
}

.homepage-link:active {
  text-decoration: underline;
}
</style>
