<template>
  <v-card flat outlined>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>
        <v-icon class="accent--text mr-4">mdi-calendar-multiple</v-icon>
        <span class="accent--text text-h6 text-uppercase">{{
          $t("machines.info.warranty.label")
        }}</span></v-toolbar-title
      >
    </v-toolbar>
    <v-card-title></v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            :label="$t('machines.info.warranty.startDate')"
            :value="warranty.startDate"
            persistent-hint
            readonly
            disabled
            prepend-icon="mdi-calendar-start"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            :label="$t('machines.info.warranty.length')"
            :value="warranty.length"
            persistent-hint
            readonly
            disabled
            prepend-icon="mdi-arrow-expand-horizontal"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            :label="$t('machines.info.warranty.endDate')"
            :value="warranty.endDate"
            persistent-hint
            readonly
            disabled
            prepend-icon="mdi-calendar-end"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["warranty"],
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style></style>
