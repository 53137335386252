<template>
  <v-snackbar
    v-model="showHide"
    :timeout="snackTimeout"
    top
    dark
    :color="snackColor"
    @input="onStateChange"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn text @click="onClose" v-bind="attrs">
        {{ $t("common.actions.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: ["show", "text"],
  data() {
    return {
      showHide: false
    }
  },
  watch: {
    show(val) {
      this.showHide = val
    }
  },
  computed: {
    snackColor() {
      return "green darken-1"
    },
    snackTimeout() {
      return 2800
    }
  },
  methods: {
    onClose() {
      this.showHide = false
    },
    onStateChange(val) {
      this.showHide = val
      this.$emit("update:show", val)
    }
  }
}
</script>

<style></style>
