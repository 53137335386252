<template>
  <v-card class="pa-0 pb-4 ma-4" color="white">
    <v-card-title class="text-uppercase">
      {{ $t("users.settings.menuItems.settings") }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t("users.settings.preferences.description") }}
    </v-card-subtitle>
    <v-card-text>
      <v-card tile color="details_background">
        <v-card-text class="black--text">
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            top
          ></v-progress-linear>
          <v-form ref="editUserPreferences" autocomplete="off" v-model="valid">
            <v-container class="black--text">
              <v-row class="align-center">
                <v-col cols="12" sm="6" lg="3" class="text-uppercase">
                  {{ $t("common.language") }}
                </v-col>
                <v-col cols="12" sm="6"
                  ><v-select
                    v-model="language"
                    :disabled="loading"
                    :items="languageItems"
                    item-text="name"
                    item-value="code"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-select
                ></v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="12" sm="6" lg="3" class="text-uppercase">
                  {{ $t("users.settings.preferences.units") }}
                </v-col>
                <v-col cols="12" sm="6"
                  ><v-radio-group v-model="unitOption" :disabled="loading">
                    <v-radio
                      key="1"
                      :label="$t('common.enums.measurementSystem.metric')"
                      value="metric"
                    ></v-radio>
                    <v-radio
                      key="3"
                      :label="$t('common.enums.measurementSystem.imperial')"
                      value="imperial"
                    ></v-radio> </v-radio-group
                ></v-col>
              </v-row>
              <v-row v-if="canToggleWeeklyReports" class="align-center">
                <v-col cols="12" sm="6" lg="3" class="text-uppercase">
                  {{ $t("users.settings.notifications.sendWeeklyReports") }}
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    class="mt-0 pt-0"
                    :disabled="loading"
                    v-model="sendWeeklyReports"
                    :label="
                      $t('users.settings.notifications.sendWeeklyReportsDesc')
                    "
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card-actions class="pt-6">
        <v-spacer></v-spacer>
        <v-btn
          class="ml-2 mb-2"
          :disabled="loading"
          @click.stop="onSavePreferences"
          >{{ $t("common.actions.save") }}</v-btn
        >
        <v-btn
          class="ml-2 mb-2"
          :disabled="loading"
          @click.stop="onCancelPreferences"
          >{{ $t("common.actions.cancel") }}</v-btn
        >
      </v-card-actions>
    </v-card-text>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import languages from "@/utils/languages"
import { mapActions } from "vuex"
import fetchClient from "@/utils/fetchClient"

export default {
  name: "UserPreferences",
  data() {
    return {
      valid: false,
      snackbar: false,
      sendWeeklyReports: true,
      languageItems: languages.supported,
      language: languages.getFromLocalStorage(),
      unitOption: "metric",
      initialView: "map"
    }
  },
  components: {},
  created() {},
  mounted() {
    this.fetchUserDetails()
  },
  computed: {
    snackbarText() {
      return this.$t("common.saveSuceeded")
    },
    loading() {
      return this.$store.getters.loading
    },
    loggedInUser() {
      return this.$store.getters.loggedInUser
    },
    canToggleWeeklyReports() {
      return (
        this.$store.getters.isAdmin ||
        this.$store.getters.isService ||
        this.$store.getters.isLocalAdmin
      )
    }
  },
  watch: {},
  methods: {
    ...mapActions(["setError"]),

    async fetchUserDetails() {
      await this.$store.dispatch("fetchUserDetails", {
        tenantUid: this.loggedInUser.tenantUid,
        uid: this.loggedInUser.uid
      })
      const res = this.$store.getters.userDetails

      if (res != null) {
        if (res.preferences != null) {
          if (res.preferences?.language) {
            this.language = res.preferences.language
          }
          if (res.preferences?.units) {
            this.unitOption = res.preferences.units
          }
          if (res.preferences?.initialView) {
            this.initialView = res.preferences.initialView
          }
        }
        if (res.notifications !== null) {
          this.sendWeeklyReports = res.notifications?.weeklyReports
        }
      }
    },
    async onSavePreferences() {
      try {
        if (this.canToggleWeeklyReports) {
          // Only save weekly report settings if user is allowed to change it.
          // Users having user role cannot get weekly reports, thus it's hidden.
          const url = `/api/v1/tenants/${this.loggedInUser.tenantUid}/users/${this.loggedInUser.uid}/notifications`
          await fetchClient(this.$i18n).putOrThrow(url, {
            weeklyReports: this.sendWeeklyReports
          })
        }

        let preferences = {
          language: this.language,
          units: this.unitOption,
          initialView: this.initialView
        }
        await this.$store
          .dispatch("updateUserPreferences", preferences)
          .catch(() => {
            // Error already displayed by dispatched action.
          })
        this.$root.setLanguage(this.language) //Set language after "updateUserPreferences" is completed, to not update language in database 2nd time
        this.snackbar = true
      } catch (error) {
        this.setError(error)
      }
    },
    async onCancelPreferences() {
      this.fetchUserDetails()
    }
  }
}
</script>

<style></style>
