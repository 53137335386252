export default {
  tenant: {
    contacts: {
      actions: {
        addContact: null,
        editContact: null,
        removeContact: null
      },
      contactTypes: {
        sales: null,
        service: null
      },
      dataTable: {
        headers: {
          address: null,
          name: null,
          phoneNumber: null,
          type: null
        }
      },
      dialogs: {
        edit: {
          title: null
        }
      },
      messages: {
        contactAdded: null,
        contactUpdated: null,
        removed: null
      },
      title: null
    },
    customer: {
      actions: {
        addCustomer: " Aggiungi i dati del cliente",
        addDeliveryAddress: " Aggiungi l’indirizzo di consegna",
        editCustomer: " Modifica i dati del cliente",
        editDeliveryAddress: " Modifica l’indirizzo di consegna",
        removeCustomer: " Elimina i dati del cliente"
      },
      labels: {
        addDetailsQuestion:
          "Vuoi aggiungere i dati del cliente per questo locatario? In questo modo verrà creato un codice cliente per il negozio online.",
        address: "Indirizzo",
        addressDialogDescription:
          "Inserisci i dati dell'indirizzo di consegna del cliente.",
        addressLine: "Riga indirizzo",
        customerNo: "Codice cliente",
        customerType: "Tipo cliente",
        dealer: "Rivenditore",
        dealerAddressDescription:
          "L’indirizzo del rivenditore sarà recuperato dal monitor dal negozio online.",
        deliveryAddressDescription:
          "Indirizzi a cui possono essere spediti gli ordini del negozio online. È possibile aggiungere un massimo di {maxRecords} indirizzi.",
        deliveryAddressInfo:
          "Non è possibile aggiungere altri indirizzi di consegna. È stato inserito il numero massimo di indirizzi.",
        deliveryAddresses: "Indirizzi di consegna",
        discount: "Percentuale di sconto sul negozio online",
        endCustomer: "Cliente finale",
        endCustomerName: "Nome cliente finale",
        endCustomerNo: "Codice cliente finale",
        freightContact: "Contatto per la spedizione",
        missingCountry:
          "Devi specificare un paese prima di poter aggiungere i dati del cliente. Selezionarne uno nella vista Profilo locatario.",
        priceListId: "ID listino prezzi negozio online"
      },
      messages: {
        removed: "I dati del cliente sono stati eliminati",
        saved: "I dati del cliente sono stati salvati"
      },
      placeholders: {
        address: "Inserisci l'indirizzo",
        customerNo: "Inserisci un codice cliente Monitor",
        customerType: "Selezione il tipo cliente",
        discount: "Inserisci lo sconto nel negozio online (0-100%)",
        freightContact: "Inserisci il contatto per la spedizione",
        priceListId: "Inserisci l’ID listino prezzi del negozio online"
      },
      title: "Cliente"
    },
    dialogs: {
      edit: {
        addTitle: "Aggiungi locatario",
        city: "Città",
        cityPlaceholder: "Inserisci il nome della città",
        country: "Paese",
        countryPlaceholder: "Seleziona il paese",
        description: "Descrizione",
        descriptionPlaceholder: "Inserisci la descrizione",
        editTitle: "Modifica locatario",
        engineHoursBeforeNextService:
          "Ore motore prima della prossima manutenzione.",
        enterEmailAddress: "Inserisci indirizzo e-mail",
        messages: {
          added: "Il locatario è stato aggiunto",
          updated: "Il locatario è stato aggiornato"
        },
        name: "Nome",
        namePlaceholder: "Inserisci il nome del locatario",
        sendMaintanenceNotifications:
          "Invia notifiche e-mail relative alla manutenzione."
      }
    },
    itemName: "locatario",
    menuItems: {
      downloads: "Download",
      fleetAnalytics: null,
      fleet_analytics: null,
      machines: "Macchine",
      manuals: "Manuali",
      manualsProductId: "Manuali per Brokk ",
      map: "Mappa",
      reports: "Rapporti",
      tenants: "Locatari",
      users: "Utenti"
    },
    noLocationData: "Nessun dato sulla posizione da visualizzare",
    profile: {
      title: "Profilo locatario"
    },
    selectAnObject: "Seleziona un oggetto nella visualizzazione ad albero."
  }
}
