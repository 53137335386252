/* eslint-disable */

export default {
  namespaced: true,
  state: () => ({
    promise: null,
    data: null,
    timestamp: new Date
  }),
  mutations: {
    setPromise(state, payload) {
      state.promise = payload
    },
    setData(state, payload) {
      state.data = payload
    },
    setTimestamp(state, payload) {
      state.timestamp = payload
    },
  },
  actions: {
    /** To avoid multiple calls to the api from various components at quite the same time,
     * calls to this method done simultaneously in one second use the same promise/data */
    async fetchData({ state, commit }, fetchMethod) {
      try {
        if (new Date - state.timestamp < 1000/*ms*/) {
          if (state.data) {
            return Promise.resolve(state.data)
          }
          if (state.promise) {
            return state.promise
          }
        }
        let promise = fetchMethod()
          .then(
            data => {
              commit("setPromise", null)
              commit("setData", data)
              return Promise.resolve(data)
            },
            error => Promise.reject(error)
          )
        commit("setTimestamp", new Date)
        commit("setPromise", promise)
        return promise
      } catch (error) {
        commit("setError", error, { root: true })
      }
    },
  },
  getters: {
    promise(state) {
      return state.promise
    },
    data(state) {
      return state.data
    },
    timestamp(state) {
      return state.timestamp
    }
  }
}
