<template>
  <v-container fluid>
    <v-card :loading="loading" flat :disabled="loading">
      <v-card-text>
        <v-row>
          <v-col>
            <MachineOrder :order="order" />
          </v-col>
          <v-col>
            <MachineWarranty :warranty="warranty" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <MachineSegment
              :loading="loading"
              :order="order"
              :warranty="warranty"
              :machine="machine"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MachineOrder from "@/components/machines/MachineOrder"
import MachineWarranty from "@/components/machines/MachineWarranty"
import MachineSegment from "@/components/machines/MachineSegment"

import formatter from "@/utils/formatter"

export default {
  props: ["machine"],
  components: { MachineOrder, MachineWarranty, MachineSegment },
  data() {
    return {}
  },
  computed: {
    loading() {
      return this.$store.getters.loadingMachineInfo
    },
    machineInfo() {
      return this.$store.getters.machineInfo
    },
    order() {
      return {
        orderNumber: this.machineInfo?.orderNo,
        orderType: this.machineInfo?.orderType,
        deliveryDate: this.formatDate(this.machineInfo?.deliveryDate),
        commissioningDate: this.formatDate(this.machineInfo?.commissioningDate)
      }
    },
    warranty() {
      let warrantyLength = "-"
      if (this.machineInfo?.warrantyLength) {
        warrantyLength = this.$tc(
          "common.units.month",
          this.machineInfo?.warrantyLength
        )
      }
      return {
        startDate: this.formatDate(this.machineInfo?.warrantyStartDate),
        endDate: this.formatDate(this.machineInfo?.warrantyEndDate),
        length: warrantyLength
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    formatDate(dateStr) {
      if (dateStr) {
        return formatter(this.$i18n).formatDate(new Date(dateStr))
      }
      return "-"
    },
    fetchData() {
      this.$store.dispatch("fetchMachineInfo", {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo
      })
    }
  }
}
</script>
