<template>
  <v-card flat outlined class="ma-4">
    <!-- Title -->
    <v-card-title>
      {{ $t("tenant.contacts.title") }}
      <v-spacer></v-spacer>
      <v-tooltip top v-if="canAddContact">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon :disabled="showEditDialog" @click="onAddContact()"
              >mdi-plus-circle
            </v-icon>
          </span>
        </template>
        <span>{{ $t("tenant.contacts.actions.addContact") }}</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text class="pb-0">
      <!-- Table -->
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="contacts">
            <template v-slot:[`item.action`]="{ item }">
              <v-icon small class="mr-2" @click="onEditContact(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="onRemoveClick(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              {{ $t("tenant.contacts.contactTypes." + item.type) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Error message -->
      <v-row>
        <v-col cols="12" class="py-0">
          <v-alert
            v-if="error"
            :type="error.type"
            class="ma-0"
            elevation="2"
            border="left"
            colored-border
            dense
            dismissible
          >
            <div>{{ error.message }}</div>
            <div v-if="error.causedBy" class="subtitle">
              {{ error.causedBy }}
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <ConfirmRemoveDialog
      :itemName="`${$t('tenant.contacts.title')}`"
      confirmRemoveEvent="confirmEvent"
      :show="showConfirmRemove"
      @close="showConfirmRemove = false"
      v-on:confirmEvent="onRemoveConfirm"
    />
    <EditTenantContactDialog
      :editedItem="editedItem"
      :show="showEditDialog"
      @save="onEditSave"
      @cancel="onEditCancel"
    />
  </v-card>
</template>

<script>
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"
import EditTenantContactDialog from "@/dialogs/EditTenantContactDialog"
import fetchClient from "@/utils/fetchClient"

export default {
  data() {
    return {
      headers: [
        {
          text: this.$t("tenant.contacts.dataTable.headers.name"),
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("tenant.contacts.dataTable.headers.type"),
          sortable: true,
          value: "type"
        },
        {
          text: this.$t("tenant.contacts.dataTable.headers.phoneNumber"),
          sortable: true,
          value: "phoneNumber"
        },
        {
          text: this.$t("tenant.contacts.dataTable.headers.address"),
          sortable: true,
          value: "address"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action"
        }
      ],

      contacts: [],
      showEditDialog: false,
      editedItem: undefined,
      showConfirmRemove: false,
      error: null,
      snackbar: false,
      snackbarText: ""
    }
  },
  components: {
    ConfirmRemoveDialog,
    EditTenantContactDialog
  },
  mounted() {
    this.fetchContacts()
  },
  computed: {
    canAddContact() {
      return this.$store.getters.canEditTenants
    },
  },
  methods: {
    async fetchContacts() {
      try {
        await this.$store.dispatch("fetchTenantContacts", {
          tenantUid: this.$route.params.tenantUid
        })

        this.contacts = this.$store.getters.tenantContacts
      } catch (error) {
        this.error = { type: "error", ...error }
        this.contacts = []
      }
    },
    onEditContact(contact = null) {
      this.editedItem = contact
      this.showEditDialog = true
    },
    onAddContact() {
      this.onEditContact({
        id: null,
        name: "",
        type: "",
        phoneNumber: "",
        address: ""
      })
    },
    async onEditSave(info) {
      this.showEditDialog = false
      this.snackbarText = info.isNewItem
        ? this.$t("tenant.contacts.messages.contactAdded")
        : this.$t("tenant.contacts.messages.contactUpdated")
      this.snackbar = true
      await this.fetchContacts()
    },
    onEditCancel() {
      this.showEditDialog = false
    },
    onRemoveClick(contact) {
      this.editedItem = contact
      this.showConfirmRemove = true
    },
    async onRemoveConfirm() {
      this.isUpdating = true
      this.error = null

      try {
        // TODO move to store
        const url = `/api/v1/tenants/${this.$route.params.tenantUid}/contacts/${this.editedItem.uid}`
        await fetchClient(this.$i18n).deleteOrThrow(url)
        this.snackbarText = this.$t("tenant.contacts.messages.removed")
        this.snackbar = true
        await this.fetchContacts()
        this.isUpdating = false
      } catch (error) {
        console.log(error)
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    }
  }
}
</script>

<style></style>
