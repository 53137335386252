<template>
  <v-dialog v-model="show" max-width="400" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-home-account</v-icon>
          <span class="title accent--text">
            {{ $t("tenant.customer.title") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="onCancel">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-subtitle class="pt-4 pb-0">
        <div class="body-1">
          {{ $t("tenant.customer.placeholders.customerType") }}
        </div>
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <v-form ref="customerTypeForm" autocomplete="off" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-radio-group
                v-model="customerType"
                column
                mandatory
                class="mt-2"
              >
                <v-radio
                  :label="$t('tenant.customer.labels.endCustomer')"
                  value="endCustomer"
                >
                </v-radio>
                <v-radio
                  :label="$t('tenant.customer.labels.dealer')"
                  value="dealer"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="pr-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="mr-2" :disabled="!valid" @click.stop="onSave">{{
          $t("common.actions.ok")
        }}</v-btn>
        <v-btn @click.stop="onCancel">
          {{ $t("common.actions.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      valid: false,
      customerType: ""
    }
  },
  computed: {},
  methods: {
    async onSave() {
      if (!this.$refs.customerTypeForm.validate()) {
        return
      }
      this.$emit("save", { customerType: this.customerType })
    },
    onCancel() {
      this.$emit("cancel", {})
    }
  }
}
</script>
