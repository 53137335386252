<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-progress-wrench</v-icon>
          <span class="title accent--text">{{
            $t("tenant.contacts.dialogs.edit.title")
          }}</span></v-toolbar-title
        >
        <v-spacer />
        <v-btn icon @click="onCancel">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-1 pb-0">
        <v-form
          v-if="!!editedItem"
          ref="editContactForm"
          autocomplete="off"
          v-model="valid"
        >
          <v-container>
            <!-- Name -->
            <v-row>
              <v-col cols="12" class="pl-1 ml-1 my-0">
                <v-text-field
                  v-model="editedItem.name"
                  required
                  :label="$t('tenant.contacts.dataTable.headers.name')"
                  :disabled="isUpdating"
                  :placeholder="$t('tenant.contacts.dataTable.headers.name')"
                  :rules="[validationRules.required, validationRules.maxLimit]"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Type -->
            <v-row>
              <v-col cols="12" class="pl-1 ml-1 my-0">
                <v-autocomplete
                  v-model="editedItem.type"
                  required
                  :label="$t('tenant.contacts.dataTable.headers.type')"
                  :placeholder="$t('tenant.contacts.dataTable.headers.type')"
                  :rules="[validationRules.required]"
                  :items="contactTypes"
                  :disabled="isUpdating"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Phone Number -->
            <v-row>
              <v-col cols="12" class="pl-1 ml-1 my-0">
                <v-text-field
                  v-model="editedItem.phoneNumber"
                  required
                  :label="$t('tenant.contacts.dataTable.headers.phoneNumber')"
                  :disabled="isUpdating"
                  :placeholder="
                    $t('tenant.contacts.dataTable.headers.phoneNumber')
                  "
                  :rules="[validationRules.maxLimit]"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Address -->
            <v-row>
              <v-col cols="12" class="pl-1 ml-1 my-0">
                <v-text-field
                  v-model="editedItem.address"
                  :label="$t('tenant.contacts.dataTable.headers.address')"
                  :disabled="isUpdating"
                  :placeholder="$t('tenant.contacts.dataTable.headers.address')"
                  :rules="[validationRules.maxLimit]"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Errors -->
            <v-row>
              <v-col cols="12" class="py-0 px-2">
                <v-alert
                  v-if="error"
                  :type="error.type"
                  class="ma-0"
                  elevation="2"
                  border="left"
                  colored-border
                  dense
                  dismissible
                >
                  <div>{{ error.message }}</div>
                  <div v-if="error.causedBy" class="subtitle">
                    {{ error.causedBy }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="pr-6 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2 mb-2"
          :disabled="!valid || isUpdating"
          :loading="isUpdating"
          @click.stop="onSave"
          >{{ $t("common.actions.save") }}</v-btn
        >
        <v-btn class="mb-2" :disabled="isUpdating" @click.stop="onCancel">{{
          $t("common.actions.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import fetchClient from "@/utils/fetchClient"

let validationRules = null

export default {
  name: "EditTenantContactDialog",
  props: ["editedItem", "show"],

  data() {
    return {
      valid: false,
      validationRules: validationRules,
      isUpdating: false,
      error: null,
      hasPendingTransfer: false,
      contactTypes: [
        {
          text: this.$t("tenant.contacts.contactTypes.sales"),
          value: "sales"
        },
        {
          text: this.$t("tenant.contacts.contactTypes.service"),
          value: "service"
        },
      ],
      isNewItem: false,
      originalItem: {}
    }
  },
  watch: {
    show() {
      this.isNewItem = !this.editedItem.name
      Object.assign(this.originalItem, this.editedItem)
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  methods: {
    async onSave() {
      if (!this.$refs.editContactForm.validate()) {
        return
      }

      this.error = null
      this.isUpdating = true

      // TODO: move this to store
      try {
        const url = `/api/v1/tenants/${this.$store.getters.tenant.uid}/contacts`
        if (this.isNewItem) {
          await fetchClient(this.$i18n).postOrThrow(url, this.editedItem)
        } else {
          await fetchClient(this.$i18n).putOrThrow(url + `/${this.editedItem.uid}`, this.editedItem)
        }
        this.$emit("save", { isNewItem: this.isNewItem })
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    },
    onCancel() {
      this.error = null
      Object.assign(this.editedItem, this.originalItem)
      this.$emit("cancel", {})
    }
  }
}
</script>
