<template>
  <v-card class="valuewidget-container">
    <!-- Simplified header -->
    <div
      v-if="this.hideActions || this.disabled"
      class="text-center mb-0 disabled"
    >
      <p class="pb-0 mb-0 text-uppercase text-h6 two-lines-text-truncate">
        {{ title }}
      </p>
    </div>

    <!-- Header with optional actions and select list -->
    <div v-else>
      <p
        class="secondary text-center pb-0 mb-0 text-uppercase text-h6 two-lines-text-truncate"
      >
        {{ title }}
      </p>
      <div class="ma-2" style="clear:both;">
        <div
          style="display: flex; height: 100%; width: 100%; text-align: center;"
        >
          <div style="flex-grow: 1;">
            <!-- Select list -->
            <div v-if="valuesObject">
              <v-select
                :items="valuesObjectSelectionList"
                v-model="select"
                v-on:change="fetchValuesIfNeeded"
                class="ml-4 pt-2"
                style="z-index: 2;"
                :label="$t('common.filters.timeRange')"
              ></v-select>
            </div>
          </div>
          <!-- Action icon placed in the top right corner -->
          <v-btn
            v-if="actionPath"
            depressed
            text
            fab
            small
            @click="onActionLink"
            style="z-index:2; align-self: center;"
          >
            <v-icon small>{{ actionIcon }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div>
      <div
        class="d-flex align-center justify-center ma-0 pa-0"
        style="position: absolute; width: 100%; top: 2em; left:0; bottom: 0"
      >
        <!-- Icon centered in the background -->
        <div style="position: absolute; left: 50%; ">
          <div style="position: relative; left: -50%;">
            <v-icon
              v-if="backgroundIcon"
              class="valuewidget-icon"
              :class="iconColor"
              >{{ backgroundIcon }}</v-icon
            >
          </div>
        </div>

        <!-- Value placed on top of the background icon -->
        <div
          class="black--text text-center text-uppercase text-h4"
          :class="valueColorClass"
          style="z-index: 2; white-space: pre-line;"
        >
          <div v-if="loading" class="is-loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>

          <div v-else>
            {{ filteredValue }}
          </div>
        </div>

        <!-- Text below the icon and value -->
        <div
          class="primary--text lighten-2 text-center text-caption"
          style="position:absolute; z-index: 2; bottom: 0.2em"
        >
          {{ text }}
        </div>

        <!-- Disabled message -->
        <h1
          class="title d-flex mb-5"
          style="color: #000000;  opacity: 0.6; position: relative; text-align: center; padding: 10px;"
          v-if="this.disabled"
        >
          <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
          {{ $t("machines.featureNotAvailable") }}
        </h1>
      </div>
      <v-overlay
        v-if="this.disabled"
        absolute
        color="#fff"
        opacity=".9"
        class="text--primary"
      >
        <h1 class="title d-flex mb-5">
          <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
          {{ $t("machines.featureNotAvailable") }}
        </h1>
      </v-overlay>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ValueWidget",
  props: [
    "title",
    "value",
    "valueColor",
    "text",
    "description",
    "loading",
    "backgroundIcon",
    "backgroundIconColor",
    "actionIcon",
    "actionPath",
    "actionDetailsLink",
    "disabled",
    "valuesObject",
    "valuesObjectSelectionList",
    "defaultValueObjectProperty",
    "valueFilter",
    "hideActions"
  ],
  emits: ["lazyLoad"],
  data: () => ({
    fab: false,
    select: {},
    lazyLoadComplete: false
  }),
  mounted() {
    if (this.valuesObject) {
      if (!this.valuesObjectSelectionList) {
        this.valuesObjectSelectionList = Object.keys(this.valuesObject)
      }
      if (this.defaultValueObjectProperty) {
        this.select = this.defaultValueObjectProperty
      } else {
        this.select =
          this.valuesObjectSelectionList[0].value ??
          this.valuesObjectSelectionList[0]
      }
    }
  },
  methods: {
    onActionLink() {
      this.$router.push({
        path: this.actionPath,
        query: { show: this.actionDetailsLink }
      })
    },
    fetchValuesIfNeeded() {
      if (this.$listeners.lazyLoad && !this.lazyLoadComplete && !this.loading) {
        this.lazyLoadComplete = false
        this.$emit("lazyLoad")
        this.lazyLoadComplete = true
      }
    }
  },
  computed: {
    valueColorClass() {
      if (this.valueColor !== undefined && this.valueColor !== null) {
        return this.valueColor
      }
      return "accent--text"
    },
    iconColor() {
      if (
        this.backgroundIconColor !== undefined &&
        this.backgroundIconColor !== null
      ) {
        return this.backgroundIconColor
      }
      return "grey--text text--lighten-3"
    },
    filteredValue() {
      const val = this.valuesObject
        ? this.valuesObject[this.select]
        : this.value
      return this.valueFilter ? this.valueFilter(val) : val
    }
  }
}
</script>
<style lang="scss" scoped>
.valuewidget-container {
  min-height: 220px;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.valuewidget-icon {
  z-index: 0;
  opacity: 0.85;
  font-size: 10em;
}

.two-lines-text-truncate {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: 4rem; /* Fallback for non-webkit, line-height * 2 */
  line-height: 2rem;
  -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}
</style>
