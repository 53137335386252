<template>
  <v-card class="pa-0 pb-4 ma-4" color="white">
    <v-card-title class="text-uppercase">
      {{ title }}
    </v-card-title>
    <v-card-subtitle>
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text>
      <p v-for="descr in descriptions" :key="descr.id">
        {{ descr.text }}
      </p>
    </v-card-text>

    <v-container fluid px-md-6>
      <!-- <v-row> </v-row> -->
      <v-col xs="auto" class="mr-0">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('common.filters.filter')"
          hide-details
          class="ma-0 pa-0"
        ></v-text-field>
      </v-col>

      <v-row class="d-flex align-end">
        <v-col xs="auto" class="mr-0">
          <v-data-table
            :headers="headers"
            :items="linksArray"
            :search="search"
            :custom-filter="searchFilter"
            :loading="isDownloadingFile"
            hide-default-footer
            disable-pagination
            sort-by="name"
            sort-asc
          >
            <template v-slot:[`item.icon`]="{ item }">
              <v-icon>{{ item.icon }}</v-icon>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <span v-if="canDownloadItem()" @click="rowClickHandler(item)">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">mdi-download</v-icon>
                  </template>
                  {{ $t("machines.manuals.actions.downloadFile") }}
                </v-tooltip>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import fetchClient from "@/utils/fetchClient"

export default {
  props: ["title", "subtitle", "descriptions"],
  data() {
    return {
      linksArray: [],
      downloadingFiles: 0,
      componentPath: "",
      search: ""
    }
  },

  watch: {
    $route: function() {
      this.emptyContent()
      this.getComponentPath()
      this.getComponentsPaths()
    }
  },

  created() {
    this.getComponentPath()
    this.getComponentsPaths()
  },

  computed: {
    headers() {
      return [
        {
          text: "",
          align: "right",
          sortable: false,
          value: "icon",
          width: 20
        },
        {
          text: this.$t("machines.manuals.dataTable.headers.fileName"),
          align: "left",
          sortable: true,
          value: "displayname"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action"
        }
      ]
    },
    isDownloadingFile() {
      return this.downloadingFiles > 0
    }
  },

  methods: {
    getComponentsPaths() {
      // ------------- Warranty -------------
      if (this.componentPath == "warranty_claims") {
        const componentsPaths = this.$store.getters.warrantyClaims

        let obj = componentsPaths.links

        for (var k in obj) {
          this.linksArray.push({
            type: obj[k].type,
            displayname: obj[k].displayname,
            file: obj[k].file,
            icon: obj[k].icon
          })
        }
      }

      // ------------- Return form -------------
      if (this.componentPath == "return_form") {
        const componentsPaths = this.$store.getters.returnForm

        let obj = componentsPaths.links

        for (var k1 in obj) {
          this.linksArray.push({
            type: obj[k1].type,
            displayname: obj[k1].displayname,
            file: obj[k1].file,
            icon: obj[k1].icon
          })
        }
      }

      // ------------- Service Protol -------------
      if (this.componentPath == "service_protocols") {
        const componentsPaths = this.$store.getters.serviceProtocols

        let obj = componentsPaths.links

        for (var k2 in obj) {
          this.linksArray.push({
            type: obj[k2].type,
            displayname: obj[k2].displayname,
            file: obj[k2].file,
            icon: obj[k2].icon
          })
        }
      }

      // ------------- Service Manuals -------------
      if (this.componentPath == "service_manuals") {
        const componentsPaths = this.$store.getters.serviceManuals

        let obj = componentsPaths.links

        for (var k3 in obj) {
          this.linksArray.push({
            type: obj[k3].type,
            displayname: obj[k3].displayname,
            file: obj[k3].file,
            icon: obj[k3].icon
          })
        }
      }

      // ------------- Spare parts -------------
      if (this.componentPath == "spare_parts") {
        const componentsPaths = this.$store.getters.spareParts

        let obj = componentsPaths.links

        for (var k4 in obj) {
          this.linksArray.push({
            type: obj[k4].type,
            displayname:
              obj[k4].displayname + " [ " + obj[k4].description + " ]",
            file: obj[k4].file,
            icon: obj[k4].icon
          })
        }
      }
    },
    canDownloadItem() {
      // Uggly fix to use other permission for spare_parts
      if (this.componentPath === "spare_parts") {
        return this.$store.getters.canReadSpareParts
      }
      return this.$store.getters.canReadDownloads
    },
    // ---------------------------------------------

    async rowClickHandler(clickedItem) {
      let errorData = null
      let downloadPath = ""

      if (clickedItem.type == "HTTPLink") {
        window.open(clickedItem.file, "_blank")
      } else {
        if (this.componentPath == "service_manuals") {
          downloadPath =
            "/api/v1/downloads/open/service_manuals/" + clickedItem.file
        } else if (this.componentPath == "spare_parts") {
          downloadPath =
            "/api/v1/downloads/open/spare_parts/" + clickedItem.file
        } else {
          downloadPath = "/api/v1/downloads/open/service/" + clickedItem.file
        }

        try {
          const response = await fetchClient(this.$i18n).getResponse(
            "GET",
            downloadPath
          )

          if (response.status === 200) {
            const responseData = await response.arrayBuffer()
            const blob = new Blob([responseData], {
              type: response.headers.get("content-type")
            })

            let link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)

            // link.download = clickedItem.file
            link.target = "_blank"
            link.click()
          } else {
            errorData = await fetchClient(this.$i18n).getError(response)
          }
        } catch (error) {
          errorData = {
            message: this.$t("machines.manuals.messages.failedToDownload", {
              file: downloadPath
            }),
            causedBy: error.toString(),
            details: error.message
          }
        } finally {
          if (errorData != null) {
            errorData.requestMethod = "GET"
            errorData.requestUri = downloadPath

            const err = {
              response: {
                data: errorData
              }
            }
            this.$store.dispatch("setError", err)
          }
        }
      }
    },

    // ---------------------------------------------

    emptyContent() {
      if (this.linksArray.length > 0) {
        this.linksArray.splice(0)
      }
    },
    // ---------------------------------------------

    getComponentPath() {
      const parts = this.$route.path.split("/")
      this.componentPath = parts[2]
    },

    searchFilter(value, search, item) {
      return item.displayname.toLowerCase().includes(search.toLowerCase())
    }
  }
}
</script>

<style></style>
