export default engineSeconds => {
  if (engineSeconds !== undefined && engineSeconds !== null) {
    if (engineSeconds < 1.0) {
      return "-"
    }
    // Truncate the enginehours so it will be the same as is displayed in the remote control unit.
    // E.g. even if the engineSeconds value corresponds to 8.99 hours we still return 8 (integer).
    return parseInt("" + engineSeconds / 3600) + " h"
  }

  return "-"
}
