function EditableCircle(i18n) {
  this.lat = null
  this.lng = null
  this.radius = null
  this.i18n = i18n

  this.isEditEvent = false

  this.markerCircle = new window.google.maps.Marker({
    sName: "Geofence center",
    title: this.i18n.t("machines.location.map.geofenceCenter"),
    icon: {
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 3,
      fillColor: "#000",
      fillOpacity: 1,
      strokeColor: "#FFF",
      strokeWeight: 0.8
    },
    optimized: false,
    zIndex: 1
  })

  this.internalCircleOnRadiusChanged = null
  this.internalCircleOnCenterChanged = null
  this.internalCircleOptions = {
    strokeColor: "#FFFFFF",
    strokeOpacity: 0,
    strokeWeight: 2,
    draggable: false,
    editable: false,
    fillColor: "#FF0000",
    fillOpacity: 0.0,
    optimized: false,
    zIndex: 999
  }
  this.internalCircle = new window.google.maps.Circle(
    this.internalCircleOptions
  )

  this.externalOverlayOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.0,
    strokeWeight: 2,
    draggable: false,
    editable: false,
    fillColor: "#000000",
    fillOpacity: 0.6
  }
  this.externalOverlay = new window.google.maps.Circle(
    this.externalOverlayOptions
  )
}

EditableCircle.prototype.getLocation = function() {
  return { lat: this.lat, lng: this.lng }
}

EditableCircle.prototype.setLocation = function(latLng) {
  this.lat = typeof latLng.lat === "function" ? latLng.lat() : latLng.lat
  this.lng = typeof latLng.lng === "function" ? latLng.lng() : latLng.lng
}

/** Returns overlay center */
EditableCircle.prototype.getOverlayLocation = function() {
  return { lat: -this.lat, lng: (this.lng - 180) % 180 }
}

/** Returns overlay radius
 * @param {number} radius - radius of the circle
 */
EditableCircle.prototype.getOverlayRadius = function(radius) {
  return (
    20037508.34 /*radius of reversed circle covering entire globe*/ - radius
  )
}

/** Set circle position
 * @param {(google.maps.LatLng|google.maps.LatLngLiteral)} latLng - location of the center
 */
EditableCircle.prototype.setCenter = function(latLng) {
  this.setLocation(latLng)
  this.externalOverlay.setCenter(this.getOverlayLocation())
  this.markerCircle.setPosition(this.getLocation())
  !this.isEditEvent && this.internalCircle.setCenter(this.getLocation()) // When edit event then it is already changed by user so we need to synch only the overlay and marker
  this.isEditEvent = false
}

/** Set circle position
 * @param {number}  radius - radius of the circle
 */
EditableCircle.prototype.setRadius = function(radius) {
  this.radius = radius
  this.externalOverlay.setRadius(this.getOverlayRadius(radius))
  !this.isEditEvent && this.internalCircle.setRadius(radius) // When edit event then it is already changed by user so we need to synch only the overlay
  this.isEditEvent = false
}

/** Remove circle from the map */
EditableCircle.prototype.clear = function() {
  this.setMap(null)
}

/** Add circle to a map
 * {google.maps.Map} map - Map used to show circle on
 */
EditableCircle.prototype.setMap = function(map, onEditCallback) {
  this.map = map
  this.externalOverlay.setMap(map)
  this.internalCircle.setMap(map)
  this.markerCircle.setMap(map)

  if (map) {
    const self = this
    const setCenter = attr => {
      self.isEditEvent = true
      self.setCenter.call(self, attr)
    }
    const setRadius = attr => {
      self.isEditEvent = true
      self.setRadius.call(self, attr)
    }

    const bounds = this.internalCircle.getBounds()
    if (bounds) {
      this.map.fitBounds(bounds)
    }

    const setEvents = () => {
      if (!window.google) {
        setTimeout(setEvents, 100)
        return
      }
      this.internalCircleOnRadiusChanged = window.google.maps.event.addListener(
        this.internalCircle,
        "radius_changed",
        function() {
          setRadius(this.radius)
          onEditCallback && onEditCallback({ radius: this.radius })
        }
      )
      this.internalCircleOnCenterChanged = window.google.maps.event.addListener(
        this.internalCircle,
        "center_changed",
        function() {
          setCenter(this.center)
          onEditCallback &&
            onEditCallback({
              latitude: this.center.lat(),
              longitude: this.center.lng()
            })
        }
      )
    }
    setEvents()
  } else {
    this.internalCircleOnRadiusChanged &&
      window.google.maps.event.removeListener(
        this.internalCircleOnRadiusChanged
      )
    this.internalCircleOnCenterChanged &&
      window.google.maps.event.removeListener(
        this.internalCircleOnCenterChanged
      )
    this.internalCircleOnRadiusChanged = null
    this.internalCircleOnCenterChange = null
  }
}

/** Allows/deny to edit circle
 * {bool} editable - allow/deny
 */
EditableCircle.prototype.setEditable = function(editable) {
  this.internalCircle.setEditable(editable)
  const isSatelliteMapStyle = true
  //this.map.getMapTypeId() == window.google.maps.MapTypeId.HYBRID
  const strokeColor = isSatelliteMapStyle ? "#FFFFFF" : "#000000"
  if (editable) {
    this.markerCircle.setMap(null)
    this.internalCircle.setOptions({
      strokeOpacity: 0.5,
      strokeColor: strokeColor
    })
  } else {
    this.markerCircle.setMap(this.map)
    this.internalCircle.setOptions({ strokeOpacity: 0 })
  }
}

export default EditableCircle
