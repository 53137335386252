import fetchClient from "@/utils/fetchClient"

/* eslint-disable */

const coordinates_decimal_numbers_count = 5

function round(num, digits) {
  const divider = Math.pow(10, digits)
  return Math.round(num * divider) / divider
}

export default {
  state: {
    geofenceAreaIsUpdating: false,
    geofenceAreaLatitude: null,
    geofenceAreaLongitude: null,
    geofenceAreaRadius: null,
    geofenceAreaUnit: "km",
    geofenceAreaSave: false,
    geofenceAreaEditMode: false,

    geolocationEvents: [],

    machineLocalizationHistory: [],
    machineGeofencingSettings: null,
    machineGeolocationSettings: null,

    currentMachineLocalization: null,

    loadingMachineLocalizationHistory: false,
    loadingMachineGeofencingSettings: false,
    loadingMachineGeolocationSettings: false,
    loadingGeolocationEvents: false,
    loadingCurrentMachineLocalization: false
  },
  mutations: {
    setGeofenceAreaIsUpdating(state, payload) {
      state.seofenceAreaIsUpdating = payload
    },
    setGeofenceAreaLatitude(state, payload) {
      state.geofenceAreaLatitude = round(
        payload,
        coordinates_decimal_numbers_count
      )
    },
    setGeofenceAreaLongitude(state, payload) {
      state.geofenceAreaLongitude = round(
        payload,
        coordinates_decimal_numbers_count
      )
    },
    setGeofenceAreaRadius(state, payload) {
      state.geofenceAreaRadius = payload
    },
    setGeofenceAreaUnit(state, payload) {
      state.geofenceAreaUnit = payload
    },
    setGeofenceAreaEditMode(state, payload) {
      state.geofenceAreaEditMode = payload
    },
    setGeofenceAreaSave(state, payload) {
      state.geofenceAreaSave = payload
    },
    setLoadingMachineLocalizationHistory(state, payload) {
      state.loadingMachineLocalizationHistory = payload
    },
    setMachineLocalizationHistory(state, payload) {
      state.machineLocalizationHistory = payload
    },
    setLoadingMachineGeofencingSettings(state, payload) {
      state.loadingMachineGeofencingSettings = payload
    },
    setLoadingMachineGeolocationSettings(state, payload) {
      state.loadingMachineGeolocationSettings = payload
    },
    setLoadingGeolocationEvents(state, payload) {
      state.loadingGeolocationEvents = payload
    },
    setMachineGeofencingSettings(state, payload) {
      state.machineGeofencingSettings = payload
    },
    setMachineGeolocationSettings(state, payload) {
      state.machineGeolocationSettings = payload
    },
    setGeolocationEvents(state, payload) {
      state.geolocationEvents = payload
    },
    setCurrentMachineLocalization(state, payload) {
      state.currentMachineLocalization = payload
    },
    setLoadingCurrentMachineLocalization(state, payload) {
      state.loadingCurrentMachineLocalization = payload
    }
  },
  actions: {
    updateGeofenceArea(context, payload) {
      context.commit("setGeofenceAreaIsUpdating", true)
      if (payload.unit) {
        context.commit("setGeofenceAreaUnit", payload.unit)
      }
      if (payload.radius) {
        context.commit("setGeofenceAreaRadius", payload.radius)
      }
      if (payload.latitude) {
        context.commit("setGeofenceAreaLatitude", payload.latitude)
      }
      if (payload.longitude) {
        context.commit("setGeofenceAreaLongitude", payload.longitude)
      }
      context.commit("setGeofenceAreaIsUpdating", false)
    },
    setGeofenceAreaLatitude(context, geofenceAreaLatitude) {
      context.commit("setGeofenceAreaLatitude")
    },
    setGeofenceAreaLongitude(context, geofenceAreaLongitude) {
      context.commit("setGeofenceAreaLongitude", geofenceAreaLongitude)
    },
    setGeofenceAreaRadius(context, geofenceAreaRadius) {
      context.commit("setGeofenceAreaRadius", geofenceAreaRadius)
    },
    setGeofenceAreaUnit(context, geofenceAreaUnit) {
      context.commit("setGeofenceAreaUnit", geofenceAreaUnit)
    },
    setGeofenceAreaEditMode(context, geofenceAreaEditMode) {
      context.commit("setGeofenceAreaEditMode", geofenceAreaEditMode)
    },
    setGeofenceAreaSave(context, geofenceAreaSave) {
      context.commit("setGeofenceAreaSave", geofenceAreaSave)
    },
    fetchCurrentMachineLocalization(context, machine) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingCurrentMachineLocalization", true)

        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/latestMeasurements/M1`
          )
          .then(data => {
            if (data) {
              const locationParts = data.rawValue.split("|") //Format: "64.7448271|21.0342396|22.315"
              const result = {
                lat: +locationParts[0],
                lng: +locationParts[1],
                receiveTime: data.lastUpdated
              }
              context.commit("setCurrentMachineLocalization", result)
            } else {
              context.commit("setCurrentMachineLocalization", null)
            }
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingCurrentMachineLocalization", false)
          })
      })
    },
    fetchMachineLocalizationHistory(context, params) {
      context.commit("setLoadingMachineLocalizationHistory", true)

      const { tenantUid, serialNo, start, end } = params

      fetchClient(this.state.$i18n)
        .get(
          `/api/v1/tenants/${tenantUid}/machines/${serialNo}/measurements?q=range&start=${start}&end=${end}&sort=asc&parameters=M1`
        )
        .then(data => {
          const results = data.map(d => {
            const locationParts = d.rawValue.split("|") //Format: "64.7448271|21.0342396|22.315"
            return {
              lat: +locationParts[0],
              lng: +locationParts[1],
              receiveTime: d.receiveTime
            }
          })

          context.commit("setMachineLocalizationHistory", results)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoadingMachineLocalizationHistory", false)
        })
    },

    async fetchMachineGeolocationSettings(context, machine) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingMachineGeolocationSettings", true)
        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/location/tracking`
          )
          .then(data => {
            const settings = data
            context.commit("setMachineGeolocationSettings", settings)
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingMachineGeolocationSettings", false)
          })
      })
    },

    async removeMachineGeolocationSettings(context, machine) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingMachineGeolocationSettings", true)
        fetchClient(this.state.$i18n)
          .delete(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/location/tracking`
          )
          .then(() => {
            context.commit("setMachineGeolocationSettings", null)
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingMachineGeolocationSettings", false)
          })
      })
    },

    async saveMachineGeolocationSettings(
      context,
      { machine, geolocationSettings }
    ) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingMachineGeolocationSettings", true)
        fetchClient(this.state.$i18n)
          .put(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/location/tracking`,
            geolocationSettings
          )
          .then(() => {
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingMachineGeolocationSettings", false)
          })
      })
    },

    async fetchMachineGeofencingSettings(context, { machine }) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingMachineGeofencingSettings", true)
        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/location/geofence`
          )
          .then(data => {
            let radius = data?.desiredOptions?.radius || 300
            context.commit("setMachineGeofencingSettings", data)

            context.commit(
              "setGeofenceAreaLatitude",
              data?.desiredOptions?.latitude
            )
            context.commit(
              "setGeofenceAreaLongitude",
              data?.desiredOptions?.longitude
            )

            const unit = data?.radiusUnit || "km"
            const radiusInMeters = data?.desiredOptions?.radius || 300

            context.commit("setGeofenceAreaRadius", radiusInMeters)
            context.commit("setGeofenceAreaUnit", unit)
            context.commit("setGeofenceAreaSave", false)
            context.commit("setGeofenceAreaEditMode", false)

            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingMachineGeofencingSettings", false)
          })
      })
    },

    async saveMachineGeofencingSettings(
      context,
      { machine, geofencingSettings }
    ) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingMachineGeofencingSettings", true)
        fetchClient(this.state.$i18n)
          .put(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/location/geofence`,
            geofencingSettings
          )
          .then(() => {
            context.commit("setMachineGeofencingSettings", geofencingSettings)
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingMachineGeofencingSettings", false)
          })
      })
    },

    async removeMachineGeofencingSettings(context, machine) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingMachineGeofencingSettings", true)
        fetchClient(this.state.$i18n)
          .delete(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/location/geofence`
          )
          .then(() => {
            context.commit("setMachineGeofencingSettings", null)
            context.dispatch("updateGeofenceArea", {
              latitude: null,
              longitude: null,
              radius: 300,
              unit: "km"
            })
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingMachineGeofencingSettings", false)
          })
      })
    },

    async fetchGeolocationEvents(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingGeolocationEvents", true)

        const { tenantUid, serialNo, start, end } = params

        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${tenantUid}/machines/${serialNo}/location/events?q=range&start=${start}&end=${end}`
          )
          .then(data => {
            context.commit("setGeolocationEvents", data)
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingGeolocationEvents", false)
          })
      })
    }
  },
  getters: {
    geofenceAreaIsUpdating(state) {
      return state.geofenceAreaIsUpdating
    },
    geofenceAreaLatitude(state) {
      return state.geofenceAreaLatitude
    },
    geofenceAreaLongitude(state) {
      return state.geofenceAreaLongitude
    },
    geofenceAreaRadius(state) {
      return state.geofenceAreaRadius
    },
    geofenceAreaUnit(state) {
      return state.geofenceAreaUnit
    },
    geofenceAreaEditMode(state) {
      return state.geofenceAreaEditMode
    },
    geofenceAreaSave(state) {
      return state.geofenceAreaSave
    },
    loadingMachineLocalizationHistory(state) {
      return state.loadingMachineLocalizationHistory
    },
    machineLocalizationHistory(state) {
      return state.machineLocalizationHistory
    },
    loadingMachineGeolocationSettings(state) {
      return state.loadingMachineGeolocationSettings
    },
    machineGeolocationSettings(state) {
      return state.machineGeolocationSettings
    },
    loadingMachineGeofencingSettings(state) {
      return state.loadingMachineGeofencingSettings
    },
    machineGeofencingSettings(state) {
      return state.machineGeofencingSettings
    },
    geofenceAreaLatitude(state) {
      return state.geofenceAreaLatitude
    },
    geofenceAreaLongitude(state) {
      return state.geofenceAreaLongitude
    },
    geofenceAreaRadius(state) {
      return state.geofenceAreaRadius
    },
    geofenceAreaEditMode(state) {
      return state.geofenceAreaEditMode
    },
    geofenceAreaSave(state) {
      return state.geofenceAreaSave
    },
    loadingGeolocationEvents(state) {
      return state.loadingGeolocationEvents
    },
    geolocationEvents(state) {
      return state.geolocationEvents
    },
    currentMachineLocalization(state) {
      return state.currentMachineLocalization
    },
    loadingCurrentMachineLocalization(state) {
      return state.loadingCurrentMachineLocalization
    }
  }
}
