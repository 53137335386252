var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fluid d-flex ma-0 pa-0 fill-height justify-center align-center saira-medium-font"},[_c('v-card',{staticClass:"rounded-lg px-md-4",style:(_vm.minWidth),attrs:{"elevation":"24","outlined":"","raised":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-height":"32px"},attrs:{"src":_vm.$store.getters.logoBlack}})])],1),_c('v-row',[_c('v-col',{staticClass:"mt-md-4 mb-md-10 pb-md-4",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center mb-0 changePasswordText"},[_vm._v(_vm._s(_vm.$t("login.changePassword")))])])],1),_c('v-form',{ref:"firstLoginForm",attrs:{"autocomplete":"off"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-2 pb-2",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","prepend-icon":"mdi-lock","type":"password","required":"","label":`${_vm.$t('login.newPassword')}*`,"rules":[
                  _vm.validationRules.awsPasswordRequirements(this.newpassword)
                ]},model:{value:(_vm.newpassword),callback:function ($$v) {_vm.newpassword=$$v},expression:"newpassword"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-2 pb-2",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","prepend-icon":"mdi-lock","type":"password","required":"","label":`${_vm.$t('login.confirmNewPassword')}*`,"rules":[
                  _vm.validationRules.mustMatch(
                    this.confirmpassword,
                    this.newpassword
                  )
                ]},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}})],1)],1),(_vm.showPasswordStrength)?_c('v-row',[_c('v-col',{staticClass:"text-center pl-10 pr-2",attrs:{"cols":"12"}},[_c('p',{staticClass:"pb-0 mb-0"},[_vm._v(_vm._s(_vm.$t("login.passwordStrength")))]),_c('password',{staticClass:"mt-0 pt-0 pl-0",attrs:{"strength-meter-only":true},model:{value:(_vm.newpassword),callback:function ($$v) {_vm.newpassword=$$v},expression:"newpassword"}})],1)],1):_vm._e(),(_vm.error)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('app-alert',{attrs:{"errItem":_vm.error},on:{"dismissed":_vm.dismissAlert}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pl-10 pr-2",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"changePasswordBtnText",attrs:{"disabled":_vm.loading,"rounded-lg":"","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.onChangePassword.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("login.changePassword")))])],1)],1),(_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"active":"","indeterminate":""}})],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"saira-regular-font footerText"},[_c('v-row',[_c('v-col',{staticClass:"text-center mt-10 pt-10",attrs:{"cols":"12"}},[_vm._v("   ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }