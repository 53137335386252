<template>
  <v-card class="text-center">
    <div class="text-uppercase text-h6 disabled" v-if="this.disabled">
      {{ $t("machines.labels.nextServiceIn") }}
    </div>

    <div v-else class="secondary text-uppercase text-h6">
      {{ $t("machines.labels.nextServiceIn") }}
    </div>

    <v-card-text class="widget-body pa-0 d-flex justify-center align-center">
      <v-icon
        class="widget-icon grey--text text--lighten-3"
        v-if="!this.disabled"
      >
        mdi-wrench
      </v-icon>

      <v-container fluid class="widget-container text-center">
        <v-row dense class="black--text text-uppercase text-h4">
          <v-col
            v-for="service in services"
            :key="service.key"
            cols="12"
            :md="12 / services.length"
          >
            {{ service.scheduled }}
          </v-col>
        </v-row>

        <v-row dense class="primary--text lighten-2">
          <v-col
            v-for="service in services"
            :key="service.key"
            cols="12"
            :md="12 / services.length"
          >
            {{ service.name }}
          </v-col>
        </v-row>
      </v-container>

      <v-btn
        v-if="actionLink && actionIcon && !this.disabled"
        class="widget-action-button"
        depressed
        text
        fab
        small
        :to="{ path: actionLink }"
      >
        <v-icon small>{{ actionIcon }}</v-icon>
      </v-btn>
    </v-card-text>

    <v-overlay
      v-if="this.disabled"
      absolute
      color="#fff"
      opacity=".9"
      class="text--primary"
    >
      <h1 class="title d-flex mb-5">
        <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
        {{ $t("machines.featureNotAvailable") }}
      </h1>
    </v-overlay>
  </v-card>
</template>

<script>
export default {
  props: {
    upcoming: { type: Array, required: true },
    actionLink: { type: String },
    actionIcon: { type: String },
    disabled: { type: Boolean }
  },
  created() {},
  computed: {
    services() {
      return this.upcoming.map((service, index) => {
        let svc = {
          key: `${service.step}-${index}`,
          name: `${this.$t("machines.service")} ${service.number}`
        }

        if (service.status !== "upcoming") {
          svc.scheduled = this.$t("common.enums.day.now")
          return svc
        }

        let scheduled = []
        if (service.remainingHours !== null) {
          scheduled.push(
            `${service.remainingHours} ${this.$t("common.units.hourShort")}`
          )
        }

        if (service.remainingDays !== null) {
          scheduled.push(this.$tc("common.units.day", service.remainingDays))
        }

        svc.scheduled = scheduled.join(" / ")

        return svc
      })
    }
  }
}
</script>

<style scoped>
.widget-body {
  position: relative;
  min-height: 220px;
}

.widget-icon {
  position: absolute;
  font-size: 10em;
}

.widget-container {
  position: relative;
}

.widget-action-button {
  position: absolute;
  right: 0.5em;
  top: 0.4em;
}

.disabled {
  background-color: #eceff1;
}
</style>
