<template>
  <v-card>
    <!-- TENANT AND DATE PICKERS -->
    <v-row class="mx-2 mt-1">
      <v-col class="ma-0 pa-0">
        <TenantHome class="no-boxshadows" @showTenants="$emit('showTenants', $event)"></TenantHome>
      </v-col>
      <v-col class="ma-0 pa-0">
        <v-card flat>
          <v-card-text>
            <!-- Date range picker -->
            <v-row>
              <v-col cols="6">
                <!-- Date preset select -->
                <v-select :items="timeRangeItems" item-value="value" item-text="text" v-model="selectedTimeRange"
                  :label="$t('common.filters.timeRange')" clearable></v-select>
              </v-col>
              <v-col cols="6">
                <!-- Custom date -->
                <v-menu ref="datePickerMenu" v-model="datePickerMenu" :close-on-content-click="false"
                  :return-value.sync="dates" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="dateRangeText" readonly :placeholder="$t('common.placeholders.selectDates')"
                      :disabled="selectedTimeRange !== 'custom'" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="datePickerMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" :disabled="dates[0] === undefined || dates[1] === undefined
                      " @click="$refs.datePickerMenu.save(dates)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card-text class="ma-0 pa-0">
      <v-row no-gutters>
        <!-- Utilization widget -->
        <v-col>
          <v-card flat>
            <v-card-text>
              <Pie :title="$t('fleetAnalytics.widgets.utilization')" :metricsData="utilizationData" formatHours />
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Segments widget -->
        <v-col>
          <v-card flat>
            <v-card-text>
              <Pie :title="$t('fleetAnalytics.widgets.segments')" :metricsData="segmentsData" formatHours />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!-- Summary table -->
        <v-col class="pt-0">
          <v-card flat>
            <v-card-text class="pt-0">
              <v-data-table :headers="headers" :items="selectedTenantsFleetSummary"
                class="elevation-1 mt-2 fleet-summary-table" :class="{ disabled: isLoading }" :footer-props="{
                  'items-per-page-options': [20, 50, 100, -1]
                }" :items-per-page="50">
                <!-- Table templates -->
                <!-- Machines -->
                <template v-slot:[`item.machines`]="{ item }">
                  <div v-for="machine in item.machines" :key="machine.productName">
                    <v-row class="ma-0">
                      <!-- Icon -->
                      <v-col class="d-flex flex-column flex-grow-0 justify-center align-center pl-0">
                        <v-avatar size="96">
                          <v-img :src="'/products/jpg/' + machine.productId + '.jpg'"
                            onerror="this.onerror=null;this.src='/products/jpg/default.jpg';this.title='Missing product image'"
                            :alt="machine.productName" />
                        </v-avatar>
                        <div>{{ machine.productName }}</div>
                      </v-col>
                      <!-- Details -->
                      <v-col class="d-flex flex-column justify-center">
                        <div>
                          {{ $t("fleetAnalytics.dataTable.machines.count") }}:
                          {{ machine.count }}
                        </div>
                        <div>
                          {{
                            $t("fleetAnalytics.dataTable.machines.engineHours")
                          }}: {{ machine.engineSeconds | engineHourFilter }}
                        </div>
                        <div>
                          {{
                            $t(
                              "fleetAnalytics.dataTable.machines.serviceAgreements"
                            )
                          }}: {{ machine.serviceAgreements }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider inset class="ma-0 pa-0"></v-divider>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TenantHome from "@/components/TenantHome"
import Pie from "@/components/widgets/Pie"
export default {
  name: "FleetAnalyticsView",
  components: { TenantHome, Pie },
  data() {
    return {
      // Date pickers
      dates: [],
      datePickerMenu: false,
      selectedTimeRange: null,
      dateRangeText: "",

      // Table
      isLoading: false,
      headers: [
        {
          text: this.$t("fleetAnalytics.dataTable.headers.tenant"),
          value: "tenantName",
          hide: "xs",
          class: "fleet-analytics-header"
        },
        {
          text: this.$t("fleetAnalytics.dataTable.headers.machines"),
          value: "machines",
          hide: "xs",
          class: "fleet-analytics-header"
        },
        {
          text: this.$t("fleetAnalytics.dataTable.headers.segments"),
          value: "segmentsText",
          hide: "xs",
          class: "fleet-analytics-header"
        },
        {
          text: this.$t("fleetAnalytics.dataTable.headers.contacts"),
          value: "contacts",
          hide: "xs",
          class: "fleet-analytics-header"
        }
      ],
      contacts: []
    }
  },
  async created() {
    await this.fetchContacts()
    this.setDateRange()
  },
  computed: {
    timeRangeItems() {
      return [
        { text: this.$t("common.enums.day.today"), value: "today" },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            7
          )}`,
          value: "7 days"
        },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            30
          )}`,
          value: "30 days"
        },
        { text: this.$t("common.filters.customRange"), value: "custom" }
      ]
    },
    filteredMachines() {
      let originalMachines = this.$store.getters.machinesFilteredByTenants(
        this.$store.getters.selectedTenantArray
      )

      let filteredMachines = []
      if (this.selectedTimeRange) {
        let customUtilizations = this.$store.getters.machinesUtilizations
        for (let machine of originalMachines) {
          let newMachine = Object.assign({}, machine)
          let found = customUtilizations.find(
            u => u.serialNo == newMachine.serialNo
          )
          newMachine.engineSeconds = found?.engineSeconds ?? 0
          filteredMachines.push(newMachine)
        }
      } else {
        filteredMachines = originalMachines
      }

      return filteredMachines
    },
    selectedTenantsFleetSummary() {
      let selectedTenantsFleetSummary = this.filteredMachines.reduce(
        (acc, cur) => {
          // If no tenant
          if (!acc[cur.tenantName]) {
            acc[cur.tenantName] = {
              tenantName: cur.tenantName,
              machines: {},
              segments: {},
              contacts: this.contacts[cur.tenantUid] ?? ""
            }
          }
          // if no machine
          if (!acc[cur.tenantName].machines[cur.productName]) {
            acc[cur.tenantName].machines[cur.productName] = {
              productName: cur.productName,
              productId: cur.productId,
              count: 0,
              engineSeconds: 0,
              serviceAgreements: 0
            }
          }

          acc[cur.tenantName].machines[cur.productName].count += 1
          acc[cur.tenantName].machines[cur.productName].engineSeconds +=
            cur.engineSeconds
          acc[cur.tenantName].machines[
            cur.productName
          ].serviceAgreements += cur.serviceAgreement ? 1 : 0
          acc[cur.tenantName].segments[cur.segmentName] =
            1 + (acc[cur.tenantName].segments[cur.segmentName] ?? 0)
          return acc
        },
        {}
      )

      let retval = Object.values(selectedTenantsFleetSummary)
      for (let fs of retval) {
        fs.machines = Object.values(fs.machines)
        fs.segmentsText = Object.keys(fs.segments)
          .map(seg => `${seg}: ${fs.segments[seg]}`)
          .join("\n")
      }
      return retval
    },
    utilizationData() {
      // Group machines by name
      let machinesByNames = Object.groupBy(
        this.filteredMachines,
        ({ productName }) => productName
      )

      let machinesThatHaveWorked = {}

      // Sum engine hours
      let labels = Object.keys(machinesByNames)
      for (let label of labels) {
        const sum = machinesByNames[label].reduce(
          (acc, cur) => acc + cur.engineSeconds,
          0
        )

        // Filter machines with less than 1 engine hours
        if (sum > 3600) {
          machinesThatHaveWorked[label] = sum
        }
      }

      return {
        labels: Object.keys(machinesThatHaveWorked),
        dataSet: Object.values(machinesThatHaveWorked)
      }
    },
    segmentsData() {
      // Group machines by name
      let machinesBySegment = Object.groupBy(
        this.filteredMachines,
        ({ segmentName }) => segmentName
      )

      let machinesThatHaveWorked = {}

      // Sum engine hours
      let labels = Object.keys(machinesBySegment)
      for (let label of labels) {
        const sum = machinesBySegment[label].reduce(
          (acc, cur) => acc + cur.engineSeconds,
          0
        )

        // Filter machines with less than 1 engine hours
        if (sum > 3600) {
          machinesThatHaveWorked[label] = sum
        }
      }

      return {
        labels: Object.keys(machinesThatHaveWorked),
        dataSet: Object.values(machinesThatHaveWorked)
      }
    },
    serials() {
      return this.$store.getters
        .machinesFilteredByTenants(this.$store.getters.selectedTenantArray)
        .map(m => m.serialNo)
    },
    userTenantUid() {
      return this.$store.getters.loggedInUser.tenantUid
    }
  },
  methods: {
    setDateRange() {
      let startDate = new Date()
      let offset = 0

      if (!this.selectedTimeRange) {
        this.dates = []
        return
      } else if (this.selectedTimeRange === "today") {
        offset = 0
      } else if (this.selectedTimeRange === "7 days") {
        offset = 6 // Becomes 7 since today will be included
      } else if (this.selectedTimeRange === "30 days") {
        offset = 29 // Becomes 30 since today will be included
      }

      startDate.setDate(startDate.getDate() - offset)
      this.dates = [
        startDate.toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10)
      ]
    },
    fetchCustomMachinesUtilizations() {
      this.$store.dispatch("fetchMachinesUtilizations", {
        tenantUid: this.userTenantUid,
        start: this.dates[0],
        end: this.dates[1],
        serials: this.serials
      })
    },
    async fetchContacts() {
      try {
        await this.$store.dispatch("fetchTenantContacts", {
          tenantUid: this.userTenantUid,
          withSubtenants: true
        })

        this.contacts = this.$store.getters.tenantContacts.reduce(
          (acc, cur) => {
            if (!acc[cur.tenantUid]) {
              acc[cur.tenantUid] = `${cur.name} (${cur.type})`
            } else {
              acc[cur.tenantUid] += "\n " + cur.name
            }
            return acc
          },
          {}
        )
      } catch (error) {
        this.error = { type: "error", ...error }
        this.contacts = {}
      }
    }
  },
  watch: {
    selectedTimeRange(newRange) {
      if (newRange == "" || newRange !== "custom") {
        this.setDateRange()
        // Fetch data
      } else {
        // Display date picker. OK callback will trigger fetch
        this.datePickerMenu = true
      }
    },
    dates(newDates) {
      // Always set earliest date as start date
      if (!newDates || newDates.length !== 2) {
        this.dateRangeText = ""
        return
      }
      if (newDates[0] > newDates[1]) {
        newDates.reverse()
      }
      this.dateRangeText = newDates.join(" ~ ")
      this.fetchCustomMachinesUtilizations()
    }
  }
}
</script>

<style type="scss">
.no-boxshadows {
  box-shadow: unset !important;
}

.fleet-analytics-header {
  background-color: #ffcd00 !important;
  color: black !important;
  text-transform: uppercase;
}

.fleet-summary-table {
  white-space-collapse: preserve-breaks;
}
</style>
