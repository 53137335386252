import Formatter from "@/utils/formatter"
export default (value, filterFunction, i18n) => {
  if (value !== undefined && value !== "") {
    if (filterFunction !== undefined && filterFunction !== null) {
      switch (filterFunction) {
        case "daysFromNow":
          return Formatter(i18n).formatDaysFromNow(value)
        case "localeString":
          var localeString = new Date(value)
          return localeString.toLocaleString()
        case "secondsToWholeHours":
          return Number(value / 3600).toFixed(0)
        default:
          return i18n ? i18n.t("common.invalidUsage") : "Invalid usage"
      }
    }
  }

  return ""
}
