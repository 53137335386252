function limitations(i18n) {
  const items = [
    {
      text:
        i18n.t(
          "machines.rental.lease.settings.restrictionTypes.stopEngineAfter"
        ) +
        " " +
        i18n.tc("common.units.second", 30),
      value: 1
    },
    {
      text:
        i18n.t(
          "machines.rental.lease.settings.restrictionTypes.stopEngineAfter"
        ) +
        " " +
        i18n.tc("common.units.second", 300),
      value: 2
    },
    {
      text: i18n.t(
        "machines.rental.lease.settings.restrictionTypes.disableToolA"
      ),
      value: 4
    },
    {
      text: i18n.t(
        "machines.rental.lease.settings.restrictionTypes.reduceMovementSpeedToLvl3"
      ),
      value: 8
    },
    {
      text: i18n.t(
        "machines.rental.lease.settings.restrictionTypes.blinkLights"
      ),
      value: 16
    }
  ]
  return {
    items: items,
    getText: value => {
      return items.find(i => i.value == value)?.text
    }
  }
}

export default limitations
