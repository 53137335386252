<template>
  <v-card class="pie-container">
    <!-- Unprovisioned machine title -->
    <div v-if="this.disabled" class="text-center mb-0 disabled">
      <p class="pb-0 mb-0 text-uppercase text-h6 two-lines-text-truncate">
        {{ title }}
      </p>
    </div>

    <!-- Provisioned machine title -->
    <div v-else class="secondary text-center">
      <p class="text-uppercase text-h6">{{ title }}</p>
    </div>

    <div>
      <div
        class="d-flex align-center justify-center ma-0 pa-0 overflow-y-auto"
        style="position: absolute; width: 100%; top: 2em; left:0; bottom: 0"
      >
        <apexchart
          :width="apexChartWidth"
          height="150"
          type="pie"
          :options="chartOptions"
          :series="series"
        />
      </div>
    </div>

    <v-overlay
      v-if="this.disabled"
      absolute
      color="#fff"
      opacity=".9"
      class="text--primary"
    >
      <h1 class="title d-flex mb-5">
        <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
        {{ $t("machines.featureNotAvailable") }}
      </h1>
    </v-overlay>
  </v-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts"
import colors from "vuetify/lib/util/colors"

export default {
  name: "Pie",
  props: {
    title: String,
    metricsData: Object,
    disabled: Boolean,
    formatHours: Boolean,
  },
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    apexChartWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300px"
        case "sm":
        case "md":
          return "400px"
        case "lg":
        case "xl":
          return (this.$vuetify.breakpoint.width * 0.3).toFixed(0) + "px"
      }
      return "100%"
    },
    series() {
      return this.metricsData.dataSet
    },
    chartOptions() {
      return {
        chart: {
          fontFamily: "Roboto, sans-serif"
        },
        noData: {
          text: this.$i18n.t("$vuetify.noDataText"),
          style: {
            fontFamily: "Roboto, sans-serif"
          }
        },
        tooltip: {
          y: this.formatHours
            ? {
                formatter: function(value) {
                  return `${Math.floor(value / 3600)} h`
                }
              }
            : {}
        },
        colors: [
          colors.orange.darken1,
          colors.green.lighten2,
          colors.blueGrey.darken3,
          colors.amber.lighten2
        ],
        dataLabels: {
          enabled: true,
          dropShadow: false,
          formatter: function(val) {
            return val.toFixed(0) + " %"
          },
          style: {
            fontFamily: "Roboto, sans-serif",
            fontWeight: 300
          }
        },
        legend: {
          show: this.series.length > 0,
          markers: {
            radius: 3,
            width: 24,
            height: 10
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -15
            },
            donut: {
              size: "50%"
            }
          }
        },
        labels: this.metricsData.labels,
        responsive: [
          {
            breakpoint: 600,
            options: {
              legend: {
                position: "bottom",
                offsetY: 0
              }
            }
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pie-container {
  min-height: 220px;
  position: relative;
  overflow: hidden;
  height: 100%;
}
</style>
