<template>
  <v-card v-if="message" flat class="ma-0">
    <v-card-title class="font-weight-bold text-h5">
      {{ message.title }}
    </v-card-title>
    <v-card-text>
      <div class="black--text" v-html="message.description"></div>
      <div class="caption pt-4">{{ formatDate(message.pubDate) }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import formatter from "@/utils/formatter"

export default {
  props: ["message"],
  data: () => ({}),
  methods: {
    formatDate(date) {
      if (date) {
        return formatter(this.$i18n).formatDate(date)
      }
      return ""
    }
  }
}
</script>
