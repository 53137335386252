<template>
  <v-container class="grab-tenants-view">
    <v-row>
      <v-col
        cols="auto"
        v-if="showTenants"
        class="pa-0 ml-4 mt-4 mb-4 d-flex align-stretch"
      >
        <TenantTree />
      </v-col>
      <!-- right-hand side content view -->
      <v-col cols="9,auto" class="home-background pa-0 ma-4">
        <router-view @showTenants="onShowTenants"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TenantTree from "@/components/tenants/TenantTree"

export default {
  components: {
    TenantTree
  },
  data() {
    return {
      showTenants: false
    }
  },
  created() {},
  computed: {},
  methods: {
    onShowTenants() {
      this.showTenants = !this.showTenants
    }
  },
  watch: {}
}
</script>

<style></style>
