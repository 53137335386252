export default {
  home: {
    documents: {
      description: "The full library of all our Brokk models."
    },
    greeting: "Welcome to My Brokk",
    machines: {
      description: "View information about your Brokk machines.",
      register: "Register a machine"
    },
    webshop: {
      description: "Purchase spare parts in our webshop.",
      noAccess: {
        description: "Contact your local administrator to learn more.",
        title: "Webshop access has not been granted"
      }
    }
  }
}
