<template>
  <v-container class="pa-0">
    <v-row class="d-flex align-end">
      <v-col xs="auto" class="mr-0">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('common.filters.filter')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="auto" class="mx-0 px-0">
        <v-tooltip top v-if="canEditMeasurements">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                icon
                @click="refreshSettings"
                :loading="refreshLoading"
                :disabled="refreshLoading"
              >
                <v-icon>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="refreshLoader">
                    <v-icon>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </span>
          </template>
          {{ $t('common.actions.refresh') }}
        </v-tooltip>

        <v-tooltip top v-if="canEditMeasurements">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                icon
                @click="pushStashedChanges"
                :disabled="!hasStashedChanges"
              >
                <v-icon>mdi-content-save</v-icon>
                <template v-slot:loader>
                  <span>
                    <v-icon>mdi-content-save</v-icon>
                  </span>
                </template>
              </v-btn>
            </span>
          </template>
          {{ $t('common.actions.applyChanges') }}
        </v-tooltip>
      </v-col>
    </v-row>

    <v-data-table
      v-if="machineSettings"
      :headers="headers"
      :items="machineSettings"
      :loading="isLoading"
      :search="search"
      @click:row="rowClickHandler"
    >
      <template v-slot:[`item.updateInterval`]="{ item }">{{
        item.updateInterval | optionalTextFilter
      }}</template>

      <template v-slot:[`item.status`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div class="d-flex justify-center">
              <v-icon v-on="on" :color="getColor(item.status)">{{
                getIcon(item.status)
              }}</v-icon>
            </div>
          </template>
          <span>{{ getToolTip(item.status, item.statusMessage) }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditSettingsDialog
      v-if="settingsDialog"
      show="true"
      :settingsDialog="settingsDialog"
    />
  </v-container>
</template>

<script>
import EditSettingsDialog from "../../dialogs/EditSettingsDialog"

export default {
  name: "MachineSettings",
  props: ["machine"],
  components: { EditSettingsDialog },

  data: () => ({
    parameters: [],
    search: "",
    settingState: {
      DEFAULT: "default",
      APPLIED: "applied",
      PENDING: "pending",
      MODIFIED: "modified",
      ERROR: "error"
    },
    settingsDialog: null,
    refreshLoading: false
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("machines.dataTable.headers.id"),
          align: "center",
          sortable: true,
          filterable: true,
          value: "parameterId",
          width: "5%",
        },
        {
          text: this.$t("machines.dataTable.headers.number"),
          align: "end",
          sortable: true,
          filterable: true,
          value: "oldParameter",
          width: "8%",
        },
        {
          text: this.$t("machines.dataTable.headers.category"),
          align: "start",
          sortable: true,
          filterable: true,
          value: "category"
        },
        {
          text: this.$t("machines.dataTable.headers.device"),
          align: "start",
          sortable: true,
          filterable: true,
          value: "device"
        },
        {
          text: this.$t("machines.dataTable.headers.description"),
          align: "start",
          sortable: true,
          filterable: true,
          value: "description"
        },
        {
          text: this.$t("machines.dataTable.headers.updateInterval"),
          align: "center",
          sortable: false,
          filterable: false,
          value: "updateInterval"
        },
        {
          text: this.$t("machines.dataTable.headers.status"),
          align: "center",
          sortable: false,
          filterable: false,
          value: "status",
          width: "5%",
        }
      ]
    },
    machineSettings() {
      const mergedSettings = [
        ...this.$store.getters.machineSettings,
        ...this.$store.getters.stashedChanges.filter(
          x => x.machineId == this.machine.serialNo
        )
      ]

      const uniqueById = [
        ...new Map(
          mergedSettings.map(item => [item.parameterId, item])
        ).values()
      ]
      return uniqueById
    },
    hasStashedChanges() {
      return this.$store.getters.stashedChanges.length > 0
    },
    isLoading() {
      return this.$store.getters.isLoadingSettings
    },
    canEditMeasurements() {
      return this.$store.getters.canEditMeasurements
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getToolTip(status, message) {
      switch (status) {
        case this.settingState.MODIFIED:
          return this.$t("machines.messages.unsavedChanges")
        case this.settingState.APPLIED:
          return this.$t("machines.messages.changesApplied")
        case this.settingState.PENDING:
          return this.$t("machines.messages.applyChangesPending")
        case this.settingState.ERROR:
          return message !== undefined ? message : this.$t("machines.messages.errorApplyingChanges")
        default:
          return ""
      }
    },
    getColor(status) {
      switch (status) {
        case this.settingState.MODIFIED:
          return "primary"
        case this.settingState.APPLIED:
          return "success"
        case this.settingState.PENDING:
          return "primary"
        case this.settingState.ERROR:
          return "orange"
        default:
          return ""
      }
    },
    getIcon(status) {
      switch (status) {
        case this.settingState.MODIFIED:
          return "mdi-pencil"
        case this.settingState.APPLIED:
          return "mdi-check-circle"
        case this.settingState.PENDING:
          return "mdi-swap-vertical"
        case this.settingState.ERROR:
          return "mdi-alert"
        default:
          return ""
      }
    },

    pushStashedChanges() {
      this.$store.dispatch("pushStashedChanges", {
        serialNo: this.machine.serialNo,
        tenantUid: this.machine.tenantUid
      })
    },
    fetchData() {
      const langCode = this.$store.getters.dataLangCode

      this.$store.dispatch("fetchMachineSettings", {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo,
        langCode: langCode
      })
      this.$store.dispatch("clearStashedChanges")
    },
    refreshSettings() {
      this.refreshLoading = true
      this.fetchData()
      setTimeout(() => {
        this.refreshLoading = false
      }, 1000)
    },
    rowClickHandler(clickedItem) {
      if (!this.canEditMeasurements) {
        return
      }

      let dialog = {
        item: { ...clickedItem },
        buttons:
          clickedItem.editMode !== "never"
            ? [
                {
                  label: this.$t("common.actions.ok"),
                  action: () => {
                    this.$store.dispatch("updateStashedChanges", {
                      ...this.$data.settingsDialog.item,
                      machineId: this.machine.serialNo
                    })
                  }
                },
                {
                  label: this.$t("common.actions.cancel"),
                  action: () => {}
                }
              ]
            : []
      }
      this.$data.settingsDialog = dialog
    }
  }
}
</script>
<style>
.stateCol {
  width: 130px;
}
.v-data-table__wrapper tr {
  cursor: pointer;
}
.v-data-table__wrapper th {
  cursor: default;
}
.refreshLoader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
