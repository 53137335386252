<template>
  <v-expansion-panel-header class="mr-n6 px-0 py-0" :class="messageColor" @click="onItemClicked">
    <v-container fluid class="fill-height mr-n6 my-0 py-0" justify="center">
      <v-icon :class="getIconClass(isRead)" class="ma-0 pa-0">mdi-circle-medium</v-icon>
      <v-card :class="messageColor" flat align="left" justify="center" class="title-card my-0 py-0">
        <v-card-title class="ml-3 my-0 py-2 px-0">
          <div class="py-0">{{ message.title }}</div>
          <div class="message-publish-date text-caption pr-2 py-0">{{ formatDate(message.pubDate) }}</div>
        </v-card-title>
        <v-card-subtitle class="text-truncate text-caption ml-3 py-1 px-0">{{ message.ingress }}</v-card-subtitle>
      </v-card>
    </v-container>
  </v-expansion-panel-header>
</template>
<script>
import formatter from "@/utils/formatter"

export default {
  props: ["message", "isRead", "isSelected"],
  data: () => ({}),
  watch: {},
  created() {},
  computed: {
    messageColor() {
      let color = this.isRead ? "white" : "secondary"
      color = this.isSelected ? "grey lighten-4" : color
      return color
    }
  },
  methods: {
    onItemClicked() {
      this.$emit("msgClicked", this.message)
    },
    formatDate(date) {
      return formatter(this.$i18n).formatDate(date)
    },
    getIconClass(isRead) {
      return isRead ? "circle-icon-hide" : ""
    }
  }
}
</script>

<style type="scss">
  .message-publish-date {
    margin-left: auto;
  }
  .circle-icon-hide {
    visibility: hidden;
  }
  .title-card {
    width:calc(100% - 30px) !important
  }
</style>
