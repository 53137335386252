export default {
  fleetAnalytics: {
    dashboardHeader: "Fleet Analytics Dashboard",
    dataTable: {
      headers: {
        contacts: "Contacts",
        machines: "Machines",
        segments: "Segments",
        tenant: "Tenant"
      },
      machines: {
        count: "Count",
        engineHours: "Worked",
        serviceAgreements: "Service agreements"
      }
    },
    widgets: {
      segments: "Segments",
      utilization: "Utilization"
    }
  }
}
