"use strict"

import { Auth } from "aws-amplify"
import { Storage } from "aws-amplify"

function fetchClient(i18n) {
  const urlEnv = process.env.VUE_APP_API_URL || "http://localhost:12100"
  const endpointUrl = urlEnv.endsWith("/")
    ? urlEnv.slice(0, urlEnv.length - 1)
    : urlEnv

  const buildUrl = requestUrl => {
    return endpointUrl + requestUrl
  }

  const getJwtToken = async () => {
    const item = localStorage.getItem("loggedInUser")
    if (item && item.length > 0) {
      const user = JSON.parse(item)
      if (user && user.authToken) {
        return `Bearer ${user.authToken}`
      }
    }

    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    return `Bearer ${jwtToken}`
  }

  const getHederConfig = async () => {
    return {
      "Content-Type": "application/json",
      Authorization: await getJwtToken()
    }
  }

  const getCredentials = () => {
    return "same-origin"
  }

  const createGetRequestConfig = async url => {
    return new Request(buildUrl(url), {
      method: "GET",
      headers: await getHederConfig(),
      credentials: getCredentials()
    })
  }

  const createPostRequestConfig = async (url, data) => {
    return new Request(buildUrl(url), {
      method: "POST",
      headers: await getHederConfig(),
      credentials: getCredentials(),
      body: JSON.stringify(data)
    })
  }

  const createPutRequestConfig = async (url, data) => {
    return new Request(buildUrl(url), {
      method: "PUT",
      headers: await getHederConfig(),
      credentials: getCredentials(),
      body: JSON.stringify(data)
    })
  }

  const createDeleteRequestConfig = async url => {
    return new Request(buildUrl(url), {
      method: "DELETE",
      headers: await getHederConfig(),
      credentials: getCredentials()
    })
  }

  const getErrorForResponse = async response => {
    const error = i18n ? i18n.t("store.errors.error") : "Error"

    const contentType = response.headers.get("content-type")
    if (contentType) {
      if (contentType.toLowerCase().indexOf("application/json") !== -1) {
        const json = await response.json()
        if (json && (json.message || json.causedBy)) {
          return {
            message: json.message,
            causedBy: json.causedBy,
            status: response.status,
            statusText: response.statusText,
            url: response.url,
            toString: function() {
              return `${error}: ${this.message}: ${this.causedBy}`
            }
          }
        }
      } else if (contentType.toLowerCase().indexOf("text/plain") !== -1) {
        const text = await response.text()
        if (text && text.length > 0) {
          const json = JSON.parse(text)
          if (json && (json.message || json.causedBy)) {
            return {
              message: json.message,
              causedBy: json.causedBy,
              status: response.status,
              statusText: response.statusText,
              url: response.url,
              toString: function() {
                return `${error}: ${this.message}: ${this.causedBy}`
              }
            }
          }
        }
      }
    }

    return {
      message: `${error}: ${response.status} ${response.statusText}`,
      toString: function() {
        return this.message
      }
    }
  }

  const getResponseData = async response => {
    const contentType = response.headers.get("content-type")
    if (contentType) {
      if (contentType.toLowerCase().indexOf("application/json") !== -1) {
        const json = await response.json()
        return Promise.resolve(json)
      } else if (contentType.toLowerCase().indexOf("text/plain") !== -1) {
        const text = await response.text()
        try {
          const json = JSON.parse(text)
          return Promise.resolve(json)
        } catch {
          return Promise.resolve(text)
        }
      }
    } else {
      return Promise.resolve()
    }
  }

  const callSaveEndpointOrThrow = async (url, data, isPostCall) => {
    let exception = null

    try {
      let response = null

      if (isPostCall) {
        response = await fetch(await createPostRequestConfig(url, data))
      } else {
        response = await fetch(await createPutRequestConfig(url, data))
      }

      if (response.status === 200 || response.status === 204) {
        // All OK
      } else {
        exception = await getErrorForResponse(response)
      }
    } catch (error) {
      exception = {
        message: isPostCall
          ? i18n
            ? i18n.t("store.errors.failedToAddItem")
            : "Failed to add item"
          : i18n
          ? i18n.t("store.errors.failedToSaveItem")
          : "Failed to save item",
        causedBy: error.toString(),
        url: url
      }
    }

    if (exception && exception.message && exception.message.length > 0) {
      throw exception
    }
  }

  return {
    // Performs GET on the endpoint at 'url'.
    // @returns Promise.reject if the call failed.
    // @returns Promise<any>
    async get(url) {
      if (!url) {
        return Promise.reject(
          i18n
            ? i18n.t("common.exceptions.argumentIsNull")
            : "Required argument is not set"
        )
      }

      const requestConfig = await createGetRequestConfig(url)

      const response = await fetch(requestConfig)

      if (response.status === 200) {
        return await getResponseData(response)
      } else {
        const error = await getErrorForResponse(response)
        return Promise.reject(error)
      }
    },

    // Performs POST on the endpoint at 'url', with 'data'
    // @returns Promise<Response>
    async post(url, data) {
      if (!url) {
        return Promise.reject(
          i18n
            ? i18n.t("common.exceptions.argumentIsNull")
            : "Required argument is not set"
        )
      }

      const requestConfig = await createPostRequestConfig(url, data)

      const response = await fetch(requestConfig)

      if (response.status === 200) {
        return await getResponseData(response)
      } else if (response.status === 204) {
        return Promise.resolve()
      } else {
        const error = await getErrorForResponse(response)
        return Promise.reject(error)
      }
    },

    // Performs PUT on the endpoint at 'url', with 'data'
    // @returns Promise<Response>
    async put(url, data) {
      if (!url) {
        return Promise.reject(
          i18n
            ? i18n.t("common.exceptions.argumentIsNull")
            : "Required argument is not set"
        )
      }

      const requestConfig = await createPutRequestConfig(url, data)

      const response = await fetch(requestConfig)

      if (response.status === 200) {
        return await getResponseData(response)
      } else if (response.status === 204) {
        return Promise.resolve()
      } else {
        const error = await getErrorForResponse(response)
        return Promise.reject(error)
      }
    },

    // Performs DELETE on the endpoint at 'url', with 'data'
    // @returns Promise<Response>
    async delete(url, data) {
      if (!url) {
        return Promise.reject(
          i18n
            ? i18n.t("common.exceptions.argumentIsNull")
            : "Required argument is not set"
        )
      }

      const requestConfig = await createDeleteRequestConfig(url, data)

      const response = await fetch(requestConfig)

      if (response.status === 204) {
        return await getResponseData(response)
      } else {
        const error = await getErrorForResponse(response)
        return Promise.reject(error)
      }
    },

    // Performs GET on the endpoint at 'url'.
    // @throws error object {message, causedBy} if the call failed.
    // @returns 'data'
    async getDataOrThrow(url) {
      let exception = null
      let data = null

      try {
        const response = await fetch(await createGetRequestConfig(url))
        if (response.status === 200) {
          data = await response.json()
        } else {
          exception = await getErrorForResponse(response)
        }
      } catch (error) {
        exception = {
          message: i18n
            ? i18n.t("store.errors.failedToFechItem")
            : "Failed to fetch item",
          causedBy: error.toString(),
          url: url
        }
      }

      if (exception && exception.message && exception.message.length > 0) {
        throw exception
      }

      return data
    },

    // Performs GET on the endpoint at 'url'.
    // @throws error object {message, causedBy} if the call failed.
    // @returns response as a blob
    async getBlobOrThorw(url) {
      let exception = null
      let data = null

      try {
        const response = await fetch(await createGetRequestConfig(url))
        if (response.status === 200) {
          data = await response.blob()
        } else {
          exception = await getErrorForResponse(response)
        }
      } catch (error) {
        exception = {
          message: i18n
            ? i18n.t("store.errors.failedToFechItem")
            : "Failed to fetch item",
          causedBy: error.toString(),
          url: url
        }
      }

      if (exception && exception.message && exception.message.length > 0) {
        throw exception
      }

      return data
    },

    // Performs PUT on the endpoint at 'url', with 'data'
    // @throws error object {message, causedBy} if the call failed.
    // @returns void
    async putOrThrow(url, data) {
      await callSaveEndpointOrThrow(url, data, false)
    },

    // Performs POST on the endpoint at 'url', with 'data'
    // @throws error object {message, causedBy} if the call failed.
    // @returns void
    async postOrThrow(url, data) {
      await callSaveEndpointOrThrow(url, data, true)
    },

    // Performs DELETE on the endpoint at 'url'.
    // @throws error object {message, causedBy} if the call failed.
    // @returns void
    async deleteOrThrow(url) {
      let exception = null

      try {
        const response = await fetch(await createDeleteRequestConfig(url))

        if (response.status !== 204) {
          exception = await getErrorForResponse(response)
        }
      } catch (error) {
        exception = {
          message: i18n
            ? i18n.t("store.errors.failedToFechItem")
            : "Failed to fetch item",
          causedBy: error.toString(),
          url: url
        }
      }

      if (exception && exception.message && exception.message.length > 0) {
        throw exception
      }
    },

    async getResponse(method, url, data) {
      if (!method || !url) {
        return Promise.reject(
          i18n
            ? i18n.t("common.exceptions.argumentIsNull")
            : "Required argument is not set"
        )
      }

      let requestConfig = null
      if (method.toUpperCase() === "GET") {
        requestConfig = await createGetRequestConfig(url)
      } else if (method.toUpperCase() === "POST") {
        requestConfig = await createPostRequestConfig(url, data)
      } else {
        return Promise.reject(
          i18n
            ? i18n.t("common.exceptions.notSupportedRequestMethod", {
                METHOD: method
              })
            : "Not supported request method '" + method + "'"
        )
      }

      return fetch(requestConfig)
    },

    async getError(response) {
      return await getErrorForResponse(response)
    },

    async awsPutOrThrow(key, object, config) {
      try {
        let parts = key.split("/")
        const prefix = parts[0] + "/"
        parts.splice(0, 1)
        const filePath = parts.join("/")

        Storage.configure({
          customPrefix: {
            public: prefix
          }
        })
        return await Storage.put(filePath, object, config)
      } catch (error) {
        throw {
          message: i18n
            ? i18n.t("store.errors.awsFailedToPutItem")
            : "Failed to put item in AWS",
          causedBy: error.toString(),
          url: key
        }
      }
    }
  }
}

export default fetchClient
