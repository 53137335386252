import fetchClient from "@/utils/fetchClient"

/* eslint-disable */

export default {
  state: {
    messages: []
  },
  mutations: {
    setMessages(state, payload) {
      state.messages = payload
    }
  },
  actions: {
    loadMessages({ commit }, languageCode) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .get(`/api/v1/metadata/messages/${languageCode}`)
          .then(data => {
            commit("setMessages", data.messages)
            resolve()
          })
          .catch(error => {
            commit("setError", error)
            reject(error)
          })
          .finally(() => {
            commit("setLoading", false)
          })
      })
    }
  },
  getters: {
    messages(state) {
      return state.messages
    },
    unreadMessages(state) {
      // Returns a function that extracts all unread messages.
      // filters messages based on the user's list of READ message ids.
      return readMessageIds => {
        return state.messages.flatMap(msg =>
          readMessageIds.find(r => r === msg.id) ? [] : msg
        )
      }
    }
  }
}
