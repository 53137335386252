export default {
  reports: {
    actions: {},
    dataTable: {
      headers: {
        modified: "Modificato",
        name: "Nome",
        tenant: "Locatario"
      },
      noResultsText: "Nessun rapporto corrispondente"
    },
    itemName: "rapporto"
  }
}
