<template>
  <v-container fluid class="py-0 my-0">
    <v-row>
      <v-col class="pb-0 mb-0">
        <v-select
          v-model="selectedLimitation"
          :menu-props="{ value: selectExpanded }"
          @click="selectExpanded = true"
          @change="selectExpanded = false"
          @blur="selectExpanded = false"
          v-if="editModeEnabled"
          :items="availableLimitations"
          :label="
            `${$t('machines.location.geofencingSettings.limit.limitation')}*`
          "
          :disabled="limitationTypes.length == limitations.length"
          :placeholder="
            $t(
              'machines.location.geofencingSettings.limit.limitationPlaceholder'
            )
          "
        >
          <v-btn
            :disabled="!editModeEnabled"
            text
            small
            slot="append"
            @click.stop="addLimitation"
          >
            <v-icon>mdi-plus</v-icon> {{ $t("common.actions.add") }}
          </v-btn>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0  mt-0" v-if="limitations.length">
        <v-chip
          :disabled="!editModeEnabled"
          class="mr-1 mb-1 mt-1"
          v-for="limitation in selectedLimitations"
          :close="editModeEnabled"
          :key="limitation.value"
          @click:close="removeLimitation(limitation.value)"
        >
          <span class="chip-font" :title="limitation.text">
            {{ limitation.text }}
          </span>
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 mt-0 py-0 my-0">
        <p
          v-if="!selectedLimitations || selectedLimitations.length === 0"
          class="caption"
        >
          {{
            $t(
              "machines.location.geofencingSettings.messages.noLimitationsAdded"
            )
          }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LimitationUtils from "@/utils/limitations"

let limitationUtils = null

export default {
  name: "GfTriggerSettings",
  props: ["trgSettings", "editModeEnabled"],
  components: {},
  data() {
    return {
      selectExpanded: false,
      selectedLimitation: null,
      limitations: [],
      valid: false
    }
  },
  beforeCreate() {
    limitationUtils = LimitationUtils(this.$i18n)
  },
  computed: {
    limitationTypes() {
      return limitationUtils.items
    },
    selectedLimitations() {
      return this.limitationTypes.filter(
        lt => this.limitations.indexOf(lt.value) != -1
      )
    },
    availableLimitations() {
      return this.limitationTypes.filter(
        lt => this.limitations.indexOf(lt.value) == -1
      )
    }
  },
  watch: {
    trgSettings() {
      if (this.trgSettings !== null) {
        this.limitations = this.trgSettings.limitations || []
      } else {
        this.limitations = []
      }
    },
    limitations() {
      this.trgSettings.limitations = this.limitations
    }
  },
  methods: {
    addLimitation() {
      this.limitations.push(this.selectedLimitation)
    },
    removeLimitation(limitation) {
      this.limitations.splice(this.limitations.indexOf(limitation), 1)
    }
  }
}
</script>
<style>
.chip-font {
  font-size: 12px;
  height: auto;
  white-space: normal;
  text-indent: 0px;
  line-height: 1;
}
</style>
