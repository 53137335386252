<template>
  <v-card class="text-center">
    <v-card-title
      class="secondary d-flex align-center justify-center ma-0 pa-0 text-uppercase text-h6"
    >
      {{ $t("machines.dieselParticulateFilter.status.title") }}
    </v-card-title>
    <v-card-text class="black--text">
      <v-content>
        <v-row no-gutters>
          <v-col class="d-flex justify-end mt-2">
            <v-btn icon @click.stop="showInfoWindow = true">
              <v-icon color="primary" class="mr-2"
                >mdi-information-outline</v-icon
              >
            </v-btn>
            <v-dialog v-model="showInfoWindow" max-width="400">
              <DieselParticulateFilterInfo />
            </v-dialog>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <DieselParticulateFilterIcon :status="dpfStatus.status" />
          </v-col>
        </v-row>
        <v-row no-gutters class="subtitle-1">
          <v-col>
            {{ $t("machines.dieselParticulateFilter.status.ashLevel") }}
          </v-col>
          <v-col>
            {{ dpfStatus.ashLevel }}
          </v-col>
        </v-row>
        <v-row no-gutters class="subtitle-1">
          <v-col>
            {{ $t("machines.dieselParticulateFilter.status.sootLevel") }}
          </v-col>
          <v-col>
            {{ dpfStatus.sootLevel }}
          </v-col>
        </v-row>
      </v-content>
    </v-card-text>
  </v-card>
</template>

<script>
import DieselParticulateFilterIcon from "@/components/widgets/DieselParticulateFilterIcon"
import DieselParticulateFilterInfo from "@/components/widgets/DieselParticulateFilterInfo"

export default {
  props: ["dpfStatus"],
  components: {
    DieselParticulateFilterIcon,
    DieselParticulateFilterInfo
  },
  data: () => ({
    showInfoWindow: false
  }),
  computed: {},
  methods: {}
}
</script>

<style></style>
