import Vue from "vue"
import Vuetify from "vuetify/lib"

import "@mdi/font/css/materialdesignicons.css"

import colors from "vuetify/lib/util/colors"

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.grey.darken1,
        secondary: "#FFCD00" /* yellow */,
        accent: colors.grey.darken4,
        success: "#70AE6E",
        info: "#737373",
        alert: "#B80C09",
        warning: "#FFCD00" /* yellow */,
        //error: colors.red,
        details_background: colors.grey.lighten4,
        muted: colors.grey,
        generic: colors.grey.lighten3
      }
    }
  }
})
