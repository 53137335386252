<template>
  <v-card>
    <v-card-title>
      <div class="text-body-1 font-weight-bold black--text text-uppercase">
        {{ $t("tenant.menuItems.machines") }}
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6" class="d-flex justify-center secondary--text">
          <a :href="machinesLink" class="homepage-link">
            <v-img :src="imgSrc" class="machine-avatar pt-0" max-width="200" />
          </a>
        </v-col>
        <v-col cols="12" md="6" class="align-self-center">
          <v-row class="d-flex align-center">
            <v-col>
              <a :href="machinesLink" class="homepage-link">
                {{ $t("home.machines.description") }} →
              </a>
            </v-col>
          </v-row>
          <v-row v-if="canEditMachines" class="d-flex align-center">
            <v-col>
              <a :href="registerLink" class="homepage-link">
                {{ $t("home.machines.register") }} →
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    tenantUid() {
      return this.$store.getters.tenantRoot?.uid
    },
    machinesLink() {
      return `/#/tenants/${this.tenantUid}/machines`
    },
    registerLink() {
      return `/#/tenants/${this.tenantUid}/machines?add_machine`
    },
    imgSrc() {
      // Use one and the same image for all
      return "/products/jpg/500_SPP.jpg"
    },
    canEditMachines() {
      return this.$store.getters.canEditMachines
    }
  }
}
</script>

<style></style>
