<template>
  <v-container fluid>
    <v-card flat outlined>
      <v-progress-linear
        :active="loadingGeolocationSettings"
        :indeterminate="loadingGeolocationSettings"
        top
        absolute
      ></v-progress-linear>
      <v-card-title>
        {{ $t("machines.location.geolocationSettings.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("machines.location.geolocationSettings.description") }}
      </v-card-subtitle>
      <v-card-text>
        <v-card flat class="pa-0 ma-0">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pa-0 ma-0">
                <v-alert
                  v-if="offline"
                  class="ma-0 alert-message"
                  elevation="2"
                  dismissible
                  border="left"
                  colored-border
                  dense
                  type="warning"
                  :value="true"
                >
                  <div>
                    {{ $t("machines.messages.machineOffline") }}
                    {{
                      $t(
                        "machines.location.geolocationSettings.messages.settingsNotSynched"
                      )
                    }}
                  </div>
                </v-alert>
              </v-col></v-row
            ></v-card-text
          ></v-card
        >
        <v-container
          v-if="
            !this.geolocationSettings || !this.geolocationSettings.supported
          "
        >
          <v-overlay absolute color="#fff" opacity=".9" class="text--primary">
            <v-row>
              <v-col cols="10" offset="1">
                <div class="text-center">
                  <v-icon color="grey darken-3">mdi-key-variant</v-icon>
                </div>
                <h1 class="title d-flex mb-5">
                  <v-icon color="primary" class="mr-2"
                    >mdi-information-outline</v-icon
                  >
                  {{
                    $t(
                      "machines.location.geolocationSettings.messages.disabled"
                    )
                  }}
                </h1>
                <p class="pl-8">
                  {{ $t("machines.messages.contactToLearnMore") }}
                  <a :href="mailToLink">{{ brokkEmail }}</a>
                </p>
              </v-col>
            </v-row>
          </v-overlay>
        </v-container>
        <v-card flat outlined>
          <v-card-text class="black--text">
            <v-form
              ref="geolocationSettingsForm"
              v-model="valid"
              v-if="geolocationSettings"
            >
              <v-row class="align-center">
                <v-col cols="4" class="d-flex justify-left"> </v-col>
                <v-col cols="8" class="d-flex justify-end">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn
                          icon
                          v-if="canReadGeolocation"
                          @click="refreshGeolocationSettings"
                          :loading="loadingGeolocationSettings"
                          :disabled="
                            loadingGeolocationSettings ||
                              editGeolocationSettings
                          "
                        >
                          <v-icon>mdi-cached</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>{{ $t("common.actions.refresh") }}</span>
                  </v-tooltip>
                  <v-tooltip top v-if="canEditGeolocation">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn
                          icon
                          @click="onEditGeolocationSettings"
                          :disabled="
                            loadingGeolocationSettings ||
                              editGeolocationSettings
                          "
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{
                        $t("machines.location.geolocationSettings.actions.edit")
                      }}
                    </span>
                  </v-tooltip>

                  <v-tooltip
                    top
                    v-if="geolocationSettingsDefined && canRemoveGeolocation"
                  >
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn
                          icon
                          @click.stop="removeGeolocationSettings = true"
                          :disabled="
                            loadingGeolocationSettings ||
                              editGeolocationSettings
                          "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>{{
                      $t("machines.location.geolocationSettings.actions.remove")
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-checkbox
                    :disabled="
                      !editGeolocationSettings || loadingGeolocationSettings
                    "
                    class="ma-0 pa-0"
                    v-model="movementEnabled"
                    hide-details
                  ></v-checkbox>
                  <span>{{
                    $t("machines.location.geolocationSettings.movement")
                  }}</span>
                </v-col>
              </v-row>
              <v-row v-if="movementEnabled">
                <v-col cols="1" class="d-flex justify-center"> </v-col>
                <v-col cols="11" class="d-flex">
                  <v-row>
                    <v-col
                      class="d-flex py-0 my-0"
                      cols="12"
                      sm="10"
                      md="8"
                      lg="5"
                    >
                      <v-text-field
                        class="mr-2"
                        autocomplete="false"
                        type="number"
                        v-model.number="minMovement"
                        :label="
                          `${$t(
                            'machines.location.geolocationSettings.distance'
                          )}*`
                        "
                        :disabled="
                          !editGeolocationSettings || loadingGeolocationSettings
                        "
                        :placeholder="
                          $t(
                            'machines.location.geolocationSettings.distancePlaceholder'
                          )
                        "
                        :rules="[
                          validationRules.required,
                          validationRules.between(0.02, 1000)
                        ]"
                      ></v-text-field>
                      <v-select
                        class="unit-select"
                        :items="distanceUnits"
                        item-value="symbol"
                        item-text="symbol"
                        :disabled="
                          !editGeolocationSettings || loadingGeolocationSettings
                        "
                        v-model="movementUnit"
                        :label="
                          `${$t('machines.location.geolocationSettings.unit')}*`
                        "
                        :placeholder="
                          $t(
                            'machines.location.geolocationSettings.unitPlaceholder'
                          )
                        "
                      ></v-select>
                    </v-col>
                    <v-col class="d-flex my-lg-6 my-0 py-0" cols="12" lg="7">
                      {{
                        $t("machines.location.geolocationSettings.distanceHint")
                      }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-checkbox
                    :disabled="
                      !editGeolocationSettings || loadingGeolocationSettings
                    "
                    class="ma-0 pa-0"
                    v-model="timeIntervalEnabled"
                    hide-details
                  ></v-checkbox>
                  <span>{{
                    $t("machines.location.geolocationSettings.timer")
                  }}</span>
                </v-col>
              </v-row>
              <v-row v-if="timeIntervalEnabled">
                <v-col cols="1" class="d-flex justify-center"> </v-col>
                <v-col cols="11" class="d-flex">
                  <v-row>
                    <v-col
                      class="d-flex py-0 my-0"
                      cols="12"
                      sm="10"
                      md="8"
                      lg="5"
                    >
                      <v-text-field
                        type="number"
                        class="mr-2"
                        autocomplete="false"
                        v-model.number="minTimeInterval"
                        @change="roundTimeInterval"
                        :label="
                          `${$t(
                            'machines.location.geolocationSettings.interval'
                          )}*`
                        "
                        :disabled="
                          !editGeolocationSettings || loadingGeolocationSettings
                        "
                        :placeholder="
                          $t(
                            'machines.location.geolocationSettings.intervalPlaceholder'
                          )
                        "
                        :rules="[
                          validationRules.required,
                          hourIntervalValidationRule()
                        ]"
                      ></v-text-field>
                      <v-select
                        class="unit-select"
                        :items="intervalUnits"
                        :disabled="
                          !editGeolocationSettings || loadingGeolocationSettings
                        "
                        v-model="timeIntervalUnit"
                        :label="
                          `${$t('machines.location.geolocationSettings.unit')}*`
                        "
                        :placeholder="
                          $t(
                            'machines.location.geolocationSettings.unitPlaceholder'
                          )
                        "
                        v-on:change="validateIntervalField()"
                      ></v-select>
                    </v-col>
                    <v-col class="d-flex my-lg-6 my-0 py-0" cols="12" lg="7">
                      {{
                        $t("machines.location.geolocationSettings.intervalHint")
                      }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions v-if="editGeolocationSettings">
            <v-spacer></v-spacer>
            <v-btn
              :loading="loadingGeolocationSettings"
              :disabled="!valid || loadingGeolocationSettings"
              @click="onSaveGeolocationSettings"
              >{{ $t("common.actions.apply") }}
            </v-btn>
            <v-btn
              :disabled="loadingGeolocationSettings"
              @click="cancelEditGeolocationSettings"
            >
              {{ $t("common.actions.cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <ConfirmRemoveDialog
          :itemName="geolocationSettingsTitle"
          confirmRemoveEvent="confirmEvent"
          :show="removeGeolocationSettings"
          @close="removeGeolocationSettings = false"
          v-on:confirmEvent="onRemoveGeolocationSettings"
        />
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :timeout="1500"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
// import Vue from "vue"
import distanceUtils from "@/utils/distance"
import intervalUtils from "@/utils/interval"
import ValidationRules from "@/utils/validationrules"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"
import distanceUnits from "../../utils/distance"

const defaults = {
  supported: false,

  minMovement: 1000,
  movementEnabled: true,
  movementUnit: "km",

  timeIntervalEnabled: true,
  minTimeInterval: 86400,
  timeIntervalUnit: "hour"
}

let validationRules = null

export default {
  name: "GeolocationSettings",
  props: ["machine"],
  components: {
    ConfirmRemoveDialog
  },
  data() {
    return {
      minMovement: 1000,
      movementEnabled: true,
      movementUnit: "km",

      timeIntervalEnabled: true,
      minTimeInterval: 86400,
      timeIntervalUnit: "hour",

      offline: false,
      geolocationSettingsDefined: false,
      validationRules: validationRules,
      valid: false,

      editGeolocationSettings: false,
      removeGeolocationSettings: false,

      snackbar: false,
      snackbarText: ""
    }
  },
  created() {
    this.fetchData()
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    ...mapGetters([
      "canReadGeolocation",
      "canEditGeolocation",
      "canRemoveGeolocation"
    ]),
    distanceUnits() {
      return [distanceUtils.mile, distanceUtils.kilometer]
    },
    brokkEmail() {
      return this.$store.getters.brokkEmail
    },
    mailToLink() {
      return `mailto:${this.brokkEmail}?subject=Brokk Connect: geolocation settings reset for ${this.machine.productName} with serial number ${this.machine.serialNo}`
    },
    intervalUnits() {
      return [
        {
          text: this.$t("common.enums.time.minutes"),
          value: intervalUtils.minute.value
        },
        {
          text: this.$t("common.enums.time.hours"),
          value: intervalUtils.hour.value
        }
      ]
    },
    geolocationSettingsTitle() {
      return this.$t("machines.location.geolocationSettings.title")
    },
    geolocationSettings() {
      return this.$store.getters.machineGeolocationSettings
    },
    loadingGeolocationSettings() {
      return (
        !this.geolocationSettings ||
        this.$store.getters.loadingMachineGeolocationSettings
      )
    }
  },
  watch: {
    geolocationSettings() {
      this.offline = this.getOfflineState()
    },
    editGeolocationSettings() {
      this.$emit("update:editGeolocation", this.editGeolocationSettings)
    }
  },
  methods: {
    roundTimeInterval() {
      this.minTimeInterval = Math.round(this.minTimeInterval * 10) / 10
    },
    getOfflineState() {
      const settings = this.geolocationSettings
      if (settings) {
        if (settings.desiredOptions) {
          if (settings.reportedOptions) {
            if (
              JSON.stringify(settings.desiredOptions) !=
              JSON.stringify(settings.reportedOptions)
            ) {
              return true
            }
          } else {
            return true
          }
        }
      }
      return false
    },
    validateIntervalField() {
      setTimeout(() => this.$refs.geolocationSettingsForm.validate(), 0)
    },
    hourIntervalValidationRule() {
      if (this.timeIntervalUnit == "minute") {
        return validationRules.between(15, 60)
      } else {
        return validationRules.between(1, 999)
      }
    },
    async fetchData() {
      await this.$store.dispatch(
        "fetchMachineGeolocationSettings",
        this.machine
      )
      this.loadGeolocationSettings(
        this.$store.getters.machineGeolocationSettings
      )
    },
    loadGeolocationSettings(settings) {
      this.geolocationSettingsDefined = !!settings?.desiredOptions
      let defaultSettings = JSON.parse(JSON.stringify(defaults))

      this.movementUnit = settings?.movementUnit || defaultSettings.movementUnit
      this.minMovement = distanceUtils.fromMeter(
        settings?.desiredOptions?.minMovement || defaultSettings.minMovement,
        this.movementUnit,
        true
      )
      this.movementEnabled = settings?.desiredOptions?.minMovement != 0

      this.timeIntervalUnit =
        settings?.timeIntervalUnit || defaultSettings.timeIntervalUnit
      this.minTimeInterval = intervalUtils.fromSeconds(
        settings?.desiredOptions?.minTimeInterval ||
          defaultSettings.minTimeInterval,
        this.timeIntervalUnit
      )
      this.timeIntervalEnabled = settings?.desiredOptions?.minTimeInterval != 0
    },
    refreshGeolocationSettings() {
      this.fetchData()
    },
    onEditGeolocationSettings() {
      this.editGeolocationSettings = true
    },
    onSaveGeolocationSettings() {
      const saveItem = {}
      if (!this.movementEnabled) {
        saveItem.minMovement = 0
        saveItem.movementUnit = "km"
      } else {
        saveItem.minMovement = distanceUnits.round(
          distanceUtils.toMeter(
            Number(this.minMovement) ?? 0,
            this.movementUnit
          ),
          this.movementUnit
        )
        saveItem.movementUnit = this.movementUnit
      }
      if (!this.timeIntervalEnabled) {
        saveItem.minTimeInterval = 0
        saveItem.timeIntervalUnit = "hour"
      } else {
        saveItem.minTimeInterval = intervalUtils.toSeconds(
          Number(this.minTimeInterval) ?? 0,
          this.timeIntervalUnit
        )
        saveItem.timeIntervalUnit = this.timeIntervalUnit
      }

      this.$store
        .dispatch("saveMachineGeolocationSettings", {
          machine: this.machine,
          geolocationSettings: saveItem
        })
        .then(() => {
          this.snackbarText = this.$t(
            "machines.location.geolocationSettings.messages.settingsSaved"
          )
          this.snackbar = true
          this.editGeolocationSettings = false
          this.fetchData()
        })
        .catch(() => {
          // Error already displayed by sendEmailNotification.
        })
    },
    cancelEditGeolocationSettings() {
      this.editGeolocationSettings = false
      this.refreshGeolocationSettings()
    },
    onRemoveGeolocationSettings() {
      this.$store
        .dispatch("removeMachineGeolocationSettings", this.machine)
        .then(() => {
          this.snackbarText = this.$t(
            "machines.location.geolocationSettings.messages.settingsRemoved"
          )
          this.snackbar = true
          this.removeGeolocationSettings = false // Hide the delete button
          this.refreshGeolocationSettings()
        })
        .catch(() => {
          // Error already displayed by sendEmailNotification.
        })
    }
  }
}
</script>
<style scoped>
.unit-select {
  max-width: 100px;
}
</style>
