var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-progress-linear',{attrs:{"active":_vm.loadingGeolocationSettings,"indeterminate":_vm.loadingGeolocationSettings,"top":"","absolute":""}}),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("machines.location.geolocationSettings.title"))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t("machines.location.geolocationSettings.description"))+" ")]),_c('v-card-text',[_c('v-card',{staticClass:"pa-0 ma-0",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[(_vm.offline)?_c('v-alert',{staticClass:"ma-0 alert-message",attrs:{"elevation":"2","dismissible":"","border":"left","colored-border":"","dense":"","type":"warning","value":true}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("machines.messages.machineOffline"))+" "+_vm._s(_vm.$t( "machines.location.geolocationSettings.messages.settingsNotSynched" ))+" ")])]):_vm._e()],1)],1)],1)],1),(
          !this.geolocationSettings || !this.geolocationSettings.supported
        )?_c('v-container',[_c('v-overlay',{staticClass:"text--primary",attrs:{"absolute":"","color":"#fff","opacity":".9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","offset":"1"}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":"grey darken-3"}},[_vm._v("mdi-key-variant")])],1),_c('h1',{staticClass:"title d-flex mb-5"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(_vm.$t( "machines.location.geolocationSettings.messages.disabled" ))+" ")],1),_c('p',{staticClass:"pl-8"},[_vm._v(" "+_vm._s(_vm.$t("machines.messages.contactToLearnMore"))+" "),_c('a',{attrs:{"href":_vm.mailToLink}},[_vm._v(_vm._s(_vm.brokkEmail))])])])],1)],1)],1):_vm._e(),_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-text',{staticClass:"black--text"},[(_vm.geolocationSettings)?_c('v-form',{ref:"geolocationSettingsForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"d-flex justify-left",attrs:{"cols":"4"}}),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[(_vm.canReadGeolocation)?_c('v-btn',{attrs:{"icon":"","loading":_vm.loadingGeolocationSettings,"disabled":_vm.loadingGeolocationSettings ||
                            _vm.editGeolocationSettings},on:{"click":_vm.refreshGeolocationSettings}},[_c('v-icon',[_vm._v("mdi-cached")])],1):_vm._e()],1)]}}],null,false,3754795370)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.refresh")))])]),(_vm.canEditGeolocation)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.loadingGeolocationSettings ||
                            _vm.editGeolocationSettings},on:{"click":_vm.onEditGeolocationSettings}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,false,2889977741)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("machines.location.geolocationSettings.actions.edit"))+" ")])]):_vm._e(),(_vm.geolocationSettingsDefined && _vm.canRemoveGeolocation)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.loadingGeolocationSettings ||
                            _vm.editGeolocationSettings},on:{"click":function($event){$event.stopPropagation();_vm.removeGeolocationSettings = true}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,false,364272822)},[_c('span',[_vm._v(_vm._s(_vm.$t("machines.location.geolocationSettings.actions.remove")))])]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"disabled":!_vm.editGeolocationSettings || _vm.loadingGeolocationSettings,"hide-details":""},model:{value:(_vm.movementEnabled),callback:function ($$v) {_vm.movementEnabled=$$v},expression:"movementEnabled"}}),_c('span',[_vm._v(_vm._s(_vm.$t("machines.location.geolocationSettings.movement")))])],1)],1),(_vm.movementEnabled)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1"}}),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex py-0 my-0",attrs:{"cols":"12","sm":"10","md":"8","lg":"5"}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"autocomplete":"false","type":"number","label":`${_vm.$t(
                          'machines.location.geolocationSettings.distance'
                        )}*`,"disabled":!_vm.editGeolocationSettings || _vm.loadingGeolocationSettings,"placeholder":_vm.$t(
                          'machines.location.geolocationSettings.distancePlaceholder'
                        ),"rules":[
                        _vm.validationRules.required,
                        _vm.validationRules.between(0.02, 1000)
                      ]},model:{value:(_vm.minMovement),callback:function ($$v) {_vm.minMovement=_vm._n($$v)},expression:"minMovement"}}),_c('v-select',{staticClass:"unit-select",attrs:{"items":_vm.distanceUnits,"item-value":"symbol","item-text":"symbol","disabled":!_vm.editGeolocationSettings || _vm.loadingGeolocationSettings,"label":`${_vm.$t('machines.location.geolocationSettings.unit')}*`,"placeholder":_vm.$t(
                          'machines.location.geolocationSettings.unitPlaceholder'
                        )},model:{value:(_vm.movementUnit),callback:function ($$v) {_vm.movementUnit=$$v},expression:"movementUnit"}})],1),_c('v-col',{staticClass:"d-flex my-lg-6 my-0 py-0",attrs:{"cols":"12","lg":"7"}},[_vm._v(" "+_vm._s(_vm.$t("machines.location.geolocationSettings.distanceHint"))+" ")])],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"disabled":!_vm.editGeolocationSettings || _vm.loadingGeolocationSettings,"hide-details":""},model:{value:(_vm.timeIntervalEnabled),callback:function ($$v) {_vm.timeIntervalEnabled=$$v},expression:"timeIntervalEnabled"}}),_c('span',[_vm._v(_vm._s(_vm.$t("machines.location.geolocationSettings.timer")))])],1)],1),(_vm.timeIntervalEnabled)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1"}}),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex py-0 my-0",attrs:{"cols":"12","sm":"10","md":"8","lg":"5"}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"type":"number","autocomplete":"false","label":`${_vm.$t(
                          'machines.location.geolocationSettings.interval'
                        )}*`,"disabled":!_vm.editGeolocationSettings || _vm.loadingGeolocationSettings,"placeholder":_vm.$t(
                          'machines.location.geolocationSettings.intervalPlaceholder'
                        ),"rules":[
                        _vm.validationRules.required,
                        _vm.hourIntervalValidationRule()
                      ]},on:{"change":_vm.roundTimeInterval},model:{value:(_vm.minTimeInterval),callback:function ($$v) {_vm.minTimeInterval=_vm._n($$v)},expression:"minTimeInterval"}}),_c('v-select',{staticClass:"unit-select",attrs:{"items":_vm.intervalUnits,"disabled":!_vm.editGeolocationSettings || _vm.loadingGeolocationSettings,"label":`${_vm.$t('machines.location.geolocationSettings.unit')}*`,"placeholder":_vm.$t(
                          'machines.location.geolocationSettings.unitPlaceholder'
                        )},on:{"change":function($event){return _vm.validateIntervalField()}},model:{value:(_vm.timeIntervalUnit),callback:function ($$v) {_vm.timeIntervalUnit=$$v},expression:"timeIntervalUnit"}})],1),_c('v-col',{staticClass:"d-flex my-lg-6 my-0 py-0",attrs:{"cols":"12","lg":"7"}},[_vm._v(" "+_vm._s(_vm.$t("machines.location.geolocationSettings.intervalHint"))+" ")])],1)],1)],1):_vm._e()],1):_vm._e()],1),(_vm.editGeolocationSettings)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loadingGeolocationSettings,"disabled":!_vm.valid || _vm.loadingGeolocationSettings},on:{"click":_vm.onSaveGeolocationSettings}},[_vm._v(_vm._s(_vm.$t("common.actions.apply"))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.loadingGeolocationSettings},on:{"click":_vm.cancelEditGeolocationSettings}},[_vm._v(" "+_vm._s(_vm.$t("common.actions.cancel"))+" ")])],1):_vm._e()],1),_c('ConfirmRemoveDialog',{attrs:{"itemName":_vm.geolocationSettingsTitle,"confirmRemoveEvent":"confirmEvent","show":_vm.removeGeolocationSettings},on:{"close":function($event){_vm.removeGeolocationSettings = false},"confirmEvent":_vm.onRemoveGeolocationSettings}})],1)],1),_c('v-snackbar',{attrs:{"timeout":1500,"top":"","dark":"","color":"green darken-1"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("common.actions.close"))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }