<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- Unprovisioned machine -->
        <v-tabs
          v-if="this.machine.unprovisioned"
          v-model="tab"
          background-color="white"
          color="black accent-4"
          left
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#service">
            <v-icon class="mr-2" small>mdi-progress-wrench</v-icon>
            {{ $t("machines.tabHeaders.service") }}
          </v-tab>

          <v-tab v-if="canViewServiceAgreements" href="#serviceAgreement">
            <v-icon class="mr-2" small>mdi-progress-wrench</v-icon>
            {{ $t("machines.tabHeaders.serviceAgreement") }}
          </v-tab>

          <v-tab href="#notifications" :disabled="true">
            <v-icon class="mr-2" small>mdi-bell-alert-outline</v-icon>
            {{ $t("machines.tabHeaders.notifications") }}
          </v-tab>
        </v-tabs>

        <!-- Provisioned machine -->
        <v-tabs
          v-else
          v-model="tab"
          background-color="white"
          color="black accent-4"
          left
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#service">
            <v-icon class="mr-2" small>mdi-progress-wrench</v-icon>
            {{ $t("machines.tabHeaders.service") }}
          </v-tab>

          <v-tab v-if="canViewServiceAgreements" href="#serviceAgreement">
            <v-icon class="mr-2" small>mdi-progress-wrench</v-icon>
            {{ $t("machines.tabHeaders.serviceAgreement") }}
          </v-tab>

          <v-tab href="#notifications">
            <v-icon class="mr-2" small>mdi-bell-alert-outline</v-icon>
            {{ $t("machines.tabHeaders.notifications") }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab">
      <!-- SERVICE -->
      <v-tab-item value="service">
        <v-row v-if="!this.machine.unprovisioned">
          <v-col cols="12" sm="6" lg="3" class="mt-2">
            <ValueWidget
              v-if="dashboardData"
              :title="$t('machines.labels.engineHoursDays')"
              :value="dashboardData.timing.engineTime.data | engineHourFilter"
              :text="
                formatLastUpdated(dashboardData.timing.engineTime.lastUpdated)
              "
              backgroundIcon="mdi-engine"
              :backgroundIconColor="engineRunningColor"
              actionIcon="mdi-chart-line"
              actionPath="measurements"
              actionDetailsLink="engine-hours"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="6" class="mt-2">
            <NextServiceWidget :upcoming="upcoming" :machine="machine" />
          </v-col>
          <v-col cols="12" lg="3" class="mt-2">
            <ValueWidget
              :title="$t('machines.labels.notification')"
              :value="notificationText"
              :text="
                $t(
                  'machines.maintenance.service.beforeNextServiceYearlyService'
                )
              "
              backgroundIcon="mdi-notification-clear-all"
              actionIcon="mdi-bell-alert-outline"
              actionPath="maintenance#notifications"
              actionDetailsLink=""
            />
          </v-col>
        </v-row>
        <v-row v-if="!this.machine.unprovisioned">
          <v-col cols="12" class="mt-2">
            <ServiceReset
              ref="serviceReset"
              :machine="machine"
              @reset="refreshMaintenance"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-2">
            <ServiceSchedule :machine="machine" @update="refreshMaintenance" />
          </v-col>
        </v-row>
      </v-tab-item>
      <!-- SERVICE AGREEMENTS -->
      <v-tab-item value="serviceAgreement">
        <ServiceAgreement
          :machine="machine"
          :serviceAgreement="serviceAgreement"
        />
      </v-tab-item>
      <!-- NOTIFICATIONS -->
      <v-tab-item value="notifications">
        <NotificationSettings
          :disabled="false"
          disabledTitle=""
          disabledText=""
          :serialNo="machine.serialNo"
          :notification="notification"
          notificationType="maintenance"
          :canEditItem="canEditMaintenance"
          :canRemoveItem="canRemoveMaintenance"
          v-on:save="onSaveNotification"
          v-on:cancel="onCancelNotification"
          v-on:remove="onRemoveNotification"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ValueWidget from "@/components/widgets/ValueWidget"
import ValidationRules from "@/utils/validationrules"
import localeDateFilter from "@/filters/localedatefilter"
import engineHourFilter from "@/filters/enginehourfilter"
import NextServiceWidget from "@/components/widgets/NextServiceWidget"
import NotificationSettings from "@/components/NotificationSettings"
import ServiceReset from "@/components/ServiceReset"
import ServiceSchedule from "@/components/ServiceSchedule"
import ServiceAgreement from "@/components/machines/ServiceAgreement"

let validationRules = null

export default {
  name: "Maintenance",
  props: ["machine"],
  components: {
    NextServiceWidget,
    ServiceReset,
    ValueWidget,
    ServiceSchedule,
    NotificationSettings,
    ServiceAgreement
  },
  data() {
    return {
      tab: "service",
      refreshLoading: false,

      notification: null,

      recipient: undefined,
      validationRules: validationRules,
      valid: false,
      snackbar: false,
      snackbarText: ""
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.dashboardData === undefined || this.dashboardData === null) {
        this.fetchDashboardData()
      }

      const langCode = this.$store.getters.dataLangCode
      this.$store.dispatch("loadMaintenanceMetadata", langCode)

      const params = {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo
      }

      this.$store.dispatch("fetchMaintenance", params).then(() => {
        this.notification = this.$store.getters.maintenance.notification
      })

      this.$store.dispatch("fetchServiceAgreement", this.machine)
    },
    fetchDashboardData() {
      this.$store.dispatch("fetchMachineDashboard", this.machine)
    },
    refreshMaintenance() {
      this.$refs.serviceReset.checkAvailability()
      this.refreshLoading = true
      this.fetchData()
      setTimeout(() => {
        this.refreshLoading = false
      }, 1000)
    },
    formatLastUpdated(lastUpdated) {
      if (lastUpdated !== undefined && lastUpdated !== null) {
        return this.$t("machines.updated", {
          TIME: localeDateFilter(lastUpdated)
        })
      }
      return this.$t("$vuetify.noDataText")
    },
    onSaveNotification(notifParams) {
      const params = {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo,
        notification: notifParams
      }

      this.$store
        .dispatch("saveMaintenanceNotification", params)
        .then(() => {
          this.snackbarText = this.$t(
            "machines.notifications.settings.messages.notificationSettingsSaved"
          )
          this.snackbar = true
          this.fetchData()
        })
        .catch(() => {
          // Error already displayed by dispatched action.
        })
    },
    onCancelNotification() {
      this.refreshMaintenance()
    },
    onRemoveNotification() {
      const params = {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo,
        notification: this.notification
      }

      this.$store.dispatch("removeMaintenanceNotification", params).then(() => {
        this.snackbarText = this.$t(
          "machines.notifications.settings.messages.notificationSettingsRemoved"
        )
        this.snackbar = true
        this.refreshMaintenance()
      })
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    upcoming() {
      if (
        this.$store.getters.maintenance !== undefined &&
        this.$store.getters.maintenance !== null
      ) {
        return this.$store.getters.maintenance.upcoming
      }

      return []
    },
    isRunning() {
      return this.machine !== null && this.machine.status === "running"
    },
    engineRunningColor() {
      if (this.isRunning) {
        return "success--text" // Pre-defined material design color (green-ish)
      }
      return null
    },
    notificationThresholdSeconds() {
      if (this.notification !== null) {
        return 3600 * this.notification.threshold
      }
      return null
    },
    notificationText() {
      var daysBeforeYearly = "-"

      if (this.notification !== null) {
        daysBeforeYearly = this.notification.thresholdYearly + " days"
      }

      return (
        engineHourFilter(this.notificationThresholdSeconds) +
        " / " +
        daysBeforeYearly
      )
    },
    canAddMaintenance() {
      return this.$store.getters.canAddMaintenance
    },
    canEditMaintenance() {
      return this.$store.getters.canEditMaintenance
    },
    canRemoveMaintenance() {
      return this.$store.getters.canRemoveMaintenance
    },
    canViewServiceAgreements() {
      return this.$store.getters.canReadServiceAgreements
    },
    dashboardData() {
      return this.$store.getters.machineDashboard
    },
    serviceAgreement() {
      return this.$store.getters.serviceAgreement
    }
  }
}
</script>
<style></style>
