// Login form for the user to sign in
import LoginView from "@/views/LoginView"

// Landing page for My Brokk
import StartView from "@/views/StartView"
import HomeView from "@/views/HomeView"

// Brokk Connect section
import TenantsView from "@/views/TenantsView"
import MapView from "@/views/MapView"
import MachinesView from "@/views/MachinesView"
import UsersView from "@/views/UsersView"
import ReportsView from "@/views/ReportsView"

//
// Documents section
//

// Documents root view
import DocumentsView from "@/views/documents/DocumentsView"
import DocumentsManualsSelectionView from "@/views/documents/DocumentsManualsSelectionView"
import DocumentsManualsView from "@/views/documents/DocumentsManualsView"

// Re-used component for: Warranty Claims, Return Form, Service Protocols, Service Manuals
import DocumentLinkWidget from "@/views/documents/DocumentLinkWidget"

import ServiceInstructions from "@/views/documents/ServiceDocuments"
import ServiceBulletins from "@/views/documents/ServiceDocuments"

import DownloadsView from "@/views/downloads/DownloadsView"

// Auxiliary views
import TenantHome from "@/components/TenantHome"
import MachineView from "@/views/MachineView"

// Views showing details about one machine
import MachineAlarms from "@/views/machine/Alarms"
import MachineInfo from "@/views/machine/MachineInfo"
import MachineSystem from "@/views/machine/System"
import MachineDashboard from "@/views/machine/Dashboard"
import MachineMaintenance from "@/views/machine/Maintenance"
import MachineManuals from "@/views/machine/Manuals"
import MachineMeasurements from "@/views/machine/Measurements"
import MachineRental from "@/views/machine/Rental"
import MachineLocation from "@/views/machine/Location"
import MachineParameters from "@/views/machine/Parameters"

// Views related to a tenant
import TenantView from "@/views/TenantView"
import TenantProfile from "@/views/tenant/TenantProfile"
import TenantCustomer from "@/views/tenant/TenantCustomer"
import TenantContacts from "@/views/tenant/TenantContacts"

// Views related to users
import UserProfile from "@/views/user/UserProfile"
import UserSettings from "@/views/user/UserSettings"

// Views related to onboarding users
import FirstLogin from "@/views/onboarding/FirstLogin"
import ForgotPassword from "@/views/onboarding/ForgotPassword"
import ResetPassword from "@/views/onboarding/ResetPassword"

import MessagesView from "@/views/MessagesView"

import HelpView from "@/views/HelpView"

import { store } from "../store"
import FleetAnalyticsView from "../views/FleetAnalyticsView.vue"

export const routes = [
  {
    path: "/",
    component: StartView,
    redirect: "/home",
    name: "start",
    children: [
      {
        path: "/home",
        component: HomeView
      },
      {
        path: "/messages",
        component: MessagesView,
        name: "messages"
      },
      {
        path: "/user_profile",
        component: UserProfile,
        name: "user_profile"
      },
      {
        path: "/user_settings",
        component: UserSettings,
        name: "user_settings"
      },
      // Machines
      {
        path: "/machines/:tenantUid/:serialNo",
        component: MachineView,
        props: true,
        children: [
          {
            path: "dashboard",
            component: MachineDashboard,
            props: true,
            name: "dashboard"
          },
          {
            path: "info",
            component: MachineInfo,
            props: true,
            name: "info"
          },
          {
            path: "alarms",
            component: MachineAlarms,
            props: true,
            name: "alarms"
          },
          {
            path: "measurements",
            component: MachineMeasurements,
            props: true,
            name: "measurements"
          },
          {
            path: "maintenance",
            component: MachineMaintenance,
            props: true,
            name: "maintenance"
          },
          {
            path: "rental",
            component: MachineRental,
            props: true,
            name: "rental"
          },
          {
            path: "location",
            component: MachineLocation,
            props: true,
            name: "location"
          },
          {
            path: "parameters",
            component: MachineParameters,
            props: true,
            name: "parameters"
          },
          {
            path: "system",
            component: MachineSystem,
            props: true,
            name: "system"
          },
          {
            path: "documents",
            component: MachineManuals,
            props: true,
            name: "machineDocuments"
          }
        ]
      },
      // Tenants
      {
        path: "/tenants",
        component: TenantsView,
        name: "tenants",
        children: [
          {
            path: "/tenants/:tenantUid/fleet_analytics",
            component: FleetAnalyticsView,
            name: "fleet_analytics"
          },
          {
            path: "/tenants/:tenantUid",
            component: TenantHome,
            children: [
              {
                path: "machines",
                component: MachinesView,
                props: true
              },
              {
                path: "map",
                component: MapView,
                props: true
              },
              {
                path: "users",
                component: UsersView,
                props: true
              },
              {
                path: "reports",
                component: ReportsView,
                props: true
              }
            ]
          }
        ]
      },
      // Documents
      {
        path: "/documents",
        component: DocumentsView,
        props: true,
        children: [
          {
            path: "manuals",
            component: DocumentsManualsSelectionView,
            props: true,
            children: [
              {
                path: "view",
                component: DocumentsManualsView
              }
            ]
          },
          {
            path: "warranty_claims",
            component: DocumentLinkWidget,
            props: () => ({
              title: `${store.state.$i18n.t("documents.menuItems.warranty")}`,
              subtitle: `${store.state.$i18n.t("documents.warranty.subtitle")}`,
              descriptions: [
                {
                  id: 1,
                  text: `${store.state.$i18n.t(
                    "documents.warranty.description"
                  )}`
                }
              ]
            })
          },
          {
            path: "return_form",
            component: DocumentLinkWidget,
            props: () => ({
              title: `${store.state.$i18n.t("documents.menuItems.returnform")}`,
              subtitle: `${store.state.$i18n.t(
                "documents.returnform.subtitle"
              )}`,
              descriptions: [
                {
                  id: 1,
                  text: `${store.state.$i18n.t(
                    "documents.returnform.description1"
                  )}`
                },
                {
                  id: 2,
                  text: `${store.state.$i18n.t(
                    "documents.returnform.description2"
                  )}`
                },
                {
                  id: 3,
                  text: `${store.state.$i18n.t(
                    "documents.returnform.description3"
                  )}`
                }
              ]
            })
          },
          {
            path: "service_protocols",
            component: DocumentLinkWidget,
            props: () => ({
              title: `${store.state.$i18n.t(
                "documents.menuItems.serviceProtocols"
              )}`,
              subtitle: `${store.state.$i18n.t(
                "documents.serviceProtocols.subtitle"
              )}`,
              descriptions: [
                {
                  id: 1,
                  text: `${store.state.$i18n.t(
                    "documents.serviceProtocols.description1"
                  )}`
                },
                {
                  id: 2,
                  text: `${store.state.$i18n.t(
                    "documents.serviceProtocols.description2"
                  )}`
                }
              ]
            })
          },
          {
            path: "service_instructions",
            component: ServiceInstructions,
            props: true,
            name: "serviceinstructions"
          },
          {
            path: "service_manuals",
            component: DocumentLinkWidget,
            props: () => ({
              title: `${store.state.$i18n.t(
                "documents.menuItems.serviceManuals"
              )}`,
              subtitle: `${store.state.$i18n.t(
                "documents.serviceManuals.subtitle"
              )}`,
              descriptions: [
                {
                  id: 1,
                  text: `${store.state.$i18n.t(
                    "documents.serviceManuals.description"
                  )}`
                }
              ]
            })
          },
          {
            path: "spare_parts",
            component: DocumentLinkWidget,
            props: () => ({
              title: `${store.state.$i18n.t("documents.menuItems.spareParts")}`,
              subtitle: `${store.state.$i18n.t(
                "documents.spareParts.subtitle"
              )}`,
              descriptions: [
                {
                  id: 1,
                  text: `${store.state.$i18n.t(
                    "documents.spareParts.description"
                  )}`
                }
              ]
            })
          },
          {
            path: "service_bulletins",
            component: ServiceBulletins,
            props: true,
            name: "servicebulletins"
          }
        ]
      },
      {
        path: "/tenant_details/:tenantUid",
        component: TenantView,
        redirect: { name: "tenantProfile" },
        props: true,
        children: [
          {
            path: "profile",
            component: TenantProfile,
            props: true,
            name: "tenantProfile"
          },
          {
            path: "customer",
            component: TenantCustomer,
            props: true,
            name: "tenantCustomer"
          },
          {
            path: "contacts",
            component: TenantContacts,
            props: true,
            name: "tenantContacts"
          }
        ]
      },
      {
        path: "downloads",
        component: DownloadsView,
        props: true
      }
    ]
  },
  {
    path: "/help",
    component: HelpView,
    props: true
  },
  {
    path: "/forgot_password",
    component: ForgotPassword,
    name: "forgotPassword"
  },
  {
    path: "/verify/:username/:code",
    component: FirstLogin,
    name: "verify"
  },
  {
    path: "/reset/:username/:code",
    component: ResetPassword,
    name: "reset"
  },
  {
    path: "/login",
    component: LoginView,
    name: "login"
  },
  {
    path: "/shop",
    redirect: () => {
      window.location = store.getters.webshopURL
    }
  },
  {
    path: "/crm",
    redirect: () => {
      window.location = store.getters.crmURL
    }
  },
  {
    path: "/test",
    redirect: () => {
      window.location = "https://testshop.dev.brk-dev-iot.net"
    }
  }
]
