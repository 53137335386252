import fetchClient from "@/utils/fetchClient"

export default {
  state: {
    measurements: [],

    loadingMeasurements: false
  },
  mutations: {
    setMeasurements(state, payload) {
      state.measurements = payload
    },
    setLoadingMeasurements(state, payload) {
      state.loadingMeasurements = payload
    }
  },
  actions: {
    fetchMeasurements(context, queryParameters) {
      context.commit("setLoadingMeasurements", true)

      const { tenantUid, serialNo, start, end, parameters } = queryParameters

      fetchClient(this.state.$i18n)
        .get(
          `/api/v1/tenants/${tenantUid}/machines/${serialNo}/measurements?q=range&start=${start}&end=${end}&sort=asc&parameters=${parameters}`
        )
        .then(data => {
          context.commit("setMeasurements", data)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoadingMeasurements", false)
        })
    }
  },
  getters: {
    measurements(state) {
      return state.measurements
    },
    loadingMeasurements(state) {
      return state.loadingMeasurements
    }
  }
}
