var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","flat":"","dense":""}},[_c('v-toolbar-title',[_c('v-icon',{staticClass:"accent--text mr-4"},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"title accent--text"},[_vm._v(_vm._s(_vm.title))])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onCancel}},[_c('v-icon',{staticClass:"accent--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-1 pb-0"},[_c('v-form',{ref:"ServiceDocumentForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.action != 'Edit')?_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.updateMessage)+" ")]):_vm._e()])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf","placeholder":`${_vm.$t(
                    'documents.serviceDocuments.addEditItemForm.fileuploadLabel'
                  )}`,"label":`${_vm.$t(
                    'documents.serviceDocuments.addEditItemForm.uploadFilePlaceholder'
                  )}*`,"rules":_vm.fileUploadRules ? [_vm.validationRules.required] : []},on:{"change":_vm.onFileSelected}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"pl-1 ml-1",attrs:{"label":`${_vm.$t(
                    'documents.serviceDocuments.addEditItemForm.partNumberLabel'
                  )}`,"placeholder":_vm.$t(
                    'documents.serviceDocuments.addEditItemForm.partNumberPlaceholder'
                  ),"rules":[_vm.validationRules.required, _vm.validationRules.partNo],"disabled":_vm.disablePartNoTextfield},model:{value:(_vm.partNo),callback:function ($$v) {_vm.partNo=$$v},expression:"partNo"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"pl-1 ml-1",attrs:{"label":`${_vm.$t(
                    'documents.serviceDocuments.addEditItemForm.descriptionLabel'
                  )}`,"placeholder":_vm.$t(
                    'documents.serviceDocuments.addEditItemForm.descriptionPlaceholder'
                  ),"rules":[
                  _vm.validationRules.required,
                  v =>
                    (v || '').length <= 256 ||
                    _vm.$t('common.validation.errors.commentMaxChars')
                ]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('v-select',{staticClass:"pl-1 ml-1",attrs:{"label":`${_vm.$t(
                    'documents.serviceDocuments.addEditItemForm.machinesLabel'
                  )}`,"items":_vm.items(),"item-text":"name","item-value":"productId"},model:{value:(_vm.selectedMachine),callback:function ($$v) {_vm.selectedMachine=$$v},expression:"selectedMachine"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"7"}},[_c('v-text-field',{staticClass:"pl-1 ml-1",attrs:{"label":`${_vm.$t(
                    'documents.serviceDocuments.addEditItemForm.serialLabel'
                  )}*`,"disabled":_vm.isUpdating,"placeholder":_vm.$t(
                    'documents.serviceDocuments.addEditItemForm.serialPlaceholder'
                  ),"rules":[_vm.validationRules.serialNumberList]},model:{value:(_vm.serials),callback:function ($$v) {_vm.serials=$$v},expression:"serials"}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"2","sm":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.addItem}},on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("documents.serviceDocuments.action.addSerials")))])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0; my-list",attrs:{"cols":"12"}},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100px"}},_vm._l((_vm.itemsList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item))]),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('v-icon',[_vm._v("mdi-minus-circle")])],1)],1)],1)}),1)],1)],1)],1)],1),(_vm.errorMsg.length > 0)?_c('v-alert',{staticClass:"ma-0 mt-4",attrs:{"type":"error","elevation":"2","border":"left","colored-border":"","dense":""}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e()],1),_c('v-card-actions',{staticClass:"pr-6 mr-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 mb-2",on:{"click":function($event){$event.stopPropagation();return _vm.onSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("common.actions.save")))]),_c('v-btn',{staticClass:"mb-2 grab-cancel-edit-user",on:{"click":function($event){$event.stopPropagation();return _vm.onCancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("common.actions.cancel")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }