export default {
  users: {
    actions: {
      add: "Add user",
      edit: "Edit user",
      export: "Export all verified users that have web shop access"
    },
    details: {
      accountVerified: "Account verified",
      name: "Name",
      title: "User details"
    },
    dialogs: {
      edit: {
        descriptionPlaceholder: "Enter description",
        emailPlaceholder: "Enter email address",
        familyName: "Family name",
        familyNamePlaceholder: "Enter family name",
        fleetAnalyticsAccess: "Allow access to Fleet Analytics",
        givenName: "Given name",
        givenNamePlaceholder: "Enter given name",
        messages: {
          userAdded: "User has been added",
          userExport: "Users successfully exported",
          userUpdated: "User has been updated"
        },
        rolePlaceholder: "Select role",
        webshopAccess: "Web shop access role"
      }
    },
    itemAttributes: {
      description: "Description",
      email: "Email",
      fleetAnalyticsAccess: "Fleet analytics access",
      lastLogin: "Last login",
      name: "Name",
      role: "Role",
      tenant: "Tenant",
      webshopRole: "Web shop role"
    },
    itemName: "user",
    settings: {
      menuItems: {
        notifications: "Notifications",
        preferences: "Preferences",
        profile: "Profile",
        settings: "Settings"
      },
      notifications: {
        maintenance: "Maintenance",
        otherNotifications: "Other notifications",
        rental: "Rental",
        sendWeeklyReports: "Send weekly reports",
        sendWeeklyReportsDesc:
          "Send a report with status of all your Brokk robots once a week.",
        title: "Notifications"
      },
      preferences: {
        description:
          "Here you can define your own preferences according to web application defaults like Languange, Units, Default home page etc.",
        initialView: "Initial view",
        units: "Units"
      },
      profile: {
        title: "User profile"
      }
    }
  }
}
