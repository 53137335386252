<template>
  <v-dialog v-model="show" max-width="600" persistent scrollable>
    <v-card class="dialog-card">
      <v-card-title>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>
            <v-icon class="accent--text mr-4">mdi-home-city</v-icon>
            <span class="title accent--text">{{ title }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="onCancel">
            <v-icon class="accent--text">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pt-1 pb-1">
        <v-form ref="tenantForm" class="mb-4">
          <v-container>
            <!-- Tenant table -->
            <v-data-table
              v-model="selectedParent"
              :headers="headers"
              :items="tenantArray"
              item-key="uid"
              sort-by="name"
              class="elevation-1"
              :items-per-page="-1"
              show-select
              single-select
              hide-default-footer
            >
            </v-data-table>
            <!-- Error -->
            <v-row>
              <v-col cols="12" class="py-0">
                <v-alert
                  v-if="error"
                  :type="error.type"
                  class="ma-0"
                  elevation="2"
                  border="left"
                  colored-border
                  dense
                  dismissible
                >
                  <div>{{ error.message }}</div>
                  <div v-if="error.causedBy" class="subtitle">
                    {{ error.causedBy }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pr-6 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2 mb-2"
          :disabled="!valid"
          :loading="isUpdating"
          @click.stop="onSave"
          >{{ $t("common.actions.save") }}</v-btn
        >
        <v-btn
          class="mb-2 grab-cancel-edit-tenant"
          :disabled="isUpdating"
          @click.stop="onCancel"
          >{{ $t("common.actions.cancel") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.dialog-card > * {
  padding: 0 !important;
}
</style>

<script>
import fetchClient from "@/utils/fetchClient"

export default {
  name: "EditTenantDialogChangeParent",
  props: ["parent", "tenant", "show"],

  data() {
    return {
      isUpdating: false,
      error: null,
      selectedParent: [],
      headers: [
        {
          text: this.$t("tenant.dataTable.headers.name"),
          value: "name",
          hide: "xs"
        },
        {
          text: this.$t("tenant.dataTable.headers.description"),
          value: "description",
          hide: "xs"
        },
        {
          text: this.$t("tenant.dataTable.headers.country"),
          value: "country",
          hide: "xs"
        },
        {
          text: this.$t("tenant.dataTable.headers.city"),
          value: "city",
          hide: "xs"
        }
      ]
    }
  },

  computed: {
    tenantArray() {
      const filteredTenants = this.$store.getters.tenantArray.filter(
        i => i.uid != this.tenant.uid
      )

      return filteredTenants.toSorted((a, b) => a.name > b.name)
    },
    isEditing() {
      return this.tenant.uid !== undefined
    },
    title() {
      return (
        this.$t("common.actions.move") +
        " " +
        this.$store.getters.selectedTenant.name
      )
    },
    valid() {
      return !!this.selectedParent?.length
    }
  },
  methods: {
    async changeParent(newParent) {
      let url = `/api/v1/tenants/${this.tenant.uid}/parent`
      const maxLevelNumberChild = newParent.children?.reduce((prev, next) =>
        prev.level > next.level ? prev : next
      )

      const maxLevelNumber = maxLevelNumberChild
        ? Number(maxLevelNumberChild.level.replace(newParent.level + ",", ""))
        : 0

      const childNo = String(Number(maxLevelNumber + 1))

      const item = {
        // Zero-pad the level, e.g. '012' instead of '12'
        level: newParent.level + "," + childNo.padStart(3, "0"),
        parentUid: newParent.uid,
        depth: newParent.depth + 1
      }
      this.error = null

      this.isUpdating = true
      try {
        await fetchClient(this.$i18n).putOrThrow(url, item)
        this.$emit("save", Object.assign(this.tenant, item))
        this.selectedParent = []
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    },
    onSave() {
      this.changeParent(this.selectedParent[0])
    },
    onCancel() {
      this.error = null
      this.selectedParent = []
      this.$emit("cancel", {})
    }
  }
}
</script>
