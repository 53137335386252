<template>
  <v-dialog v-model="visible" max-width="600" persistent>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title class="d-flex align-center">
        <v-icon class="accent--text mr-2">mdi-transfer</v-icon>
        <span class="title accent--text"
          >{{ $t("machines.dialogs.transferMachine.title") }}
          {{ machine.serialNo }}
        </span>
      </v-toolbar-title>
    </v-toolbar>

    <v-card v-if="existingTransfer !== null">
      <v-card-title class="headline black--text">{{
        $t("machines.dialogs.transferMachine.steps.transferOwnership.title")
      }}</v-card-title>
      <v-card-text class="primary--text">
        <div v-if="existingTransfer.transferred !== undefined">
          <p>
            {{
              $t(
                "machines.dialogs.transferMachine.errors.machineAlreadyTransferred"
              )
            }}
            {{ existingTransfer.transferred | localeDateFilter }}
          </p>
          <p>{{ $t("machines.dialogs.transferMachine.refreshTheList") }}</p>
        </div>
        <div v-else>
          <p>
            {{ $t("machines.dialogs.transferMachine.errors.pendingTransfer") }}
            {{ existingTransfer.created | localeDateFilter }}
          </p>
          <v-text-field
            outlined
            readonly
            :label="$t('machines.dialogs.transferMachine.transferCode')"
            v-model="existingTransfer.transferCode"
          ></v-text-field>
          <p>
            {{
              $t("machines.dialogs.transferMachine.errors.transferCodeExpires")
            }}
            {{ existingTransfer.expires | localeDateFilter }}
          </p>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onCancel">{{ $t("common.actions.close") }}</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <v-stepper v-else v-model="stepNo">
      <v-stepper-header>
        <v-stepper-step :complete="stepNo > 1" step="1">
          {{
            $t("machines.dialogs.transferMachine.steps.transferOwnership.name")
          }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepNo > 2" step="2">
          {{ $t("machines.dialogs.transferMachine.steps.dataRetention.name") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="stepNo > 3">
          {{ $t("machines.dialogs.transferMachine.steps.confirm.name") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="newTransfer !== null">
          {{ $t("machines.dialogs.transferMachine.steps.summary.name") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card flat>
            <v-card-title class="headline black--text">
              {{
                $t(
                  "machines.dialogs.transferMachine.steps.transferOwnership.title"
                )
              }}
            </v-card-title>
            <v-card-text class="primary--text">
              <p>
                {{
                  $t(
                    "machines.dialogs.transferMachine.steps.transferOwnership.description"
                  )
                }}
              </p>
              <p>
                {{
                  $t(
                    "machines.dialogs.transferMachine.transferAutomaticRevertInfo"
                  )
                }}
              </p>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="stepNo = 2">{{ $t("common.actions.next") }}</v-btn>
            <v-btn @click="onCancel" class="grab-cancel-machine-transfer">{{
              $t("common.actions.cancel")
            }}</v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card flat>
            <v-card-title class="headline black--text">
              {{
                $t("machines.dialogs.transferMachine.steps.dataRetention.title")
              }}
            </v-card-title>
            <v-card-text class="primary--text">
              <p>
                {{
                  $t(
                    "machines.dialogs.transferMachine.steps.dataRetention.description"
                  )
                }}
              </p>
              <v-checkbox v-model="includeHistoryChecked">
                <template v-slot:label>
                  <div>
                    {{
                      $t(
                        "machines.dialogs.transferMachine.steps.dataRetention.includeHistoricalData"
                      )
                    }}
                  </div>
                </template>
              </v-checkbox>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-btn @click="stepNo = 1">{{
              $t("common.actions.previous")
            }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="stepNo = 3">{{ $t("common.actions.next") }}</v-btn>
            <v-btn @click="onCancel">{{ $t("common.actions.cancel") }}</v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card flat>
            <v-card-title class="headline black--text">
              {{ $t("machines.dialogs.transferMachine.steps.confirm.title") }}
            </v-card-title>
            <v-card-text class="primary--text">
              <p>
                {{
                  $t(
                    "machines.dialogs.transferMachine.steps.confirm.description",
                    {
                      sn: machine.serialNo,
                      productName: machine.productName,
                      tenant: machine.tenantName
                    }
                  )
                }}
              </p>
              <p>
                {{
                  includeHistoryChecked
                    ? $t(
                        "machines.dialogs.transferMachine.steps.confirm.includeHistoricalDataInfo"
                      )
                    : $t(
                        "machines.dialogs.transferMachine.steps.confirm.notIncludeHistoricalDataInfo"
                      )
                }}
              </p>
              <p>
                {{
                  $t(
                    "machines.dialogs.transferMachine.steps.confirm.proceedInfo"
                  )
                }}
              </p>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-btn :disabled="isGeneratingCode" @click="stepNo = 2">{{
              $t("common.actions.previous")
            }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="isGeneratingCode" @click="onSave">{{
              $t("common.actions.next")
            }}</v-btn>
            <v-btn :disabled="isGeneratingCode" @click="onCancel">{{
              $t("common.actions.cancel")
            }}</v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card flat>
            <v-card-title class="headline black--text">
              {{ $t("machines.dialogs.transferMachine.steps.summary.title") }}
            </v-card-title>
            <v-card-text class="primary--text" v-if="errorMsg !== null">
              <p>{{ errorMsg }}</p>
            </v-card-text>
            <v-card-text class="primary--text" v-else
              ><p>
                {{
                  $t(
                    "machines.dialogs.transferMachine.steps.summary.description"
                  )
                }}
              </p>
              <v-text-field
                v-if="newTransfer !== null"
                outlined
                readonly
                :label="$t('machines.dialogs.transferMachine.transferCode')"
                v-model="newTransfer.transferCode"
              ></v-text-field>
              <p>
                {{
                  $t(
                    "machines.dialogs.transferMachine.transferAutomaticRevertInfo"
                  )
                }}
              </p>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="onCancel">{{ $t("common.actions.close") }}</v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
export default {
  name: "GuidedMachineTransfer",
  props: ["machine", "show"],
  data() {
    return {
      stepNo: 1,
      existingTransfer: null,
      newTransfer: null,
      isGeneratingCode: false,
      includeHistoryChecked: false,
      errorMsg: null
    }
  },
  created() {},
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(value) {
        if (!value) {
          this.$emit("cancel")
        }
      }
    }
  },
  watch: {
    show() {
      this.stepNo = 1
      this.existingTransfer = null
      this.newTransfer = null
      this.isGeneratingCode = false
      this.includeHistoryChecked = false
      this.errorMsg = null

      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      if (this.machine !== undefined && this.machine !== null) {
        this.$store
          .dispatch("fetchMachineTransfer", {
            tenantUid: this.machine.tenantUid,
            serialNo: this.machine.serialNo
          })
          .then(machineTransfer => {
            this.existingTransfer = machineTransfer
          })
          .catch(() => {
            this.existingTransfer = null
          })
      }
    },
    onSave() {
      this.isGeneratingCode = true
      this.errorMsg = null

      const params = {
        machine: this.machine,
        transfer: {
          includeHistory: this.includeHistoryChecked,
          srcTenantUid: this.machine.tenantUid
        }
      }

      this.$store
        .dispatch("createMachineTransfer", params)
        .then(() => {
          this.newTransfer = this.$store.getters.machineTransfer
          this.stepNo = 4
        })
        .catch(() => {
          this.errorMsg = this.$t(
            "machines.dialogs.transferMachine.errors.internalError"
          )
          this.stepNo = 4
        })
        .finally(() => {
          this.isGeneratingCode = false
        })
    },
    onCancel() {
      this.visible = false
    }
  }
}
</script>
