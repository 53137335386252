export default {
  home: {
    documents: {
      description: "Libreria completa di tutti i nostri modelli Brokk."
    },
    greeting: "Benvenuto in My Brokk",
    machines: {
      description: "Visualizza le informazioni sulle tue macchine Brokk.",
      register: "Registra una macchina"
    },
    webshop: {
      description: "Acquista pezzi di ricambio nel nostro negozio online.",
      noAccess: {
        description:
          "Per ulteriori informazioni, contatta l'amministratore locale.",
        title: "L'accesso al negozio online non è stato autorizzato"
      }
    }
  }
}
