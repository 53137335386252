export default {
  fleetAnalytics: {
    dashboardHeader: null,
    dataTable: {
      headers: {
        contacts: null,
        machines: null,
        segments: null,
        tenant: null
      },
      machines: {
        count: null,
        engineHours: null,
        serviceAgreements: null
      }
    },
    widgets: {
      segments: null,
      utilization: null
    }
  }
}
