import { Auth } from "aws-amplify"
import { store } from "../store"

async function refreshSessionTokens(user, session) {
  return new Promise((resolve, reject) => {
    user.refreshSession(session.getRefreshToken(), (err, result) => {
      if (err != null) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  })
}

export async function checkSessionTokens() {
  let session = await Auth.currentSession()
  const user = await Auth.currentAuthenticatedUser()

  // Get the access token expiration UTC time in seconds
  let expireTime = +session.accessToken.payload.exp
  const expDate = new Date()
  expDate.setTime(expireTime * 1000)

  //  Current UTC time in seoncds
  const nowTime = Date.now() / 1000

  // Remaining time in seconds
  const remainingTime = expireTime - nowTime

  // Min valid token time in seconds before we make a refresh
  const threshold = 120

  if (remainingTime < threshold) {
    // Refresh tokens using current session info
    await refreshSessionTokens(user, session)

    // Update the session object so that changes
    // are reflected in the cookies
    session = await Auth.currentSession()

    // Update the data in the state store
    await store.dispatch("setSessionTokens", session)
  }
}
