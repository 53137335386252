import fetchClient from "@/utils/fetchClient"
import fetchData from "../fetchData"

/* eslint-disable */

// Convert the tree structure with child elements
// to a flat array.
function flatten(tree) {
  let arr = []
  var tenant = Object.assign({}, tree)
  //delete tenant.children

  // Label to display in UI.
  if (tenant.country && tenant.country.length > 0) {
    tenant.label = `${tenant.name} (${tenant.country})`
  } else {
    tenant.label = tenant.name
  }

  arr.push(tenant)

  if (tree.children) {
    tree.children.forEach(child => {
      arr.push(...flatten(child))
    })
  }

  // Sort the list by name
  arr.sort((itemA, itemB) => {
    return itemA.name.localeCompare(itemB.name)
  })

  return arr
}

function removeTenantFromTree(tree, tenantUid) {
  if (tree.children !== undefined) {
    let ix = tree.children.findIndex(elem => elem.uid === tenantUid)
    if (ix == -1) {
      for (var childNo = 0; childNo < tree.children.length; childNo++) {
        var elem = tree.children[childNo]
        if (removeTenantFromTree(elem, tenantUid)) {
          return true
        }
      }
    } else {
      tree.children.splice(ix, 1)
      if (tree.children.length == 0) {
        // remove the children propery
        delete tree.children
      }
      return true
    }
  }
  return false
}

export default {
  state: {
    // Tree structure used in the treeview.
    tenantTree: [],
    // Flat array used in drop-down lists.
    tenantArray: [],
    // Currently selected tenant
    selectedTenant: null,
    // Flat array of selected Tenant and its children
    selectedTenantArray: [],
    // If the tenant tree is mini version
    isTenantTreeMini: false,

    // Details about the tenant, Profile sort of.
    tenantDetails: {},
    // The tenants customer information (for the web shop)
    tenantCustomer: {},
    // The tenants contacts
    tenantContacts: [],
  },
  mutations: {
    setTenantTree(state, payload) {
      // Store response object as array, which is suitable for the tree view.
      state.tenantTree = [payload]
      // Convert the tree to a flat array for drop-down lists (selection).
      state.tenantArray = flatten(payload)
    },
    removeTenant(state, tenantUid) {
      // We make a copy of the tree so that the framework will know that it has changed.
      // If we only change the inner contents then the tree view will not be updated.
      var tree = Object.assign({}, state.tenantTree[0])
      removeTenantFromTree(tree, tenantUid)
      state.tenantTree = [tree]
      state.tenantArray = flatten(tree)
    },
    setSelectedTenant(state, payload) {
      if (payload === undefined || payload === null) {
        state.selectedTenant = null
        state.selectedTenantArray = []
      } else {
        state.selectedTenant = payload
        state.selectedTenantArray = flatten(payload)
      }
    },
    setIsTenantTreeMini(state, payload) {
      state.isTenantTreeMini = payload
    },
    setTenantDetails(state, payload) {
      state.tenantDetails = payload
    },
    setTenantCustomer(state, payload) {
      state.tenantCustomer = payload
    },
    setTenantContacts(state, payload) {
      state.tenantContacts = payload
    }
  },
  actions: {
    loadTenantTree({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true)
        dispatch(
          "tenantTreeStore/fetchData",
          () => fetchClient(this.state.$i18n).get("/api/v1/tenants"),
          { root: true }
        )
          .then(data => {
            commit("setTenantTree", data.tenants)
            resolve()
          })
          .catch(error => {
            commit("setError", error)
            reject()
          })
          .finally(() => {
            commit("setLoading", false)
          })
      })
    },
    fetchTenant(context, { tenantUid }) {
      context.commit("setLoading", true)
      fetchClient(this.state.$i18n)
        .get(`/api/v1/tenants/${tenantUid}`)
        .then(data => {
          context.commit("setTenantDetails", data)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoading", false)
        })
    },
    fetchTenantCustomer(context, { tenantUid }) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)
        fetchClient(this.state.$i18n)
          .get(`/api/v1/tenants/${tenantUid}/customer`)
          .then(data => {
            context.commit("setTenantCustomer", data)
            resolve()
          })
          .catch(error => {
            if (error && error.status === 404) {
              // Not found, set empty object
              context.commit("setTenantCustomer", {})
              resolve()
            } else {
              context.commit("setError", error)
              reject(error)
            }
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    fetchTenantContacts(context, { tenantUid, withSubtenants = false }) {
      return new Promise((resolve, reject) => {
        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${tenantUid}/contacts${
              withSubtenants ? "?withSubtenants" : ""
            }`
          )
          .then(data => {
            context.commit("setTenantContacts", data)
            resolve()
          })
          .catch(error => {
            if (error && error.status === 404) {
              // Not found, set empty object
              context.commit("setTenantContacts", [])
              resolve()
            } else {
              context.commit("setError", error)
              reject(error)
            }
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    removeTenant(context, tenant) {
      context.commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .delete(`/api/v1/tenants/${tenant.uid}`, tenant)
        .then(() => {
          context.commit("removeTenant", tenant.uid)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoading", false)
        })
    },
    setSelectedTenant(context, tenant) {
      context.commit("setSelectedTenant", tenant)
    },
    setIsTenantTreeMini(context, isTenantTreeMini) {
      context.commit("setIsTenantTreeMini", isTenantTreeMini)
    }
  },
  getters: {
    tenantTree(state) {
      return state.tenantTree
    },
    tenantRoot(state) {
      return state.tenantTree[0]
    },
    tenantArray(state) {
      return state.tenantArray
    },
    selectedTenant(state) {
      return state.selectedTenant
    },
    selectedTenantArray(state) {
      return state.selectedTenantArray
    },
    isTenantTreeMini(state) {
      return state.isTenantTreeMini
    },
    tenant(state) {
      return state.tenantDetails
    },
    tenantCustomer(state) {
      return state.tenantCustomer
    },
    tenantContacts(state) {
      return state.tenantContacts
    }
  },
  modules: {
    tenantTreeStore: fetchData
  }
}
