<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title class="d-flex align-center">
          <v-icon class="accent--text mr-2">mdi-license</v-icon>
          <span class="title accent--text">{{ $t("termsOfService.title") }}</span></v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="pt-6 subtitle-1">
        <p>{{ $t("termsOfService.description", { APP_TITLE: $store.getters.appTitle }) }}</p>
        <v-checkbox v-model="isChecked">
          <template v-slot:label>
            <i18n path="termsOfService.agreeTerms" tag="div">
              <template v-slot:TERMS>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="/docs/terms-of-service.html"
                      @click.stop
                      v-on="on"
                    >
                      {{ $t("termsOfService.title") }}
                    </a>
                  </template>
                  {{ $t("common.actions.openInNewWindow") }}
                </v-tooltip>
              </template>
              <template v-slot:POLICY>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://www.brokk.com/privacy-policy/"
                      @click.stop
                      v-on="on"
                    >
                    {{ $t("termsOfService.privacyPolicy") }}
                    </a>
                  </template>
                  {{ $t("common.actions.openInNewWindow") }}
                </v-tooltip>
              </template>
            </i18n>
          </template>
        </v-checkbox>
      </v-card-text>

      <v-card-actions v-if="config.buttons !== undefined">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!isChecked"
          @click.stop="
            onClose()
            config.buttons[0].action()
          "
          class="ma-2"
        >
          {{ config.buttons[0].label }}
        </v-btn>
        <v-btn
          @click.stop="
            onClose()
            config.buttons[1].action()
          "
          class="ma-2"
        >
          {{ config.buttons[1].label }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TermsOfServiceDialog",
  props: ["config"],
  data() {
    return {
      dialog: true,
      isChecked: false
    }
  },
  methods: {
    onClose() {
      this.dialog = false
    }
  }
}
</script>
