<template>
  <v-row
    class="ma-0 pa-0"
    :class="messageColor"
    style="cursor:pointer;"
    @click.stop="onItemClicked"
    align="center"
  >
    <v-col cols="1" class="ma-0 pa-0 text-center align-center">
      <v-icon v-if="!isRead">mdi-circle-medium</v-icon>
    </v-col>
    <v-col cols="9" class="ma-0 pa-0">
      <v-card flat rounded="0" :class="messageColor">
        <v-card-title class="pa-0 ma-0 px-4 pt-2">
          {{ message.title }}
        </v-card-title>
        <v-card-subtitle class="text-truncate text-caption pt-2 px-4">{{ message.ingress }}</v-card-subtitle>
      </v-card>
    </v-col>
    <v-col cols="2" class="ma-0 pa-0 text-center text-caption">
      {{ formatDate(message.pubDate) }}
    </v-col>
  </v-row>
</template>

<script>
import formatter from "@/utils/formatter"

export default {
  props: ["message", "isRead", "isSelected"],
  data: () => ({}),
  watch: {},
  created() {},
  computed: {
    messageColor() {
      let color = this.isRead ? "white" : "secondary"
      color = this.isSelected ? "grey lighten-4" : color
      return color
    }
  },
  methods: {
    onItemClicked() {
      this.$emit("msgClicked", this.message)
    },
    formatDate(date) {
      return formatter(this.$i18n).formatDate(date)
    }
  }
}
</script>

