import temperatureUtils from "@/utils/temperature"
import pressureUtils from "@/utils/pressure"

const unitUtils = {
  getCode: (unitCode, units) => {
    switch (unitCode){
      case "dgr C":
        return temperatureUtils.getCode(units)
      case "bar":
        return pressureUtils.getCode(units)
      default:
        return unitCode
    }
  },
  convert: (unitCode, value, units) => {
    switch(unitCode){
      case "dgr C":
        return Number(temperatureUtils.convertFromCelcius(value, units)).toFixed(0)
      case "bar":
        return Number(pressureUtils.convertFromBar(value, units)).toFixed(0)
      default:
        return value
    }
  }
}
export default unitUtils
