<template>
  <div :id="'machine_' + id" class="dropdown-image-control">
    <div>
      <div class="body-block" v-show="collapsed" @click="onExpandCollapse(false)">
        <img ref="image" @error="onError" :src="src" :title="title" :alt="alt" class="machine-manual-avatar" />
      </div>
      <div class="body-block" v-show="!collapsed">
        <select ref="optionsElement" :size="items.length > 1 ? items.length : 2" @click="onSelect"
          v-on:keyup.enter="onSelect" v-on:keyup.space="onSelect" v-on:keyup.escape="onBlur(true, $event)"
          @blur="onBlur(false, $event)" v-model="select">
          <option v-for="(option, index) in items" :value="option" :key="index">{{ option.serialRange }}</option>
        </select>
      </div>
      <button ref="buttonElement" type="button" @click="onExpandCollapse(!collapsed)"
        v-bind:class="(collapsed) ? 'collapsed' : 'expanded'" v-on:keyup.up="onExpandCollapse(false)">
        <span class="button-label">{{ name }}</span>
        <v-icon color="info" v-if="!collapsed">mdi-chevron-up</v-icon>
        <v-icon color="info" v-if="collapsed">mdi-chevron-down</v-icon>
      </button>

    </div>
  </div>
</template>

<script>
export default {
  emits: ["select"],
  props: ["id", "name", "src", "noImageSrc", "title", "alt", "items"],
  data: () => ({
    collapsed: true,
    menu: false,
    select: {}
  }),
  methods: {
    onSelect(event) {
      if (this.select) {
        this.$emit("select", event, this.select)
      }
    },
    onExpand() {
      setTimeout(() => {
        this.$refs.optionsElement.focus()
      }, 100)
    },
    onBlur(byKeyboard, event) {
      if (!byKeyboard && event.relatedTarget == this.$refs.buttonElement) {
        return
      }
      this.collapsed = true
      if (byKeyboard) {
        this.$refs.buttonElement.focus()
      }
    },
    onExpandCollapse(collapsed) {
      const newCollapsed = collapsed ?? !this.collapsed
      this.collapsed = newCollapsed
      if (!newCollapsed) {
        this.onExpand()
      }
    },
    onError() {
      if (this.noImageSrc) {
        this.$refs.image.src = this.noImageSrc
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min--moz-device-pixel-ratio:0) {
  .dropdown-image-control .body-block select {
    scrollbar-width: thin;
  }
}

.dropdown-image-control {
  --dropdown-image-control-primary-color: 255, 255, 255;
  --dropdown-image-control-border-color: 220, 220, 220;
  --dropdown-image-control-secondary-color: 220, 220, 220;
  --dropdown-image-control-font-color: rgba(0, 0, 0, 0.87);
  --dropdown-image-control-font-size: 0.75rem;
  --dropdown-image-control-border-radius: 4px;
  --dropdown-image-control-border: 1px;
  --dropdown-image-control-width: 11rem;
  --dropdown-image-control-height: 10rem;
  --dropdown-image-control-btn-height: 2rem;
  --dropdown-image-control-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important; //.elevation-3

  -webkit-box-shadow: var(--dropdown-image-control-box-shadow);
  box-shadow: var(--dropdown-image-control-box-shadow);

  &>div {
    display: flex;
    flex-direction: column;
  }

  border-radius: var(--dropdown-image-control-border-radius);
  background-color: rgb(var(--dropdown-image-control-primary-color));
  color: var(--dropdown-image-control-font-color);
  font-size: var(--dropdown-image-control-font-size);
  min-width: calc(var(--dropdown-image-control-width) + (2 * var(--dropdown-image-control-border)));
  width:auto;

  //Custom scrollbar for webkit:
  select::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: rgba(var(--dropdown-image-control-secondary-color), 0.2);
  }

  select::-webkit-scrollbar {
    width: 5px;
  }

  select::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(var(--dropdown-image-control-secondary-color), 0.5);
  }

  //End of custom scrollbar

  select {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    outline: none;
    background-color: rgb(var(--dropdown-image-control-primary-color));
    border-end-start-radius: calc(var(--dropdown-image-control-border-radius) - var(--dropdown-image-control-border));
    border-end-end-radius: calc(var(--dropdown-image-control-border-radius) - var(--dropdown-image-control-border));

    &:focus option:checked {
      background: rgb(var(--dropdown-image-control-secondary-color)) linear-gradient(0deg, rgb(var(--dropdown-image-control-secondary-color)) 0%, rgb(var(--dropdown-image-control-secondary-color)));
    }

    option {
      color: var(--dropdown-image-control-font-color) !important;
      padding: 5px;

      &:hover,
      &:focus,
      &:active,
      &:checked {
        background-color: RGBA(var(--dropdown-image-control-secondary-color), 0.5);
        color: var(--dropdown-image-control-font-color) !important;
      }
    }
  }

  button {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    background-color: rgb(var(--dropdown-image-control-primary-color));
    color: var(--dropdown-image-control-font-color);
    min-width: var(--dropdown-image-control-width);
    max-height: var(--dropdown-image-control-btn-height);

    -webkit-box-shadow: var(--dropdown-image-control-box-shadow);
    box-shadow: var(--dropdown-image-control-box-shadow);

    &.collapsed,
    &.expanded {
      border-top: var(--dropdown-image-control-border) solid rgb(var(--dropdown-image-control-secondary-color));
      border-end-start-radius: calc(var(--dropdown-image-control-border-radius) - var(--dropdown-image-control-border));
      border-end-end-radius: calc(var(--dropdown-image-control-border-radius) - var(--dropdown-image-control-border));
    }

    .button-label {
      margin-left: 24px;
      flex: 1;
      text-align: center;
      vertical-align: middle;
    }
  }

  i {
    color: var(--dropdown-image-control-font-color) !important;
  }

  .machine-manual-avatar {
    max-height: var(--dropdown-image-control-height);
    min-width: var(--dropdown-image-control-width);
  }

  .body-block {
    display: flex;
    justify-content: center;

    img {
      min-height: var(--dropdown-image-control-height);
      border-start-start-radius: calc(var(--dropdown-image-control-border-radius) - var(--dropdown-image-control-border));
      border-start-end-radius: calc(var(--dropdown-image-control-border-radius) - var(--dropdown-image-control-border));
    }

    height: var(--dropdown-image-control-height);
    min-width: var(--dropdown-image-control-width);
  }
}
</style>
