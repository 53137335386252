import Vue from "vue"
import Vuex from "vuex"

import system from "./system"
import events from "./events"
import machines from "./machines"
import maintenance from "./maintenance"
import measurements from "./measurements"
import messages from "./messages"
import metadata from "./metadata"
import rental from "./rental"
import location from "./location"
import settings from "./settings"
import shared from "./shared"
import users from "./users"
import tenants from "./tenants"
import documents from "./documents"

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    vueAppVersion: process.env.VUE_APP_VERSION || "0"
  },
  getters: {
    appVersion: state => {
      return state.vueAppVersion
    }
  },
  modules: {
    system,
    events,
    machines,
    maintenance,
    measurements,
    messages,
    metadata,
    rental,
    settings,
    shared,
    users,
    tenants,
    location,
    documents,
  }
})
