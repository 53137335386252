<template>
  <div class="text-center d-inline-flex fluid">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-x
      nudge-right="8"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text v-bind="attrs" v-on="on">
          {{ filterName }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item v-for="item in items" :key="item.id">
            <v-list-item-action>
              <v-checkbox v-model="item.selected"></v-checkbox>
            </v-list-item-action>
            <v-list-item-title
              >{{ item.label }} ({{ item.count }})
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onApply">
            {{ $t("common.actions.apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["filterName", "availableItems", "selectedItems"],
  data: () => ({
    menu: false,
    items: []
  }),
  created() {
    this.setSelection()
  },
  methods: {
    onApply() {
      const selected = this.items.filter(it => {
        return it.selected
      })
      for (let ix = 0; ix < selected.length; ix++) {
        const s = selected[ix]
        delete s.selected
      }
      this.$emit("update:selectedItems", selected)
      this.menu = false
    },
    setSelection() {
      this.items = this.availableItems

      for (let ix = 0; ix < this.items.length; ix++) {
        const selItem = this.selectedItems.find(si => {
          return si.id === this.items[ix].id
        })
        if (selItem) {
          this.items[ix].selected = true
        } else {
          this.items[ix].selected = false
        }
      }
    }
  },
  watch: {
    menu(newMenuState) {
      if (newMenuState) {
        this.setSelection()
      }
    }
  }
}
</script>

<style></style>
