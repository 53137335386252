export default {
  home: {
    documents: {
      description: "La biblioteca al completo de todos nuestros modelos Brokk."
    },
    greeting: "Bienvenido a Mi Brokk",
    machines: {
      description: "Vea información sobre sus máquinas Brokk.",
      register: "Registrar una máquina"
    },
    webshop: {
      description: "Compre repuestos en nuestra tienda web.",
      noAccess: {
        description:
          "Póngase en contacto con su administrador local para obtener más información.",
        title: "No se ha concedido acceso a la tienda web"
      }
    }
  }
}
