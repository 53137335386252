<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-form-textbox-password</v-icon>
          <span class="title accent--text">{{ $t("login.changePassword") }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="onClose">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-main v-show="!passwordChanged" class="pa-0">
        <v-form
          ref="passwordForm"
          autocomplete="off"
          v-model="valid"
          class="pa-6"
        >
          <v-text-field
            v-model="oldpassword"
            :counter="50"
            :label="$t('login.oldPassword')"
            :rules="[validationRules.awsPasswordRequirements(this.oldpassword)]"
            :type="show1 ? 'text' : 'password'"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show1 = !show1"
          ></v-text-field>
          <v-text-field
            v-model="newpassword"
            :counter="50"
            :label="$t('login.newPassword')"
            :rules="[
              validationRules.awsPasswordRequirements(this.newpassword),
              validationRules.mustNotMatch(this.newpassword, this.oldpassword)
            ]"
            :type="show2 ? 'text' : 'password'"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show2 = !show2"
          ></v-text-field>
          <v-text-field
            v-model="confirmpassword"
            :counter="50"
            :label="$t('login.confirmNewPassword')"
            :rules="[
              validationRules.mustMatch(this.confirmpassword, this.newpassword)
            ]"
            :type="show3 ? 'text' : 'password'"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show3 = !show3"
          ></v-text-field>
          <p class="text-center">{{ $t("login.passwordStrength") }}</p>
          <password
            v-if="newpassword !== undefined"
            v-model="newpassword"
            :strength-meter-only="true"
          />
          <p class="error--text">{{ errorText }}</p>
          <v-card-actions class="mt-5 px-0 align-end">
            <v-spacer />
            <v-btn
              @click.stop="onChangePassword"
              :disabled="!this.valid || this.isChangingPassword"
              :loading="this.isChangingPassword"
              >{{ $t("login.changePassword") }}
            </v-btn>
            <v-btn @click.stop="onClose">{{ $t("common.actions.cancel") }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-main>
      <v-container fluid v-show="passwordChanged">
        <v-col align="center" justify="center" class="py-10">
          <h2>{{ $t("login.passwordHasChanged") }}</h2>
          <p>{{ $t("login.pleaseLoginWithNewPassword") }}</p>
          <v-btn class="mt-6" @click.stop="onClose">OK</v-btn>
        </v-col>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Password from "vue-password-strength-meter"
import ValidationRules from "@/utils/validationrules"
import { Auth } from "aws-amplify"

let validationRules = null

export default {
  components: {
    Password
  },
  data() {
    return {
      errorText: "",
      show1: false,
      show2: false,
      show3: false,
      valid: false,
      isChangingPassword: false,
      passwordChanged: false,
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
      validationRules: validationRules
    }
  },
  props: {
    value: Boolean
  },
  beforeCreate(){ validationRules = ValidationRules(this.$i18n) },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    onClose() {
      this.show = false
      this.$refs.passwordForm.reset()
      this.valid = false
      if (this.passwordChanged) {
        this.$store.dispatch("logout")
      }
    },
    async onChangePassword() {
      if (this.valid) {
        this.isChangingPassword = true
        const user = await Auth.currentAuthenticatedUser()
        try {
          const responseData = await Auth.changePassword(
            user,
            this.oldpassword,
            this.newpassword
          )
          if (responseData === "SUCCESS") {
            this.errorText = ""
            this.passwordChanged = true

            // TODO: send e-mail to user about the changed password?
          }
        } catch (error) {
          this.errorText = error
        }
        this.isChangingPassword = false
      }
    }
  }
}
</script>
