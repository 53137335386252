<template>
  <v-card flat outlined class="ma-4">
    <v-card-title>
      {{ $t("tenant.profile.title") }}
      <v-spacer></v-spacer>
      <v-tooltip top v-if="canEditItem">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon :disabled="editProfile" @click="editProfile=true"
              >mdi-pencil</v-icon
            >
          </span>
        </template>
        <span>{{ $t("tenant.customer.actions.editCustomer") }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-form ref="tenantForm" v-model="valid" :disabled="!editProfile">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="tenant.name"
              required
              :label="`${$t('tenant.dialogs.edit.name')}*`"
              :placeholder="$t('tenant.dialogs.edit.namePlaceholder')"
              :rules="[validationRules.name]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="tenant.description"
              :label="$t('tenant.dialogs.edit.description')"
              :placeholder="$t('tenant.dialogs.edit.descriptionPlaceholder')"
              :rules="[validationRules.maxLimit]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="pb-0">
            <v-autocomplete
              v-model="tenant.country"
              :label="$t('tenant.dialogs.edit.country')"
              :placeholder="$t('tenant.dialogs.edit.countryPlaceholder')"
              :rules="[validationRules.optional]"
              prepend-icon="mdi-map-marker"
              :items="countries"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" class="pb-0">
            <v-text-field
              v-model="tenant.city"
              :label="$t('tenant.dialogs.edit.city')"
              :placeholder="$t('tenant.dialogs.edit.cityPlaceholder')"
              :rules="[validationRules.optional]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-checkbox
              v-model="enableTenantNotification"
            >
              <template v-slot:label>
                <div>
                  {{ $t("tenant.dialogs.edit.sendMaintanenceNotifications") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="enableTenantNotification">
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="notificationEmail"
              :rules="[validationRules.email, emailCheck]"
              :label="$t('tenant.dialogs.edit.enterEmailAddress')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="enableTenantNotification">
          <v-col cols="12">
            <v-select
              :items="maintenanceThresholds"
              v-model="notificationThreshold"
              :label="$t('tenant.dialogs.edit.engineHoursBeforeNextService')"
              :rules="[thresholdCheck]"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-alert
              v-if="error"
              :type="error.type"
              class="ma-0"
              elevation="2"
              border="left"
              colored-border
              dense
              dismissible
            >
              <div>{{ error.message }}</div>
              <div v-if="error.causedBy" class="subtitle">
                {{ error.causedBy }}
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions v-if="editProfile" class="pr-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        :disabled="!valid || isUpdating"
        :loading="isUpdating"
        @click.stop="onSaveClick"
      >
        {{ $t("common.actions.save") }}
      </v-btn>
      <v-btn :disabled="isUpdating" @click.stop="onCancelClick"
        >{{ $t("common.actions.cancel") }}
      </v-btn>
    </v-card-actions>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import fetchClient from "@/utils/fetchClient"
import countryList from "country-list"

let validationRules = null

// Default threshold in hours
const DEFAULT_NOTIFICATION_THRESHOLD = 20

export default {
  data() {
    return {
      valid: false,
      validationRules: validationRules,
      editProfile: false,
      isUpdating: false,
      error: null,
      enableTenantNotification: false,
      notificationThreshold: DEFAULT_NOTIFICATION_THRESHOLD,
      notificationEmail: "",
      emailCheck: value => {
        return (
          !this.enableTenantNotification ||
          (value !== undefined && value !== null && value.length > 0)
        )
      },
      thresholdCheck: value => {
        return (
          !this.enableTenantNotification ||
          (value !== undefined && value !== null)
        )
      },
      snackbar: false,
      snackbarText: ""
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    canEditItem() {
      return this.$store.getters.canEditTenants
    },
    tenant() {
      return this.$store.getters.tenant
    },
    isEditing() {
      return this.tenant.uid !== undefined
    },
    title() {
      return this.$t("tenant.dialogs.edit.editTitle")
    },
    countries() {
      return countryList.getNames()
    },
    maintenanceThresholds() {
      return this.$store.getters.maintenanceThresholds
    }
  },
  watch: {
    tenant() {
      this.showHideNotification()
    }
  },
  mounted() {
    this.showHideNotification()
  },
  methods: {
    showHideNotification() {
      if (
        this.tenant?.notificationThreshold !== undefined &&
        this.tenant?.notificationThreshold !== null
      ) {
        this.notificationThreshold = this.tenant.notificationThreshold
        this.notificationEmail = this.tenant.notificationEmail
        this.enableTenantNotification = true
      } else {
        // Set a default threshold
        this.notificationThreshold = DEFAULT_NOTIFICATION_THRESHOLD
        this.notificationEmail = ""
        this.enableTenantNotification = false
      }
    },
    async fetchTenant() {
      await this.$store.dispatch("fetchTenant", {
        tenantUid: this.$route.params.tenantUid
      })
    },
    async onSaveClick() {
      if (!this.$refs.tenantForm.validate()) {
        return
      }

      const country = this.tenant.country || ""
      const countryCode = countryList.getCode(country) || ""

      const item = {
        name: this.tenant.name,
        description: this.tenant.description,
        country,
        countryCode,
        city: this.tenant.city
      }

      if (this.enableTenantNotification) {
        item.notificationThreshold = this.notificationThreshold
        item.notificationEmail = this.notificationEmail
      } else {
        // If notification is disabled we'll remove the threshold and email server side.
        item.notificationThreshold = -1
      }

      let url = ""

      if (this.isEditing) {
        url = `/api/v1/tenants/${this.tenant.uid}`
      } else {
        url = "/api/v1/tenants"

        const maxLevelNumberChild = this.parent.children?.reduce((prev, next) =>
          prev.level > next.level ? prev : next
        )

        const maxLevelNumber = maxLevelNumberChild
          ? Number(
              maxLevelNumberChild.level.replace(this.parent.level + ",", "")
            )
          : 0

        const childNo = String(Number(maxLevelNumber + 1))

        // Zero-pad the level, e.g. '012' instead of '12'
        item.level = this.parent.level + "," + childNo.padStart(3, "0")

        item.parentUid = this.parent.uid
        item.depth = this.parent.depth + 1
      }

      this.error = null

      this.isUpdating = true

      try {
        if (this.isEditing) {
          await fetchClient(this.$i18n).putOrThrow(url, item)
        } else {
          await fetchClient(this.$i18n).postOrThrow(url, item)
        }
        this.snackbarText = this.$t("tenant.dialogs.edit.messages.updated")
        this.snackbar = true
        this.editProfile = false
        await this.fetchTenant()
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    },
    async onCancelClick() {
      this.error = null
      this.editProfile = false
      await this.fetchTenant()
    }
  }
}
</script>
