<template>
  <v-card>
    <v-card-title>
      <div class="text-body-1 font-weight-bold black--text text-uppercase">
        {{ $t("mainMenu.webshop") }}
      </div>
    </v-card-title>
    <v-card-text>
      <v-row v-if="!canAccessWebshop">
        <v-col cols="12">
          <v-overlay absolute color="#fff" opacity=".9" class="text--primary">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="justify-self-center text-center text-subtitle-1 font-weight-medium"
                >
                  <v-icon color="primary">
                    mdi-information-outline
                  </v-icon>
                  {{ $t("home.webshop.noAccess.title") }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="justify-self-center text-center text-body-2"
                >
                  <span v-html="$t('home.webshop.noAccess.description')" />
                </v-col>
              </v-row>
            </v-container>
          </v-overlay>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="d-flex align-center secondary--text">
          <!-- mdi-cart -->
          <svg style="width:128px;height:128px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z"
            />
          </svg>
        </v-col>
        <v-col cols="8" class="d-flex align-center">
          <div v-if="canAccessWebshop">
            <a :href="webshopLink" target="_blank" class="homepage-link">
              {{ $t("home.webshop.description") }} →
            </a>
          </div>
          <div v-else>
            {{ $t("home.webshop.description") }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      webshopLink: `${this.$store.getters.webshopURL}`
    }
  },
  computed: {
    canAccessWebshop() {
      return this.$store.getters.canAccessWebShop
    }
  }
}
</script>

<style></style>
