<template>
  <v-card class="gauge-container" height="100%">
    <!-- Unprovisioned machine title -->
    <div v-if="this.disabled" class="text-center mb-0 disabled">
      <p class="pb-0 mb-0 text-uppercase text-h6 two-lines-text-truncate">
        {{ title }}
      </p>
    </div>

    <!-- Provisioned machine title -->
    <div v-else class="secondary text-center mb-0">
      <p class="pb-0 mb-0 text-uppercase text-h6 two-lines-text-truncate">
        {{ title }}
      </p>
      <!-- place the action icon in the top left corner -->
      <div class="ma-2" style="clear:both; float: right">
        <div>
          <v-btn
            v-if="actionPath"
            depressed
            text
            fab
            small
            @click="onActionLink"
            style="z-index:2"
          >
            <v-icon small>{{ actionIcon }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div>
      <div
        class="d-flex align-center justify-center ma-0 pa-0"
        style="position: absolute; width: 100%; top: 2em; left:0; bottom: 0;"
      >
        <!-- center the gauge in the background -->
        <div style="position: absolute; left: 50%; top: 2.5em">
          <div style="position: relative; left: -50%">
            <!-- Chart component -->
            <div style="min-height: 200px; padding: 2px;">
              <apexchart
                style="z-index:0; width:300px; margin-top: -40px;"
                type="radialBar"
                :options="chartOptions"
                :series="series"
              />
            </div>
          </div>
        </div>

        <!-- place the value just below the gauge and show tooltip with average and max -->
        <div style="z-index: 2; position: absolute; left: 50%; top: 55%;">
          <div
            class="black--text text-center text-uppercase text-h4"
            style="position: relative; left: -50%;"
          >
            <div v-if="valueAvg && valueMax">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ value }}{{ metricsLabel }}</span>
                </template>
                <span
                  >{{ $t("chart.legend.average") }}: {{ valueAvg }}
                  {{ metricsLabel }}</span
                >
                <br />
                <span
                  >{{ $t("chart.legend.max") }}: {{ valueMax }}
                  {{ metricsLabel }}</span
                >
              </v-tooltip>
            </div>
            <div v-else>
              <span>{{ value }}{{ metricsLabel }}</span>
            </div>
          </div>
        </div>

        <!-- place the text below the icon and value -->
        <div
          class="primary--text lighten-2 text-center text-caption"
          style="position:absolute; z-index: 2; bottom: 0.2em"
        >
          {{ text }}
        </div>
      </div>
    </div>
    <v-overlay
      v-if="this.disabled"
      absolute
      color="#fff"
      opacity=".9"
      class="text--primary"
    >
      <h1 class="title d-flex mb-5">
        <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
        {{ $t("machines.featureNotAvailable") }}
      </h1>
    </v-overlay>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import colors from "vuetify/lib/util/colors"

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: [
    "title",
    "text",
    "value",
    "valueMin",
    "valueAvg",
    "valueMax",
    "gaugeMin",
    "gaugeMax",
    "invertedColorLimits",
    "metrics",
    "metricsLabel",
    "actionIcon",
    "actionPath",
    "actionDetailsLink",
    "disabled"
  ],
  computed: {
    series() {
      var series = [(this.value / this.gaugeMax) * 100]
      return series
    },
    gaugeValueColor() {
      const normalColor = colors.blueGrey.darken2
      const warningColor = colors.orange.lighten1
      const alertColor = colors.red.darken2

      const normalMax = 0.7
      const warningMax = 0.85

      const normalMin = 1 - normalMax
      const warningMin = 1 - warningMax

      let valuePercent = this.value / this.gaugeMax

      let returnColor = normalColor

      if (this.invertedColorLimits) {
        if (valuePercent <= normalMin) {
          returnColor = warningColor
        }

        if (valuePercent <= warningMin) {
          returnColor = alertColor
        }
      } else {
        if (valuePercent >= normalMax) {
          returnColor = warningColor
        }

        if (valuePercent >= warningMax) {
          returnColor = alertColor
        }
      }
      return returnColor
    },
    chartOptions() {
      return {
        chart: {
          fontFamily: "Roboto, sans-serif"
        },
        colors: [this.gaugeValueColor],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            dataLabels: {
              show: false
            },
            hollow: {
              size: "40%"
            },
            track: {
              background: colors.grey.lighten2
            }
          }
        },
        fill: {
          type: "solid",
          stops: [0, 85, 100]
        },
        labels: [""]
      }
    }
  },
  methods: {
    onActionLink() {
      this.$router.push({
        path: this.actionPath,
        query: { show: this.actionDetailsLink }
      })
    }
  }
}
</script>

<style>
.gauge-container {
  min-height: 220px;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.widget-gauge {
  position: relative;
}
.widget-gauge--min-max {
  opacity: 0;
  transition: opacity 300ms;
  min-width: 220px;
  min-height: 32px;
}
.widget-gauge .icon-gauges {
  color: "#ddd";
}
.widget-gauge:hover .widget-gauge--min-max {
  transition: opacity 300ms;
  opacity: 1;
}

.two-lines-text-truncate {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: 4rem; /* Fallback for non-webkit, line-height * 2 */
  line-height: 2rem;
  -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled {
  background-color: #eceff1;
}
</style>
