<template>
  <div
    class="fluid d-flex ma-0 pa-0 fill-height justify-center align-center saira-medium-font"
  >
    <v-card
      elevation="24"
      outlined
      raised
      :style="minWidth"
      class="rounded-lg px-md-4"
    >
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <img style="max-height:32px;" :src="$store.getters.logoBlack" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-md-4 mb-md-2 pb-md-2">
            <p class="text-center mb-0 forgotText">{{ $t("login.forgotPassword")}}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center mb-md-1 px-4 pb-md-1">
            <p v-if="!checkInstructions">
              {{$t("login.passwordResetDescription")}}
            </p>
          </v-col>
        </v-row>

        <div v-if="checkInstructions">
          <v-row>
            <v-col cols="12" class="text-center">
              <p>
                {{$t("login.passwordResetCheckEmail")}}
              </p>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-form
            ref="forgotPasswordForm"
            autocomplete="off"
            lazy-validation
            v-model="valid"
          >
            <v-container>
              <v-row>
                <v-col cols="12" class="px-2 pb-2">
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    autofocus
                    v-model="email"
                    prepend-icon="mdi-account"
                    required
                    label="Email*"
                    :rules="[validationRules.required, validationRules.email]"
                    v-on:keyup.enter="onPasswordReset"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="error">
                <v-col cols="12">
                  <app-alert
                    @dismissed="dismissAlert"
                    :errItem="error"
                  ></app-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-center pl-10 pr-2 pt-2 mb-md-12 pb-md-12"
                >
                  <v-btn
                    :disabled="loading"
                    rounded-lg
                    class="forgotBtnText"
                    block
                    @click.stop="onPasswordReset"
                    >{{ $t("login.buttons.labels.sendPasswordResetEmail") }}
                    </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="loading">
                <v-col cols="12">
                  <v-progress-linear active indeterminate />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
let validationRules = null


export default {
  data() {
    return {
      valid: false,
      email: "",
            validationRules: validationRules,
      checkInstructions: false
    }
  },
  beforeCreate(){ validationRules = ValidationRules(this.$i18n) },
  computed: {
    loading() {
      return this.$store.getters.loading
    },
    error() {
      return this.$store.getters.error
    },
    minWidth() {
      var minPercentage = "100%"

      if (this.$vuetify.breakpoint.name === "sm") {
        minPercentage = "75%"
      } else if (this.$vuetify.breakpoint.name === "md") {
        minPercentage = "30%"
      } else if (this.$vuetify.breakpoint.name === "lg") {
        minPercentage = "25%"
      } else if (this.$vuetify.breakpoint.name === "xl") {
        minPercentage = "20%"
      }
      return `width:${minPercentage}`
    }
  },
  watch: {
    email() {
      this.dismissAlert()
    }
  },
  methods: {
    async onPasswordReset(e) {
      e.preventDefault()
      if (!this.$refs.forgotPasswordForm.validate()) {
        return
      }
      if (this.valid) {
        const repsonseData = await this.$store.dispatch(
          "forgotPassword",
          this.email
        )
        if (repsonseData) {
          this.checkInstructions = true
        }
      }
    },
    dismissAlert() {
      this.$store.dispatch("clearError")
    }
  }
}
</script>

<style scoped>
.forgotText {
  color: #303030;
  font-size: 2em;
  font-weight: 500;
  text-transform: uppercase;
}
.forgotBtnText {
  color: #303030;
  background-color: #ffcd00 !important;
  font-size: 1.1em;
  font-weight: 600;
  letter-spacing: 0.02em;
}
</style>
