<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-plus-circle</v-icon>
          <span class="title accent--text">{{
            $t("machines.dialogs.addServiceSchedule.title")
          }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="onCancel">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-1 pb-0">
        <v-form ref="registerServiceForm" autocomplete="off" v-model="valid">
          <!-- Select, service  -->
          <v-select
            item-text="label"
            :items="services"
            item-value="value"
            v-model="selectedService.value"
            :label="`${$t('machines.dialogs.addServiceSchedule.serviceTitle')}`"
          >
          </v-select>

          <!-- Input field, engine hours  -->
          <v-text-field
            ref="engineHoursTextField"
            class="mt-2"
            v-model="engineHours"
            :label="`${$t('machines.dialogs.addServiceSchedule.engineHours')}`"
            :placeholder="
              $t('machines.dialogs.addServiceSchedule.enterEngineHoursTitle')
            "
            :rules="[validationRules.engineHoursService]"
          ></v-text-field>

          <!-- Text area, comment -->
          <v-textarea
            ref="commentTextArea"
            class="mb-4"
            :label="$t('machines.dialogs.addServiceSchedule.comment')"
            :placeholder="
              $t('machines.dialogs.addServiceSchedule.commentPlaceholder')
            "
            :hint="$t('machines.dialogs.addServiceSchedule.commentHint')"
            v-model="comment"
            :rules="[
              v =>
                (v || '').length <= 2000 ||
                $t('machines.maintenance.service.commentMaxChars')
            ]"
            rows="4"
            counter="2000"
            auto-grow
            persistent-hint
          />

          <!-- date picker -->

          <v-menu
            ref="datePickerMenu"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="serviceDate"
                readonly
                prepend-icon="mdi-calendar"
                :placeholder="$t('machines.dataTable.headers.date')"
                v-bind="attrs"
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              v-model="serviceDate"
              @input="datePickerMenu = false"
            >
            </v-date-picker>
          </v-menu>

          <v-alert
            v-if="errorMsg.length > 0"
            class="ma-0 mt-4"
            type="error"
            elevation="2"
            border="left"
            colored-border
            dense
          >
            {{ errorMsg }}
          </v-alert>
        </v-form>
      </v-card-text>

      <v-card-actions class="pr-6 mr-3">
        <v-spacer></v-spacer>
        <v-btn class="mr-2 mb-2" @click="onSave">{{
          $t("common.actions.save")
        }}</v-btn>
        <v-btn class="mb-2 grab-cancel-edit-user" @click.stop="onCancel">{{
          $t("common.actions.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
let validationRules = null

export default {
  name: "GuidedServiceAdd",
  props: ["tenantUid", "show"],
  data() {
    return {
      stepNo: 1,
      valid: false,
      serialNo: "",
      engineHours: "",
      selectedProductId: "",

      validationRules: validationRules,
      errorMsg: "",

      addInfo: null,

      selectedService: {
        label: "Service 2",
        value: "2"
      },

      services: [
        {
          label: "Service 2",
          value: "2"
        },
        {
          label: "Service 3",
          value: "3"
        },
        {
          label: "Service 4",
          value: "4"
        }
      ],

      comment: "",
      datePickerMenu: false,
      serviceDate: new Date().toISOString().substr(0, 10)
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(value) {
        if (!value) {
          this.$emit("cancelAdd")
        }
      }
    }
  },
  watch: {
    show(value) {
      if (value && this.$refs.registerServiceForm) {
        // Reset input validation errors when the dialog is shown
        this.$refs.registerServiceForm.resetValidation()
      }
      this.initForm()
    }
  },
  methods: {
    async onSave() {
      if (!this.$refs.registerServiceForm.validate()) {
        return
      }

      this.errorMsg = ""

      this.$store
        .dispatch("registerService", {
          tenantUid: this.$route.params.tenantUid,
          serialNo: this.$route.params.serialNo,
          step: this.selectedService.value,
          engineHours: this.engineHours,
          comment: this.comment,
          serviceDate: this.serviceDate
        })
        .then(() => {})
        .catch(errMsg => {
          this.errorMsg = this.$t(
            "machines.dialogs.addMachine.errors.couldNotRegisterService",
            { msg: errMsg }
          )
        })
        .finally(() => {
          if (this.errorMsg.length === 0) {
            this.visible = false
            this.$emit("saveAdd")
          }
        })
    },
    onCancel() {
      this.visible = false
    },
    initForm() {
      this.selectedService = {
        label: "Service 2",
        value: "2"
      }
      this.engineHours = ""
      this.comment = ""
      this.serviceDate = new Date().toISOString().substr(0, 10)
      this.errorMsg = ""
    }
  }
}
</script>

<style>
.gm-style img.machine-avatar {
  max-width: 150px;
}
</style>
