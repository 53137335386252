export default {
  reports: {
    actions: {},
    dataTable: {
      headers: {
        modified: "Modifié",
        name: "Nom",
        tenant: "Locataire"
      },
      noResultsText: "Aucun rapport correspondant"
    },
    itemName: "rapport"
  }
}
