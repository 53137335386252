<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-progress-wrench</v-icon>
          <span class="title accent--text">{{
            $t("machines.dialogs.editMachine.title")
          }}</span></v-toolbar-title
        >
        <v-spacer />
        <v-btn icon @click="onCancel">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-1 pb-0">
        <v-form
          v-if="machine !== undefined"
          ref="editMachineForm"
          autocomplete="off"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col cols="2" class="pl-1 ml-1 mb-2">
                <label
                  for="serialText"
                  class="caption v-input v-input--is-label-active"
                >
                  {{ $t("machines.dialogs.editMachine.serialNo") }}
                </label>
                <span id="serialText" class="accent--text body-1 py-2 my-4">{{
                  machine.serialNo
                }}</span>
              </v-col>
              
              <v-col cols="2" class="pl-1 ml-1 mb-2">
                <label
                  for="revisionText"
                  class="caption v-input v-input--is-label-active"
                >
                  {{ $t("machines.dialogs.editMachine.revision") }}
                </label>
                <span id="revisionText" class="accent--text body-1 py-2 my-4">{{
                  machine.revision
                }}</span>
              </v-col>

              <v-col cols="auto" class="pl-1 ml-1 mb-2">
                <label
                  for="createdText"
                  class="caption v-input v-input--is-label-active"
                >
                  {{ $t("machines.dialogs.editMachine.createdTimestamp") }}
                </label>
                <span id="createdText" class="accent--text body-1 py-2 my-4">{{
                  formatDate(machine.created)
                }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="machine.tenantUid"
                  required
                  :label="`${$t('machines.dialogs.editMachine.tenant')}*`"
                  :placeholder="
                    $t('machines.dialogs.editMachine.tenantPlaceholder')
                  "
                  :rules="[validationRules.required]"
                  :items="tenants"
                  item-text="label"
                  item-value="uid"
                  :readonly="hasPendingTransfer"
                  :disabled="isUpdating || hasPendingTransfer"
                  persistent-hint
                  :hint="tenantHint"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pl-1 ml-1 my-0">
                <v-text-field
                  v-model="machine.name"
                  :label="`${$t('machines.dialogs.editMachine.name')}*`"
                  :disabled="isUpdating"
                  :placeholder="
                    $t('machines.dialogs.editMachine.namePlaceholder')
                  "
                  :rules="[validationRules.name]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pl-1 ml-1 my-0">
                <v-text-field
                  v-model="machine.description"
                  :label="$t('machines.dialogs.editMachine.description')"
                  :disabled="isUpdating"
                  :placeholder="
                    $t('machines.dialogs.editMachine.descriptionPlaceholder')
                  "
                  :rules="[validationRules.maxLimit]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="py-0 px-2">
                <v-alert
                  v-if="error"
                  :type="error.type"
                  class="ma-0"
                  elevation="2"
                  border="left"
                  colored-border
                  dense
                  dismissible
                >
                  <div>{{ error.message }}</div>
                  <div v-if="error.causedBy" class="subtitle">
                    {{ error.causedBy }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pr-6 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2 mb-2"
          :disabled="!valid || isUpdating"
          :loading="isUpdating"
          @click.stop="onSave"
          >{{ $t("common.actions.save") }}</v-btn
        >
        <v-btn class="mb-2" :disabled="isUpdating" @click.stop="onCancel">{{
          $t("common.actions.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import fetchClient from "@/utils/fetchClient"
import formatter from "@/utils/formatter"

let validationRules = null

export default {
  name: "EditMachineDialog",
  props: ["machine", "show"],

  data() {
    return {
      valid: false,
      orgTenantUid: null,
      validationRules: validationRules,
      isUpdating: false,
      error: null,
      hasPendingTransfer: false
    }
  },
  watch: {
    // When the dialog becomes visible we want to save the original tenant UID.
    show(value) {
      if (value) {
        this.setOrgTenant()
        this.fetchTransfer()
      }
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    tenantHint() {
      return this.hasPendingTransfer
        ? this.$t("machines.dialogs.editMachine.tenantHint")
        : ""
    },
    tenants() {
      return this.$store.getters.tenantArray
    }
  },
  methods: {
    setOrgTenant() {
      // Save the original value so that we can use that to update the machine
      // with a new tenant.
      this.orgTenantUid = this.machine.tenantUid
      this.disableTenantSelect = true
    },
    async fetchTransfer() {
      this.hasPendingTransfer = false
      const url = `/api/v1/machines/${this.machine.serialNo}/transfers?q=pending`

      try {
        const data = await fetchClient(this.$i18n).getDataOrThrow(url)
        // Returned data is an array
        if (data !== null && data.length > 0) {
          this.hasPendingTransfer = true
        }
      } catch (error) {
        this.error = { type: "error", ...error }
      }
    },
    async onSave() {
      if (!this.$refs.editMachineForm.validate()) {
        return
      }

      this.error = null

      const updatedItem = {
        tenantUid: this.machine.tenantUid,
        name: this.machine.name,
        description: this.machine.description
      }

      this.isUpdating = true

      // Using org tenantUid since it might have changed.
      const url = `/api/v1/tenants/${this.orgTenantUid}/machines/${this.machine.serialNo}`

      try {
        await fetchClient(this.$i18n).putOrThrow(url, updatedItem)

        // So far machines cannot be added in the FE, but future feature
        // may allow to add machines which do not have connection to the cloud.
        this.$emit("save", { wasAdded: false })
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    },
    onCancel() {
      this.error = null
      this.$emit("cancel", {})
    },
    formatDate(dateStr) {
      if (dateStr) {
        return formatter(this.$i18n).formatDate(new Date(dateStr))
      }
      return ""
    }
  }
}
</script>
