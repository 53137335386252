<template>
  <v-card tile color="details_background">
    <v-card-title v-if="detailsTitle !== null && detailsTitle !== undefined">
      <div class="title accent--text mb-4">{{ detailsTitle }}</div>
    </v-card-title>
    <v-card-text class="accent--text">
      <v-row class="ml-0">
        <v-col cols="12" sm="4">
          <div class="overline">{{ $t("users.itemAttributes.email") }}</div>
          <div class="font-weight-medium pb-0 mb-0">{{ user.email }}</div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="overline">{{ $t("users.details.name") }}</div>
          <div class="font-weight-medium pb-0 mb-0">{{ userName }}</div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="overline">
            {{ $t("users.itemAttributes.description") }}
          </div>
          <div class="font-weight-medium">
            {{ user.description | optionalTextFilter }}
          </div>
        </v-col>
      </v-row>
      <v-row class="ml-0">
        <v-col cols="12" sm="4">
          <div class="overline">{{ $t("users.itemAttributes.role") }}</div>
          <div class="font-weight-medium pb-0 mb-0">{{ user.roleName }}</div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="overline">
            {{ $t("users.itemAttributes.webshopRole") }}
          </div>
          <div class="font-weight-medium pb-0 mb-0">{{ webshopRole }}</div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="overline">{{ $t("users.itemAttributes.tenant") }}</div>
          <div class="font-weight-medium pb-0 mb-0">{{ user.tenantName }}</div>
        </v-col>
      </v-row>
      <v-row class="ml-0">
        <v-col cols="12" sm="4">
          <div class="overline">{{ $t("users.details.accountVerified") }}</div>
          <v-icon color="success" v-if="user.accepted">mdi-check-circle</v-icon>
          <div v-else>-</div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="overline">{{ $t("users.itemAttributes.lastLogin") }}</div>
          <div class="font-weight-medium">
            {{ user.lastLogin | localeDateFilter }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="overline">
            {{ $t("users.itemAttributes.fleetAnalyticsAccess") }}
          </div>
          <v-icon v-if="fleetAnalyticsAccess">mdi-check-circle</v-icon>
          <div v-else>-</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["user", "title"],
  components: {},
  data() {
    return {}
  },
  computed: {
    detailsTitle() {
      if (this.title !== undefined) {
        return this.title
      } else {
        return this.$t("users.details.title")
      }
    },
    userName() {
      return `${this.user.firstName} ${this.user.lastName}`
    },
    webshopRole() {
      if (this.user.webshopRole !== undefined) {
        return this.user.webshopRole
      }
      const access = this.$store.getters.getWebshopAccess(this.user)
      if (access.localeCompare("none") == 0) {
        return "-"
      }
      return access
    },
    fleetAnalyticsAccess() {
      return this.$store.getters.getReadFleetAnalytics(this.user)
    }
  }
}
</script>
