import fetchClient from "@/utils/fetchClient"

/* eslint-disable */

export default {
  state: {
    activeAlarms: [],
    eventHistory: [],

    loadingAlarms: false,
    loadingEventHistory: false
  },
  mutations: {
    setActiveAlarms(state, payload) {
      state.activeAlarms = payload
    },
    setEventHistory(state, payload) {
      state.eventHistory = payload
    },
    setLoadingAlarms(state, payload) {
      state.loadingAlarms = payload
    },
    setLoadingEventHistory(state, payload) {
      state.loadingEventHistory = payload
    }
  },
  actions: {
    async fetchActiveAlarms(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingAlarms", true)

        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${params.tenantUid}/machines/${params.serialNo}/events?q=active`
          )
          .then(data => {
            context.commit("setActiveAlarms", data)
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingAlarms", false)
          })
      })
    },
    async fetchEventHistory(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingEventHistory", true)

        const { tenantUid, serialNo, start, end } = params

        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${tenantUid}/machines/${serialNo}/events?q=range&start=${start}&end=${end}`
          )
          .then(data => {
            context.commit("setEventHistory", data)
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingEventHistory", false)
          })
      })
    }
  },
  getters: {
    activeAlarms(state) {
      return state.activeAlarms
    },
    eventHistory(state) {
      return state.eventHistory
    },
    loadingAlarms(state) {
      return state.loadingAlarms
    },
    loadingEventHistory(state) {
      return state.loadingEventHistory
    }
  }
}
