<template>
  <v-dialog
    @input="onCancel"
    :value="visible"
    max-width="400"
    :persistent="loading"
  >
    <v-date-picker v-model="date" scrollable :disabled="loading">
      <v-spacer></v-spacer>

      <v-btn text color="primary" @click="onCancel" :disabled="loading">
        {{ $t("common.actions.cancel") }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="onSave"
        :disabled="loading"
        :loading="loading"
      >
        {{ $t("common.actions.save") }}
      </v-btn>
    </v-date-picker>

    <v-alert
      v-if="errorMessage"
      class="ma-0 mt-4"
      type="error"
      elevation="2"
      border="left"
      colored-border
      dense
    >
      {{ errorMessage }}
    </v-alert>
  </v-dialog>
</template>

<script>
import fetchClient from "@/utils/fetchClient"

export default {
  data: () => ({
    visible: false,
    loading: false,
    resolve: null,
    machine: null,
    engineSeconds: null,
    date: null,
    errorMessage: null,
  }),

  methods: {
    open(machine, engineSeconds, date) {
      this.machine = machine
      this.engineSeconds = engineSeconds
      this.date = new Date(date).toISOString().substr(0, 10)
      this.visible = true

      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    onCancel() {
      this.visible = false
      this.resolve(false)
    },
    async onSave() {
      this.loading = true

      const url = `/api/v1/tenants/${this.machine.tenantUid}/machines/${this.machine.serialNo}/maintenance/service/${this.engineSeconds}`
      const data = { serviceDate: this.date }

      let errorMessage = null

      await fetchClient(this.$i18n)
        .post(url, data)
        .then(() => {})
        .catch((error) => {
          errorMessage = error.toString()
        })

      this.loading = false

      if (errorMessage === null) {
        this.visible = false
        this.resolve(true)
      } else {
        this.errorMessage = errorMessage
      }
    },
  },
}
</script>
