export default {
  machines: {
    actions: {
      addMachine: "Registra una macchina",
      importMachine: "Importa macchina",
      provisioned: "Fornita",
      transfer: "Trasferimento",
      unprovisioned: "Non fornita"
    },
    activities: {
      beltActiveTime: "Tempo di attività della cinghia",
      otherTime: "Altro tempo",
      toolActiveTime: "Tempo di attività dell'attrezzo"
    },
    alarms: {
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              category: "Categoria",
              description: "Descrizione",
              value: "Valore"
            }
          }
        }
      },
      moreExists: "altro"
    },
    dataTable: {
      headers: {
        active: "Attivo",
        brokkConnect: "Brokk Connect",
        category: "Categoria",
        code: "Codice",
        date: "Data",
        description: "Descrizione",
        device: "Dispositivo",
        engineHours: "Ore motore",
        extraInfo: "Informazioni aggiuntive",
        id: "Numero",
        lastSeen: "Ultima visualizzazione",
        name: "Nome",
        number: "Id",
        product: "Prodotto",
        serviceAgreement: null,
        serviceStatus: "Stato della manutenzione",
        severity: "Gravità",
        status: "Stato",
        tenantName: "Locatario",
        updateInterval: "Intervallo di aggiornamento"
      },
      tooltips: {
        cellular: "Brokk Connect utilizza la rete cellulare",
        overdue: "Scaduta",
        pending: "In sospeso",
        service: "Manutenzione",
        serviceAgreementMissing: null,
        serviceAgreementPresent: null,
        unknown: "Sconosciuto",
        unprovisioned: "Brokk Connect non montato",
        upcoming: "OK",
        wifi: "Brokk Connect utilizza la rete Wi-Fi"
      }
    },
    dialogs: {
      addMachine: {
        addMachineSucceededText:
          "La macchina Brokk {machine} è stata aggiunta.",
        confirmAddMachine: "Vuoi aggiungere la macchina?",
        confirmAddMachineProceedInfo:
          "Per aggiungere la macchina seleziona un modello e premere Avanti.",
        confirmAddMachineProceedInfoNext:
          "Per aggiungere la macchina premi Avanti.",
        confirmAddMachineTitle: "Aggiungere una macchina?",
        engineHours: "Ore motore",
        enterEngineHoursTitle: "Inserisci le ore motore",
        enterMachineInformationTitle:
          "Inserisci le informazioni della macchina",
        enterserialNoDescription: "Inserisci un numero di serie valido",
        enterserialNoTitle: "Inserisci il numero di serie della macchina",
        errors: {
          couldNotAddMachine: "{msg}",
          couldNotRegisterService: "{msg}",
          couldNotUpdateService: "{msg}",
          noSuchserialNo: "Errore: Numero di serie non valido"
        },
        serialNo: "Numero di serie",
        steps: {
          confirm: "Conferma",
          overview: "Panoramica",
          summary: "Riepilogo"
        },
        title: "Aggiungi una macchina non fornita"
      },
      addServiceSchedule: {
        comment: "Commento sulla manutenzione",
        commentHint:
          "Il commento sulla manutenzione sarà visibile nella cronologia della manutenzione",
        commentPlaceholder: "Inserisci un commento sulla manutenzione",
        engineHours: "Ore motore",
        enterEngineHoursTitle: "Inserisci le ore motore",
        service2: "Manutenzione 2",
        service3: "Manutenzione 3",
        service4: "Manutenzione 4",
        serviceInformationTitle: "Inserisci le informazioni di manutenzione",
        serviceTitle: "Manutenzione",
        steps: {
          confirm: "Conferma",
          overview: "Panoramica",
          summary: "Riepilogo"
        },
        title: "Aggiungi schema di manutenzione"
      },
      commissioning: {
        saved: "Messa in servizio salvata correttamente.",
        steps: {
          applications: {
            hint: "(Facoltativo) Seleziona una o più applicazioni",
            name: "Applicazioni"
          },
          category: {
            name: "Categoria",
            otherCategoryHint: "Specifica un'altra categoria",
            otherCategoryPlaceholder: "Altra categoria"
          },
          commissioning: {
            description:
              "Scegli la data in cui la macchina è stata messa in servizio. Questo influirà sulle date di inizio e fine della garanzia.",
            name: "Messa in servizio"
          },
          confirm: {
            description: "Conferma che i seguenti dettagli sono corretti:",
            name: "Conferma",
            proceedInfo: "Per mettere in servizio la macchina, premi Salva."
          },
          segment: {
            description:
              "Scegli un segmento e una categoria che descrive come verrà utilizzata la macchina.",
            name: "Segmento"
          }
        },
        title: "Messa in servizio della macchina"
      },
      editMachine: {
        createdTimestamp: "Creata",
        description: "Descrizione",
        descriptionPlaceholder: "Inserisci la descrizione",
        name: "Nome",
        namePlaceholder: "Inserisci il nome",
        revision: null,
        serialNo: "Numero di serie",
        tenant: "Locatario",
        tenantHint:
          "Il locatario non può essere modificato mentre è in corso un trasferimento",
        tenantPlaceholder: "Seleziona locatario",
        title: "Modifica macchina"
      },
      editServiceSchedule: {
        engineHours: "Ore motore",
        enterEngineHoursTitle: "Inserisci le ore motore",
        service2: "Manutenzione 2",
        service3: "Manutenzione 3",
        service4: "Manutenzione 4",
        serviceInformationTitle: "Inserisci le informazioni di manutenzione",
        serviceTitle: "Manutenzione",
        steps: {
          confirm: "Conferma",
          overview: "Panoramica",
          summary: "Riepilogo"
        },
        title: "Modifica schema di manutenzione"
      },
      editSettings: {
        cantEdit: "Questo parametro non può essere modificato",
        category: "Categoria",
        description: "Descrizione",
        descriptionPlaceholder: "Inserisci la descrizione",
        device: "Dispositivo",
        id: "Numero",
        number: "Id",
        operatorMustConfirm:
          "Le tue modifiche dovranno essere approvate da un operatore prima di essere effettive",
        title: "Modifica intervallo di aggiornamento",
        updateInterval: "Intervallo di aggiornamento"
      },
      importMachine: {
        confirmTransferMachine:
          "Effettuare il trasferimento {machine} con il numero di serie {sn} all’attuale locatario {tenant}?",
        confirmTransferProceedInfo:
          "Per trasferire la macchina a questo locatario, premi Avanti.",
        confirmTransferTitle: "Trasferire la macchina?",
        enterTransferCodeDescription:
          "Inserisci un codice di trasferimento per spostare una macchina da un altro locatario al locatario attualmente selezionato",
        enterTransferCodeTitle: "Inserisci il codice di trasferimento",
        errors: {
          couldNotCompleteTransfer:
            "Errore: Impossibile completare il trasferimento. {msg}",
          noSuchTransferCode: "Errore: Nessun codice di trasferimento",
          transferCodeAlreadyUsed:
            "Errore: Il codice di trasferimento è già stato utilizzato",
          transferCodeExpired: "Errore: Il codice di trasferimento è scaduto",
          transferReverted: "Errore: Il trasferimento è stato ritornato"
        },
        steps: {
          confirm: "Conferma",
          overview: "Panoramica",
          summary: "Riepilogo"
        },
        title: "Trasferisci la macchina a {tenant}",
        transferCode: "Codice di trasferimento",
        transferCodePlaceholder: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
        transferSucceededText:
          "La {machine} con il numero di serie {sn} è stata trasferita a {tenant}."
      },
      transferMachine: {
        errors: {
          internalError:
            "Non è stato possibile generare un codice di trasferimento in questo momento a causa di un errore interno.",
          machineAlreadyTransferred:
            "Questa macchina è stata trasferita a un altro locatario",
          pendingTransfer:
            "Questa macchina ha un trasferimento in sospeso che è stato creato",
          transferCodeExpires: "Il codice di trasferimento scade"
        },
        refreshTheList: "Aggiorna l'elenco delle macchine.",
        steps: {
          confirm: {
            description:
              "Trasferimento della proprietà della macchina {sn} ({productName}) attualmente di proprietà di {tenant}?",
            includeHistoricalDataInfo:
              "Il trasferimento includerà i dati storici.",
            name: "Conferma",
            notIncludeHistoricalDataInfo:
              "Il trasferimento non includerà i dati storici.",
            proceedInfo:
              "Per generare un codice di trasferimento e avviare il trasferimento premi Avanti.",
            title: "Conferma"
          },
          dataRetention: {
            description:
              "Includendo i dati storici della macchina nel trasferimento consenti al destinatario di visualizzare tutti gli allarmi storici, gli eventi e le misurazioni inclusa la geolocalizzazione.",
            includeHistoricalData: "Includi i dati storici nel trasferimento.",
            name: "Conservazione dei dati",
            title: "Includi cronologia"
          },
          summary: {
            description:
              "Copia il codice di trasferimento indicato sotto e invialo per e-mail al destinatario, che lo utilizzerà per completare il trasferimento.",
            name: "Riepilogo",
            title: "Codice di trasferimento"
          },
          transferOwnership: {
            description:
              "Trasferimento della proprietà di questa macchina a un altro locatario utilizzando un codice di trasferimento. La macchina rimarrà accessibile finché il trasferimento è in sospeso.",
            name: "Panoramica",
            title: "Trasferimento di proprietà"
          }
        },
        title: "Trasferisci macchina",
        transferAutomaticRevertInfo:
          "Il trasferimento verrà automaticamente annullato se il destinatario non completa il trasferimento entro 3 giorni.",
        transferCode: "Codice di trasferimento"
      }
    },
    dieselParticulateFilter: {
      info: {
        error: "Contatta la manutenzione Brokk.",
        ok: "Stato OK.",
        title: "Filtro antiparticolato diesel",
        warning:
          "Rigenerazione manuale necessaria. Guarda il display sulla macchina."
      },
      status: {
        ashLevel: "Livello di cenere",
        sootLevel: "Livello di fuliggine",
        title: "Stato DPF"
      }
    },
    engineHoursSelectionList: {
      lastDay: null,
      lastMonth: null,
      lastWeek: null,
      total: null
    },
    featureNotAvailable:
      "Questa funzione non è attualmente abilitata su questa macchina",
    filters: {
      dataSource: "Fonte di dati"
    },
    info: {
      order: {
        commissioningDate: "Data di messa in servizio",
        commissioningHint: "Max 6 mesi dalla data di consegna",
        deliveryDate: "Data di consegna",
        label: "Ordine macchina",
        orderNumber: "Numero di ordine",
        orderType: "Tipo di ordine"
      },
      segment: {
        actions: {
          commission: "Messa in servizio di questa macchina"
        },
        applications: "Applicazioni",
        category: "Categoria",
        label: "Segmento",
        overlay: {
          description:
            "Mettere in servizio la macchina per attivare la garanzia e definire in quale tipo di applicazioni verrà utilizzata.",
          disabledDescription:
            "La messa in servizio è possibile quando la macchina ha una data di consegna e una durata della garanzia.",
          heading: "Questa è una macchina di riserva"
        }
      },
      warranty: {
        endDate: "Data di fine",
        label: "Garanzia",
        length: "Lunghezza",
        startDate: "Data di inizio"
      }
    },
    labels: {
      activeAlarms: "Allarmi attivi",
      elMotorTemp: "Temperatura el. del motore",
      engineHours: "Ore motore",
      engineHoursDays: "Ore motore/giorni",
      fuelLevel: "Livello del carburante",
      highPressureTime: "Tempo di alta pressione",
      highTempTime: "Tempo ad alta temperatura",
      hydraulicFluidTemp: "Temp. fluido idraulico",
      machineActivity: "Attività della macchina",
      nextServiceIn: "Prossima manutenzione entro",
      noMachines: {
        descriptionHasAccess:
          "Premi il pulsante in basso per registrare una macchina non fornita. In questo modo puoi accedere subito alla documentazione corretta e alle parti di ricambio.",
        descriptionNoAccess:
          "Registrando la tua macchina puoi accedere subito alla documentazione corretta e ai pezzi di ricambio. Contatta il rivenditore più vicino per registrare la macchina.",
        title: "Registra una macchina"
      },
      notification: "Notifica"
    },
    location: {
      currentMachineSettings: "Impostazioni attuali della macchina",
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              propertyCategory: "Categoria",
              propertyName: "Nome",
              propertyValue: "Valore"
            }
          }
        }
      },
      events: {
        dataTable: {
          headers: {
            date: "Data",
            description: "Descrizione",
            extraData: "Informazioni supplementari",
            source: "Fonte"
          }
        }
      },
      geofencingSettings: {
        actions: {
          add: "Aggiungi geofence",
          edit: "Modifica le impostazioni di geofence",
          remove: "Rimuovi le impostazioni di geofence"
        },
        confirm: {
          description:
            "Sei sicuro di voler applicare le modifiche a geofence? Questo potrebbe influire sulla capacità di funzionamento della macchina. Accertati che la macchina si trovi in una posizione che non provochi danni alle persone o alle apparecchiature.",
          title: "Applica Geofence"
        },
        coordinates: "Coordinate",
        description: " ",
        latitude: "Latitudine",
        latitudePlaceholder: "Inserisci latitudine",
        limit: {
          description: "",
          limitation: "Limitazione",
          limitationPlaceholder: "Seleziona la limitazione da aggiungere",
          title: "Limitazioni"
        },
        longitude: "Longitudine",
        longitudePlaceholder: "Inserisci longitudine",
        messages: {
          anyLimitationOrNotificationRequired:
            "Specificare una o più notifiche",
          disabled:
            "La funzione di geofence non è abilitata su questa macchina.",
          noLimitationsAdded:
            "Non è stata aggiunta ancora nessuna limitazione.",
          settingsNotSynched:
            "Le impostazioni di geofence non sono state sincronizzate dalla macchina.",
          settingsRemoved: "Il geofence è stato rimosso",
          settingsSaved: "Il geofence è stato salvato"
        },
        notifications: {
          description:
            "Immettere gli indirizzi e-mail delle persone che dovrebbero ricevere notifiche e-mail quando la macchina entra o esce dall'area di geofence.",
          title: "Notifiche"
        },
        radius: "Raggio",
        radiusPlaceholder: "Inserisci raggio",
        title: "Geofence",
        triggers: {
          enter: "Entra",
          exit: "Esci"
        },
        unit: "Unità",
        unitPlaceholder: "Inserisci unità"
      },
      geolocationSettings: {
        actions: {
          edit: "Modifica le impostazioni di geolocalizzazione",
          remove: "Rimuovi le impostazioni di geolocalizzazione"
        },
        description:
          "Impostazioni di monitoraggio della geolocalizzazione offline",
        distance: "Distanza",
        distanceHint:
          "Distanza minima che la macchina deve percorrere prima di inviare un aggiornamento",
        distancePlaceholder: "Inserisci distanza",
        interval: "Intervallo",
        intervalHint:
          "Intervallo di tempo minimo che deve trascorrere tra gli aggiornamenti",
        intervalPlaceholder: "Seleziona intervallo",
        messages: {
          disabled:
            "La funzione di geolocalizzazione non è attualmente abilitata su questa macchina.",
          settingsNotSynched:
            "Le impostazioni di geolocalizzazione non sono state sincronizzate dalla macchina.",
          settingsRemoved:
            "Le impostazioni di geolocalizzazione sono state rimosse",
          settingsSaved:
            "Le impostazioni di geolocalizzazione sono state salvate"
        },
        movement: "Movimento",
        timer: "Timer",
        title: "Impostazioni di geolocalizzazione",
        unit: "Unità",
        unitPlaceholder: "Seleziona unità"
      },
      location: {
        actions: {
          setGeofencingLocation: "Imposta la posizione geofence qui"
        },
        description:
          "Definire un'area in cui la macchina deve operare e ricevere le notifiche quando entra o esce dall'area.",
        messages: {
          changeGeofencingCoordinatesTip:
            "Utilizza il pulsante 'Disegna un cerchio' sulla mappa per definire o modificare il geofence della macchina."
        },
        showHistory: "Mostra cronologia delle posizioni",
        title: "Impostazioni della posizione"
      },
      map: {
        geofenceCenter: "Centro Geofence",
        machineLocation: "Posizione della macchina"
      }
    },
    maintenance: {
      service: {
        add: "La manutenzione è stata aggiunta correttamente",
        beforeNextServiceYearlyService:
          "Prima della prossima manutenzione/manutenzione annuale",
        commentMaxChars: "Il commento deve contenere 2000 caratteri al massimo",
        overdue: "Servizi scaduti",
        reset: {
          actions: {
            send: "Invia il comando di ripristino della manutenzione"
          },
          availableForServiceUsersOnly:
            "La funzione di ripristino della manutenzione è disponibile solo per gli utenti della manutenzione.",
          comment: "Commento sulla manutenzione",
          commentHint:
            "Il commento sulla manutenzione sarà visibile nella cronologia della manutenzione",
          commentPlaceholder: "Inserisci un commento sulla manutenzione",
          confirm: {
            description:
              "Eseguire un ripristino della manutenzione sulla macchina <strong>{0}</strong>?",
            note: "Nota: Questa azione non può essere annullata.",
            title: "Conferma il ripristino della manutenzione"
          },
          description:
            "Verrà inviato un comando di ripristino della manutenzione alla macchina che è identico a un ripristino della manutenzione utilizzando lo strumento di configurazione Brokk.",
          disabled:
            "La funzione di ripristino della manutenzione non è attualmente abilitata su questa macchina.",
          errors: {
            notAvailable:
              "È possibile eseguire un ripristino della manutenzione 100 ore prima della manutenzione programmata o quando una manutenzione annuale è in sospeso.",
            offline:
              "La macchina deve essere online per ripristinare una manutenzione.",
            waitingForYearly:
              "Sulla macchina non è stata ancora attivata la manutenzione annuale. La sua attivazione potrebbe impiegarci un’ora. Attendi e riprova più tardi."
          },
          success: {
            description:
              "La manutenzione sulla macchina <strong>{0}</strong> è stata ripristinata con successo",
            title: "Ripristino della manutenzione riuscito"
          },
          title: "Ripristino della manutenzione"
        },
        schedule: {
          dataTable: {
            headers: {
              comment: "Commento",
              loggedHours: "Ore registrate",
              scheduled: "programmata",
              service: "Manutenzione",
              serviceDate: "Data della manutenzione",
              status: "Stato"
            }
          },
          description:
            "Lo schema di manutenzione si basa sul tempo di funzionamento e sulla data dell’ultima manutenzione della macchina. Poiché le condizioni di lavoro possono variare, potrebbe esserenecessario adattare l'intervallo di manutenzione alle relative condizioni di lavoro e ambientali.",
          received: "Ricevuto",
          title: "Schema di manutenzione"
        },
        update: "La manutenzione è stata aggiornata correttamente"
      },
      serviceAgreement: {
        actions: {
          add: null,
          edit: null
        },
        labels: {
          description: null,
          endDate: null,
          filename: null,
          fileuploadLabel: null,
          startDate: null,
          uploadFilePlaceholder: null
        },
        messages: {
          added: null,
          deleted: null,
          failedToDownload: null,
          updated: null
        },
        title: null,
        warnings: {
          isExpired: null
        }
      }
    },
    manuals: {
      actions: {
        downloadFile: "Scarica file"
      },
      dataTable: {
        headers: {
          fileName: "Nome file"
        }
      },
      messages: {
        failedToDownload: "Download non riuscito {file}"
      }
    },
    measurements: {
      electricMotor: "Motore elettrico",
      electronicUnit: "Unità elettronica",
      engineHours: "Ore motore",
      export: {
        csv: {
          columns: {
            category: "Categoria",
            description: "Descrizione",
            parameterId: "ID parametro",
            timestamp: "Timestamp",
            unit: "Unità",
            value: "Valore"
          }
        }
      },
      gateway: "Gateway",
      highPressureTime: "Tempo di alta pressione",
      highTempTime: "Tempo ad alta temperatura:",
      hydraulicFluid: "Fluido idraulico",
      hydraulicFluidPressure: "Pressione del fluido idraulico",
      temperatures: "Temperature"
    },
    menuItems: {
      alarms: "Allarmi",
      dashboard: "Pannello di controllo",
      info: "Info",
      location: "Posizione",
      maintenance: "Manutenzione",
      manuals: "Manuali",
      measurements: "Misure",
      parameters: "Parametri",
      rental: "Noleggio – Controllo",
      system: "Sistema"
    },
    messages: {
      applyChangesPending: "In attesa che il dispositivo applichi le modifiche",
      changesApplied: "Le modifiche sono state applicate",
      contactToLearnMore: "Per saperne di più, contatta",
      errorApplyingChanges: "Errore durante l'applicazione delle modifiche",
      fetchingMachineDetails:
        "Recupero dei dettagli della macchina in corso...",
      machineAdded: "La macchina è stata aggiunta",
      machineDetailsAreBeeingFetched:
        "È in corso il recupero dei dettagli della macchina. Attendere",
      machineDoesNotLoadInfo:
        " Se la macchina non si carica, torna indietro e aggiorna manualmente la visualizzazione delle macchine.",
      machineOffline: "La macchina è offline.",
      machineUpdated: "La macchina è stata aggiornata",
      unsavedChanges: "Modifiche non salvate"
    },
    missingMachineImage: "Immagine del prodotto mancante",
    notFound: "Macchina non trovata",
    notifications: {
      settings: {
        actions: {
          edit: "Modifica impostazioni di notifica",
          remove: "Rimuovi le impostazioni di notifica",
          send: "Invia notifica"
        },
        daysBeforeLeaseExpiration:
          "Giorni prima della scadenza della locazione",
        daysBeforeNextYearlyService:
          "Giorni prima della prossima manutenzione annuale",
        description:
          "Inserisci gli indirizzi e-mail delle persone che dovrebbero ricevere le notifiche e-mail.",
        dialogs: {
          sendNotification: {
            confirm:
              "Inviare una notifica via e-mail al {count} destinatario? | Inviare una notifica via e-mail ai {count} destinatari?",
            successMessage:
              "La notifica e-mail è stata inviata | Le notifiche e-mail sono state inviate"
          }
        },
        engineHoursBeforeLeaseExpires:
          "Ore motore prima della scadenza del contratto di locazione",
        engineHoursBeforeNextService:
          "Ore motore prima della prossima manutenzione",
        maxRecipientsHint:
          "È possibile aggiungere al massimo 5 indirizzi e-mail",
        messages: {
          notificationSettingsRemoved:
            "Le impostazioni di notifica sono state rieseguito",
          notificationSettingsSaved:
            "Le impostazioni di notifica sono state salvate"
        },
        noLeaseAdded:
          "Per aggiungere le notifiche e-mail, torna alla scheda Locazione e specifica una locazione.",
        norecipientsAdded: "Nessun destinatario è stato ancora aggiunto.",
        recipients: "Destinatari",
        selectEmails:
          "Seleziona fino a 5 indirizzi e-mail che dovrebbero ricevere le notifiche",
        sendHint: "Invia subito un'e-mail a tutti i destinatari",
        threshold: "Soglia",
        title: "Impostazioni di notifica"
      }
    },
    parameters: {
      dataTable: {
        headers: {
          category: "Categoria",
          description: "Descrizione",
          device: "Dispositivo",
          id: "Numero",
          number: "Id",
          value: "Valore"
        }
      },
      dialogs: {
        editParameter: {
          category: "Categoria",
          messages: {
            cantBeEdited: "Questo parametro non può essere modificato",
            changesNeedApproval:
              "Le tue modifiche dovranno essere approvate da un operatore prima di essere effettive"
          },
          parameterNo: "Parametro n.",
          updateinterval: "Intervallo di aggiornamento"
        }
      }
    },
    props: {
      description: "Descrizione",
      lastSeen: "Ultima visualizzazione",
      name: "Nome",
      product: "Prodotto",
      serialNo: "Numero di serie",
      serialNumber: "Numero di serie",
      serviceStatus: "Stato della manutenzione",
      status: "Stato",
      tenant: "Locatario"
    },
    rental: {
      lease: {
        notDefined: {
          description:
            "Per aggiungere un impostazione, premi il pulsante in basso. Sarai guidato nei passaggi necessari per impostare le funzioni di controllo sulla tua macchina",
          title: "Non sono state definite le impostazioni"
        },
        notEnabled: {
          description:
            "Il noleggio della macchina può essere acquistato come componente aggiuntivo.<br />Per saperne di più, contatta",
          title:
            "La funzione di noleggio non è attualmente abilitata su questa macchina."
        },
        notSupportingDate:
          "Questa macchina supporta solo le impostazioni di controllo per ora motore.",
        settings: {
          actions: {
            add: "Aggiungi le impostazioni di controllo",
            edit: "Modifica delle impostazioni del contratto di locazione",
            remove: "Elimina le impostazioni del contratto di locazione"
          },
          configuredLeaseSettings:
            "Impostazioni del contratto di locazione configurate.",
          confirm: {
            description:
              " Applicare il contratto di locazione? Questo potrebbe influire sulla capacità di funzionamento della macchina. Accertati che la macchina si trovi in una posizione che non provochi danni alle persone o alle apparecchiature.",
            title: "Conferma l’aggiornamento del contratto di locazione"
          },
          description:
            " Imporre restrizioni alla macchina una volta raggiunte le ore motore specificate",
          engineHours: "Ore motore",
          engineHoursHover:
            "Le ultime ore motore note riportate dalla macchina",
          headers: {
            active: "Attivo",
            requested: "Richiesto"
          },
          leaseEnd: "Fine della locazione",
          leaseSettingsReportedByMachine:
            "Impostazioni del contratto di locazione riportate dalla macchina.",
          leaseStatusDescription: {
            applied: "Le modifiche sono state applicate",
            notApplicable: "N/A",
            pending: "In attesa del dispositivo"
          },
          machineRestrictions: "Limitazioni della macchina",
          messages: {
            emailNotificationHasBeenSent: "La notifica e-mail è stata inviata",
            leaseUpdateRequestHasBeenSent:
              "È stata inviata alla macchina una richiesta di aggiornamento del contratto di locazione. Una volta applicata, verrà inviata una notifica."
          },
          restrictionTypes: {
            blinkLights: "Luci lampeggianti",
            disableToolA: "la disattivazione dell’attrezzo A",
            reduceMovementSpeedToLvl3:
              "Riduci la velocità di movimento al livello di riduzione della velocità 3",
            stopEngineAfter: "Ferma il motore dopo"
          },
          sendEmailNotificationAboutLeaseChange:
            "Inviare subito un’e-mail di notifica per informare l'operatore che le impostazioni del contratto di locazione stanno per cambiare?",
          title: "Impostazioni del contratto di locazione o controllo macchina"
        }
      }
    },
    service: "Manutenzione",
    system: {
      newVersionAvailable: "È disponibile una nuova versione per il download.",
      newVersionDetails: "Versione {version}, rilasciata {releaseDate}.",
      noDataDescription:
        "Questo è normale quando la macchina è nuova e non sono ancora stati segnalati i suoi valori. Ricontrolla dopo un paio di ore motore."
    },
    tabHeaders: {
      alarms: "Allarmi",
      events: "Eventi",
      lease: "Locazione",
      location: "Posizione",
      measurements: "Misure",
      notifications: "Notifiche",
      service: "Manutenzione",
      serviceAgreement: null,
      settings: "Impostazioni"
    },
    updated: "Aggiornato {TIME}"
  }
}
