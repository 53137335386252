export default {
  tenant: {
    contacts: {
      actions: {
        addContact: null,
        editContact: null,
        removeContact: null
      },
      contactTypes: {
        sales: null,
        service: null
      },
      dataTable: {
        headers: {
          address: null,
          name: null,
          phoneNumber: null,
          type: null
        }
      },
      dialogs: {
        edit: {
          title: null
        }
      },
      messages: {
        contactAdded: null,
        contactUpdated: null,
        removed: null
      },
      title: null
    },
    customer: {
      actions: {
        addCustomer: "Añadir detalles del cliente",
        addDeliveryAddress: "Añadir dirección de entrega",
        editCustomer: "Editar detalles del cliente",
        editDeliveryAddress: "Editar dirección de entrega",
        removeCustomer: "Eliminar detalles del cliente"
      },
      labels: {
        addDetailsQuestion:
          "¿Desea añadir detalles de cliente para este inquilino? Esto creará un número de cliente para la tienda web.",
        address: "Dirección",
        addressDialogDescription:
          "Introduzca los detalles de la dirección de entrega del cliente.",
        addressLine: "Línea de dirección",
        customerNo: "Número de cliente",
        customerType: "Tipo de cliente",
        dealer: "Distribuidor",
        dealerAddressDescription:
          "La dirección del distribuidor se obtendrá de Monitor por la tienda web",
        deliveryAddressDescription:
          "Direcciones a las que se pueden enviar los pedidos de la tienda web. Se puede añadir un máximo de {maxRecords} direcciones.",
        deliveryAddressInfo:
          "No se pueden añadir más direcciones de entrega. Se ha introducido el número máximo de direcciones.",
        deliveryAddresses: "Direcciones de entrega",
        discount: "Porcentaje de descuento en la tienda web",
        endCustomer: "Cliente final",
        endCustomerName: "Nombre del cliente final",
        endCustomerNo: "Número de cliente final",
        freightContact: "Contacto del flete",
        missingCountry:
          "Se debe especificar un país antes de poder añadir los detalles del cliente. Seleccione uno en la vista Perfil del inquilino.",
        priceListId: "ID de la lista de precios de la tienda web"
      },
      messages: {
        removed: "Se han eliminado los datos del cliente",
        saved: "Se han guardado los datos del cliente"
      },
      placeholders: {
        address: "Introduzca la dirección",
        customerNo: "Introduzca un número de cliente de Monitor",
        customerType: "Elija el tipo de cliente",
        discount: "Introduzca el descuento de la tienda web (0-100 %)",
        freightContact: "Introduzca el contacto del flete",
        priceListId: "Introduzca la ID de la lista de precios de la tienda web"
      },
      title: "Cliente"
    },
    dialogs: {
      edit: {
        addTitle: "Añadir inquilino",
        city: "Ciudad",
        cityPlaceholder: "Introducir nombre de la ciudad",
        country: "País",
        countryPlaceholder: "Seleccionar país",
        description: "Descripción",
        descriptionPlaceholder: "Introducir descripción",
        editTitle: "Editar inquilino",
        engineHoursBeforeNextService:
          "Horas del motor antes del próximo mantenimiento",
        enterEmailAddress: "Introducir dirección de correo electrónico",
        messages: {
          added: "Se ha añadido el inquilino",
          updated: "Se ha actualizado el inquilino"
        },
        name: "Nombre",
        namePlaceholder: "Introducir nombre de inquilino",
        sendMaintanenceNotifications:
          "Enviar notificaciones por correo electrónico relacionadas con el mantenimiento."
      }
    },
    itemName: "inquilino",
    menuItems: {
      downloads: "Descargas",
      fleetAnalytics: null,
      fleet_analytics: null,
      machines: "Máquinas",
      manuals: "Manuales",
      manualsProductId: "Manuales para Brokk ",
      map: "Mapa",
      reports: "Informes",
      tenants: "Inquilinos",
      users: "Usuarios"
    },
    noLocationData: "No hay datos de ubicación para mostrar",
    profile: {
      title: "Perfil del inquilino"
    },
    selectAnObject: "Selecciona un objeto de la vista del árbol."
  }
}
