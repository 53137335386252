/* eslint-disable */

export function webshopNoAccess() {
  return "none"
}
export function webshopGuest() {
  return "guest"
}
export function webshopUser() {
  return "user"
}
export function webshopAdmin() {
  return "admin"
}
export function webshopCorporate() {
  return "corporate"
}
export function webshopPartner() {
  return "partner"
}
