<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-text class="py-0 my-0">
            <v-row>
              <v-col class="pa-0 ma-0">
                <v-checkbox
                  :disabled="!editModeEnabled"
                  class="ma-0 pa-0"
                  v-model="enter"
                  hide-details
                  :label="
                    $t('machines.location.geofencingSettings.triggers.enter')
                  "
                ></v-checkbox>
              </v-col>
              <v-col class="pa-0 ma-0">
                <v-checkbox
                  :disabled="!editModeEnabled"
                  class="ma-0 pa-0"
                  v-model="exit"
                  hide-details
                  :label="
                    $t('machines.location.geofencingSettings.triggers.exit')
                  "
                ></v-checkbox>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col class="subtitle-1 pa-0 ma-0 mt-4">
                {{ $t("machines.notifications.settings.recipients") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0 ma-0">
                <v-text-field
                  v-show="editModeEnabled"
                  :disabled="
                    !editModeEnabled ||
                      recipients.length === 5 ||
                      !(enter || exit)
                  "
                  v-model="recipient"
                  :label="$t('common.placeholders.email')"
                  :rules="[validationRules.email]"
                  ref="recipientTextField"
                  v-on:keyup.enter="addRecipient"
                  :hint="
                    $t('machines.notifications.settings.maxRecipientsHint')
                  "
                  persistent-hint
                >
                  <v-btn
                    :disabled="!editModeEnabled || recipients.length === 5"
                    text
                    small
                    slot="append"
                    @click.stop="addRecipient"
                  >
                    <v-icon>mdi-plus</v-icon> {{ $t("common.actions.add") }}
                  </v-btn>
                </v-text-field>
                <p
                  v-if="!recipients || recipients.length === 0"
                  class="caption mt-3"
                >
                  {{ $t("machines.notifications.settings.norecipientsAdded") }}
                </p>
                <v-chip
                  :disabled="!editModeEnabled"
                  class="mr-1 mb-1 mt-1"
                  v-for="recipient in recipients"
                  :close="editModeEnabled"
                  :key="recipient"
                  @click:close="removeRecipient(recipient)"
                >
                  <span class="text-truncate chip-font" :title="recipient">
                    {{ recipient }}
                  </span>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ValidationRules from "@/utils/validationrules"

let validationRules = null

export default {
  name: "GfTriggerSettings",
  props: ["trgSettings", "editModeEnabled"],
  components: {},
  data() {
    return {
      validationRules: validationRules,
      valid: false,

      recipient: undefined,
      recipients: [],
      enter: false,
      exit: false
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  created() {},
  computed: {},
  watch: {
    trgSettings() {
      if (this.trgSettings !== null) {
        this.recipients =
          (this.trgSettings.enterRecipients?.length
            ? this.trgSettings.enterRecipients
            : this.trgSettings.exitRecipients) || [] //We use the same recipients for enter and exit
        this.enter = this.trgSettings.enterRecipients?.length
        this.exit = this.trgSettings.exitRecipients?.length
      } else {
        this.recipients = []
        this.enter = false
        this.exit = false
      }
    },
    recipients() {
      this.updateRecipients()
    },
    enter() {
      this.updateRecipients()
    },
    exit() {
      this.updateRecipients()
    }
  },
  methods: {
    updateRecipients() {
      const triggerMissing = !this.enter && !this.exit
      if (triggerMissing && this.recipients.length) {
        this.recipients = []
        return
      }
      this.trgSettings.enterRecipients = this.enter ? this.recipients : []
      this.trgSettings.exitRecipients = this.exit ? this.recipients : []
    },
    addRecipient() {
      if (
        !this.$refs.recipientTextField.validate() ||
        !this.recipient ||
        this.recipient.length === 0
      ) {
        return
      }
      if (!this.recipients.includes(this.recipient)) {
        this.recipients.push(this.recipient)
        this.$refs.recipientTextField.reset()
      }
    },
    removeRecipient(recipient) {
      this.recipients = this.recipients.filter(x => x != recipient)
    }
  }
}
</script>
<style>
.chip-font {
  font-size: 12px;
  height: auto;
  white-space: normal;
  text-indent: 0px;
  line-height: 1;
}
</style>
