<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" lg="5">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('common.filters.filter')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <v-select
          :items="timeRangeItems"
          item-value="value"
          v-model="selectedTimeRange"
          :label="$t('common.filters.timeRange')"
          :disabled="isLoading"
        ></v-select>
      </v-col>
      <v-col cols="11" sm="5" lg="3">
        <v-menu
          ref="datePickerMenu"
          v-model="datePickerMenu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateRangeText"
              readonly
              :placeholder="$t('common.placeholders.selectDates')"
              :disabled="selectedTimeRange !== 'custom'"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="datePickerMenu = false">{{
              $t("common.actions.cancel")
            }}</v-btn>
            <v-btn
              text
              color="primary"
              :disabled="dates[0] === undefined || dates[1] === undefined"
              @click="
                $refs.datePickerMenu.save(dates)
                onSavePickedDate()
              "
              >{{ $t("common.actions.ok") }}</v-btn
            >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1" class="pl-0 d-flex justify-start align-center">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              @click="fetchData"
              :loading="refreshLoading"
              :disabled="refreshLoading"
            >
              <v-icon>mdi-cached</v-icon>
              <template v-slot:loader>
                <span class="refreshLoader">
                  <v-icon>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </template>
          <span>{{ $t("common.actions.refresh") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="eventHeaders"
          :items="eventHistory"
          :items-per-page="15"
          :search="search"
          item-key="id"
          sort-by="receiveTime"
          sort-desc
        >
          <template v-slot:[`item.source`]="{ item }">
            <span>{{ item.source }}</span>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <span>{{ item.description }}</span>
          </template>
          <template v-slot:[`item.receiveTime`]="{ item }">
            <span>{{ item.receiveTime | localeDateFilter }}</span>
          </template>
          <template v-slot:[`item.extraData`]="{ item }">
            <span v-if="item.extraData" @click="showExtraData(item)">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on">mdi-information</v-icon>
                </template>
                <div
                  v-for="(part, index) in splitExtraData(item.extraData)"
                  :key="index"
                >
                  {{ part.name }}{{ part.name ? ":" : "" }}{{ part.value }}
                </div>
              </v-tooltip>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <location-extra-info-dialog
      v-model="extraInfoDialog"
      :items="extraInfoItems"
    />
  </v-container>
</template>

<script>
import LimitationUtils from "@/utils/limitations"
import LocationExtraInfoDialog from "@/components/LocationExtraInfoDialog"

let limitationUtils = null

export default {
  name: "LocationEvents",
  props: ["machine"],
  components: { LocationExtraInfoDialog },
  data() {
    return {
      refreshLoading: true,
      search: "",
      dates: [],

      datePickerMenu: false,

      selectedTimeRange: "today",
      dateRangeText: "",

      extraInfoItems: null,
      extraInfoDialog: false
    }
  },
  beforeCreate() {
    limitationUtils = LimitationUtils(this.$i18n)
  },
  async created() {
    this.setDateRange()
    await this.fetchData()
  },
  computed: {
    timeRangeItems() {
      return [
        { text: this.$t("common.enums.day.today"), value: "today" },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            7
          )}`,
          value: "7 days"
        },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            30
          )}`,
          value: "30 days"
        },
        { text: this.$t("common.filters.customRange"), value: "custom" }
      ]
    },
    isLoading() {
      return this.$store.getters.loadingGeolocationEvents
    },
    eventHeaders() {
      return [
        {
          text: this.$t("machines.location.events.dataTable.headers.source"),
          value: "source"
        },
        {
          text: this.$t(
            "machines.location.events.dataTable.headers.description"
          ),
          value: "description"
        },
        {
          text: this.$t("machines.location.events.dataTable.headers.date"),
          value: "receiveTime"
        },
        {
          text: this.$t("machines.location.events.dataTable.headers.extraData"),
          value: "extraData"
        }
      ]
    },
    eventHistory() {
      return this.$store.getters.geolocationEvents
    }
  },
  methods: {
    onSavePickedDate() {
      this.datePickerMenu = false
      this.dateRangeText = this.dates.join(" ~ ")
      this.fetchData()
    },
    async fetchData() {
      this.refreshLoading = true
      try {
        await this.$store.dispatch("fetchGeolocationEvents", {
          tenantUid: this.$route.params.tenantUid,
          serialNo: this.$route.params.serialNo,
          start: this.dates[0],
          end: this.dates[1]
        })
      } catch (error) {
        // Error already handled
      } finally {
        this.refreshLoading = false
      }
    },
    setDateRange() {
      let startDate = new Date()
      let offset = 0

      if (this.selectedTimeRange === "today") {
        offset = 0
      } else if (this.selectedTimeRange === "7 days") {
        offset = 6 // Becomes 7 since today will be included
      } else if (this.selectedTimeRange === "30 days") {
        offset = 29 // Becomes 30 since today will be included
      }

      startDate.setDate(startDate.getDate() - offset)
      this.dates = [
        startDate.toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10)
      ]
    },
    splitExtraData(extraInfo) {
      // Maximum number of extras to display
      const MAX_NUM_EXTRA = 5

      let lines = this.formatExtraInfo(extraInfo)

      if (lines.length > MAX_NUM_EXTRA) {
        lines = lines.slice(0, MAX_NUM_EXTRA)
        lines.push({
          category: "-",
          name: "",
          value: `...${this.$t("machines.alarms.moreExists")}`
        })
      }
      return lines
    },
    showExtraData(item) {
      this.extraInfoItems = this.formatExtraInfo(item.extraData)
      this.extraInfoDialog = true
    },

    formatExtraInfo(data) {
      const extraInfo = JSON.parse(data)
      let results = this.getProperties(extraInfo)
      return results.sort((a, b) => {
        if ((a.category || b.category) && a.category != b.category) {
          return a.category > b.category ? 1 : -1
        }
        return a.name > b.name ? 1 : -1
      })
    },

    getProperties(extraInfo, category) {
      let results = []

      for (var propertyName in extraInfo) {
        const property = extraInfo[propertyName]
        if (propertyName == "position" && property) {
          results = results.concat(
            this.getProperties(
              property,
              this.$t("machines.location.geofencingSettings.coordinates")
            )
          )
        } else if (propertyName == "restrictions" && property) {
          results = results.concat(
            this.getRestrictions(
              property,
              this.$t("machines.location.geofencingSettings.limit.limitation")
            )
          )
        } else {
          results.push({
            category: category || "-",
            name: propertyName,
            value: property
          })
        }
      }
      return results
    },
    getRestrictions(rawData, category) {
      let results = []
      if (!rawData) {
        return results
      }
      const bitValues = [1, 2, 4, 8, 16]
      let values = bitValues.filter(bv => bv & rawData)
      results = values.map(i => {
        return {
          category: category || "-",
          name: limitationUtils.getText(i),
          value: "-"
        }
      })
      return results
    }
  },
  watch: {
    selectedTimeRange(newRange) {
      if (newRange !== "custom") {
        this.setDateRange()
        this.fetchData()
      } else {
        // Display date picker. OK callback will trigger fetch
        this.datePickerMenu = true
      }
    },
    dates(newDates) {
      // Always set earliest date as start date
      if (newDates[0] > newDates[1]) {
        newDates.reverse()
      }
      this.dateRangeText = newDates.join(" ~ ")
    }
  }
}
</script>

<style type="scss">
.refreshLoader {
  animation: loader 1s infinite;
  display: flex;
}
</style>
