<template>
  <v-card flat outlined>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>
        <v-icon class="accent--text mr-4">mdi-database-cog-outline</v-icon>
        <span class="accent--text text-h6 text-uppercase">{{
          $t("machines.info.segment.label")
        }}</span></v-toolbar-title
      >
    </v-toolbar>
    <v-overlay
      v-if="showOverlay"
      absolute
      color="#fff"
      opacity=".9"
      class="text--primary"
    >
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <h1 class="title mb-5">
            <v-icon color="primary" class="mr-2"
              >mdi-information-outline</v-icon
            >
            {{ $t("machines.info.segment.overlay.heading") }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <p class="mx-8">
            {{ $t("machines.info.segment.overlay.description") }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="disableCommissioning"
          cols="12"
          class="d-flex justify-center"
        >
          {{ $t("machines.info.segment.overlay.disabledDescription") }}
        </v-col>
        <v-col v-else cols="12" class="d-flex justify-center">
          <v-btn
            @click="showCommissioningGuide = true"
            :disabled="disableCommissioning"
            color="secondary accent--text"
            light
          >
            <v-icon left>mdi-plus-circle</v-icon>
            {{ $t("machines.info.segment.actions.commission") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-overlay>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6" class="pb-0">
          <v-text-field
            :label="$t('machines.info.segment.label')"
            :value="segmentName"
            persistent-hint
            readonly
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
          <v-text-field
            :label="$t('machines.info.segment.category')"
            :value="categoryName"
            persistent-hint
            readonly
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pb-0" v-if="hasApplications || showOverlay">
        <v-col cols="12" class="pt-0">
          <v-card flat outlined>
            <v-card-subtitle>{{
              $t("machines.info.segment.applications")
            }}</v-card-subtitle>
            <v-card-text>
              <v-chip
                v-for="app in applications"
                :key="app"
                color="primary"
                outlined
                class="mr-2 mb-2"
              >
                {{ app }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <GuidedMachineCommissioning
      :show="showCommissioningGuide"
      :order="order"
      :warranty="warranty"
      :machine="machine"
      @save="onSaveCommissioning"
      @cancel="onCancelCommissioning"
    />

    <Snackbar
      :show.sync="showSnackbar"
      :text="$t('machines.dialogs.commissioning.saved')"
    />
  </v-card>
</template>

<script>
import GuidedMachineCommissioning from "@/dialogs/GuidedMachineCommissioning"
import Snackbar from "@/components/widgets/Snackbar"

export default {
  props: ["loading", "order", "warranty", "machine"],
  components: {
    GuidedMachineCommissioning,
    Snackbar
  },
  data: () => ({
    showCommissioningGuide: false,
    showSnackbar: false
  }),
  computed: {
    showOverlay() {
      if (this.machineInfo) {
        return (
          this.machineInfo.isStockMachine &&
          this.machineInfo.commissioningDate === null
        )
      }
      return false
    },
    machineInfo() {
      return this.$store.getters.machineInfo
    },
    segmentName() {
      if (this.machineInfo) {
        return this.machineInfo.segmentName
      }
      return ""
    },
    categoryName() {
      let name = ""
      if (this.machineInfo) {
        name = this.machineInfo.categoryName
        if (this.machineInfo.otherCategory.length > 0) {
          name = `${name} / ${this.machineInfo.otherCategory}`
        }
      }
      return name
    },
    applications() {
      if (this.machineInfo) {
        return this.machineInfo.applications
      }
      return []
    },
    hasApplications() {
      return this.applications && this.applications.length > 0
    },
    disableCommissioning() {
      let hasDeliveryDate = false
      if (this.order) {
        hasDeliveryDate = this.order.deliveryDate.length > 2
      }
      return this.loading || !hasDeliveryDate
    }
  },
  methods: {
    onSaveCommissioning() {
      this.showCommissioningGuide = false
      this.showSnackbar = true

      this.$store.dispatch("fetchMachineInfo", {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo
      })
    },
    onCancelCommissioning() {
      this.showCommissioningGuide = false
    }
  }
}
</script>

<style></style>
