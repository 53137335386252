<template>
  <v-dialog v-model="visible" max-width="600" persistent>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title class="d-flex align-center">
        <v-icon class="accent--text mr-2">mdi-transfer</v-icon>
        <span class="title accent--text" v-if="selectedTenant !== null"
          >{{
            $t("machines.dialogs.importMachine.title", {
              tenant: selectedTenant.name
            })
          }}
        </span>
      </v-toolbar-title>
    </v-toolbar>

    <v-stepper v-model="stepNo">
      <v-stepper-header>
        <v-stepper-step :complete="stepNo > 1" step="1">
          {{ $t("machines.dialogs.importMachine.steps.overview") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepNo > 2" step="2">
          {{ $t("machines.dialogs.importMachine.steps.confirm") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepNo == 3" step="3">
          {{ $t("machines.dialogs.importMachine.steps.summary") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card flat>
            <v-card-title>{{
              $t("machines.dialogs.importMachine.enterTransferCodeTitle")
            }}</v-card-title>
            <v-card-text class="primary--text">
              <p class="accent--text" v-if="selectedTenant !== null">
                {{
                  $t(
                    "machines.dialogs.importMachine.enterTransferCodeDescription"
                  )
                }}
                <span class="font-weight-bold">{{ selectedTenant.name }}</span>
              </p>
              <v-form
                ref="importMachineForm"
                autocomplete="off"
                v-model="valid"
              >
                <v-text-field
                  class="mt-2"
                  v-model="transferCode"
                  required
                  :label="
                    `${$t('machines.dialogs.importMachine.transferCode')}*`
                  "
                  :placeholder="
                    $t('machines.dialogs.importMachine.enterTransferCodeTitle')
                  "
                  :rules="[validationRules.transferCode]"
                  :error-messages="errorMsg"
                ></v-text-field>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="fetchTransfer"
              :loading="loadingTransferInfo"
              :disabled="loadingTransferInfo"
            >
              {{ $t("common.actions.next") }}
            </v-btn>
            <v-btn @click="onCancel" class="grab-cancel-machine-import">{{
              $t("common.actions.cancel")
            }}</v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card flat>
            <v-card-title>{{
              $t("machines.dialogs.importMachine.confirmTransferTitle")
            }}</v-card-title>
            <v-card-text class="primary--text" v-if="transferInfo !== null">
              <p :v-html="confirmTransferMachine"></p>
              <p>
                {{
                  $t(
                    "machines.dialogs.importMachine.confirmTransferProceedInfo"
                  )
                }}
              </p>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-btn :disabled="importingMachine" @click="stepNo = 1">
              {{ $t("common.actions.previous") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="importingMachine" @click="onSave">
              {{ $t("common.actions.next") }}
            </v-btn>
            <v-btn :disabled="importingMachine" @click="onCancel">{{
              $t("common.actions.cancel")
            }}</v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card flat>
            <v-card-title>{{
              $t("machines.dialogs.importMachine.steps.summary")
            }}</v-card-title>
            <v-card-text class="primary--text" v-if="transferInfo !== null">
              <p v-if="errorMsg.length > 0">{{ errorMsg }}</p>
              <p v-else>
                {{
                  $t("machines.dialogs.importMachine.transferSucceededText", {
                    machine: transferInfo.productName,
                    sn: transferInfo.serialNo,
                    tenant: selectedTenant.name
                  })
                }}
              </p>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="onCancel">{{ $t("common.actions.close") }}</v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
let validationRules = null

export default {
  name: "GuidedMachineImport",
  props: ["tenantUid", "show"],
  data() {
    return {
      stepNo: 1,
      valid: false,
      transferCode: "",

      validationRules: validationRules,
      errorMsg: "",

      transferInfo: null,
      loadingTransferInfo: false,
      importingMachine: false
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(value) {
        if (!value) {
          this.$emit("cancel")
        }
      }
    },
    selectedTenant() {
      return this.$store.getters.selectedTenant
    },
    confirmTransferMachine() {
      let bold = text => `<span class="font-weight-bold">${text}</span>`
      let transferDetails = {
        machine: bold(this.transferInfo.productName),
        sn: bold(this.transferInfo.serialNo),
        tenant: bold(this.selectedTenant.name)
      }
      return this.$t(
        "machines.dialogs.importMachine.confirmTransferMachine",
        transferDetails
      )
    }
  },
  watch: {
    show() {
      this.stepNo = 1
      this.valid = false
      this.transferCode = ""
      this.errorMsg = ""

      this.transferInfo = null
      this.loadingTransferInfo = false
      this.importingMachine = false
    },
    transferCode() {
      this.errorMsg = ""
    }
  },
  methods: {
    fetchTransfer() {
      if (!this.$refs.importMachineForm.validate()) {
        return
      }

      this.loadingTransferInfo = true
      this.errorMsg = ""

      this.$store
        .dispatch("fetchTransferByCode", {
          code: this.transferCode
        })
        .then(machineTransfer => {
          this.transferInfo = machineTransfer
          if (this.transferInfo !== null) {
            if (this.transferInfo.isExpired) {
              this.errorMsg = this.$t(
                "machines.dialogs.importMachine.errors.transferCodeExpired"
              )
            } else if (this.transferInfo.isTransferred) {
              this.errorMsg = this.$t(
                "machines.dialogs.importMachine.errors.transferCodeAlreadyUsed"
              )
            } else if (this.transferInfo.transferState !== "pending") {
              this.errorMsg = this.$t(
                "machines.dialogs.importMachine.errors.transferReverted"
              )
            } else {
              this.stepNo = 2
            }
          } else {
            this.errorMsg = this.$t(
              "machines.dialogs.importMachine.errors.noSuchTransferCode"
            )
          }
        })
        .catch(() => {
          this.transferInfo = null
        })
        .finally(() => {
          this.loadingTransferInfo = false
        })
    },
    onSave() {
      if (this.transferInfo !== null) {
        this.importingMachine = true

        this.errorMsg = ""

        this.$store
          .dispatch("transferMachine", {
            code: this.transferInfo.transferCode,
            dstTenantUid: this.selectedTenant.uid
          })
          .then(() => {})
          .catch(errMsg => {
            this.errorMsg = this.$t(
              "machines.dialogs.importMachine.errors.couldNotCompleteTransfer",
              { msg: errMsg }
            )
          })
          .finally(() => {
            this.importingMachine = false
            this.stepNo = 3
          })
      } else {
        return
      }
      this.$refs.importMachineForm.reset()
    },
    onCancel() {
      this.$refs.importMachineForm.reset()
      this.visible = false
    }
  }
}
</script>
