import fetchClient from "@/utils/fetchClient"
import { Auth } from "aws-amplify"
import fetchData from "../fetchData"
import {
  saveTokenCookies,
  removeTokenCookies,
  saveWebshopCookie,
  removeWebshopCookies
} from "./cookiejar"
import {
  webshopNoAccess,
  webshopGuest,
  webshopUser,
  webshopPartner,
  webshopCorporate,
  webshopAdmin
} from "../metadata/webshop_roles"

function getAssetPermission(user) {
  return assetName => {
    if (user !== null) {
      const permission = user.permissions.find(permObj => {
        return permObj.asset === assetName
      })
      if (permission !== undefined) {
        return permission
      }
      return null
    }
    return null
  }
}

function getTokens(session) {
  return {
    accessToken: session.getAccessToken(),
    idToken: session.getIdToken(),
    refreshToken: session.getRefreshToken()
  }
}

function setUserData(context, session) {
  const tokens = getTokens(session)

  const data = {
    ...tokens.idToken.payload,
    uid: tokens.idToken.payload.sub,
    permissions: JSON.parse(tokens.idToken.payload.permissions),
    roleId: +tokens.idToken.payload.roleId,
    authToken: tokens.idToken.jwtToken
  }
  localStorage.setItem("loggedInUser", JSON.stringify(data))
  context.commit("setLoggedInUser", data)

  const expireTime = +tokens.accessToken.payload.exp

  saveTokenCookies(
    {
      accessToken: tokens.accessToken.jwtToken,
      idToken: tokens.idToken.jwtToken,
      refreshToken: tokens.refreshToken.token
    },
    expireTime
  )

  // The refresh token does not have any expire time field,
  // so we set it 30 days (in seconds) after access token time
  const webshopExpireTimeSec = expireTime + 30 * 24 * 3600

  // List all Cognito values in local storage
  for (var i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key.startsWith("Cognito")) {
      // Get the name part from the end of the key:
      // CognitoIdentityServiceProvider.2c567jolhefkme4mmro…3125be7a-4cd3-4f54-a892-eefec0279a3c.refreshToken
      const parts = key.split(".")
      const name = parts[parts.length - 1]
      const value = localStorage.getItem(key)
      saveWebshopCookie(
        `bc.ws.${name}`,
        JSON.stringify({ key, value }),
        webshopExpireTimeSec
      )
    }
  }

  context.commit("clearError")
}

function getGrant(user, itemName, perm) {
  let grant = getAssetPermission(user)(itemName)
  if (grant !== null) {
    var regex = new RegExp(`[${perm}]`)
    return regex.test(grant.permission)
  }
  return false
}

async function callUserEndpoint(method, context, tenantUid, uid, data = null) {
  let errorData = null
  let responseData = null
  const url = `/api/v1/tenants/${tenantUid}/users/${uid}`

  try {
    context.commit("clearError")
    context.commit("setLoading", true)

    const res = await fetchClient(this.state.$i18n).getResponse(
      method,
      url,
      data
    )

    if (res.status === 200) {
      responseData = await res.json()
    } else {
      errorData = await fetchClient(this.state.$i18n).getError(res)
    }
  } catch (error) {
    const msg =
      method === "GET"
        ? this.state.$i18n.t("store.errors.failedToFetchUserDetails")
        : this.state.$i18n.t("store.errors.failedToUpdateUserDetails")
    errorData = {
      message: msg,
      causedBy: error.toString(),
      details: error.message
    }
  } finally {
    context.commit("setLoading", false)

    if (errorData != null) {
      errorData.requestMethod = method
      errorData.requestUri = url

      const err = {
        response: {
          data: errorData
        }
      }
      context.commit("setError", err)
    }
  }
  return responseData
}

function getWebshopRole(user) {
  let webshopRole = webshopNoAccess()

  if (user.permissions !== undefined) {
    if (
      getGrant(user, "webshop", "c") &&
      getGrant(user, "webshop", "r") &&
      getGrant(user, "webshop", "u") &&
      getGrant(user, "webshop", "d")
    ) {
      webshopRole = webshopAdmin()
    } else if (
      getGrant(user, "webshop", "c") &&
      getGrant(user, "webshop", "r") &&
      getGrant(user, "webshop", "u") &&
      !getGrant(user, "webshop", "d")
    ) {
      webshopRole = webshopCorporate()
    } else if (
      !getGrant(user, "webshop", "c") &&
      getGrant(user, "webshop", "r") &&
      getGrant(user, "webshop", "u") &&
      !getGrant(user, "webshop", "d")
    ) {
      webshopRole = webshopPartner()
    } else if (
      !getGrant(user, "webshop", "c") &&
      !getGrant(user, "webshop", "r") &&
      getGrant(user, "webshop", "u") &&
      !getGrant(user, "webshop", "d")
    ) {
      webshopRole = webshopUser()
    } else if (
      !getGrant(user, "webshop", "c") &&
      getGrant(user, "webshop", "r") &&
      !getGrant(user, "webshop", "u") &&
      !getGrant(user, "webshop", "d")
    ) {
      webshopRole = webshopGuest()
    }
  }
  return webshopRole
}

function isAdminRole(roleId) {
  return roleId === 100
}

function isServiceRole(roleId) {
  return roleId === 150
}

function isLocalAdminRole(roleId) {
  return roleId === 200
}

export default {
  state: {
    users: [],
    loggedInUser: JSON.parse(localStorage.getItem("loggedInUser"))
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload
    },
    removeUser(state, userUid) {
      let ix = state.users.findIndex(user => user.uid === userUid)
      state.users.splice(ix, 1)
    },
    setLoggedInUser(state, payload) {
      state.loggedInUser = payload
    }
  },
  actions: {
    fetchUsers(context, tenantUid) {
      context.commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .get(`/api/v1/tenants/${tenantUid}/users`)
        .then(data => {
          context.commit("setUsers", data)
          context.commit("clearError")
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoading", false)
        })
    },
    async fetchUserDetails(context, user) {
      try {
        context.commit("setLoading", true)
        await context.dispatch(
          "userDetailsStore/fetchData",
          () =>
            fetchClient(this.state.$i18n).get(
              `/api/v1/tenants/${user.tenantUid}/users/${user.uid}`
            ),
          { root: true }
        )
      } catch (error) {
        context.commit("setError", error)
      } finally {
        context.commit("setLoading", false)
      }
    },
    async updateUser(context, user) {
      let data = {}
      Object.assign(data, user)
      delete data.tenantUid
      delete data.uid
      delete data.userUid
      delete data.email
      return await callUserEndpoint(
        "PUT",
        context,
        user.tenantUid,
        user.uid,
        data
      )
    },
    updateUserTerms(context, user) {
      context.commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .put(`/api/v1/tenants/${user.tenantUid}/users/${user.uid}/terms`, {
          accepted: true
        })
        .then(() => {
          // Terms agreement updated for user ${user.uid}
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoading", false)
        })
    },
    async updateUserPreferences(context, userPreferences) {
      context.commit("setLoading", true)
      await fetchClient(this.state.$i18n)
        .put(
          `/api/v1/tenants/${context.state.loggedInUser.tenantUid}/users/${context.state.loggedInUser.uid}/preferences`,
          userPreferences
        )
        .then(() => {})
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoading", false)
        })
      await context.dispatch("fetchUserDetails", {
        //Pull up to date details from database
        tenantUid: context.state.loggedInUser.tenantUid,
        uid: context.state.loggedInUser.uid
      })
    },
    removeUser(context, user) {
      context.commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .delete(`/api/v1/tenants/${user.tenantUid}/users/${user.uid}`)
        .then(() => {
          context.commit("removeUser", user.uid)
          context.commit("clearError")
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoading", false)
        })
    },
    async getUserById(context, user) {
      return await callUserEndpoint("GET", context, user.tenantUid, user.uid)
    },
    async authenticate(context, request) {
      context.commit("setLoading", true)
      const { email, password } = request

      try {
        const user = await Auth.signIn(email, password)
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          context.commit("setLoading", false)
          return "NEW_PASSWORD_REQUIRED"
        }
        const session = await Auth.currentSession()
        setUserData(context, session)
      } catch (error) {
        localStorage.removeItem("loggedInUser")
        context.commit("setLoggedInUser", null)
        context.commit("setError", error)

        if (error.code === "PasswordResetRequiredException") {
          // User must set a new password.
          context.commit("setLoading", false)
          return "NEW_PASSWORD_REQUIRED"
        }
      }
      context.commit("setLoading", false)
      return true
    },
    setSessionTokens(context, session) {
      setUserData(context, session)
      context.commit("clearError")
    },
    async firstTimeSetPassword(context, request) {
      const { newpassword, username, tempPassword } = request
      context.commit("setLoading", true)

      // Clear any logged in user before setting password.
      localStorage.removeItem("loggedInUser")
      context.commit("setLoggedInUser", null)

      try {
        const user = await Auth.signIn(username, tempPassword)
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          try {
            await Auth.completeNewPassword(user, newpassword)
            const session = await Auth.currentSession()
            setUserData(context, session)
          } catch (error) {
            context.commit("setError", error)
          }
        }
      } catch (error) {
        localStorage.removeItem("loggedInUser")
        context.commit("setLoggedInUser", null)
        context.commit("setError", error)
      }
      context.commit("setLoading", false)
    },
    async forgotPassword(context, email) {
      context.commit("setLoading", true)
      try {
        const responseData = await Auth.forgotPassword(email)
        context.commit("setLoading", false)
        return responseData
      } catch (error) {
        context.commit("setError", error)
      }
      context.commit("setLoading", false)
    },
    async setNewPassword(context, request) {
      context.commit("setLoading", true)
      const { username, code, newpassword } = request
      try {
        await Auth.forgotPasswordSubmit(username, code, newpassword)
        context.commit("setLoading", false)
        return true
      } catch (error) {
        context.commit("setError", error)
      }
      context.commit("setLoading", false)
    },
    async logout(context) {
      context.commit("setLoading", true)
      try {
        await Auth.signOut()
        localStorage.removeItem("loggedInUser")
        removeTokenCookies()
        removeWebshopCookies()
        context.commit("setLoggedInUser", null)
        context.commit("clearError")
        context.commit("setLoading", false)
      } catch (error) {
        // error signing out
      }
    }
  },
  getters: {
    users(state) {
      return state.users
    },
    userDetails(state) {
      return state.userDetailsStore.data
    },
    readMessages(state) {
      if (
        state.userDetailsStore.data &&
        state.userDetailsStore.data.notifications &&
        state.userDetailsStore.data.notifications.readMessages
      ) {
        return state.userDetailsStore.data.notifications.readMessages
      }
      return []
    },
    loggedInUser(state) {
      return state.loggedInUser
    },
    authToken(state) {
      if (state.loggedInUser !== null) {
        return state.loggedInUser.authToken
      } else {
        return null
      }
    },
    isAdmin(state) {
      return isAdminRole(state.loggedInUser.roleId)
    },
    isService(state) {
      return isServiceRole(state.loggedInUser.roleId)
    },
    isLocalAdmin(state) {
      return state.loggedInUser.roleId === 200
    },
    isUser(state) {
      return state.loggedInUser.roleId === 500
    },
    hasWebshopAccess(state) {
      const role = getWebshopRole(state.loggedInUser)
      return role.localeCompare(webshopNoAccess()) !== 0
    },
    canAddTenants(state) {
      return getGrant(state.loggedInUser, "tenants", "c")
    },
    canReadTenants(state) {
      return getGrant(state.loggedInUser, "tenants", "r")
    },
    canEditTenants(state) {
      return getGrant(state.loggedInUser, "tenants", "u")
    },
    canRemoveTenants(state) {
      return getGrant(state.loggedInUser, "tenants", "d")
    },
    canReadMaps(state) {
      return getGrant(state.loggedInUser, "maps", "r")
    },
    canReadManuals(state) {
      return getGrant(state.loggedInUser, "manuals", "r")
    },
    canReadSystem(state) {
      return getGrant(state.loggedInUser, "system", "r")
    },
    canAddMachines(state) {
      return getGrant(state.loggedInUser, "machines", "u")
    },
    canReadMachines(state) {
      return getGrant(state.loggedInUser, "machines", "r")
    },
    canEditMachines(state) {
      return getGrant(state.loggedInUser, "machines", "u")
    },
    canRemoveMachines(state) {
      return getGrant(state.loggedInUser, "machines", "d")
    },
    canAddUsers(state) {
      return getGrant(state.loggedInUser, "users", "c")
    },
    canReadUsers(state) {
      return getGrant(state.loggedInUser, "users", "r")
    },
    canEditUsers(state) {
      return getGrant(state.loggedInUser, "users", "u")
    },
    canRemoveUsers(state) {
      return getGrant(state.loggedInUser, "users", "d")
    },
    canAddParameters(state) {
      return getGrant(state.loggedInUser, "parameters", "c")
    },
    canReadParameters(state) {
      return getGrant(state.loggedInUser, "parameters", "r")
    },
    canEditParameters(state) {
      return getGrant(state.loggedInUser, "parameters", "u")
    },
    canRemoveParameters(state) {
      return getGrant(state.loggedInUser, "parameters", "d")
    },
    canAddMeasurements(state) {
      return getGrant(state.loggedInUser, "measurements", "c")
    },
    canReadMeasurements(state) {
      return getGrant(state.loggedInUser, "measurements", "r")
    },
    canEditMeasurements(state) {
      return getGrant(state.loggedInUser, "measurements", "u")
    },
    canRemoveMeasurements(state) {
      return getGrant(state.loggedInUser, "measurements", "d")
    },
    canAddMaintenance(state) {
      return getGrant(state.loggedInUser, "maintenance", "c")
    },
    canReadMaintenance(state) {
      return getGrant(state.loggedInUser, "maintenance", "r")
    },
    canEditMaintenance(state) {
      return getGrant(state.loggedInUser, "maintenance", "u")
    },
    canRemoveMaintenance(state) {
      return getGrant(state.loggedInUser, "maintenance", "d")
    },
    canAddRental(state) {
      return getGrant(state.loggedInUser, "rental", "c")
    },
    canReadRental(state) {
      return getGrant(state.loggedInUser, "rental", "r")
    },
    canEditRental(state) {
      return getGrant(state.loggedInUser, "rental", "u")
    },
    canRemoveRental(state) {
      return getGrant(state.loggedInUser, "rental", "d")
    },
    canAddReports(state) {
      return getGrant(state.loggedInUser, "reports", "c")
    },
    canReadReports(state) {
      return getGrant(state.loggedInUser, "reports", "r")
    },
    canEditReports(state) {
      return getGrant(state.loggedInUser, "reports", "u")
    },
    canRemoveReports(state) {
      return getGrant(state.loggedInUser, "reports", "d")
    },
    canAddGeolocation(state) {
      return getGrant(state.loggedInUser, "geolocation", "c")
    },
    canReadGeolocation(state) {
      return getGrant(state.loggedInUser, "geolocation", "r")
    },
    canEditGeolocation(state) {
      return getGrant(state.loggedInUser, "geolocation", "u")
    },
    canRemoveGeolocation(state) {
      return getGrant(state.loggedInUser, "geolocation", "d")
    },
    canAddGeofence(state) {
      return getGrant(state.loggedInUser, "geofence", "c")
    },
    canReadGeofence(state) {
      return getGrant(state.loggedInUser, "geofence", "r")
    },
    canEditGeofence(state) {
      return getGrant(state.loggedInUser, "geofence", "u")
    },
    canRemoveGeofence(state) {
      return getGrant(state.loggedInUser, "geofence", "d")
    },
    canAccessWebShop(state) {
      return (
        getGrant(state.loggedInUser, "webshop", "r") ||
        getGrant(state.loggedInUser, "webshop", "u")
      )
    },
    canReadWarranty(state) {
      return getGrant(state.loggedInUser, "warranty", "r")
    },
    canReadReturnForm(state) {
      return getGrant(state.loggedInUser, "returnForm", "r")
    },
    canReadServiceProtocols(state) {
      return getGrant(state.loggedInUser, "serviceProtocols", "r")
    },
    canReadSpareParts(state) {
      return (
        isAdminRole(state.loggedInUser.roleId) ||
        isServiceRole(state.loggedInUser.roleId) ||
        isLocalAdminRole(state.loggedInUser.roleId)
      )
    },
    canReadServiceInstructions(state) {
      return getGrant(state.loggedInUser, "serviceInstructions", "r")
    },
    canReadServiceManuals(state) {
      return getGrant(state.loggedInUser, "serviceManuals", "r")
    },
    canReadServiceBulletins(state) {
      return getGrant(state.loggedInUser, "serviceBulletins", "r")
    },
    canReadDownloads(state) {
      return getGrant(state.loggedInUser, "download", "r")
    },
    canRemoveDownloads(state) {
      return getGrant(state.loggedInUser, "download", "d")
    },
    canEditWebShop(state) {
      return (
        getGrant(state.loggedInUser, "webshop", "r") &&
        getGrant(state.loggedInUser, "webshop", "u")
      )
    },
    canReadFleetAnalytics(state) {
      return getGrant(state.loggedInUser, "fleetAnalytics", "r")
    },
    canReadServiceAgreements(state) {
      return (
        isServiceRole(state.loggedInUser.roleId) ||
        isAdminRole(state.loggedInUser.roleId)
      )
    },
    getWebshopAccess() {
      return user => {
        return getWebshopRole(user)
      }
    },
    getReadFleetAnalytics() {
      return user => {
        if (!user.permissions) {
          return false
        }
        return getGrant(user, "fleetAnalytics", "r")
      }
    }
  },
  modules: {
    userDetailsStore: fetchData
  }
}
