<template>
  <v-container fluid class="pa-0">
    <router-view v-if="showSelection"></router-view>
    <v-card class="pa-0 pb-4 ma-4" color="white" v-else>
      <v-card-title class="text-uppercase">
        {{ $t("documents.manuals.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("home.documents.description") }}
      </v-card-subtitle>
      <v-container fluid>
        <v-progress-linear :active="isLoading" :indeterminate="isLoading" absolute top></v-progress-linear>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels focusable accordion v-model="selectedGroupIndex">
              <v-expansion-panel v-for="(item, i) in productGroups" :key="i">
                <v-expansion-panel-header>
                  {{ item.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content ripple>
                  <v-expand-transition>
                    <div class="items-grid" v-show="item.isLoaded">
                      <div v-for="machine in item.products" :key="machine.code">
                        <SelectListWithImage :id="machine.productId" :name="machine.productName"
                          :src="machine.imagePath" :title="machine.name" :alt="machine.name"
                          :items="items(item, machine.productId)" @select="onSelect" />
                      </div>
                    </div>
                  </v-expand-transition>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex"
import fetchClient from "@/utils/fetchClient"
import SelectListWithImage from "@/components/controls/SelectListWithImage"

export default {
  components: {
    SelectListWithImage
  },
  data() {
    return {
      selectedGroupIndex: null,
      select: {},
      manuals: [],
      productGroups: []
    }
  },
  created() {
    this.fetchProductGroups()
  },
  watch: {
    $route: function () {
      this.select = {}
    },
    selectedGroupIndex: function () {
      if (this.selectedGroupIndex >= 0) {
        const group = this.productGroups[this.selectedGroupIndex]
        if (group && group.products.length == 0) {
          this.fetchProductModels(group)
          this.fetchManuals(group)
        }
      }
    }
  },
  computed: {
    showSelection() {
      return this.$route.path.endsWith("view")
    }
  },
  methods: {
    ...mapActions(["setError"]),
    changeRoute(select) {
      if (!select) {
        select = this.select
      }
      if (select != null) {
        this.$router.push({ path: "/documents/manuals/view", query: select })
      }
    },
    onSelect(event, select) {
      const selectedValue = event.target.value
      if (selectedValue && select) {
        this.changeRoute(select)
      }
    },

    async fetchProductGroups() {
      const url = "/api/v1/metadata/product_groups"
      this.isLoading = true
      try {
        const metaData = await fetchClient(this.$i18n).getDataOrThrow(url)
        metaData.productGroups.forEach(group => { group.products = [], group.manuals = [], group.isLoaded = false })
        this.productGroups = metaData.productGroups
      } catch (error) {
        this.setError(error)
      } finally {
        this.isLoading = false
      }
    },

    async fetchProductModels(group) {
      const url = `/api/v1/metadata/product_models/${group.groupId}`

      this.isLoading = true
      try {
        const metaData = await fetchClient(this.$i18n).getDataOrThrow(url)
        group.products = metaData.productModels
        setTimeout(() => group.isLoaded = true, 300)//Added to make transition after data loading smooth
      } catch (error) {
        this.setError(error)
      } finally {
        this.isLoading = false
      }
    },

    async fetchManuals(group) {
      //const langCode = this.$store.getters.dataLangCode
      const url = `/api/v1/metadata/product_manuals/${group.groupId}`

      this.isLoading = true
      try {
        const metaData = await fetchClient(this.$i18n).getDataOrThrow(url)
        group.manuals = metaData.productManuals
      } catch (error) {
        this.setError(error)
      } finally {
        this.isLoading = false
      }
    },
    items(group, productId) {
      const productManuals = group.manuals.filter(
        product => product.productId === productId
      )
      productManuals.map(manual => {
        manual.serialRange = `Serial No: ${manual.serialStart} - ${manual.serialEnd}`
      })
      return productManuals
    }
  }
}
</script>

<style>
.items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  grid-gap: 1rem;
  padding: 12px;
}

.v-expansion-panel--active>.v-expansion-panel-header {
  min-height: auto;
}

.v-expansion-panel-content__wrap {
  padding: 0;
}

.v-expansion-panels--focusable .v-expansion-panel-header--active {
  background-color: #FFD300;

  &::before {
    opacity: 0 !important;
  }
}

.v-expansion-panel-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
