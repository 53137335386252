const SERVICE_UNKNOWN = -1

function findMaxServiceSeverity(machine) {
  //numbers match the ones in severity
  if (machine.unprovisioned) {
    // unknown
    return SERVICE_UNKNOWN
  }
  if (machine.serviceStatus.includes("overdue")) {
    return 5
  }
  if (machine.serviceStatus.includes("pending")) {
    return 4
  }
  if (machine.serviceStatus.includes("upcoming")) {
    return 0
  }
  return SERVICE_UNKNOWN
}
function formatTooltip(machine, serviceSeverity, i18n) {
  if (serviceSeverity === SERVICE_UNKNOWN) {
    return `${i18n.t("machines.actions.unprovisioned")}:
    ${i18n.t("machines.dataTable.tooltips.service")}
    ${i18n.t("machines.dataTable.tooltips.unknown")}`
  }
  if (serviceSeverity === 0) {
    return `${machine.status}:
    ${i18n.t("machines.dataTable.tooltips.service")}
    ${i18n.t("machines.dataTable.tooltips.upcoming")}`
  }
  if (serviceSeverity === 5) {
    return `${machine.status}:
    ${i18n.t("machines.dataTable.tooltips.service")}
    ${i18n.t("machines.dataTable.tooltips.overdue")}`
  }
  if (serviceSeverity === 4) {
    return `${machine.status}:
    ${i18n.t("machines.dataTable.tooltips.service")}
    ${i18n.t("machines.dataTable.tooltips.pending")}`
  }
  const parts = []

  return `${machine.status}: ${parts.join(", ")}`
}

function serviceLabel(severity, i18n) {
  let label = ""
  switch (severity) {
    case SERVICE_UNKNOWN:
      // Don't know the service status due to machine being unprovisioned
      label = `${i18n.t("machines.dataTable.tooltips.unknown")}`
      break
    case 0:
      // Service is done on time or has not been used yet but is provisioned
      label = `${i18n.t("machines.dataTable.tooltips.upcoming")}`
      break
    case 4:
      // Service is pending
      label = `${i18n.t("machines.dataTable.tooltips.pending")}`
      break
    case 5:
      // Machine overdue for service
      label = `${i18n.t("machines.dataTable.tooltips.overdue")}`
      break
    default:
      break
  }

  return label
}
function serviceInfo(machine, i18n) {
  const serviceSeverity = findMaxServiceSeverity(machine)

  let tooltip = formatTooltip(machine, serviceSeverity, i18n)

  let { iconName, colorName } = serviceIcon(serviceSeverity, false)

  return { iconName, colorName, tooltip }
}
function serviceIcon(severity) {
  let iconName = ""
  let colorName = ""

  switch (severity) {
    case SERVICE_UNKNOWN:
      iconName = "mdi-minus"
      colorName = "primary"
      break
    case 0:
      iconName = "mdi-check-circle"
      colorName = "success"
      break
    case 4:
      iconName = "mdi-alert" // triangle
      colorName = "orange"
      break
    case 5:
      iconName = "mdi-alert-octagon" // similar to stop sign
      colorName = "error"
      break
    default:
      break
  }
  return { iconName, colorName }
}

export default {
  level(machine) {
    const serviceSeverity = findMaxServiceSeverity(machine)
    return serviceSeverity
  },
  eventIcon(severity) {
    let { iconName } = serviceIcon(severity, true)
    return iconName
  },
  eventColor(severity) {
    let { colorName } = serviceIcon(severity, true)
    return colorName
  },
  icon(machine, i18n) {
    let { iconName } = serviceInfo(machine, i18n)
    return iconName
  },
  color(machine, i18n) {
    let { colorName } = serviceInfo(machine, i18n)
    return colorName
  },
  tooltip(machine, i18n) {
    let { tooltip } = serviceInfo(machine, i18n)
    return tooltip
  },
  label(severity, i18n) {
    let label = serviceLabel(severity, i18n)
    return label
  }
}
