<template>
  <div>
    <v-app v-if="isUserLoggedIn" class="normalBackground">
      <v-main class="d-flex align-stretch">
        <div>
          <app-alert
            v-if="error"
            @dismissed="dismissAlert"
            :errItem="error"
          ></app-alert>
        </div>
        <router-view></router-view>
      </v-main>

      <div>
        <v-footer dark>
          <div class="muted--text overline">
            Copyright &copy; {{ new Date().getFullYear() }} Brokk AB.
            {{ $t("common.allRightsReserved") }}.
          </div>
        </v-footer>
      </div>
    </v-app>

    <v-app v-else>
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>

    <GenericDialog
      v-if="genericDialog"
      show="true"
      :genericDialog="genericDialog"
    />

    <TermsOfServiceDialog
      v-if="showTermsOfServiceDialog"
      show="true"
      :config="termsConfig"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex"

import GenericDialog from "@/dialogs/GenericDialog"
import TermsOfServiceDialog from "@/dialogs/TermsOfServiceDialog"

export default {
  name: "App",
  components: {
    GenericDialog,
    TermsOfServiceDialog
  },
  data() {
    return {
      showTermsOfServiceDialog: false
    }
  },
  beforeMount() {
    this.$root.setLanguage(this.$store.getters.langCode || "en")
  },
  computed: {
    buttons() {
      return [
        {
          label: this.$t("termsOfService.agreeAndContinue"),
          action: () => {
            const loggedInUser = this.$store.getters.loggedInUser

            const updatedUser = {
              uid: loggedInUser.uid,
              tenantUid: loggedInUser.tenantUid,
              accepted: true
            }
            this.$store
              .dispatch("updateUserTerms", updatedUser)
              .then(() => {
                this.$router.push({ name: "home" }).catch()
              })
              .catch(() => {
                // Error already displayed by dispatched action.
              })
              .finally(() => {
                this.showTermsOfServiceDialog = false
              })
          }
        },
        {
          label: this.$t("common.actions.cancel"),
          action: () => {
            this.showTermsOfServiceDialog = false
            this.$store.dispatch("logout")
            return false
          }
        }
      ]
    },
    error() {
      return this.$store.getters.error
    },
    genericDialog() {
      return this.$store.getters.genericDialog
    },
    isUserLoggedIn() {
      return this.$store.getters.authToken !== null
    },
    termsConfig() {
      return {
        buttons: this.buttons
      }
    }
  },
  created() {
    if (
      this.$store.getters.loggedInUser &&
      this.$store.getters.loggedInUser.accepted
    ) {
      this.fetchData()
    }
  },

  methods: {
    ...mapActions(["setError"]),

    async fetchData() {
      try {
        if (!this.$store.getters.userDetails) {
          // No user details, which might be the case when the page is reloaded.
          await this.$store.dispatch("fetchUserDetails", {
            tenantUid: this.$store.getters.loggedInUser.tenantUid,
            uid: this.$store.getters.loggedInUser.uid
          })
        }
      } catch (error) {
        this.setError(error)
      }
    },
    dismissAlert() {
      this.$store.dispatch("clearError")
    }
  },
  watch: {
    isUserLoggedIn() {
      this.showTermsOfServiceDialog = false

      if (this.isUserLoggedIn) {
        // Check if user should approve license agreement and privacy policy.
        let user = this.$store.getters.loggedInUser

        if (user.accepted !== undefined && user.accepted !== null) {
          // User already agreed to the terms
          this.fetchData()
        } else {
          this.showTermsOfServiceDialog = true
        }
      } else {
        // User is not logged in, redirect to login page if not already there.
        if (this.$route.name !== "login") {
          this.$router
            .push({
              name: "login",
              params: { country: this.$store.getters.langCode }
            })
            .catch()
        }
      }
    }
  }
}
</script>

<style>
div.normalBackground.v-application {
  background-color: #737373;
}
@font-face {
  font-family: SairaMedium;
  src: url("/fonts/sairasemicondensed-medium.ttf");
}
@font-face {
  font-family: SairaRegular;
  src: url("/fonts/sairasemicondensed-regular.ttf");
}
@font-face {
  font-family: "MyBrokkDpf";
  src: url("/fonts/dpf-regular.ttf");
  font-weight: bolder;
  font-style: normal;
  font-display: block;
}
.saira-medium-font {
  font-family: SairaMedium;
}
.saira-regular-font {
  font-family: SairaRegular;
}
.dpf-regular-font {
  font-family: MyBrokkDpf;
}
</style>
