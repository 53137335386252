import localeDateFilter from "@/filters/localedatefilter"

function formatter(i18n) {

  function formatDays(numDays) {
    if (numDays !== undefined && numDays !== null) {
      if (numDays === 0) {
        return i18n && i18n.t ? i18n.t("common.enums.day.today"): "today"
      }

      if (numDays === 1) {
        return i18n && i18n.t ? i18n.t("common.enums.day.yesterday") : "yesterday"
      }
      return i18n && i18n.tc ? `${i18n.tc("common.units.daysAgo", numDays)}`: `${numDays} day(s) ago`
    }
    return ""
  }
  function formatDaysFromNow(date) {
    if (date !== undefined && date !== null) {
      const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      const startDate = new Date(date)
      const endDate = new Date()

      const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay))
      return this.formatDays(diffDays)
    }
    return ""
  }
  function formatLastSeen(machine) {
    if (machine.status === "running" || machine.status === "online") {
      return machine.status
    } else {
      return localeDateFilter(machine.lastSeen)
    }
  }
  function toHoursMinutesSeconds(timeInSeconds) {
    return new Date(timeInSeconds * 1000).toISOString().substr(11, 8)
  }
  function formatDate(date) {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return date.toISOString().substring(0, 10)
  }
  return {
    formatDays,
    formatDaysFromNow,
    formatLastSeen,
    toHoursMinutesSeconds,
    formatDate
  }
}
export default formatter
