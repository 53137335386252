const MAX_LENGTH = 100

const MIN_EMAIL_LENGTH = 5
const MAX_EMAIL_LENGTH = 254

const ATLEAST_ONE_LOWER_CASE_REGEX = /(?=.*?[a-z])/
const ATLEAST_ONE_UPPER_CASE_REGEX = /(?=.*?[A-Z])/
const ATLEAST_ONE_DIGIT_REGEX = /(?=.*?[0-9])/
const ATLEAST_ONE_SPECIAL_CHARACTER_REGEX = /(?=.*?[#?!@$%^&*-])/

function validationRules(i18n) {
  function checkStringLength(value) {
    if (value.length > 0 && value.length <= MAX_LENGTH) {
      return true
    }
    return i18n.t("common.validation.errors.fieldLengthBetween", {
      MIN: 1,
      MAX: MAX_LENGTH
    })
  }

  function checkStringContent(value) {
    // Check if there's a word character in the beginning
    if (/^\w/.test(value)) {
      return true
    }
    return i18n.t("common.validation.errors.fieldMustBeginWithWord")
  }

  function textField(value) {
    if (value !== undefined && value !== null) {
      let isOK = checkStringContent(value)

      if (isOK === true) {
        isOK = checkStringLength(value)
      }
      return isOK
    } else {
      return i18n.t("common.validation.errors.fieldIsRequired")
    }
  }

  return {
    required: value => {
      if (value === undefined || value === null || value.length === 0) {
        return i18n.t("common.validation.errors.fieldIsRequired")
      }
      return true
    },
    optional: value => {
      if (value !== undefined && value !== null && value.length > 0) {
        return textField(value)
      }
      return true
    },
    serialNo: value => textField(value),
    customerNo: value => {
      if (value === undefined || value === null || value.length === 0) {
        return true
      }
      const regEx = new RegExp(/^BC[0-9]{5}$/i)
      if (("" + value).match(regEx)) {
        return true
      }
      return i18n.t("common.validation.errors.customerNoInvalid")
    },
    monitorCustomerNo: value => {
      if (value === undefined || value === null || value.length === 0) {
        return true
      }
      const str = "" + value

      // 123456XX (landskod), Ex: 132101SE N-mek
      let regEx = new RegExp(/^[0-9]{6}[A-Z]{2}$/i)
      if (str.match(regEx)) {
        return true
      }
      // Ex: 276820IT-M Brokk Italia Srl (Maskiner)
      regEx = new RegExp(/^[0-9]{6}[A-Z]{2}-[A-Z]{1}$/i)
      if (str.match(regEx)) {
        return true
      }
      // EX: 101220FRLC Brokk France SAS (Maskiner)
      regEx = new RegExp(/^[0-9]{6}[A-Z]{4}$/i)
      if (str.match(regEx)) {
        return true
      }
      // 4-digit customer numbers in Monitor G5
      regEx = new RegExp(/^[0-9]{4}$/i)
      if (str.match(regEx)) {
        return true
      }
      return i18n.t("common.validation.errors.customerNoInvalid")
    },
    priceListId: value => {
      if (value === undefined || value === null || value.length === 0) {
        return true
      }
      const regEx = new RegExp(/^[A-Z]{2}[0-9]{2}$/i)
      if (("" + value).match(regEx)) {
        return true
      }
      return i18n.t("common.validation.errors.priceListIdInvalid")
    },
    discount: value => {
      if (value === undefined || value === null || value.length === 0) {
        return true
      }
      const regEx = new RegExp(/^[0-9]{0,3}$/i)
      if (("" + value).match(regEx)) {
        const n = Number.parseInt("" + value, 10)
        if (!Number.isNaN(n)) {
          if (n >= 0 && n <= 100) {
            return true
          }
        }
      }
      return i18n.t("common.validation.errors.discountInvalid")
    },
    name: value => {
      if (value !== undefined && value !== null) {
        let isOK = checkStringContent(value)

        if (isOK === true) {
          isOK = checkStringLength(value)
        }
        return isOK
      } else {
        return i18n.t("common.validation.errors.fieldIsRequired")
      }
    },
    email: value => {
      if (value === undefined || value === null || value.length === 0) {
        return true
      }
      if (
        value.length >= MIN_EMAIL_LENGTH &&
        value.length <= MAX_EMAIL_LENGTH
      ) {
        let parts = value.split("@")
        if (parts.length >= 2) {
          // local address and domain
          if (parts[0].length > 0) {
            let domains = parts[1].split(".") // domain should contain period
            if (domains.length >= 2) {
              if (domains[0].length > 0 && domains[1].length > 0) {
                return true
              }
            }
          }
        }
        return i18n.t("common.validation.errors.emailInvalid")
      }
      return i18n.t("common.validation.errors.emailLengthInvalid")
    },
    leaseHours: value => {
      if (value === undefined || value === null) {
        return true
      }
      if (value.length === 0) {
        // Empty is ok
        return true
      }
      if (value.length > 5) {
        return i18n.t("common.validation.errors.numericFieldLengthInvalid", {
          MIN: 1,
          MAX: 5
        })
      }
      const regEx = new RegExp(/^[1-9]{1}[0-9]{0,4}$/i)
      if (("" + value).match(regEx)) {
        return true
      }
      return i18n.t("common.validation.errors.numericFieldInvalid")
    },
    maxLimit: value =>
      value === undefined ||
      value === null ||
      value.length <= 50 ||
      i18n.t("common.validation.errors.maxNumberOfCharacters", { NUMBER: 50 }),
    mustMatch: (password1, password2) => {
      return password1 === password2
        ? true
        : i18n.t("common.validation.errors.passwordDoesNotMatch")
    },
    mustNotMatch: (password1, password2) => {
      return password1 !== password2
        ? true
        : i18n.t("common.validation.errors.passwordTheSameAsOldOne")
    },
    transferCode: value => {
      if (value !== undefined && value !== null) {
        // Regex for uuid v4 (RFC 4122), 16 bytes (octets), as 5 groups of hex-digits.
        const v4RegEx = new RegExp(
          /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
        )

        if (value.match(v4RegEx)) {
          return true
        }
        return i18n.t("common.validation.errors.transferCodeInvalid")
      }
      return i18n.t("common.validation.errors.transferCodeInvalid")
    },
    addEngineSerialNo: value => {
      if (value !== undefined && value !== null) {
        const regEx = new RegExp(/^9[0-9]{5}$/i)
        if (!value.match(regEx)) {
          return i18n.t("common.validation.errors.seriaNoInvalid")
        }
      }
      return true
    },
    serialNumberList: value => {
      if (value === undefined || value === null) {
        return true
      } else {
        const regex = /^(?:9\d{5}(?:-(9\d{5}))*(?:,9\d{5}(?:-(9\d{5}))*]*)*)$/
        const matches = value.match(regex)

        if (matches) {
          for (let i = 1; i < matches.length; i += 2) {
            const firstNumber = parseInt(matches[i - 1], 10)
            const secondNumber = parseInt(matches[i], 10)

            if (firstNumber >= secondNumber) {
              return i18n.t("common.validation.errors.seriaNoInvalid")
            }
          }
          return true
        }
      }
      return i18n.t("common.validation.errors.seriaNoInvalid")
    },
    engineHours: value => {
      if (value !== undefined && value !== null) {
        const regEx = new RegExp(/^(\s*|0|[1-9]{1}[0-9]{0,3})$/)

        if (!value.match(regEx)) {
          return i18n.t("common.validation.errors.engineHoursInvalid")
        }
      }
      return true
    },
    engineHoursService: value => {
      if (value !== undefined && value !== null) {
        const regEx = new RegExp(/^(0|[1-9]{1}[0-9]{0,3})$/)

        if (!value.match(regEx)) {
          return i18n.t("common.validation.errors.engineHoursInvalid")
        }
      }
      return true
    },
    isLatitude: latitude => {
      if (latitude && isFinite(latitude) && Math.abs(latitude) <= 90) {
        return true
      }
      return i18n.t("common.validation.errors.latitudeInvalid")
    },
    isLongitude: longitude => {
      if (longitude && isFinite(longitude) && Math.abs(longitude) <= 180) {
        return true
      }
      return i18n.t("common.validation.errors.longitudeInvalid")
    },
    between: (min, max) => {
      return value => {
        if (value && value >= min && value <= max) {
          return true
        }
        return i18n.t("common.validation.errors.betweenInvalid", {
          MIN: min,
          MAX: max
        })
      }
    },
    partNo: value => {
      const regEx = new RegExp(/^(?:\d{4} \d{4} \d{2}|\d{2}-\d{3})$/)
      if (("" + value).match(regEx)) {
        return true
      }
      return i18n.t("common.validation.errors.partNumberInvalid")
    },
    awsPasswordRequirements: password => {
      const check = !!password
      if (check) {
        if (password.length > +process.env.VUE_APP_PASSWORD_MAX_LENGTH) {
          return i18n.t("common.validation.errors.passwordTooLong")
        }
        if (password.length < +process.env.VUE_APP_PASSWORD_MIN_LENGTH) {
          return i18n.t("common.validation.errors.passwordTooShort")
        }
        if (
          process.env.VUE_APP_PASSWORD_LOWER_CASE &&
          !ATLEAST_ONE_LOWER_CASE_REGEX.test(password)
        ) {
          return i18n.t("common.validation.errors.missingLowerCaseInPassword")
        }
        if (
          process.env.VUE_APP_PASSWORD_UPPERCASE_CASE &&
          !ATLEAST_ONE_UPPER_CASE_REGEX.test(password)
        ) {
          return i18n.t("common.validation.errors.missingUpperCaseInPassword")
        }
        if (
          process.env.VUE_APP_PASSWORD_NUMBERS_CASE &&
          !ATLEAST_ONE_DIGIT_REGEX.test(password)
        ) {
          return i18n.t("common.validation.errors.missingDigitInPassword")
        }
        if (
          process.env.VUE_APP_PASSWORD_CHARACTERS_CASE &&
          !ATLEAST_ONE_SPECIAL_CHARACTER_REGEX.test(password)
        ) {
          return i18n.t("common.validation.errors.missingSpecialCharInPassword")
        }
        return true
      } else {
        return false
      }
    }
  }
}

export default validationRules
