<template>
  <v-card class="infobox-container">
    <div class="secondary text-center">
      <p class="text-uppercase text-h6">{{ title }}</p>
    </div>
    <div>
      <v-list class="infobox-list">
        <v-list-item
          v-for="item in info"
          :key="item.parameterId"
          class="float-left float-md-none"
        >
          <div
            class="black--text text-uppercase text-subtitle-1 font-weight-medium"
          >
            {{ item.description }}
          </div>
          <div class="mb-6">{{ item.value }}</div>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "InfoBox",
  props: ["title", "info"],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.infobox-container {
  min-height: 220px;
  position: relative;
  overflow: hidden;
  height: 100%;
}
.infobox-list {
  height: 100%;
}
.infobox-list div {
  display: block;
  min-width: 50%;
}
</style>
