<template>
  <v-card fluid pa-0 ma-0 flat outlined>
    <v-card-text class="pa-2 ma-0">
      <v-container v-if="!this.supportsNotifications">
        <v-overlay absolute color="#fff" opacity=".9" class="text--primary">
          <v-row>
            <v-col cols="10" offset="1">
              <div class="text-center">
                <v-icon color="grey darken-3">mdi-key-variant</v-icon>
              </div>
              <h1 class="title d-flex mb-5">
                <v-icon color="primary" class="mr-2"
                  >mdi-information-outline</v-icon
                >
                {{
                  $t("machines.location.geofencingSettings.messages.disabled")
                }}
              </h1>
              <p class="pl-8">
                {{ $t("machines.messages.contactToLearnMore") }}
                <a :href="mailToLink">{{ brokkEmail }}</a>
              </p>
            </v-col>
          </v-row>
        </v-overlay>
      </v-container>
      <v-container v-else-if="!currentMachineLocalization">
        <v-overlay absolute color="#fff" opacity=".9" class="text--primary">
        </v-overlay>
      </v-container>
      <v-card flat>
        <v-progress-linear
          :active="isLoading"
          :indeterminate="isLoading"
          absolute
          top
        ></v-progress-linear>
        <v-card-title class="py-0 my-0">
          {{ $t("machines.location.geofencingSettings.title") }}
          <v-spacer></v-spacer>
          <v-row class="align-center">
            <v-col class="d-flex justify-end" v-if="!editModeEnabled">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      icon
                      v-if="canReadGeofence"
                      @click="refreshGFSettings"
                      :loading="isLoading"
                      :disabled="isLoading || editModeEnabled"
                    >
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>{{ $t("common.actions.refresh") }}</span>
              </v-tooltip>
              <v-tooltip top v-if="canEditGeofence">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-icon
                      :disabled="editModeEnabled"
                      @click="editModeEnabled = true"
                      >mdi-pencil</v-icon
                    >
                  </span>
                </template>
                <span>{{
                  $t("machines.location.geofencingSettings.actions.edit")
                }}</span>
              </v-tooltip>
              <v-tooltip top v-if="gfSettingsDefined && canRemoveGeofence">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      icon
                      @click.stop="removeGFSettings = true"
                      :disabled="isLoading || editModeEnabled"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>{{
                  $t("machines.location.geofencingSettings.actions.remove")
                }}</span>
              </v-tooltip>
            </v-col>
            <v-col class="d-flex justify-end" v-if="editModeEnabled">
              <v-row>
                <v-spacer></v-spacer>
                <v-col class="pa-0 ma-0 shrink">
                  <v-btn
                    small
                    :loading="isLoading"
                    :disabled="isLoading || noActionDefined"
                    @click="onSaveGFSettings"
                    >{{ $t("common.actions.apply") }}
                  </v-btn>
                </v-col>
                <v-col
                  class="pa-0 ma-0 text-right"
                  :class="$vuetify.breakpoint.width < 1920 ? 'grow' : 'shrink'"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    class="ml-1"
                    small
                    :disabled="isLoading"
                    @click="cancelEdit"
                  >
                    {{ $t("common.actions.cancel") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle class="py-0 my-0">
          {{ $t("machines.location.geofencingSettings.description") }}
        </v-card-subtitle>
        <v-card-text class="py-0 my-0">
          <v-form
            v-if="machine !== undefined"
            ref="editGFSettingsForm"
            autocomplete="off"
            v-model="valid"
          >
            <v-row>
              <v-col cols="12" md="6" lg="4" class="pt-0 pb-0">
                <v-text-field
                  type="number"
                  @blur="forceReloadLatitude += 1"
                  :key="forceReloadLatitude"
                  v-model.number="latitude"
                  :label="
                    `${$t('machines.location.geofencingSettings.latitude')}*`
                  "
                  :disabled="true"
                  placeholder="-"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="pt-0 pb-0">
                <v-text-field
                  type="number"
                  v-model.number="longitude"
                  @blur="forceReloadLongitude += 1"
                  :key="forceReloadLongitude"
                  :label="
                    `${$t('machines.location.geofencingSettings.longitude')}*`
                  "
                  :disabled="true"
                  placeholder="-"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="4" class="d-none d-lg-flex">
                <v-subheader>{{
                  $t("machines.location.geofencingSettings.coordinates")
                }}</v-subheader>
              </v-col>
            </v-row>
            <v-row class="pt-0 pb-0">
              <v-col cols="12" lg="4" md="6" class="pt-0 pb-0">
                <v-text-field
                  type="number"
                  v-model.number="radius"
                  @blur="forceReloadRadius += 1"
                  :key="forceReloadRadius"
                  :label="
                    `${$t('machines.location.geofencingSettings.radius')}*`
                  "
                  :disabled="!editModeEnabled"
                  :placeholder="
                    $t('machines.location.geofencingSettings.radiusPlaceholder')
                  "
                  :rules="[validationRules.between(0.02, 1000)]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="4" md="6" class="pt-0 pb-0">
                <v-select
                  :items="distanceUnits"
                  v-model="unit"
                  :label="`${$t('machines.location.geofencingSettings.unit')}*`"
                  :disabled="!editModeEnabled"
                  :placeholder="
                    $t('machines.location.geofencingSettings.unitPlaceholder')
                  "
                  item-value="symbol"
                  item-text="symbol"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
          <v-alert
            v-model="noActionDefined"
            class="alert-message"
            elevation="2"
            dense
            type="info"
            :value="true"
          >
            <v-row align="center">
              <v-col class="grow py-0">
                {{
                  $t(
                    "machines.location.geofencingSettings.messages.anyLimitationOrNotificationRequired"
                  )
                }}
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
      </v-card>
      <v-row class="align-center">
        <v-col cols="12"> <v-divider></v-divider></v-col>
      </v-row>
      <v-card flat>
        <v-card-title class="py-0 my-0 mt-3">
          {{ $t("machines.location.geofencingSettings.notifications.title") }}
        </v-card-title>
        <v-card-text class="py-0 my-0">
          <div>
            <p>
              {{
                $t(
                  "machines.location.geofencingSettings.notifications.description"
                )
              }}
            </p>
          </div>
          <GeofencingNotifications
            ref="enterTriggerSettings"
            :trgSettings="this.notifications"
            :editModeEnabled="this.editModeEnabled"
          />
        </v-card-text>
      </v-card>
      <v-card flat outlined v-if="showLimitationsView">
        <v-card-title class="py-0 my-0 mt-3">
          <v-row>
            <v-col cols="12" class="py-0 my-0">
              {{ $t("machines.location.geofencingSettings.limit.title") }}
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
              <v-tabs
                v-model="tab"
                background-color="white"
                color="black accent-4"
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab v-for="tab of tabs" :key="tab.href" :href="tab.href">
                  <v-icon class="mr-2" small>{{ tab.icon }}</v-icon>
                  {{ tab.title }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="py-0 my-0">
          <v-row>
            <!-- geofencing tabs -->
            <v-col cols="12">
              <v-tabs-items v-model="tab">
                <!-- enter -->
                <v-tab-item value="enter" eager>
                  <GeofencingLimitations
                    ref="enterTriggerSettings"
                    :trgSettings="this.trgEnter"
                    :editModeEnabled="this.editModeEnabled"
                  />
                </v-tab-item>
                <!-- exit -->
                <v-tab-item value="exit" eager>
                  <GeofencingLimitations
                    ref="exitTriggerSettings"
                    :trgSettings="this.trgExit"
                    :editModeEnabled="this.editModeEnabled"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>

    <ConfirmRemoveDialog
      :itemName="$t('machines.location.geofencingSettings.title')"
      confirmRemoveEvent="confirmEvent"
      :show="removeGFSettings"
      @close="removeGFSettings = false"
      v-on:confirmEvent="onRemoveGFSettings"
    />
    <!-- TODO: Uncomment when Limitations are enabled -->
    <!-- GeofencingApplyConfirmDialog ref="geofencingApplyConfirmDialog" / -->

    <v-snackbar
      v-model="snackbar"
      :timeout="1500"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import ValidationRules from "@/utils/validationrules"
import distanceUnits from "@/utils/distance"

import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"
import GeofencingNotifications from "@/components/GeofencingNotifications"
import GeofencingLimitations from "@/components/GeofencingLimitations"

// TODO: Uncomment when Limitations are enabled -->
// import GeofencingApplyConfirmDialog from "@/dialogs/GeofencingApplyConfirmDialog"

let validationRules = null

export default {
  name: "GfSettings",
  props: ["machine", "editGeofence"],
  components: {
    GeofencingNotifications,
    GeofencingLimitations,
    ConfirmRemoveDialog
    // GeofencingApplyConfirmDialog
  },
  data() {
    return {
      tab: "enter",
      validationRules: validationRules,
      valid: false,
      removeGFSettings: false,

      showLimitationsView: false, // TODO: Feature toggle to disable Limitations.

      notifications: {
        enterRecipients: [],
        exitRecipients: []
      },

      trgEnter: { limitations: [] },
      trgExit: { limitations: [] },

      //These fields ensure that input field is trimmed(rounded) to the proper number on blur
      forceReloadLatitude: 0,
      forceReloadLongitude: 0,
      forceReloadRadius: 0,

      snackbar: false,
      snackbarText: ""
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  created() {
    this.fetchData()
  },
  computed: {
    ...mapGetters([
      "canReadGeofence",
      "canEditGeofence",
      "canRemoveGeofence",
      "currentMachineLocalization"
    ]),

    noActionDefined() {
      if (this.isLoading || !this.editModeEnabled) {
        return false
      }
      if (this.latitude || this.longitude) {
        const anyRecipientDefined =
          this.notifications.enterRecipients?.length ||
          this.notifications.exitRecipients?.length
        const anyLimitationDefined =
          this.trgEnter.limitations?.length || this.trgExit.limitations?.length
        if (!anyRecipientDefined && !anyLimitationDefined) {
          return true
        }
      }
      return false
    },
    supportsNotifications() {
      return this.$store.getters.machineGeofencingSettings?.capabilities
        ?.notifications
    },
    gfSettingsDefined() {
      return this.gfSettings?.desiredOptions !== null
    },
    brokkEmail() {
      return this.$store.getters.brokkEmail
    },
    mailToLink() {
      return `mailto:${this.brokkEmail}?subject=Brokk Connect: Geofencing settings for ${this.machine.productName} with serial number ${this.machine.serialNo}`
    },
    tabs() {
      return [
        {
          href: "#enter",
          icon: "mdi-map-marker-down",
          title: this.$t("machines.location.geofencingSettings.triggers.enter")
        },
        {
          href: "#exit",
          icon: "mdi-map-marker-right",
          title: this.$t("machines.location.geofencingSettings.triggers.exit")
        }
      ]
    },
    isLoading() {
      return this.$store.getters.loadingMachineGeofencingSettings
    },
    gfSettings() {
      return this.$store.getters.machineGeofencingSettings
    },
    distanceUnits() {
      return [distanceUnits.mile, distanceUnits.kilometer]
    },
    latitude: {
      get() {
        return this.$store.getters.geofenceAreaLatitude
      },
      set(val) {
        this.$store.dispatch("setGeofenceAreaLatitude", val)
      }
    },
    longitude: {
      get() {
        return this.$store.getters.geofenceAreaLongitude
      },
      set(val) {
        this.$store.dispatch("setGeofenceAreaLongitude", val)
      }
    },
    radius: {
      get() {
        let radius = this.$store.getters.geofenceAreaRadius
        if (!radius) {
          return radius
        }
        return distanceUnits.fromMeter(radius, this.unit, true)
      },
      set(val) {
        let unit = this.unit || "km"
        let result = null
        if (val) {
          result = distanceUnits.toMeter(val, unit)
        }
        this.$store.dispatch("setGeofenceAreaRadius", result)
      }
    },
    unit: {
      get() {
        return this.$store.getters.geofenceAreaUnit
      },
      set(val) {
        this.$store.dispatch("setGeofenceAreaUnit", val)
      }
    },
    editModeEnabled: {
      get() {
        const editMode = this.$store.getters.geofenceAreaEditMode
        this.$emit("update:editGeofence", editMode)
        return editMode
      },
      set(val) {
        this.$store.dispatch("setGeofenceAreaEditMode", val)
      }
    },
    geofenceAreaSave: {
      get() {
        return this.$store.getters.geofenceAreaSave
      },
      set(val) {
        this.$store.dispatch("setGeofenceAreaSave", val)
      }
    }
  },
  watch: {
    async geofenceAreaSave() {
      if (this.geofenceAreaSave) {
        await this.onSaveGFSettings()
      }
      this.geofenceAreaSave = false
    },
    gfSettings() {
      let radius = this.gfSettings?.desiredOptions?.radius || 300
      if (this.gfSettings !== null) {
        this.notifications = {
          enterRecipients: this.gfSettings.enterRecipients,
          exitRecipients: this.gfSettings.exitRecipients
        }
        this.trgEnter = {
          limitations: this.bitMaskToArray(
            this.gfSettings?.desiredOptions?.enterRestrictions ?? []
          )
        }
        this.trgExit = {
          limitations: this.bitMaskToArray(
            this.gfSettings?.desiredOptions?.exitRestrictions ?? []
          )
        }
        if (
          !this.trgEnter.limitations.length &&
          this.trgExit.limitations.length
        ) {
          this.tab = "exit"
        }
      } else {
        // Set default values
        this.latitude = null
        this.longitude = null
        this.radius = radius
        this.trgEnter = {
          limitations: []
        }
        this.trgExit = {
          limitations: []
        }
      }
    }
  },
  methods: {
    bitMaskToArray(bitMask) {
      const bitValues = [1, 2, 4, 8, 16]
      return bitValues.filter(bv => bv & bitMask)
    },
    arrayToBitMaskInt(array) {
      const bitsCount = 5
      const bitMask = Math.pow(2, bitsCount) - 1
      return array.reduce((sum, x) => sum + (bitMask & x), 0)
    },
    fetchData() {
      this.$store.dispatch("fetchMachineGeofencingSettings", {
        machine: this.machine
      })
    },
    refreshGFSettings() {
      this.fetchData()
    },
    cancelEdit() {
      this.editModeEnabled = false
      this.refreshGFSettings()
    },
    async onSaveGFSettings() {
      if (!this.$refs.editGFSettingsForm.validate()) {
        return
      }
      if (this.noActionDefined) {
        return
      }

      if (this.showLimitationsView) {
        const confirm = await this.$refs.geofencingApplyConfirmDialog.open()
        if (!confirm) {
          return
        }
      }
      let saveDate = {
        latitude: this.latitude,
        longitude: this.longitude,
        radius: distanceUnits.round(
          this.$store.getters.geofenceAreaRadius ?? 0,
          this.unit
        ),
        radiusUnit: this.unit,
        enterRestrictions: this.arrayToBitMaskInt(
          this.trgEnter.limitations || []
        ),
        exitRestrictions: this.arrayToBitMaskInt(
          this.trgExit.limitations || []
        ),
        enterRecipients: this.notifications.enterRecipients,
        exitRecipients: this.notifications.exitRecipients
      }

      await this.$store
        .dispatch("saveMachineGeofencingSettings", {
          machine: this.machine,
          geofencingSettings: saveDate
        })
        .then(() => {
          this.snackbarText = this.$t(
            "machines.location.geofencingSettings.messages.settingsSaved"
          )
          this.snackbar = true
          this.cancelEdit()
        })
        .catch(() => {
          // Error already displayed
        })
    },
    onRemoveGFSettings() {
      this.$store
        .dispatch("removeMachineGeofencingSettings", this.machine)
        .then(() => {
          this.snackbarText = this.$t(
            "machines.location.geofencingSettings.messages.settingsRemoved"
          )
          this.snackbar = true
          this.editModeEnabled = false
          this.removeGFSettings = false // Hide the delete button
          this.fetchData()
        })
        .catch(() => {
          // Error already displayed by sendEmailNotification.
        })
    }
  }
}
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.chip-font {
  font-size: 12px;
  height: auto;
  white-space: normal;
  text-indent: 0px;
  line-height: 1;
}
</style>
