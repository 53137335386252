<template>
  <v-container fluid class="pa-0 ma-0 full-height">
    <v-card v-if="locationDataMissing" flat outlined class="full-height">
      <v-card-text class="full-height">
        <v-row class="full-height" align="center" justify="center">
          <v-col cols="11" sm="9">
            <h1 class="title d-flex mb-5">
              <v-icon color="primary" class="mr-2"
                >mdi-information-outline</v-icon
              >
              {{ $t("$vuetify.noDataText") }}
            </h1>
            <p class="pl-8">
              {{ $t("machines.system.noDataDescription") }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="!locationDataMissing">
      <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        absolute
        top
      ></v-progress-linear>
      <div id="locationMap"></div>
    </v-card>
    <v-card v-if="!locationDataMissing" flat>
      <v-card-text class="ma-0 pa-0 grab-location-show-history">
        <v-row>
          <v-col>
            <v-checkbox
              class="ma-0 pa-0"
              hide-details
              v-model="showHistory"
              :label="$t('machines.location.location.showHistory')"
            ></v-checkbox>
          </v-col>
        </v-row>
        <TimeRange
          v-show="showHistory"
          :canEdit="showHistory"
          :return-value.sync="historyDates"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import { initialize } from "@/utils/googlemapsadapter"
import EditableCircle from "@/utils/googlemaps/editablecircle"
import TimeRange from "@/components/filters/TimeRange"

export default {
  props: ["machine"],
  components: { TimeRange },
  data() {
    return {
      showHistory: false,
      historyDates: [],
      //Map objects
      map: null,
      polilyne: null,
      marker: null,
      circle: null,
      drawingManager: null,

      isMapInitialized: false,
      defaultCenterPosition: { lat: 64.7448271, lng: 21.0342396 },
      currentLocation: null,

      locationDataMissing: false
    }
  },
  async created() {
    await this.$store.dispatch("fetchCurrentMachineLocalization", this.machine)
    if (!this.currentMachineLocalization) {
      this.locationDataMissing = true
      return
    }

    this.fetchMachineLocalizationHistory()
    this.configureMap()
  },
  computed: {
    ...mapGetters([
      "canReadGeofence",
      "canEditGeofence",
      "currentMachineLocalization"
    ]),

    locationHistoryCoordinates() {
      return this.showHistory
        ? this.$store.getters.machineLocalizationHistory
        : null
    },
    isLoading() {
      return (
        this.$store.getters.loadingMachineLocalizationHistory ||
        this.$store.getters.loadingMachineGeofencingSettings
      )
    },
    latitude() {
      return this.$store.getters.geofenceAreaLatitude
    },
    longitude() {
      return this.$store.getters.geofenceAreaLongitude
    },
    radius() {
      return this.$store.getters.geofenceAreaRadius
    },
    unit() {
      return this.$store.getters.geofenceAreaUnit
    },
    geofenceAreaIsUpdating() {
      return this.$store.getters.geofenceAreaIsUpdating
    },
    editModeEnabled: {
      get() {
        return this.$store.getters.geofenceAreaEditMode
      },
      set(val) {
        this.$store.dispatch("setGeofenceAreaEditMode", val)
      }
    },
    geofenceAreaSettings() {
      return {
        lat: this.latitude,
        lng: this.longitude,
        radius: this.radius,
        editModeEnabled: this.editModeEnabled
      }
    },
    supported() {
      return this.$store.getters.machineGeofencingSettings?.capabilities
        ?.notifications
    }
  },
  watch: {
    geofenceAreaIsUpdating: function() {
      if (!this.geofenceAreaIsUpdating) {
        this.setGeofenceArea(
          this.geofenceAreaSettings.lat,
          this.geofenceAreaSettings.lng,
          this.geofenceAreaSettings.radius,
          this.geofenceAreaSettings.editModeEnabled
        )
      }
    },
    geofenceAreaSettings: function() {
      this.setGeofenceArea(
        this.geofenceAreaSettings.lat,
        this.geofenceAreaSettings.lng,
        this.geofenceAreaSettings.radius,
        this.geofenceAreaSettings.editModeEnabled
      )
      this.updateDrawingManager()
    },
    machine: function() {
      this.configureMap()
    },
    locationHistoryCoordinates: function() {
      this.updateHistory()
    },
    historyDates: function() {
      if (!this.showHistory) {
        return
      }
      this.fetchMachineLocalizationHistory()
    },
    showHistory: function() {
      if (this.showHistory) {
        this.fetchMachineLocalizationHistory()
      }
      this.updateHistory()
    },
    isLoading: function() {
      if (!this.isLoading && !this.isMapInitialized) {
        this.configureMap()
      }
      this.setGeofenceArea(
        this.geofenceAreaSettings.lat,
        this.geofenceAreaSettings.lng,
        this.geofenceAreaSettings.radius,
        this.geofenceAreaSettings.editModeEnabled
      )
    },
    supported() {
      this.updateDrawingManager()
    },
    canEditGeofence: function() {
      this.updateDrawingManager()
    }
  },
  methods: {
    onCircleEdit(args) {
      if (this.$store.getters.geofenceAreaIsUpdating) {
        return
      }
      this.$store.dispatch("updateGeofenceArea", args)
    },
    fetchMachineLocalizationHistory() {
      if (
        this.historyDates?.length == 2 &&
        this.historyDates[0] &&
        this.historyDates[1]
      ) {
        const params = {
          tenantUid: this.machine.tenantUid,
          serialNo: this.machine.serialNo,
          start: this.historyDates[0],
          end: this.historyDates[1]
        }
        this.$store.dispatch("fetchMachineLocalizationHistory", params)
      }
    },
    updateHistory() {
      if (!this.map) {
        return
      }
      const google = window.google
      if (this.polilyne) {
        this.polilyne.setMap(null)
        this.polilyne = null
      }
      if (this.showHistory && this.locationHistoryCoordinates) {
        this.polilyne = new google.maps.Polyline({
          path: this.locationHistoryCoordinates,
          geodesic: true,
          strokeColor: "#000000",
          strokeOpacity: 0.5,
          strokeWeight: 2
        })
        this.polilyne.setMap(this.map)
      }
    },
    setGeofenceArea(lat, lng, radius, editMode) {
      if (
        this.$store.getters.loadingMachineGeofencingSettings ||
        this.$store.getters.geofenceAreaIsUpdating ||
        !this.canReadGeofence
      ) {
        return
      }
      if (!lat || !lng || !radius) {
        this.removeGeofenceArea()
        return
      }
      if (
        this.circle &&
        this.circle.lat == lat &&
        this.circle.lng == lng &&
        this.circle.radius == radius
      ) {
        this.circle.setEditable(editMode)
        return
      }
      if (!this.circle) {
        this.circle = new EditableCircle(this.$i18n)
      }
      this.circle.setCenter({ lat, lng })
      this.circle.setRadius(radius)
      this.circle.setMap(this.map, this.onCircleEdit)
      this.circle.setEditable(editMode)
    },
    removeGeofenceArea() {
      if (this.circle) {
        this.circle.setEditable(false)
        this.circle.setMap(null)
      }
      const position = this?.marker?.getPosition()
      if (position) {
        this?.map.setCenter(position)
      }
    },
    saveGeofenceArea() {
      if (this.editModeEnabled) {
        this.$store.dispatch("setGeofenceAreaSave", true) //It is saved on the GeofencingSettings.vue
      }
    },
    updateDrawingManager() {
      if (!this.isMapInitialized) {
        return
      }
      if (this.supported && this.canEditGeofence) {
        this.addDrawingManager()
      } else {
        this.drawingManager?.setMap(null)
      }
    },
    addDrawingManager() {
      const google = window.google

      let drawingManager = this.drawingManager
      if (!drawingManager) {
        drawingManager = new google.maps.drawing.DrawingManager({
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.CIRCLE]
          },
          circleOptions: EditableCircle.internalCircleOptions
        })
        this.drawingManager = drawingManager

        let omitNextDrawingModeEvent = false
        let vm = this

        google.maps.event.addListener(
          drawingManager,
          "circlecomplete",
          function(event) {
            // Get circle center and radius
            var center = event.getCenter()
            var radius = event.getRadius()

            // Remove overlay from map
            event.setMap(null)
            omitNextDrawingModeEvent = true
            drawingManager.setDrawingMode(null)
            vm.editModeEnabled = true
            vm.onCircleEdit({
              latitude: center.lat(),
              longitude: center.lng(),
              radius: radius,
              unit: this.unit ?? "km"
            })
          }
        )

        google.maps.event.addListener(
          drawingManager,
          "drawingmode_changed",
          function() {
            if (omitNextDrawingModeEvent) {
              omitNextDrawingModeEvent = false
              return
            }
            if (!vm.circle) {
              return
            }
            if (!drawingManager.getDrawingMode()) {
              vm.saveGeofenceArea()
            } else {
              if (vm.circle.map) {
                vm.circle.setEditable(true)
                vm.editModeEnabled = true
                omitNextDrawingModeEvent = true
                drawingManager.setDrawingMode(null)
              }
            }
          }
        )
      }

      drawingManager.setMap(this.map)
    },
    async configureMap() {
      if (!this.machine || this.isLoading || this.locationDataMissing) {
        return
      }
      this.isMapInitialized = false

      try {
        await initialize()
        const google = window.google

        const mapOptions = {
          zoom: 12,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.RIGHT_TOP
          }
        }

        const map = new google.maps.Map(
          document.getElementById("locationMap"),
          mapOptions
        )
        this.map = map

        let position = this.defaultCenterPosition

        if (this.currentMachineLocalization !== null) {
          position = new google.maps.LatLng(
            this.currentMachineLocalization.lat,
            this.currentMachineLocalization.lng
          )

          this.marker = new google.maps.Marker({
            position: position,
            icon: "/marker.png",
            map: map,
            title: this.$t("machines.location.map.machineLocation")
          })
        }

        this.updateHistory()
        this.updateDrawingManager()

        const centerPos = position || this.defaultCenterPosition

        map.setCenter(centerPos)
      } catch (error) {
        console.error(error)
      }
      this.isMapInitialized = true
    }
  }
}
</script>

<style>
.full-height {
  height: 100%;
}

#locationMap {
  height: 60vh;
}
/*The following styles overides default styles for a circle component on the map (2nd for touch devices)*/
#locationMap
  div[style^="position: absolute; left: 0px; top: 0px; width: 9px; height: 9px;"],
#locationMap
  div[style^="position: absolute; left: 9px; top: 9px; width: 9px; height: 9px;"] {
  border-color: white !important;
  border-width: 1px !important;
  background-color: #444444 !important;
}
</style>
