<template>
  <v-dialog :value="value" @input="input" width="600">
    <v-card v-if="items">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">
              {{
                $t(
                  "machines.location.dialogs.extraInfo.dataTable.headers.propertyCategory"
                )
              }}
            </th>
            <th class="text-left">
              {{
                $t(
                  "machines.location.dialogs.extraInfo.dataTable.headers.propertyName"
                )
              }}
            </th>
            <th class="text-left">
              {{
                $t(
                  "machines.location.dialogs.extraInfo.dataTable.headers.propertyValue"
                )
              }}
            </th>
          </tr>
        </thead>

        <tbody class="extra-info-dialog__body">
          <tr
            class="extra-info-dialog__row"
            v-for="(info, index) in items"
            :key="index"
          >
            <td>{{ info.category }}</td>
            <td>{{ info.name }}</td>
            <td>{{ info.value }}</td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="input(false)">
          {{ $t("common.actions.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["items", "value"],
  methods: {
    input(open) {
      this.$emit("input", open)
    }
  }
}
</script>

<style>
.extra-info-dialog__body .extra-info-dialog__row {
  cursor: default;
}
</style>
