<template>
  <v-card>
    <v-skeleton-loader
      v-if="loading"
      type="list-item-three-line, list-item-two-line"
    ></v-skeleton-loader>
    <div v-else>
      <v-card-title>
        <v-list-item class="text-center" :href="href">
          <v-list-item-content class="py-0">
            <v-list-item-title
              class="text-uppercase text-h2 font-weight-regular"
            >
              {{ value }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <v-card-subtitle>
        <v-list-item class="text-center">
          <v-list-item-content class="py-0">
            <v-list-item-title
              class="text-uppercase text-subtitle-2 font-weight-light"
            >
              {{ title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-subtitle>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["title", "value", "href", "loading"],
  computed: {}
}
</script>

<style></style>
