class LanguageUtils {
  static defaultLang() {
    return LanguageUtils.supported[0]
  }
  static getName = code =>
    LanguageUtils.supported.filter(l => l.code == code).map(l => l.name)[0]
  static isSupported = country =>
    !!LanguageUtils.supported.filter(l => l.country == country).length
  static getFromLocalStorage() {
    let langCode = localStorage.getItem("langCode")
    if (!langCode || !LanguageUtils.isSupported(langCode)) {
      return LanguageUtils.defaultLang().code
    }
    return langCode
  }
  static saveToLocalStorage(langCode) {
    localStorage.setItem("langCode", langCode)
  }
  static getSupportedLanguageCode(country) {
    const lang = LanguageUtils.supported.filter(l => l.country == country)
    if (lang.length > 0) {
      return lang[0].code
    } else {
      return LanguageUtils.getFromLocalStorage()
    }
  }
}

LanguageUtils.supported = [
  { country: "us", code: "en", name: "English" },
  { country: "uk", code: "en", name: "English" },
  { country: "de", code: "de", name: "Deutsch" },
  { country: "ch", code: "de", name: "Deutsch" },
  { country: "fr", code: "fr", name: "Francais" },
  { country: "it", code: "it", name: "Italiano" },
  { country: "es", code: "es", name: "Español" }
]

export default LanguageUtils
