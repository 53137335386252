import fetchClient from "@/utils/fetchClient"

/* eslint-disable */

export default {
  state: {
    rental: null,
    loadingRental: false
  },
  mutations: {
    setRental(state, payload) {
      state.rental = payload
    },
    setLoadingRental(state, payload) {
      state.loadingRental = payload
    }
  },
  actions: {
    fetchRental(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingRental", true)
        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${params.tenantUid}/machines/${params.serialNo}/rental`
          )
          .then(data => {
            const rental = data
            rental.tenantUid = params.tenantUid
            rental.serialNo = params.serialNo
            context.commit("setRental", rental)
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingRental", false)
          })
      })
    },
    saveLease(context, rental) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingRental", true)

        fetchClient(this.state.$i18n)
          .put(
            `/api/v1/tenants/${rental.tenantUid}/machines/${rental.serialNo}/rental/lease`,
            rental.lease
          )
          .then(() => {
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingRental", false)
          })
      })
    },
    removeLease(context, rental) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingRental", true)

        fetchClient(this.state.$i18n)
          .delete(
            `/api/v1/tenants/${rental.tenantUid}/machines/${rental.serialNo}/rental/lease`
          )
          .then(() => {
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingRental", false)
          })
      })
    },
    saveRentalNotification(context, rental) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingRental", true)

        fetchClient(this.state.$i18n)
          .put(
            `/api/v1/tenants/${rental.tenantUid}/machines/${rental.serialNo}/rental/notifications`,
            rental.notification
          )
          .then(() => {
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingRental", false)
          })
      })
    },
    removeRentalNotification(context, rental) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingRental", true)

        fetchClient(this.state.$i18n)
          .delete(
            `/api/v1/tenants/${rental.tenantUid}/machines/${rental.serialNo}/rental/notifications`
          )
          .then(() => {
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingRental", false)
          })
      })
    }
  },
  getters: {
    rental(state) {
      return state.rental
    },
    loadingRental(state) {
      return state.loadingRental
    }
  }
}
