export default {
  users: {
    actions: {
      add: "Ajouter un utilisateur",
      edit: "Modifier l'utilisateur",
      export:
        "Exportation de tous les utilisateurs vérifiés qui ont accès à la boutique en ligne"
    },
    details: {
      accountVerified: "Compte vérifié",
      name: "Nom",
      title: "Détails de l'utilisateur"
    },
    dialogs: {
      edit: {
        descriptionPlaceholder: "Entrez la description",
        emailPlaceholder: "Entrez l'adresse e-mail",
        familyName: "Nom de famille",
        familyNamePlaceholder: "Entrez le nom de famille",
        fleetAnalyticsAccess: null,
        givenName: "Prénom",
        givenNamePlaceholder: "Entrez le prénom",
        messages: {
          userAdded: "L'utilisateur a été ajouté",
          userExport: "Exportation réussie des utilisateurs",
          userUpdated: "L'utilisateur a été mis à jour"
        },
        rolePlaceholder: "Sélectionnez le rôle",
        webshopAccess: "Rôle d’accès à la boutique en ligne"
      }
    },
    itemAttributes: {
      description: "Description",
      email: "E-mail",
      fleetAnalyticsAccess: null,
      lastLogin: "Dernière connexion",
      name: "Nom",
      role: "Rôle",
      tenant: "Locataire",
      webshopRole: "Rôle de la boutique en ligne"
    },
    itemName: "utilisateur",
    settings: {
      menuItems: {
        notifications: "Notifications",
        preferences: "Préférences",
        profile: "Profil",
        settings: "Réglages"
      },
      notifications: {
        maintenance: "Entretien",
        otherNotifications: "Autres notifications",
        rental: "Location",
        saveSuceeded: "Les modifications ont été enregistrées",
        sendWeeklyReports: "Envoyer des rapports hebdomadaires",
        sendWeeklyReportsDesc:
          "Envoyez un rapport avec le statut de tous vos robots Brokk une fois par semaine.",
        title: "Notifications"
      },
      preferences: {
        description:
          "Ici, vous pouvez définir vos propres préférences en fonction des paramètres par défaut de l'application Web comme la langue, les unités, la page d'accueil par défaut, etc.",
        initialView: "Vue initiale",
        units: "Unités"
      },
      profile: {
        title: "Profil de l'utilisateur"
      }
    }
  }
}
