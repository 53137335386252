const DEFAULT_DISTANCE_UNIT = "km"

const ratioToMeter = {
  kilometer: 1000.0,
  foot: 0.3048,
  mile: 1609.344
}

function round(num, digits) {
  const divider = Math.pow(10, digits)
  return Math.round(num * divider) / divider
}

let distanceUnits = {
  meter: {
    symbol: "m",
    name: "meter",
    decimalDigits: 0, //Number of decimal digits for unit (UI)
    meterDecimalDigits: 0, //Number of decimal digits for meters saved to the database
    toMeter: value => value * 1.0,
    fromMeter: value => value
  },
  kilometer: {
    symbol: "km",
    name: "kilometer",
    decimalDigits: 2, //Number of decimal digits for unit (UI)
    meterDecimalDigits: 0, //Number of decimal digits for meters saved to the database
    toMeter: value => value * ratioToMeter.kilometer,
    fromMeter: value => value / ratioToMeter.kilometer
  },
  foot: {
    symbol: "ft",
    name: "foot",
    decimalDigits: 0, //Number of decimal digits for unit (UI)
    meterDecimalDigits: 1, //Number of decimal digits for meters saved to the database
    toMeter: value => value * ratioToMeter.foot,
    fromMeter: value => value / ratioToMeter.foot
  },
  mile: {
    symbol: "mi",
    name: "mile",
    decimalDigits: 2, //Number of decimal digits for unit (UI)
    meterDecimalDigits: 0, //Number of decimal digits for meters saved to the database
    toMeter: value => value * ratioToMeter.mile,
    fromMeter: value => value / ratioToMeter.mile
  }
}

distanceUnits.items = [
  distanceUnits.foot,
  distanceUnits.mile,
  distanceUnits.meter,
  distanceUnits.kilometer
]
distanceUnits.fromMeter = (value, symbol, roundDistance) => {
  if (!value || !symbol) {
    return value
  }
  let unit = distanceUnits.items.find(i => i.symbol == symbol)
  if (unit) {
    let distance = unit.fromMeter(value)
    if (roundDistance) {
      distance = round(distance, unit.decimalDigits)
    }
    return distance
  }
  return value
}

distanceUnits.toMeter = (value, symbol) => {
  if (!value || !symbol) {
    return value
  }
  let unit = distanceUnits.items.find(i => i.symbol == symbol)
  if (unit) {
    return unit.toMeter(value)
  }
  return value
}

distanceUnits.find = symbol =>
  distanceUnits.items.find(i => i.symbol == (symbol || DEFAULT_DISTANCE_UNIT))

distanceUnits.round = (distanceInMeters, unitSymbol) => {
  const unit = distanceUnits.find(unitSymbol)
  let distance = unit.fromMeter(distanceInMeters)
  distance = round(distance, unit.decimalDigits) //Round to get proper unit value e.g. for miles we allow to have one decimal digit so e.g. 1,654 km is rounded to 1,6 km (1600 m)
  distance = unit.toMeter(distance)
  return round(distance, unit.meterDecimalDigits) //Round decimal digits in meters saved to the database. E.g. in case of feet we allow to save one decimal digit: 1 feet = 0.3, 2 feets = 0.7 etc.
}

Object.freeze(distanceUnits)

export default distanceUnits
