<template>
  <v-card flat outlined class="ma-4">
    <v-card-title>
      {{ $t("machines.maintenance.serviceAgreement.title") }}
      <v-tooltip top v-if="isExpired">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon color="warning">mdi-alert</v-icon>
          </span>
        </template>
        <span>{{
          $t("machines.maintenance.serviceAgreement.warnings.isExpired")
        }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip top v-if="canEditItem">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon :disabled="showEdit" @click="showEdit = true">{{
              isNewItem ? "mdi-plus-circle" : "mdi-pencil"
            }}</v-icon>
          </span>
        </template>
        <span>{{
          $t(
            isNewItem
              ? "machines.maintenance.serviceAgreement.actions.add"
              : "machines.maintenance.serviceAgreement.actions.edit"
          )
        }}</span>
      </v-tooltip>

      <v-tooltip top v-if="!isNewItem && editedItem.filename">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon :disabled="showEdit" @click="downloadItem"
              >mdi-download</v-icon
            >
          </span>
        </template>
        <span>{{ $t("common.actions.download") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="!isNewItem">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon :disabled="showEdit" @click="removeItem">mdi-delete</v-icon>
          </span>
        </template>
        <span>{{ $t("common.actions.remove") }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-form ref="serviceAgreementForm" v-model="valid" :disabled="!showEdit">
        <v-row>
          <!-- Start date -->
          <v-col cols="12" sm="6" class="pb-0">
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="customFields.startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="customFields.startDate"
                  required
                  :label="
                    $t('machines.maintenance.serviceAgreement.labels.startDate')
                  "
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[validationRules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="customFields.startDate"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startDateMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startDateMenu.save(customFields.startDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" class="pb-0">
            <!-- End date -->
            <v-menu
              ref="endDateMenu"
              v-model="endDateMenu"
              :close-on-content-click="false"
              :return-value.sync="customFields.endDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="customFields.endDate"
                  required
                  :label="
                    $t('machines.maintenance.serviceAgreement.labels.endDate')
                  "
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[validationRules.required]"
                ></v-text-field>
              </template>
              <v-date-picker v-model="customFields.endDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="endDateMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endDateMenu.save(customFields.endDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!-- Description -->
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="editedItem.description"
              required
              :label="
                $t('machines.maintenance.serviceAgreement.labels.description')
              "
              :placeholder="
                $t('machines.maintenance.serviceAgreement.labels.description')
              "
              :rules="[validationRules.optional]"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- File name -->
        <v-row v-if="!isNewItem && editedItem.filename">
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="editedItem.filename"
              disabled
              required
              :label="
                $t('machines.maintenance.serviceAgreement.labels.filename')
              "
              :placeholder="
                $t('machines.maintenance.serviceAgreement.labels.filename')
              "
              :rules="[validationRules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- File -->
        <v-row v-if="isNewItem">
          <v-col cols="12" class="pb-0">
            <v-file-input
              v-model="customFields.file"
              accept=".pdf"
              required
              :placeholder="
                `${$t(
                  'machines.maintenance.serviceAgreement.labels.fileuploadLabel'
                )}`
              "
              :label="
                `${$t(
                  'machines.maintenance.serviceAgreement.labels.uploadFilePlaceholder'
                )}*`
              "
            ></v-file-input>
          </v-col>
        </v-row>
        <!-- Errors -->
        <v-row>
          <v-col cols="12" class="py-0">
            <v-alert
              v-if="error"
              :type="error.type"
              class="ma-0"
              elevation="2"
              border="left"
              colored-border
              dense
              dismissible
            >
              <div>{{ error.message }}</div>
              <div v-if="error.causedBy" class="subtitle">
                {{ error.causedBy }}
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions v-if="showEdit" class="pr-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        :disabled="!valid || isUpdating"
        :loading="isUpdating"
        @click.stop="onSaveClick"
      >
        {{ $t("common.actions.save") }}
      </v-btn>
      <v-btn :disabled="isUpdating" @click.stop="resetForm()"
        >{{ $t("common.actions.cancel") }}
      </v-btn>
    </v-card-actions>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <ConfirmRemoveDialog
      :itemName="editedItem.filename"
      :item="machine"
      :show="showRemoveDialog"
      confirmRemoveEvent="confirmRemove"
      v-on:confirmRemove="onConfirmRemove()"
    />
  </v-card>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import fetchClient from "@/utils/fetchClient"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"
import { mapGetters } from "vuex"

let validationRules = null

export default {
  components: {
    ConfirmRemoveDialog
  },
  props: ["machine"],
  data() {
    return {
      valid: false,
      validationRules: validationRules,
      showEdit: false,
      isUpdating: false,
      error: null,
      snackbar: false,
      snackbarText: "",
      isNewItem: true,
      editedItem: {},
      startDateMenu: false,
      endDateMenu: false,
      customFields: {
        startDate: "",
        endDate: "",
        file: null
      },
      downloadingFiles: 0,
      showRemoveDialog: false,
      wasRemoveClicked: false
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    ...mapGetters(["serviceAgreement"]),
    canEditItem() {
      return this.$store.getters.canEditMaintenance
    },
    isExpired() {
      return !this.isNewItem && new Date(this.editedItem.endDate) < new Date()
    }
  },
  mounted() {
    this.editedItem = Object.assign({}, this.serviceAgreement)
    if (this.serviceAgreement) {
      this.customFields.startDate = this.editedItem.startDate.substring(0, 10)
      this.customFields.endDate = this.editedItem.endDate.substring(0, 10)
      this.isNewItem = false
    }
  },
  watch: {
    startDateMenu() {
      this.checkDateOrder()
    },
    endDateMenu() {
      this.checkDateOrder()
    },
    serviceAgreement(val) {
      if (!val && (!this.isNewItem || this.wasRemoveClicked)) {
        this.wasRemoveClicked = false
        this.isNewItem = true
        this.customFields = {
          startDate: "",
          endDate: "",
          file: null
        }
        this.showEdit = false
        this.editedItem = {}
      }
    }
  },
  methods: {
    checkDateOrder() {
      if (
        this.customFields.startDate &&
        this.customFields.endDate &&
        this.customFields.startDate > this.customFields.endDate
      ) {
        const temp = this.customFields.endDate
        this.customFields.endDate = this.customFields.startDate
        this.customFields.startDate = temp
      }
    },
    async onSaveClick() {
      if (!this.$refs.serviceAgreementForm.validate()) {
        return
      }

      this.error = null

      this.isUpdating = true

      this.editedItem.startDate = new Date(
        this.customFields.startDate
      ).toISOString()
      this.editedItem.endDate = new Date(
        this.customFields.endDate
      ).toISOString()

      // TODO move to store
      await this.updateOrAdd(this.editedItem)
    },
    async updateOrAdd(item) {
      let url = `/api/v1/tenants/${this.machine.tenantUid}/machines/${this.machine.serialNo}/maintenance/agreement`
      try {
        if (this.isNewItem) {
          if (this.customFields.file) {
            item.filename = this.customFields.file.name
          }
          await fetchClient(this.$i18n).postOrThrow(url, item)
          this.snackbarText = this.$t(
            "machines.maintenance.serviceAgreement.messages.added"
          )

          // Upload the file to AWS S3 into the documents bucket.
          // The first part of the S3 path will be used as the prefix to AWS Amplify,
          // which by default will upload files to a 'public' folder unless configured differently.
          if (this.customFields.file) {
            await fetchClient(this.$i18n).awsPutOrThrow(
              this.getS3Path(item.filename),
              this.customFields.file
            )
          }
        } else {
          await fetchClient(this.$i18n).putOrThrow(url, item)
          this.snackbarText = this.$t(
            "machines.maintenance.serviceAgreement.messages.updated"
          )
        }

        this.$store.commit("setServiceAgreement", Object.assign({}, item))
        this.snackbar = true
        this.showEdit = false
        this.isNewItem = false
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    },
    getS3Path(filename) {
      return `machines/${this.machine.serialNo}/service/agreements/${filename}`
    },
    resetForm() {
      this.editedItem = Object.assign({}, this.serviceAgreement)

      this.customFields = {
        startDate: this.editedItem?.startDate?.substring(0, 10) ?? "",
        endDate: this.editedItem?.endDate?.substring(0, 10) ?? "",
        file: null
      }
      this.error = null
      this.showEdit = false
    },
    async downloadItem() {
      if (!this.editedItem.locked) {
        this.downloadingFiles += 1
        this.editedItem.locked = true

        let errorData = null
        let downloadPath = ""
        downloadPath = `/api/v1/downloads/${this.getS3Path(
          this.editedItem.filename
        )}`
        try {
          const response = await fetchClient(this.$i18n).getResponse(
            "GET",
            downloadPath
          )

          if (response.status === 200) {
            const responseData = await response.arrayBuffer()
            const blob = new Blob([responseData], {
              type: response.headers.get("content-type")
            })

            let link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = this.editedItem.filename
            link.click()
          } else {
            errorData = await fetchClient(this.$i18n).getError(response)
          }
        } catch (error) {
          errorData = {
            message: this.$t(
              "machines.maintenance.serviceAgreement.messages.failedToDownload",
              {
                file: downloadPath
              }
            ),
            causedBy: error.toString(),
            details: error.message
          }
        } finally {
          this.editedItem.locked = false
          this.downloadingFiles -= 1

          if (errorData != null) {
            errorData.requestMethod = "GET"
            errorData.requestUri = downloadPath

            const err = {
              response: {
                data: errorData
              }
            }
            this.$store.dispatch("setError", err)
          }
        }
      }
    },
    async removeItem() {
      this.showRemoveDialog = true
    },
    async onConfirmRemove() {
      try {
        await this.$store.dispatch("removeServiceAgreement", this.machine)
        this.showRemoveDialog = false

        setTimeout(
          () => {
            this.snackbarText = this.$t(
              "machines.maintenance.serviceAgreement.messages.deleted"
            )
            this.snackbar = true
          },
          this.snackbar ? 2000 : 0
        )

        this.$store.commit("setServiceAgreement", null)
        this.resetForm()
        this.isNewItem = true
      } catch (error) {
        this.error = { type: "error", ...error }
      }
    }
  }
}
</script>
