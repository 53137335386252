<template>
  <v-dialog v-model="visible" max-width="600" persistent>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title class="d-flex align-center">
        <v-icon class="accent--text mr-2">mdi-plus-circle</v-icon>
        <span class="title accent--text"
          >{{ $t("machines.dialogs.addMachine.title") }}
        </span>
      </v-toolbar-title>
    </v-toolbar>

    <v-stepper v-model="stepNo">
      <v-stepper-header>
        <v-stepper-step :complete="stepNo > 1" step="1">
          {{ $t("machines.dialogs.addMachine.steps.overview") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepNo > 2" step="2">
          {{ $t("machines.dialogs.addMachine.steps.confirm") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepNo == 3" step="3">
          {{ $t("machines.dialogs.addMachine.steps.summary") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- ------------------ STEP 1 ------------------ -->

        <v-stepper-content step="1">
          <v-card flat>
            <v-card-title>{{
              $t("machines.dialogs.addMachine.enterMachineInformationTitle")
            }}</v-card-title>
            <v-card-text class="primary--text">
              <v-form ref="addMachineForm" autocomplete="off" v-model="valid">
                <v-text-field
                  class="mt-2"
                  v-model="serialNo"
                  required
                  :label="`${$t('machines.dialogs.addMachine.serialNo')}*`"
                  :placeholder="
                    $t('machines.dialogs.addMachine.enterserialNoTitle')
                  "
                  :rules="[validationRules.addEngineSerialNo]"
                  :error-messages="errorMsg"
                ></v-text-field>

                <v-text-field
                  class="mt-2"
                  v-model="engineHours"
                  :label="`${$t('machines.dialogs.addMachine.engineHours')}`"
                  :placeholder="
                    $t('machines.dialogs.addMachine.enterEngineHoursTitle')
                  "
                  :rules="[validationRules.engineHours]"
                ></v-text-field>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="fetchProductId"
              :loading="loadingAddMachineInfo"
              :disabled="loadingAddMachineInfo"
            >
              {{ $t("common.actions.next") }}
            </v-btn>
            <v-btn class="grab-cancel-machine-add" @click="onCancel">
              {{ $t("common.actions.cancel") }}
            </v-btn>
          </v-card-actions>
        </v-stepper-content>

        <!-- ------------------ STEP 2 ------------------ -->

        <v-stepper-content step="2">
          <v-card flat>
            <v-card-title>{{
              $t("machines.dialogs.addMachine.confirmAddMachineTitle")
            }}</v-card-title>
            <v-card-text class="primary--text" v-if="addInfo !== null">
              <p v-if="this.addInfo.products.length > 1">
                {{
                  $t("machines.dialogs.addMachine.confirmAddMachineProceedInfo")
                }}
              </p>
              <p v-else>
                {{
                  $t(
                    "machines.dialogs.addMachine.confirmAddMachineProceedInfoNext"
                  )
                }}
              </p>
              <v-form
                ref="selectMachineModelForm"
                autocomplete="off"
                v-model="valid"
              >
                <v-radio-group v-model="selectedProductId">
                  <v-row
                    v-for="machineModel in addInfo.products"
                    :key="machineModel.productId"
                  >
                    <v-col cols="4">
                      <v-radio
                        v-if="addInfo.products.length > 1"
                        :label="`Brokk ${machineModel.productId}`"
                        :value="machineModel.productId"
                      >
                      </v-radio>
                      <p v-else>Brokk {{ machineModel.productId }}</p>
                    </v-col>
                    <v-col cols="4">
                      <img
                        :src="
                          '/products/jpg/' + machineModel.productId + '.jpg'
                        "
                        :alt="machineModel.productId"
                        class="machine-avatar"
                      />
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-form>
              <v-alert
                v-if="errorMsg.length > 0"
                class="ma-0 mt-4"
                type="error"
                elevation="2"
                border="left"
                colored-border
                dense
              >
                {{ errorMsg }}
              </v-alert>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-btn
              :disabled="addingMachine"
              @click=";(stepNo = 1), (errorMsg = '')"
            >
              {{ $t("common.actions.previous") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :loading="addingMachine"
              @click="onSave"
              :disabled="errorMsg.length > 0"
            >
              {{ $t("common.actions.next") }}
            </v-btn>
            <v-btn :disabled="addingMachine" @click="onCancel">{{
              $t("common.actions.cancel")
            }}</v-btn>
          </v-card-actions>
        </v-stepper-content>

        <!-- ------------------ STEP 3 ------------------ -->

        <v-stepper-content step="3">
          <v-card flat>
            <v-card-title>{{
              $t("machines.dialogs.addMachine.steps.summary")
            }}</v-card-title>
            <v-card-text class="primary--text" v-if="addInfo !== null">
              <p v-if="errorMsg.length > 0">{{ errorMsg }}</p>
              <p v-else>
                {{
                  $t("machines.dialogs.addMachine.addMachineSucceededText", {
                    machine: this.selectedProductId,
                    sn: this.serialNo
                  })
                }}
              </p>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="onCancel">{{ $t("common.actions.close") }}</v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import fetchClient from "@/utils/fetchClient"
import ValidationRules from "@/utils/validationrules"
let validationRules = null

export default {
  name: "GuidedMachineAdd",
  props: ["tenantUid", "show"],
  data() {
    return {
      stepNo: 1,
      valid: false,
      serialNo: "",
      engineHours: "",
      selectedProductId: "",

      validationRules: validationRules,
      errorMsg: "",

      addInfo: null,
      loadingAddMachineInfo: false,
      addingMachine: false
    }
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(value) {
        if (!value) {
          this.$emit("cancel")
        }
      }
    }
  },
  watch: {
    show() {
      this.stepNo = 1
      this.valid = false
      this.serialNo = ""
      this.engineHours = ""
      this.errorMsg = ""
      this.selectedProductId = ""

      this.addInfo = null
      this.loadingAddMachineInfo = false
      this.addingMachine = false
    },
    serialNo() {
      this.errorMsg = ""
    }
  },
  methods: {
    async fetchProductId() {
      if (!this.$refs.addMachineForm.validate()) {
        return
      }

      this.loadingAddMachineInfo = true
      this.errorMsg = ""

      const url = `/api/v1/metadata/products/en?serialNo=${this.serialNo}`

      try {
        this.addInfo = await fetchClient(this.$i18n).getDataOrThrow(url)

        if (this.addInfo.products.length === 0) {
          this.errorMsg = this.$t(
            "machines.dialogs.addMachine.errors.noSuchserialNo"
          )
        } else {
          this.selectedProductId = this.addInfo.products[0].productId
          this.stepNo = 2
        }
      } catch (error) {
        this.setError(error)
      }

      this.loadingAddMachineInfo = false
    },
    onSave() {
      if (this.addInfo !== null) {
        this.addingMachine = true

        this.errorMsg = ""

        this.$store
          .dispatch("addMachine", {
            tenantUid: this.$route.params.tenantUid,
            serialNo: this.serialNo,
            productId: this.selectedProductId,
            engineHours: this.engineHours
          })
          .then(() => {})
          .catch(errMsg => {
            this.errorMsg = this.$t(
              "machines.dialogs.addMachine.errors.couldNotAddMachine",
              { msg: errMsg }
            )
          })
          .finally(() => {
            this.addingMachine = false
            if (this.errorMsg.length === 0) {
              this.stepNo = 3
            }
          })
      } else {
        return
      }
      this.$refs.addMachineForm.reset()
    },
    onCancel() {
      this.$refs.addMachineForm.reset()
      this.visible = false
    }
  }
}
</script>

<style>
.gm-style img.machine-avatar {
  max-width: 150px;
}
</style>
