<template>
  <v-card flat tile class="py-3 pr-3">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-end">
        <LanguageSelect />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-badge
              color="error"
              offset-x="16"
              offset-y="18"
              :content="numUnreadMessages"
              :value="showMessageBadge"
            >
              <v-btn
                v-on="on"
                icon
                link
                href="#/messages"
                class="grab-messages-menu ml-2"
              >
                <v-icon dark>mdi-bell</v-icon>
              </v-btn>
            </v-badge>
          </template>
          {{ $t("mainMenu.messages") }}
        </v-tooltip>

        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="grab-account-menu-btn mx-2"
            >
              <v-icon dark>mdi-account</v-icon>
            </v-btn>
          </template>
          <ProfileMenu />
        </v-menu>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import LanguageSelect from "@/components/LanguageSelect"
import ProfileMenu from "@/components/ProfileMenu"

export default {
  props: ["numUnreadMessages"],
  components: {
    LanguageSelect,
    ProfileMenu
  },
  data() {
    return {}
  },
  computed: {
    showMessageBadge() {
      return this.numUnreadMessages > 0
    }
  }
}
</script>

<style></style>
