<template>
  <v-card tile color="details_background">
    <v-card-title>
      <div class="title">
        {{ $t("documents.serviceDocuments.machineSerials") }}
      </div>
    </v-card-title>
    <v-card-text class="accent--text">
      <v-row class="ml-0">
        <v-col cols="12" sm="4">
          <div class="font-weight-medium pb-0 mb-0">
            <li v-for="item in filteredItems" :key="item.id">
              Brokk {{ item.productIds }}, {{ item.serials }}
            </li>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    items: {
      type: Array
    },
    partNo: {
      type: String
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.partNo === this.partNo)
    }
  }
}
</script>
