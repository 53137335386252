export default {
  home: {
    documents: {
      description: "Die vollständige Bibliothek aller unserer Brokk-Modelle."
    },
    greeting: "Herzlich willkommen bei My Brokk",
    machines: {
      description: "Informationen über Ihre Brokk-Geräte anzeigen.",
      register: "Gerät registrieren"
    },
    webshop: {
      description: "Ersatzteile können in unserem Webshop erworben werden.",
      noAccess: {
        description:
          "Wenden Sie sich an Ihren lokalen Administrator, um mehr zu erfahren.",
        title: "Kein Webshopzugang gewährt"
      }
    }
  }
}
