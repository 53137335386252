const SEVERITY_UNKNOWN = -1

function findMaxAlarmSeverity(machine) {
  let maxVal = 0 // Default: no alarms

  if (machine.status === "offline" || machine.unprovisioned) {
    maxVal = SEVERITY_UNKNOWN
  } else {
    if (machine.activeEvents !== undefined) {
      machine.activeEvents.forEach(event => {
        if (event.severity > maxVal) {
          maxVal = event.severity
        }
      })
    }
  }
  return maxVal
}

function findMaxServiceSeverity(machine) {
  if (machine.unprovisioned) {
    // unknown
    return SEVERITY_UNKNOWN
  }
  if (machine.serviceStatus.includes("overdue")) {
    return 5
  }

  if (machine.serviceStatus.includes("pending")) {
    return 4
  }

  return SEVERITY_UNKNOWN
}

function formatTooltip(machine, alarmSeverity, serviceSeverity) {
  const maxSeverity = Math.max(alarmSeverity, serviceSeverity)
  if (maxSeverity === SEVERITY_UNKNOWN) {
    return `${machine.status}: Unknown`
  }

  if (maxSeverity === 0) {
    return `${machine.status}: Healthy`
  }

  const parts = []

  if (serviceSeverity !== SEVERITY_UNKNOWN) {
    let serviceStatus = "pending"
    if (serviceSeverity === 5) {
      serviceStatus = "overdue"
    }

    parts.push(`Service ${serviceStatus}`)
  }

  if (alarmSeverity > 0 && machine.activeEvents !== undefined) {
    const codes = machine.activeEvents.map(event => event.parameterId)

    let label = "Info"
    if (alarmSeverity === 5) {
      label = "Error"
    } else if (alarmSeverity >= 3) {
      label = "Warning"
    }

    parts.push(`${label} (${codes.join(", ")})`)
  }

  return `${machine.status}: ${parts.join(", ")}`
}

function severityLabel(severity) {
  let label = ""

  switch (severity) {
    case SEVERITY_UNKNOWN:
      // Don't know if there are active alarms since machine is offline.
      label = "unknown"
      break
    case 0:
      // No severity since there are no active alarms.
      label = "ok"
      break
    case 1:
      // Info
      label = "1-info"
      break
    case 2:
      // Info
      label = "2-info"
      break
    case 3:
      // Warning.
      label = "3-info"
      break
    case 4:
      // Warning (machine may stop)
      label = "4-warning"
      break
    case 5:
      // Severe error, machine stops (interlocked)
      label = "5-error"
      break
    default:
      break
  }

  return label
}

function severityInfo(machine) {
  const alarmSeverity = findMaxAlarmSeverity(machine)
  const serviceSeverity = findMaxServiceSeverity(machine)
  const maxSeverity = Math.max(alarmSeverity, serviceSeverity)

  let tooltip = formatTooltip(machine, alarmSeverity, serviceSeverity)

  let { iconName, colorName } = severityIcon(maxSeverity, false)

  return { iconName, colorName, tooltip }
}

function severityIcon(severity, isForEvent) {
  let iconName = ""
  let colorName = ""

  switch (severity) {
    case SEVERITY_UNKNOWN:
      // Don't know if there are active alarms since machine is offline.
      iconName = "mdi-lightning-bolt-circle"
      colorName = "primary"
      break
    case 0:
      // No severity since there are no active alarms.
      iconName = "mdi-check-circle"
      colorName = "success"
      break
    case 1:
      // Info
      iconName = "mdi-information"
      colorName = isForEvent ? "info" : "success"
      break
    case 2:
      // Info
      iconName = isForEvent ? "mdi-information" : "mdi-alert-circle"
      colorName = "info"
      break
    case 3:
      // Warning.
      iconName = "mdi-alert-rhombus"
      colorName = "warning"
      break
    case 4:
      // Warning (machine may stop)
      iconName = "mdi-alert" // triangle
      colorName = "orange"
      break
    case 5:
      // Severe error, machine stops (interlocked)
      iconName = "mdi-alert-octagon" // similar to stop sign
      colorName = "error"
      break
    default:
      break
  }
  return { iconName, colorName }
}

export default {
  level(machine) {
    const alarmSeverity = findMaxAlarmSeverity(machine)
    const serviceSeverity = findMaxServiceSeverity(machine)
    return Math.max(alarmSeverity, serviceSeverity)
  },
  eventIcon(severity) {
    let { iconName } = severityIcon(severity, true)
    return iconName
  },
  eventColor(severity) {
    let { colorName } = severityIcon(severity, true)
    return colorName
  },
  icon(machine) {
    let { iconName } = severityInfo(machine)
    return iconName
  },
  color(machine) {
    let { colorName } = severityInfo(machine)
    return colorName
  },
  tooltip(machine) {
    let { tooltip } = severityInfo(machine)
    return tooltip
  },
  label(severity) {
    let label = severityLabel(severity)
    return label
  }
}
