export default {
  users: {
    actions: {
      add: "Aggiungi utente",
      edit: "Modifica utente",
      export:
        "Esporta tutti gli utenti verificati che hanno accesso al negozio online"
    },
    details: {
      accountVerified: "Account verificato",
      name: "Nome",
      title: "Dettagli utente"
    },
    dialogs: {
      edit: {
        descriptionPlaceholder: "Inserisci la descrizione",
        emailPlaceholder: "Inserisci indirizzo e-mail",
        familyName: "Cognome",
        familyNamePlaceholder: "Inserisci il cognome",
        fleetAnalyticsAccess: null,
        givenName: "Nome attribuito",
        givenNamePlaceholder: "Inserisci il nome attribuito",
        messages: {
          userAdded: "L'utente è stato aggiunto",
          userExport: "Utenti esportati correttamente",
          userUpdated: "L'utente è stato aggiornato"
        },
        rolePlaceholder: "Seleziona ruolo",
        webshopAccess: "Ruolo di accesso al negozio online"
      }
    },
    itemAttributes: {
      description: "Descrizione",
      email: "E-mail",
      fleetAnalyticsAccess: null,
      lastLogin: "Ultimo login",
      name: "Nome",
      role: "Ruolo",
      tenant: "Locatario",
      webshopRole: "Ruolo negozio online"
    },
    itemName: "utente",
    settings: {
      menuItems: {
        notifications: "Notifiche",
        preferences: "Preferenze",
        profile: "Profilo",
        settings: "Impostazioni"
      },
      notifications: {
        maintenance: "Manutenzione",
        otherNotifications: "Altre notifiche",
        rental: "Noleggio",
        saveSuceeded: "Le modifiche sono state salvate",
        sendWeeklyReports: "Invia rapporti settimanali",
        sendWeeklyReportsDesc:
          "Invia un rapporto con lo stato di tutti i tuoi robot Brokk una volta alla settimana.",
        title: "Notifiche"
      },
      preferences: {
        description:
          "Qui puoi definire le tue preferenze per le impostazioni predefinite delle applicazioni web come Lingua, Unità, Home page predefinita e così via.",
        initialView: "Visualizzazione iniziale",
        units: "Unità"
      },
      profile: {
        title: "Profilo utente"
      }
    }
  }
}
