import fetchClient from "@/utils/fetchClient"
import {
  webshopGuest,
  webshopUser,
  webshopPartner,
  webshopCorporate,
  webshopAdmin
} from "./webshop_roles"

/* eslint-disable */

export default {
  state: {
    eventMetadata: [],
    maintenanceMetadata: [],
    measurementMetadata: [],
    parameterMetadata: [],
    productMetadata: [],
    roleMetadata: [],
    specialFuncsMetadata: [],
    segmentMetadata: []
  },
  mutations: {
    setEventMetadata(state, payload) {
      state.eventMetadata = payload
    },
    setMaintenanceMetadata(state, payload) {
      state.maintenanceMetadata = payload
    },
    setMeasurementMetadata(state, payload) {
      state.measurementMetadata = payload
    },
    setParameterMetadata(state, payload) {
      state.parameterMetadata = payload
    },
    setProductMetadata(state, payload) {
      state.productMetadata = payload
    },
    setRoleMetadata(state, payload) {
      state.roleMetadata = payload
    },
    setSpecialFuncsMetadata(state, payload) {
      state.specialFuncsMetadata = payload
    },
    setSegmentMetadata(state, payload) {
      state.segmentMetadata = payload
    }
  },
  actions: {
    loadEventMetadata({ commit }, languageCode) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .get(`/api/v1/metadata/events/${languageCode}`)
          .then(data => {
            commit("setEventMetadata", data.events)
            resolve(data.events)
          })
          .catch(error => {
            commit("setError", error)
            reject(error)
          })
          .finally(() => {
            commit("setLoading", false)
          })
      })
    },

    loadMaintenanceMetadata({ commit }, languageCode) {
      commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .get(`/api/v1/metadata/maintenance/${languageCode}`)
        .then(data => {
          commit("setMaintenanceMetadata", data.maintenance)
        })
        .catch(error => {
          commit("setError", error)
        })
        .finally(() => {
          commit("setLoading", false)
        })
    },

    loadMeasurementMetadata({ commit }, languageCode) {
      commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .get(`/api/v1/metadata/measurements/${languageCode}`)
        .then(data => {
          commit("setMeasurementMetadata", data.measurements)
        })
        .catch(error => {
          commit("setError", error)
        })
        .finally(() => {
          commit("setLoading", false)
        })
    },

    loadParameterMetadata({ commit }, languageCode) {
      commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .get(`/api/v1/metadata/parameters/${languageCode}`)
        .then(data => {
          commit("setParameterMetadata", data.parameters)
        })
        .catch(error => {
          commit("setError", error)
        })
        .finally(() => {
          commit("setLoading", false)
        })
    },

    loadProductMetadata({ commit }, languageCode) {
      commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .get(`/api/v1/metadata/products/${languageCode}`)
        .then(data => {
          commit("setProductMetadata", data.products)
        })
        .catch(error => {
          commit("setError", error)
        })
        .finally(() => {
          commit("setLoading", false)
        })
    },

    loadRoleMetadata({ commit }, languageCode) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .get(`/api/v1/metadata/roles/${languageCode}`)
          .then(data => {
            commit("setRoleMetadata", data.roles)
            resolve(data.roles)
          })
          .catch(error => {
            commit("setError", error)
            reject(error)
          })
          .finally(() => {
            commit("setLoading", false)
          })
      })
    },
    loadSpecialFuncsMetadata({ commit }, languageCode) {
      commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .get(`/api/v1/metadata/specialfuncs/${languageCode}`)
        .then(data => {
          commit("setSpecialFuncsMetadata", data.specialfuncs)
        })
        .catch(error => {
          commit("setError", error)
        })
        .finally(() => {
          commit("setLoading", false)
        })
    },
    loadSegmentMetadata({ commit }, languageCode) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .get(`/api/v1/metadata/segments/${languageCode}`)
          .then(data => {
            commit("setSegmentMetadata", data.segments)
            resolve(data.roles)
          })
          .catch(error => {
            commit("setError", error)
            reject(error)
          })
          .finally(() => {
            commit("setLoading", false)
          })
      })
    }
  },
  getters: {
    eventMetadata(state) {
      return state.eventMetadata
    },
    maintenanceMetadata(state) {
      return state.maintenanceMetadata
    },
    measurementMetadata(state) {
      return state.measurementMetadata
    },
    parameterMetadata(state) {
      return state.parameterMetadata
    },
    productMetadata(state) {
      return state.productMetadata
    },
    roleMetadata(state) {
      return state.roleMetadata
    },
    allowedRoles(state) {
      // Returns a function that filters the available roles based on what role the user has.
      // e.g. a 'local admin' will not see 'admin' nor 'service'
      return userRoleId => {
        let allowedRoles = state.roleMetadata.filter(
          role => role.roleId >= userRoleId
        )
        allowedRoles.sort((a, b) => a.roleId - b.roleId)
        return allowedRoles
      }
    },
    allowedWebshopRoles(state) {
      const AdminRoleId = 100
      const LocalAdminRoleId = 200

      return userRoleId => {
        if (userRoleId === AdminRoleId) {
          return [
            "none",
            webshopGuest(),
            webshopUser(),
            webshopPartner(),
            webshopCorporate(),
            webshopAdmin()
          ]
        } else if (userRoleId === LocalAdminRoleId) {
          return ["none", webshopGuest(), webshopUser(), webshopPartner()]
        }
        return []
      }
    },
    specialFuncsMetadata(state) {
      return state.specialFuncsMetadata
    },
    segmentMetadata(state) {
      return state.segmentMetadata
    }
  }
}
