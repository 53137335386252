<template>
  <v-card class="pa-0 pb-4 ma-4" color="white">
    <v-card-title class="text-uppercase">
      {{ $t("users.settings.menuItems.profile") }}
    </v-card-title>
    <v-card-subtitle class="empty-space"></v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col>
          <UserDetails :user="userProfile" :title="null" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click.stop="showChangePasswordDialog = true">
            <v-icon left>mdi-lock-reset</v-icon>
            {{ $t("login.changePassword") }}...
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn @click.stop="onSignOut">
            <v-icon left>mdi-logout</v-icon>
            {{ $t("login.signOut") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            text
            href="https://www.brokk.com/privacy-policy/"
            target="_blank"
          >
            {{ $t("termsOfService.privacyPolicy") }}
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
          <v-btn text href="/docs/terms-of-service.html" target="_blank">
            {{ $t("termsOfService.title") }}
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>

          <!-- <a
            style="display:inline-flex"
            target="_blank"
            class="mx-2 text-no-wrap"
            href="https://www.brokk.com/privacy-policy/"
            >{{ $t("termsOfService.privacyPolicy") }}</a
          >
          <a
            style="display:inline-flex"
            target="_blank"
            class="mx-2 text-no-wrap"
            href="/docs/terms-of-service.html"
            >{{ $t("termsOfService.title") }}</a
          > -->
        </v-col>
      </v-row>
    </v-card-text>
    <ChangePasswordDialog v-model="showChangePasswordDialog" />
  </v-card>
</template>

<script>
import ChangePasswordDialog from "@/dialogs/ChangePasswordDialog"
import UserDetails from "@/components/UserDetails"

export default {
  components: {
    ChangePasswordDialog,
    UserDetails
  },
  data() {
    return {
      user: {},
      showChangePasswordDialog: false,
      onChangePassword: false
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    this.fetchUserDetails()
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    },
    userProfile() {
      const profile = Object.assign({}, this.user)

      profile.roleName = this.loggedInUser.roleName
      profile.webshopRole = this.loggedInUser.webshopRole
      profile.accepted = this.loggedInUser.accepted
      profile.lastLogin = new Date(1000 * this.loggedInUser.auth_time)
      profile.tenantName = this.$store.getters.tenantRoot?.name

      return profile
    }
  },
  watch: {
    $route: "fetchData"
  },
  methods: {
    fetchData() {
      if (this.$route.query.change_password === null) {
        this.showChangePasswordDialog = true
      }
    },
    async fetchUserDetails() {
      await this.$store.dispatch("fetchUserDetails", {
        tenantUid: this.loggedInUser.tenantUid,
        uid: this.loggedInUser.uid
      })
      const res = this.$store.getters.userDetails

      if (res != null) {
        this.user = res
      }
    },
    onSignOut() {
      this.$store.dispatch("logout")
    }
  }
}
</script>

<style>
.empty-space::after {
  content: " ";
  font-size: 0;
  white-space: pre;
}
</style>
