let intervalUnits = {
  second: {
    value: "second",
    text: "second",
    toMinute: v => v * 60,
    fromSeconds: v => v,
    toSeconds: v => v
  },
  minute: {
    value: "minute",
    text: "minute",
    toMinute: v => v,
    fromSeconds: v => v / 60,
    toSeconds: v => v * 60
  },
  hour: {
    value: "hour",
    text: "hour",
    toMinute: v => v / 60,
    fromSeconds: v => v / 60 / 60,
    toSeconds: v => v * 60 * 60
  }
}

intervalUnits.items = [
  intervalUnits.second,
  intervalUnits.minute,
  intervalUnits.hour
]

intervalUnits.fromSeconds = (value, symbol) => {
  let unit = intervalUnits.items
    .filter(i => i.value == symbol)
    .reduce(() => true)
  if (unit) {
    return unit.fromSeconds(value)
  }
  return value
}

intervalUnits.toSeconds = (value, symbol) => {
  let unit = intervalUnits.items
    .filter(i => i.value == symbol)
    .reduce(() => true)
  if (unit) {
    return unit.toSeconds(value)
  }
  return value
}

Object.freeze(intervalUnits)

export default intervalUnits
