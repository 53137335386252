<template>
  <v-dialog :value="value" @input="input" width="600">
    <v-card v-if="item">
      <v-simple-table>
        <thead>
        <tr>
          <th class="text-left">{{$t("machines.alarms.dialogs.extraInfo.dataTable.headers.category")}}</th>
          <th class="text-left">{{$t("machines.alarms.dialogs.extraInfo.dataTable.headers.description")}}</th>
          <th class="text-left">{{$t("machines.alarms.dialogs.extraInfo.dataTable.headers.value")}}</th>
        </tr>
        </thead>

        <tbody class="extra-info-dialog__body">
        <tr
          class="extra-info-dialog__row"
          v-for="(info, index) in items"
          :key="index"
        >
          <td>{{ info.category }}</td>
          <td>{{ info.description }}</td>
          <td>{{ info.value }}</td>
        </tr>
        </tbody>
      </v-simple-table>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer/>
        <v-btn text color="primary" @click="input(false)">
          {{$t("common.actions.close")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex"

const PARAM_REGEX = /(?<key>.+)=(?<value>.+)/
const EXTRA_PARAMS = {
  "OC": "occurrence counter",
}

export default {
  props: ["item", "value"],

  computed: {
    ...mapGetters(["measurementMetadata"]),

    items() {
      const rows = []

      if (!this.item) {
        return rows
      }

      const items = this.item.extraData.split("|")
      for (const data of items) {
        const row = this.formatRow(data)
        rows.push(row)
      }

      return rows
    },
  },

  methods: {
    input(open) {
      this.$emit("input", open)
    },

    formatRow(data) {
      // Check if this is a "parameter" (key=val)
      const match = PARAM_REGEX.exec(data)
      if (!match) {
        return {
          category: "–",
          description: data.replace(/_/g, " ").toLowerCase(),
          value: "–",
        }
      }

      const {key, value} = match.groups

      // Some parameters are not measurements so we'll se if we have them first
      if (Object.prototype.hasOwnProperty.call(EXTRA_PARAMS, key)) {
        return {
          category: "–",
          description: EXTRA_PARAMS[key],
          value: value,
        }
      }

      // Check if we can match against measurement metadata
      const meta = this.measurementMetadata.find((meta) => {
        return meta.parameterId === key
      })

      if (meta) {
        return {
          category: meta.category,
          description: meta.description,
          value: `${match.groups.value} ${meta.unit}`,
        }
      }

      return {
        category: "–",
        description: key.replace(/_/g, " ").toLowerCase(),
        value: value,
      }
    },
  },
}
</script>

<style>
.extra-info-dialog__body .extra-info-dialog__row {
  cursor: default;
}
</style>
