<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab" background-color="white" color="black accent-4" left>
          <v-tabs-slider></v-tabs-slider>
          <!-- MANUALS TAB -->
          <v-tab href="#manuals">
            <v-icon class="mr-2" small>mdi-book-open-variant</v-icon>
            {{ $t("documents.manuals.title") }}
          </v-tab>
          <!--  TROUBLE SHOOTING TAB -->
          <v-tab href="#troubleShooting">
            <v-icon class="mr-2" small>mdi-toolbox</v-icon>
            {{ $t("documents.troubleshooting.title") }}
          </v-tab>
          <!-- TECHNICAL DATA TAB -->
          <v-tab href="#technicaldata">
            <v-icon class="mr-2" small>mdi-information-outline</v-icon>
            {{ $t("documents.technicalData.title") }}
          </v-tab>
          <!-- DIAGRAMS TAB -->
          <v-tab href="#diagrams" v-if="isService || isAdmin">
            <v-icon class="mr-2" small>mdi-tournament</v-icon>
            {{ $t("documents.diagrams.title") }}
          </v-tab>
          <!-- CE TAB -->
          <v-tab href="#CE">
            <v-icon class="mr-2" small>mdi-atom</v-icon>
            {{ $t("documents.ce.title") }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab">
      <!-- MANUALS -->
      <v-tab-item value="manuals">
        <v-container fluid>
          <v-row>
            <v-col xs="auto" class="mr-0">
              <v-text-field v-model="search" prepend-icon="mdi-magnify" :label="$t('common.filters.filter')"
                hide-details class="ma-0 pa-0"></v-text-field>
            </v-col>
            <v-col cols="auto" class="mx-0 px-0">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn icon @click="refreshManuals" :loading="refreshLoading" :disabled="refreshLoading">
                      <v-icon>mdi-cached</v-icon>
                      <template v-slot:loader>
                        <span class="refreshLoader">
                          <v-icon>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </span>
                </template>
                {{ $t("common.actions.refresh") }}
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row class="d-flex align-end">
            <v-col xs="auto" class="mr-0">
              <v-data-table v-if="manuals" :headers="manualsHeaders" :items="manuals" :search="search"
                :custom-filter="searchFilter" :loading="isDownloadingFile" hide-default-footer disable-pagination>
                <template v-slot:[`item.icon`]="{}">
                  <v-icon> mdi-book-open-variant </v-icon>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <span @click="rowClickHandler(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">mdi-download</v-icon>
                      </template>
                      {{ $t("machines.manuals.actions.downloadFile") }}
                    </v-tooltip>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <!-- TROUBLESHOOTING -->
      <v-tab-item value="troubleShooting">
        <v-container fluid>
          <v-row v-if="this.showInfo">
            <v-col cols="auto">
              <v-alert type="info">
                {{ $t("documents.troubleshooting.includedInManual") }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="!this.showInfo" class="d-flex align-end">
            <v-col xs="auto" class="mr-0">
              <v-text-field v-model="search" prepend-icon="mdi-magnify" :label="$t('common.filters.filter')"
                hide-details class="ma-0 pa-0"></v-text-field>
            </v-col>
            <v-col cols="auto" class="mx-0 px-0">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn icon @click="refreshManuals" :loading="refreshLoading" :disabled="refreshLoading">
                      <v-icon>mdi-cached</v-icon>
                      <template v-slot:loader>
                        <span class="refreshLoader">
                          <v-icon>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </span>
                </template>
                {{ $t("common.actions.refresh") }}
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row v-if="!this.showInfo" class="d-flex align-end">
            <v-col xs="auto" class="mr-0">
              <v-data-table v-if="troubleShootings" :headers="troubleShootingHeaders" :items="troubleShootings"
                :search="search" :custom-filter="searchFilter" :loading="isDownloadingFile" sort-by="name" sort-asc>
                <template v-slot:[`item.icon`]="{}">
                  <v-icon>mdi-book-open-variant </v-icon>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <span @click="rowClickHandler(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">mdi-download</v-icon>
                      </template>
                      {{ $t("machines.manuals.actions.downloadFile") }}
                    </v-tooltip>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <!-- TECHNICAL DATA -->
      <v-tab-item value="technicaldata">
        <v-container fluid>
          <v-row v-if="this.showInfo">
            <v-col cols="auto">
              <v-alert type="info">
                {{ $t("documents.technicalData.includedInManual") }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="!this.showInfo" class="d-flex align-end">
            <v-col xs="auto" class="mr-0">
              <v-text-field v-model="search" prepend-icon="mdi-magnify" :label="$t('common.filters.filter')"
                hide-details class="ma-0 pa-0"></v-text-field>
            </v-col>
            <v-col cols="auto" class="mx-0 px-0">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn icon @click="refreshManuals" :loading="refreshLoading" :disabled="refreshLoading">
                      <v-icon>mdi-cached</v-icon>
                      <template v-slot:loader>
                        <span class="refreshLoader">
                          <v-icon>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </span>
                </template>
                {{ $t("common.actions.refresh") }}
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row v-if="!this.showInfo" class="d-flex align-end">
            <v-col xs="auto" class="mr-0">
              <v-data-table v-if="technicalData" :headers="technicalDatagHeaders" :items="technicalData"
                :search="search" :custom-filter="searchFilter" :loading="isDownloadingFile" sort-by="name" sort-asc>
                <template v-slot:[`item.icon`]="{}">
                  <v-icon>mdi-book-open-variant</v-icon>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <span @click="rowClickHandler(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">mdi-download</v-icon>
                      </template>
                      {{ $t("machines.manuals.actions.downloadFile") }}
                    </v-tooltip>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <!-- DIAGRAMS -->
      <v-tab-item value="diagrams">
        <v-container fluid>
          <v-row>
            <v-col xs="auto" class="mr-0">
              <v-text-field v-model="search" prepend-icon="mdi-magnify" :label="$t('common.filters.filter')"
                hide-details class="ma-0 pa-0"></v-text-field>
            </v-col>
            <v-col cols="auto" class="mx-0 px-0">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn icon @click="refreshManuals" :loading="refreshLoading" :disabled="refreshLoading">
                      <v-icon>mdi-cached</v-icon>
                      <template v-slot:loader>
                        <span class="refreshLoader">
                          <v-icon>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </span>
                </template>
                {{ $t("common.actions.refresh") }}
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row class="d-flex align-end">
            <v-col xs="auto" class="mr-0">
              <v-data-table v-if="diagrams" :headers="diagramsHeaders" :items="diagrams" :search="search"
                :custom-filter="searchFilter" :loading="isDownloadingFile" sort-by="name" sort-asc>
                <template v-slot:[`item.icon`]="{}">
                  <v-icon>mdi-book-open-variant</v-icon>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <span @click="rowClickHandler(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">mdi-download</v-icon>
                      </template>
                      {{ $t("machines.manuals.actions.downloadFile") }}
                    </v-tooltip>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <!-- CE -->
      <v-tab-item value="CE">
        <v-container fluid>
          <v-row>
            <v-col xs="auto" class="mr-0">
              <v-text-field v-model="search" prepend-icon="mdi-magnify" :label="$t('common.filters.filter')"
                hide-details class="ma-0 pa-0"></v-text-field>
            </v-col>
            <v-col cols="auto" class="mx-0 px-0">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn icon @click="refreshManuals" :loading="refreshLoading" :disabled="refreshLoading">
                      <v-icon>mdi-cached</v-icon>
                      <template v-slot:loader>
                        <span class="refreshLoader">
                          <v-icon>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </span>
                </template>
                {{ $t("common.actions.refresh") }}
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row class="d-flex align-end">
            <v-col xs="auto" class="mr-0">
              <v-data-table v-if="CE" :headers="CEHeaders" :items="CE" :search="search" :custom-filter="searchFilter"
                :loading="isDownloadingFile" sort-by="name" sort-asc>
                <template v-slot:[`item.icon`]="{}">
                  <v-icon>mdi-book-open-variant</v-icon>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <span @click="rowClickHandler(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">mdi-download</v-icon>
                      </template>
                      {{ $t("machines.manuals.actions.downloadFile") }}
                    </v-tooltip>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import fetchClient from "@/utils/fetchClient"
import { mapActions } from "vuex"
import countryList from "country-list"

export default {
  name: "MachineManuals",
  props: ["machine"],
  components: {},

  data() {
    return {
      tab: "manuals",
      search: "",
      refreshLoading: false,
      manuals: [],
      troubleShootings: [],
      technicalData: [],
      diagrams: [],
      CE: [],
      downloadingFiles: 0,
      filesLoading: [],
      manualsMetaData: [],
      showInfo: false
    }
  },

  computed: {
    isUser() {
      return this.$store.getters.isUser
    },
    isService() {
      return this.$store.getters.isService
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    manualsHeaders() {
      return [
        {
          text: "",
          align: "right",
          sortable: false,
          value: "icon",
          width: 20
        },
        {
          text: this.$t("machines.manuals.dataTable.headers.fileName"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action"
        }
      ]
    },
    troubleShootingHeaders() {
      return [
        {
          text: "",
          align: "right",
          sortable: false,
          value: "icon",
          width: 20
        },
        {
          text: this.$t("machines.manuals.dataTable.headers.fileName"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action"
        }
      ]
    },

    technicalDatagHeaders() {
      return [
        {
          text: "",
          align: "right",
          sortable: false,
          value: "icon",
          width: 20
        },
        {
          text: this.$t("machines.manuals.dataTable.headers.fileName"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action"
        }
      ]
    },

    diagramsHeaders() {
      return [
        {
          text: "",
          align: "right",
          sortable: false,
          value: "icon",
          width: 20
        },
        {
          text: this.$t("machines.manuals.dataTable.headers.fileName"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action"
        }
      ]
    },

    CEHeaders() {
      return [
        {
          text: "",
          align: "right",
          sortable: false,
          value: "icon",
          width: 20
        },
        {
          text: this.$t("machines.manuals.dataTable.headers.fileName"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action"
        }
      ]
    },

    components() {
      return this.$store.getters.machineComponents
    },

    isDownloadingFile() {
      return this.downloadingFiles > 0
    }
  },

  async created() {
    // ------ MANUALS ------

    await this.fetchManualsMetaData()

    if (this.manualsMetaData.length > 0) {
      this.fetch(
        this.manualsMetaData[0].productId,
        this.manualsMetaData[0].revision,
        "Manuals"
      )
    }

    // ------ TROUBLE SHOOTING -------

    if (this.manualsMetaData.length > 0) {
      this.fetch(
        this.manualsMetaData[0].productId,
        this.manualsMetaData[0].revision,
        "Troubleshooting"
      )
      if (this.manualsMetaData[0].includeTroubleshooting === true) {
        this.showInfo = true
      } else {
        this.showInfo = false
      }
    }
    // ------ TECHNICAL DATA -------

    if (this.manualsMetaData.length > 0) {
      this.fetch(
        this.manualsMetaData[0].productId,
        this.manualsMetaData[0].revision,
        "Technical_data"
      )
      if (this.manualsMetaData[0].includeTroubleshooting === true) {
        this.showInfo = true
      } else {
        this.showInfo = false
      }
    }

    // ------ DIAGRAMS -------

    if (this.manualsMetaData.length > 0) {
      this.fetch(
        this.manualsMetaData[0].productId,
        this.manualsMetaData[0].revision,
        "Diagrams"
      )
    }

    // ------ CE -------

    if (this.manualsMetaData.length > 0) {
      this.fetch(
        this.manualsMetaData[0].productId,
        this.manualsMetaData[0].revision,
        "CE"
      )
    }
  },

  methods: {
    ...mapActions(["setError"]),

    async fetch(productId, revision, type) {
      // Remove spaces
      revision = revision.replace(/\s/g, "")

      const url = `/api/v1/documents/open/${productId}/${type}/${revision}/`

      //Showing all connect manuals 3G & 4G
      const url_connect = "/api/v1/documents/open/connect_manuals/"
      this.loading = true

      try {
        const files = await fetchClient(this.$i18n).getDataOrThrow(url)
        let result = ""
        //Allows connect manuals to only be fetched when standard manuals get fetched
        if (type === "Manuals") {
          result = await fetchClient(this.$i18n).getDataOrThrow(
            url_connect
          )
        }

        let countryCode = ""
        let obj = files.files
        let res = result.files

        for (var k in obj) {
          if (obj[k] instanceof Object) {
            if (type == "Manuals" || type == "Troubleshooting") {
              countryCode = obj[k].name.substr(obj[k].name.length - 6, 2)
              files.files[k].name = countryList.getName(countryCode)
            } else {
              files.files[k].name = files.files[k].name + "  (Multilanguage)"
            }
          }
        }

        //Changes the name for connect manuals
        for (var j in res) {
          if (res[j] instanceof Object) {
            if (type == "Manuals") {
              if (res[j].name == "3136 8087 21-D.pdf") {
                result.files[j].name = "Brokk Connect 3G"
              } else {
                countryCode = res[j].name.substr(res[j].name.length - 6, 2)
                result.files[j].name = "Brokk Connect 4G " + countryList.getName(countryCode)
              }
            }
          }
        }

        //custom sort manuals by name and connect manuals here,
        //else countries like Belgium will be displayed before brokk connect while the other countries after
        if (type == "Manuals") {
          obj.sort((a, b) => (a.name > b.name) ? 1 : -1)
          res.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }

        //Merges the manuals array and connect manuals array to be displayed
        //under manuals tab if the machine is provisioned
        if (type == "Manuals") {
          if (this.machine.unprovisioned === false) {
            this.manuals = [...obj, ...res]
          } else {
            this.manuals = files.files
          }
        } else if (type == "Troubleshooting") {
          this.troubleShootings = files.files
        } else if (type == "Technical_data") {
          this.technicalData = files.files
        } else if (type == "Diagrams") {
          this.diagrams = files.files
        } else {
          this.CE = files.files
        }
      } catch (error) {
        this.setError(error)
      }

      this.loading = false
    },

    async rowClickHandler(clickedItem) {
      if (!clickedItem.locked) {
        this.downloadingFiles += 1
        clickedItem.locked = true

        let errorData = null
        const downloadPath = "/api/v1/downloads/" + clickedItem.key

        const pathParts = clickedItem.key.split("/")

        try {
          const response = await fetchClient(this.$i18n).getResponse(
            "GET",
            downloadPath
          )

          if (response.status === 200) {
            const responseData = await response.arrayBuffer()
            const blob = new Blob([responseData], {
              type: response.headers.get("content-type")
            })

            let link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)

            if (pathParts[1] === "connect_manuals") {
              link.download = pathParts[2]
            } else {
              link.download = pathParts[4]
            }

            link.click()
          } else {
            errorData = await fetchClient(this.$i18n).getError(response)
          }
        } catch (error) {
          errorData = {
            message: this.$t("machines.manuals.messages.failedToDownload", {
              file: downloadPath
            }),
            causedBy: error.toString(),
            details: error.message
          }
        } finally {
          clickedItem.locked = false
          this.downloadingFiles -= 1

          if (errorData != null) {
            errorData.requestMethod = "GET"
            errorData.requestUri = downloadPath

            const err = {
              response: {
                data: errorData
              }
            }
            this.$store.dispatch("setError", err)
          }
        }
      }
    },

    // fetchManualsMetaData
    async fetchManualsMetaData() {
      const langCode = this.$store.getters.dataLangCode

      let prodId = this.machine.productId
      if (this.machine.properties && this.machine.properties.tags.includes("smart_power_plus")) {
        prodId += "+"
      }
      const url = `/api/v1/metadata/manuals/${langCode}?serialNo=${this.$route.params.serialNo}&productId=${encodeURIComponent(prodId)}`
      try {
        const metaData = await fetchClient(this.$i18n).getDataOrThrow(url)

        this.manualsMetaData = metaData.manuals
      } catch (error) {
        this.setError(error)
      }
    },
    refreshManuals() {
      this.refreshLoading = true
      // TODO: Refresh list of manuals from BE once we have additional manuals to show/include.
      setTimeout(() => {
        this.refreshLoading = false
      }, 1000)
    },
    searchFilter(value, search, item) {
      return item.name.toLowerCase().includes(search.toLowerCase())
    }
  }
}
</script>
