import { store } from "../store"

export default parameterId => {
  let description = "-"
  let unit = ""
  let category = ""

  if (
    parameterId !== undefined &&
    parameterId != null &&
    parameterId.length > 0
  ) {
    const meta = store.getters.measurementMetadata

    /* Default to the parameterId in case no description exists */
    description = parameterId

    if (meta !== undefined && meta !== null) {
      const info = meta.find(item => item.parameterId === parameterId)

      if (info !== undefined) {
        category = info.category
        description = info.description
        unit = info.unit
      }
    }
  }
  return { category: category, description: description, unit: unit }
}
