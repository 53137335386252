<template>
  <div
    class="fluid d-flex ma-0 pa-0 fill-height justify-center align-center saira-medium-font"
  >
    <v-card
      elevation="24"
      outlined
      raised
      :style="minWidth"
      class="rounded-lg px-md-4"
    >
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <img style="max-height:32px;" :src="$store.getters.logoBlack" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-md-4 mb-md-10 pb-md-4">
            <p class="text-center mb-0 changePasswordText">{{$t("login.changePassword")}}</p>
          </v-col>
        </v-row>

        <v-form ref="firstLoginForm" autocomplete="off" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" class="px-2 pb-2">
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="newpassword"
                  prepend-icon="mdi-lock"
                  type="password"
                  required
                  :label="`${$t('login.newPassword')}*`"
                  :rules="[
                    validationRules.awsPasswordRequirements(this.newpassword)
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="px-2 pb-2">
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="confirmpassword"
                  prepend-icon="mdi-lock"
                  type="password"
                  required
                  :label="`${$t('login.confirmNewPassword')}*`"
                  :rules="[
                    validationRules.mustMatch(
                      this.confirmpassword,
                      this.newpassword
                    )
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="showPasswordStrength">
              <v-col cols="12" class="text-center pl-10 pr-2">
                <p class="pb-0 mb-0">{{$t("login.passwordStrength")}}</p>
                <password
                  class="mt-0 pt-0 pl-0"
                  v-model="newpassword"
                  :strength-meter-only="true"
                />
              </v-col>
            </v-row>
            <v-row v-if="error">
              <v-col cols="12">
                <app-alert
                  @dismissed="dismissAlert"
                  :errItem="error"
                ></app-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pl-10 pr-2">
                <v-btn
                  :disabled="loading"
                  rounded-lg
                  class="changePasswordBtnText"
                  block
                  @click.stop="onChangePassword"
                  >{{$t("login.changePassword")}}</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="loading">
              <v-col cols="12">
                <v-progress-linear active indeterminate />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <div class="saira-regular-font footerText">
          <v-row>
            <v-col cols="12" class="text-center mt-10 pt-10">
              &nbsp;
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter"
import ValidationRules from "@/utils/validationrules"
let validationRules = null

export default {
  components: {
    Password
  },
  data() {
    return {
      valid: false,
      newpassword: "",
      confirmpassword: "",
      validationRules: validationRules,
      showPasswordStrength: false
    }
  },
  beforeCreate(){ validationRules = ValidationRules(this.$i18n) },
  computed: {
    loading() {
      return this.$store.getters.loading
    },
    error() {
      return this.$store.getters.error
    },
    minWidth() {
      var minPercentage = "100%"

      if (this.$vuetify.breakpoint.name === "sm") {
        minPercentage = "75%"
      } else if (this.$vuetify.breakpoint.name === "md") {
        minPercentage = "30%"
      } else if (this.$vuetify.breakpoint.name === "lg") {
        minPercentage = "25%"
      } else if (this.$vuetify.breakpoint.name === "xl") {
        minPercentage = "20%"
      }
      return `width:${minPercentage}`
    }
  },
  watch: {
    newpassword() {
      this.dismissAlert()
    },
    confirmpassword() {
      this.dismissAlert()
    }
  },
  methods: {
    onChangePassword(e) {
      e.preventDefault()
      if (!this.$refs.firstLoginForm.validate()) {
        return
      }
      if (this.valid) {
        this.$store.dispatch("firstTimeSetPassword", {
          newpassword: this.newpassword,
          username: decodeURIComponent(this.$route.params.username),
          tempPassword: decodeURIComponent(this.$route.params.code)
        })
      }
    },
    dismissAlert() {
      this.$store.dispatch("clearError")
    }
  }
}
</script>

<style scoped>
.changePasswordText {
  color: #303030;
  font-size: 2em;
  font-weight: 500;
  text-transform: uppercase;
}
.changePasswordBtnText {
  color: #303030;
  background-color: #ffcd00 !important;
  font-size: 1.1em;
  font-weight: 600;
  letter-spacing: 0.02em;
}
.footerText {
  color: #303030;
  font-size: 1em;
}
</style>
