<template>
  <v-container fluid>
    <v-row>
      <!-- Location tabs -->
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          center-active
          background-color="white"
          color="black accent-4"
          left
          show-arrows
          show-arrows-on-hover
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="tab of tabs"
            :key="tab.href"
            :href="tab.href"
            :disabled="tab.disabled"
          >
            <v-icon class="mr-2" small>{{ tab.icon }}</v-icon>
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab" :touchless="editGeofence || editGeolocation">
      <!-- LOCATION -->
      <v-tab-item value="location">
        <v-card flat outlined class="mt-2">
          <v-card-title>
            {{ $t("machines.location.location.title") }}
          </v-card-title>
          <v-card-subtitle class="pb-0">
            <p>{{ $t("machines.location.location.description") }}</p>
            <p v-if="geofencingSupported">
              {{
                $t(
                  "machines.location.location.messages.changeGeofencingCoordinatesTip"
                )
              }}
            </p>
          </v-card-subtitle>
          <v-card-text>
            <v-row
              v-if="canAddGeofence && geofencingSupported && showAddGeofence"
            >
              <v-col cols="12">
                <v-btn
                  color="secondary accent--text"
                  class="pa-4"
                  @click="addDefaultGeofenceArea"
                  :disabled="!currentMachineLocalization"
                >
                  <v-icon left>mdi-plus-circle</v-icon>
                  {{ $t("machines.location.geofencingSettings.actions.add") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0" cols="12" v-if="geofenceSettingsNotSyched">
                <v-alert
                  v-model="geofenceSettingsNotSyched"
                  dismissible
                  class="mb-0 alert-message"
                  elevation="2"
                  border="left"
                  colored-border
                  dense
                  type="warning"
                  :value="true"
                >
                  <v-row align="center">
                    <v-col class="grow py-0">
                      {{ $t("machines.messages.machineOffline") }}
                      {{
                        $t(
                          "machines.location.geofencingSettings.messages.settingsNotSynched"
                        )
                      }}
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col :md="canReadGeofence ? 8 : 12" cols="12" class="pr-2">
                <LocationMapView :machine="machine" />
              </v-col>
              <v-col md="4" cols="12" class="pl-2">
                <GeofencingSettings
                  :machine="this.machine"
                  v-if="canReadGeofence"
                  v-bind:editGeofence.sync="editGeofence"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- GEOLOCATION SETTINGS -->
      <v-tab-item value="geolocation">
        <GeolocationSettings
          :machine="this.machine"
          v-bind:editGeolocation.sync="editGeolocation"
        />
      </v-tab-item>
      <!-- EVENTS -->
      <v-tab-item value="events">
        <LocationEvents :machine="this.machine" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import LocationMapView from "@/views/machine/LocationMapView"
import GeolocationSettings from "@/views/machine/GeolocationSettings"
import GeofencingSettings from "@/views/machine/GeofencingSettings"
import LocationEvents from "@/views/machine/LocationEvents"

export default {
  name: "Location",
  props: ["machine"],
  components: {
    LocationMapView,
    GeolocationSettings,
    GeofencingSettings,
    LocationEvents
  },
  data() {
    return {
      tab: "location",

      editGeolocation: false,
      editGeofence: false,

      // Events
      locationEvents: [],

      // Notification stuff
      notification: null,

      recipients: [],

      isSendingNotification: false,

      notificationHistory: []
    }
  },
  computed: {
    ...mapGetters([
      "canReadGeolocation",
      "canReadGeofence",
      "canAddGeofence",
      "currentMachineLocalization"
    ]),

    geofenceSettingsNotSyched: {
      get() {
        const settings = this.$store.getters.machineGeofencingSettings
        if (settings) {
          if (settings.desiredOptions) {
            if (settings.reportedOptions) {
              if (
                JSON.stringify(settings.desiredOptions) !=
                JSON.stringify(settings.reportedOptions)
              ) {
                return true
              }
            } else {
              return true
            }
          }
        }
        return false
      },
      set() {}
    },
    tabs() {
      let items = []
      items.push({
        href: "#location",
        icon: "mdi-map-marker-radius",
        title: this.$t("machines.tabHeaders.location"),
        disabled: this.editGeolocation
      })
      /* Settings tab will only be visible to users with admin role /*
      /* All others will have no access at all */
      if (this.$store.getters.canReadGeolocation) {
        items.push({
          href: "#geolocation",
          icon: "mdi-cogs",
          title: this.$t("machines.tabHeaders.settings"),
          disabled: this.editGeofence
        })
      }
      items.push({
        href: "#events",
        icon: "mdi-bell-outline",
        title: this.$t("machines.tabHeaders.events"),
        disabled: this.editGeofence || this.editGeolocation
      })
      return items
    },
    geofencingSupported() {
      return this.$store.getters.machineGeofencingSettings?.capabilities
        ?.notifications
    },
    showAddGeofence() {
      return (
        !this.$store?.getters?.geofenceAreaLatitude &&
        !this.$store?.getters?.geofenceAreaEditMode
      )
    },
    editModeEnabled: {
      get() {
        return this.$store.getters.geofenceAreaEditMode
      },
      set(val) {
        this.$store.dispatch("setGeofenceAreaEditMode", val)
      }
    }
  },
  watch: {},
  methods: {
    addDefaultGeofenceArea() {
      if (this.currentMachineLocalization) {
        this.$store.dispatch("updateGeofenceArea", {
          latitude: this.currentMachineLocalization.lat,
          longitude: this.currentMachineLocalization.lng,
          radius: 300 /*in meters*/,
          unit: "km"
        })
        this.editModeEnabled = true
      }
    }
  }
}
</script>
<style></style>
