<template>
  <v-card tile>
    <v-card-title class="ma-0 pa-0">
      <v-col>
        <v-alert
          class="mb-0 alert-message"
          dense
          dismissible
          :type="alertType"
          @click.stop="onClose"
          @input="onClose"
          :value="true"
        >
          {{ errItem.message }}
          <template v-slot:prepend>
            <v-icon class="accent--text">mdi-alert-circle</v-icon>
          </template>

          <template v-slot:default>
            <span class="accent--text font-weight-medium pl-2 pr-2">{{
              errItem.message
            }}</span>
          </template>

          <template v-slot:append>
            <v-btn
              small
              text
              @click="showDetails = !showDetails"
              class="accent--text"
              >{{$t("common.error.more")}}...</v-btn
            >
          </template>

          <template v-slot:close>
            <v-icon class="accent--text" @click.stop="onClose"
              >mdi-close-circle</v-icon
            >
          </template>
        </v-alert>
      </v-col>
    </v-card-title>
    <v-card-text v-if="showDetails" class="ma-0 pa-2">
      <v-col>
        <v-row>
          <v-col>
            <span class="accent--text">
              <b>{{$t("common.error.causedBy")}}:</b>
              {{ errItem.causedBy }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span
              v-if="errItem.requestMethod && errItem.requestUri"
              class="mb-0 accent--text"
            >
              <b>{{$t("common.error.request")}}:</b>
              {{ errItem.requestMethod }}&nbsp;{{ errItem.requestUri }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span v-if="errItem.details" class="mb-0 accent--text">
              <b>{{$t("common.error.details")}}:</b>
              {{ errItem.details }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["errItem", "type"],
  data() {
    return {
      showDetails: false
    }
  },
  methods: {
    onClose() {
      this.$emit("dismissed")
    }
  },
  computed: {
    alertType() {
      if (this.type !== undefined) {
        return this.type
      } else {
        return "warning"
      }
    }
  }
}
</script>
