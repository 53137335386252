<template>
  <v-dialog @input="onClose" :value="visible" max-width="400">
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-progress-wrench</v-icon>
          <span class="title accent--text">
            {{ $t("machines.maintenance.service.reset.success.title")}}
          </span>
        </v-toolbar-title>

        <v-spacer />

        <v-btn icon @click="onClose">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-6">
        <p class="subtitle-1 accent--text"
          v-html="$t('machines.maintenance.service.reset.success.description', [ serialNo ])"
        >
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn class="mr-2 mb-2" @click.stop="onClose">{{$t("common.actions.close")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    visible: false,
    resolve: null,
    serialNo: null
  }),

  methods: {
    open(serialNo) {
      this.serialNo = serialNo
      this.visible = true

      return new Promise(resolve => {
        this.resolve = resolve
      })
    },

    onClose() {
      this.visible = false
      this.resolve()
    }
  }
}
</script>
