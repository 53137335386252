export default {
  machines: {
    actions: {
      addMachine: "Registrar máquina",
      importMachine: "Maschine importieren",
      provisioned: "Dotada",
      transfer: "Übertragen",
      unprovisioned: "Sin dotar"
    },
    activities: {
      beltActiveTime: "Tiempo activo de la correa",
      otherTime: "Otras veces",
      toolActiveTime: "Tiempo activo de la herramienta"
    },
    alarms: {
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              category: "Categoría",
              description: "Descripción",
              value: "Valor"
            }
          }
        }
      },
      moreExists: "existe más"
    },
    dataTable: {
      headers: {
        active: "Activo",
        brokkConnect: "Brokk Connect",
        category: "Categoría",
        code: "Código",
        date: "Fecha",
        description: "Descripción",
        device: "Dispositivo",
        engineHours: "Horas del motor",
        extraInfo: "Información adicional",
        id: "Identificación",
        lastSeen: "Visto por última vez",
        name: "Nombre",
        number: "Número",
        product: "Producto",
        serviceAgreement: null,
        serviceStatus: "Estado del mantenimiento",
        severity: "Gravedad",
        status: "Estado",
        tenantName: "Inquilino",
        updateInterval: "Intervalo de actualización"
      },
      tooltips: {
        cellular: "Brokk Connect utiliza la red móvil",
        overdue: "Atrasado",
        pending: "Pendiente",
        service: "Mantenimiento",
        serviceAgreementMissing: null,
        serviceAgreementPresent: null,
        unknown: "Desconocido",
        unprovisioned: "Brokk Connect no instalado",
        upcoming: "Ok",
        wifi: "Brokk Connect utiliza la red wifi"
      }
    },
    dialogs: {
      addMachine: {
        addMachineSucceededText: "Se ha añadido la máquina Brokk {machine}.",
        confirmAddMachine: "¿Desea añadir la máquina?",
        confirmAddMachineProceedInfo:
          "Para añadir la máquina, seleccione un modelo y pulse Siguiente.",
        confirmAddMachineProceedInfoNext:
          "Para añadir la máquina, pulse Siguiente.",
        confirmAddMachineTitle: "¿Añadir máquina?",
        engineHours: "Horas del motor",
        enterEngineHoursTitle: "Introduzca las horas del motor",
        enterMachineInformationTitle: "Introduzca la información de la máquina",
        enterserialNoDescription: "Introduzca un número de serie válido",
        enterserialNoTitle: "Introduzca el número de serie de la máquina",
        errors: {
          couldNotAddMachine: "{msg}",
          couldNotRegisterService: "{msg}",
          couldNotUpdateService: "{msg}",
          noSuchserialNo: "Error: Ese número de serie no existe"
        },
        serialNo: "Número de serie",
        steps: {
          confirm: "Confirmar",
          overview: "Visión general",
          summary: "Resumen"
        },
        title: "Añadir máquina sin dotar"
      },
      addServiceSchedule: {
        comment: "Comentario sobre el mantenimiento",
        commentHint:
          "El comentario sobre el mantenimiento será visible en el historial de mantenimiento",
        commentPlaceholder: "Introducir comentario de mantenimiento",
        engineHours: "Horas del motor",
        enterEngineHoursTitle: "Introduzca las horas del motor",
        service2: "Mantenimiento 2",
        service3: "Mantenimiento 3",
        service4: "Mantenimiento 4",
        serviceInformationTitle:
          "Introduzca la información sobre el mantenimiento",
        serviceTitle: "Mantenimiento",
        steps: {
          confirm: "Confirmar",
          overview: "Visión general",
          summary: "Resumen"
        },
        title: "Añadir calendario de mantenimiento"
      },
      commissioning: {
        saved: "La puesta en marcha se ha guardado correctamente.",
        steps: {
          applications: {
            hint: "(Opcional) Seleccione una o más aplicaciones",
            name: "Aplicaciones"
          },
          category: {
            name: "Categoría",
            otherCategoryHint: "Especifique otra categoría",
            otherCategoryPlaceholder: "Otra categoría"
          },
          commissioning: {
            description:
              "Elija la fecha en que se puso en marcha la máquina. Esto afectará las fechas de inicio y finalización de la garantía.",
            name: "Puesta en marcha"
          },
          confirm: {
            description: "Confirme que los siguientes datos son correctos:",
            name: "Confirmar",
            proceedInfo: "Para poner en marcha la máquina, pulse Guardar."
          },
          segment: {
            description:
              "Elija un segmento y una categoría que describa cómo se utilizará la máquina.",
            name: "Segmento"
          }
        },
        title: "Poner en marcha la máquina"
      },
      editMachine: {
        createdTimestamp: "Creado",
        description: "Descripción",
        descriptionPlaceholder: "Introducir descripción",
        name: "Nombre",
        namePlaceholder: "Introducir nombre",
        revision: null,
        serialNo: "N.º de serie",
        tenant: "Inquilino",
        tenantHint:
          "No se puede cambiar el inquilino mientras haya una transferencia pendiente",
        tenantPlaceholder: "Seleccionar inquilino",
        title: "Editar máquina"
      },
      editServiceSchedule: {
        engineHours: "Horas del motor",
        enterEngineHoursTitle: "Introduzca las horas del motor",
        service2: "Mantenimiento 2",
        service3: "Mantenimiento 3",
        service4: "Mantenimiento 4",
        serviceInformationTitle:
          "Introduzca la información sobre el mantenimiento",
        serviceTitle: "Mantenimiento",
        steps: {
          confirm: "Confirmar",
          overview: "Visión general",
          summary: "Resumen"
        },
        title: "Editar el calendario de mantenimiento"
      },
      editSettings: {
        cantEdit: "Este parámetro no se puede editar",
        category: "Categoría",
        description: "Descripción",
        descriptionPlaceholder: "Introducir descripción",
        device: "Dispositivo",
        id: "Identificación",
        number: "Número",
        operatorMustConfirm:
          "Tus cambios necesitarán la aprobación de un operario antes de que surtan efecto.",
        title: "Editar intervalo de actualización",
        updateInterval: "Intervalo de actualización"
      },
      importMachine: {
        confirmTransferMachine:
          "¿Deseas transferir el {machine} con número de serie {sn} al inquilino actual {tenant}?",
        confirmTransferProceedInfo:
          "Para transferir la máquina a este inquilino, pulsa Siguiente.",
        confirmTransferTitle: "¿Transferir máquina?",
        enterTransferCodeDescription:
          "Introduce un código de transferencia para trasladar una máquina de otro inquilino al inquilino seleccionado actualmente",
        enterTransferCodeTitle: "Introducir código de transferencia",
        errors: {
          couldNotCompleteTransfer:
            "Error: No se pudo completar la transferencia. {msg}",
          noSuchTransferCode: "Error: No existe ese código de transferencia",
          transferCodeAlreadyUsed:
            "Error: El código de transferencia ya se usó",
          transferCodeExpired:
            "Error: El código de transferencia está caducado",
          transferReverted: "Error: La transferencia se ha revertido"
        },
        steps: {
          confirm: "Confirmar",
          overview: "Visión general",
          summary: "Resumen"
        },
        title: "Transferir la máquina a {tenant}",
        transferCode: "Código de transferencia",
        transferCodePlaceholder: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
        transferSucceededText:
          "El {machine} con número de serie {sn} se ha transferido a {tenant}."
      },
      transferMachine: {
        errors: {
          internalError:
            "No se pudo generar un código de transferencia en este momento debido a un error interno.",
          machineAlreadyTransferred:
            "Esta máquina se transfirió a otro inquilino",
          pendingTransfer:
            "Esta máquina tiene una transferencia pendiente que se creó",
          transferCodeExpires: "El código de transferencia caduca"
        },
        refreshTheList: "Actualiza la lista de máquinas.",
        steps: {
          confirm: {
            description:
              "¿Transferir la titularidad de la máquina {sn} ({productName}) actualmente propiedad de {tenant}?",
            includeHistoricalDataInfo:
              "La transferencia incluirá datos históricos.",
            name: "Confirmar",
            notIncludeHistoricalDataInfo:
              "La transferencia no incluirá datos históricos.",
            proceedInfo:
              "Para generar un código de transferencia e iniciar la transferencia, pulsa Siguiente.",
            title: "Confirmar"
          },
          dataRetention: {
            description:
              "Al incluir los datos históricos de la máquina en la transferencia, permites al destinatario ver todas las alarmas, eventos y mediciones históricas, incluida la geolocalización.",
            includeHistoricalData:
              "Incluir datos históricos en la transferencia.",
            name: "Conservación de datos",
            title: "Incluir historial"
          },
          summary: {
            description:
              "Copia el código de transferencia siguiente y envíaselo por correo electrónico al destinatario, quien lo utilizará para completar la transferencia.",
            name: "Resumen",
            title: "Código de transferencia"
          },
          transferOwnership: {
            description:
              "Transfiere la titularidad de esta máquina a otro inquilino mediante un código de transferencia. La máquina permanecerá accesible mientras la transferencia esté pendiente.",
            name: "Visión general",
            title: "Transferir titularidad"
          }
        },
        title: "Transferir máquina",
        transferAutomaticRevertInfo:
          "La transferencia se revertirá automáticamente si el destinatario no completa la transferencia al cabo de tres días.",
        transferCode: "Código de transferencia"
      }
    },
    dieselParticulateFilter: {
      info: {
        error: "Póngase en contacto con el mantenimiento de Brokk.",
        ok: "Estado OK.",
        title: "Filtro de partículas diésel",
        warning: "Se necesita regeneración manual. Ver pantalla en la máquina."
      },
      status: {
        ashLevel: "Nivel de ceniza",
        sootLevel: "Nivel de hollín",
        title: "Estado del DPF"
      }
    },
    engineHoursSelectionList: {
      lastDay: null,
      lastMonth: null,
      lastWeek: null,
      total: null
    },
    featureNotAvailable: "Esta función no está habilitada en esta máquina",
    filters: {
      dataSource: "Fuente de datos"
    },
    info: {
      order: {
        commissioningDate: "Fecha de la puesta en marcha",
        commissioningHint: "Máx. 6 meses a partir de la fecha de entrega",
        deliveryDate: "Fecha de entrega",
        label: "Pedido de la máquina",
        orderNumber: "Número de pedido",
        orderType: "Tipo de pedido"
      },
      segment: {
        actions: {
          commission: "Poner en marcha esta máquina"
        },
        applications: "Aplicaciones",
        category: "Categoría",
        label: "Segmento",
        overlay: {
          description:
            "Ponga en marcha la máquina para activar su garantía y defina en qué tipo de aplicaciones se utilizará.",
          disabledDescription:
            "La puesta en marcha es posible una vez que la máquina tiene una fecha de entrega y duración de la garantía.",
          heading: "Esta es una máquina de stock"
        }
      },
      warranty: {
        endDate: "Fecha final",
        label: "Garantía",
        length: "Duración",
        startDate: "Fecha de inicio"
      }
    },
    labels: {
      activeAlarms: "Alarmas activas",
      elMotorTemp: "Temp. del motor eléctrico",
      engineHours: "Horas del motor",
      engineHoursDays: "Horas / días del motor",
      fuelLevel: "Nivel de combustible",
      highPressureTime: "Tiempo de alta presión",
      highTempTime: "Tiempo de alta temperatura",
      hydraulicFluidTemp: "Temperatura del fluido hidráulico",
      machineActivity: "Actividad de la máquina",
      nextServiceIn: "Próximo mantenimiento en",
      noMachines: {
        descriptionHasAccess:
          "Pulse el botón de abajo para registrar una máquina sin dotar. Esto le da acceso inmediato a la documentación y a los repuestos correctos.",
        descriptionNoAccess:
          "Al registrar su máquina, tiene acceso inmediato a la documentación y a los repuestos correctos. Póngase en contacto con su distribuidor más cercano para registrar su máquina.",
        title: "Registrar una máquina"
      },
      notification: "Notificación"
    },
    location: {
      currentMachineSettings: "Ajustes actuales de la máquina",
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              propertyCategory: "Categoría",
              propertyName: "Nombre",
              propertyValue: "Valor"
            }
          }
        }
      },
      events: {
        dataTable: {
          headers: {
            date: "Fecha",
            description: "Descripción",
            extraData: "Información adicional",
            source: "Fuente"
          }
        }
      },
      geofencingSettings: {
        actions: {
          add: "Añadir geovalla",
          edit: "Editar ajustes de la geovalla",
          remove: "Eliminar ajustes de la geovalla"
        },
        confirm: {
          description:
            " ¿Está seguro de que desea aplicar los cambios de la geovalla? Esto puede afectar a la capacidad de funcionamiento de la máquina. Asegúrese de que la máquina esté en una posición que no provoque ningún daño ni lesión a las personas ni al equipo.",
          title: "Aplicar geovalla"
        },
        coordinates: "Coordenadas",
        description: " ",
        latitude: "Latitud",
        latitudePlaceholder: "Introducir latitud",
        limit: {
          description: "",
          limitation: "Limitación",
          limitationPlaceholder: "Seleccione la limitación que desea añadir",
          title: "Limitaciones"
        },
        longitude: "Longitud",
        longitudePlaceholder: "Introducir longitud",
        messages: {
          anyLimitationOrNotificationRequired:
            "Especifique una o más notificaciones",
          disabled:
            "La función de geovalla no está habilitada en esta máquina.",
          noLimitationsAdded: "Aún no se han añadido limitaciones.",
          settingsNotSynched:
            "Los ajustes de la geovalla no se han sincronizado por la máquina.",
          settingsRemoved: "Se ha eliminado la geovalla",
          settingsSaved: "Se ha guardado la geovalla"
        },
        notifications: {
          description:
            "Introduzca las direcciones de correo electrónico de las personas que deben recibir notificaciones por correo electrónico cuando la máquina entre o salga del área de la geovalla.",
          title: "Notificaciones"
        },
        radius: "Radio",
        radiusPlaceholder: "Introducir radio",
        title: "Geovalla",
        triggers: {
          enter: "Entrar",
          exit: "Salir"
        },
        unit: "Unidad",
        unitPlaceholder: "Introducir unidad"
      },
      geolocationSettings: {
        actions: {
          edit: "Editar ajustes de geolocalización",
          remove: "Eliminar ajustes de geolocalización"
        },
        description: "Ajustes de rastreo de geolocalización sin conexión",
        distance: "Distancia",
        distanceHint:
          "Distancia mínima que la máquina debe recorrer antes de enviar una actualización",
        distancePlaceholder: "Introducir distancia",
        interval: "Intervalo",
        intervalHint:
          "Intervalo de tiempo mínimo que debe pasar entre actualizaciones",
        intervalPlaceholder: "Seleccionar intervalo",
        messages: {
          disabled:
            "Especifique la función de geolocalización actualmente no está habilitada en esta máquina una o más notificaciones.",
          settingsNotSynched:
            "La configuración de geolocalización no ha sido sincronizada por la máquina.",
          settingsRemoved: "Se han eliminado los ajustes de geolocalización",
          settingsSaved: "Se han guardado los ajustes de geolocalización"
        },
        movement: "Movimiento",
        timer: "Temporizador",
        title: "Ajustes de geolocalización",
        unit: "Unidad",
        unitPlaceholder: "Seleccionar unidad"
      },
      location: {
        actions: {
          setGeofencingLocation: "Establezca la ubicación de la geovalla aquí"
        },
        description:
          "Defina un área donde la máquina debe operar y reciba notificaciones cuando entre o salga del área.",
        messages: {
          changeGeofencingCoordinatesTip:
            "Utilice el botón «Dibujar un círculo» en el mapa para definir o cambiar la geovalla de la máquina."
        },
        showHistory: "Mostrar el historial de las ubicaciones",
        title: "Ajustes de ubicación"
      },
      map: {
        geofenceCenter: "Centro de geovallas",
        machineLocation: "Ubicación de la máquina"
      }
    },
    maintenance: {
      service: {
        add: "El mantenimiento se añadió correctamente",
        beforeNextServiceYearlyService:
          "Antes del próximo mantenimiento / mantenimiento anual",
        commentMaxChars: "El comentario debe tener 2000 caracteres o menos",
        overdue: "Mantenimiento atrasado",
        reset: {
          actions: {
            send: "Enviar comando de restablecimiento de mantenimiento"
          },
          availableForServiceUsersOnly:
            "La función de restablecimiento del servicio solo está disponible para los usuarios de mantenimiento.",
          comment: "Comentario de mantenimiento",
          commentHint:
            "El comentario de mantenimiento será visible en el historial de mantenimiento.",
          commentPlaceholder: "Introducir comentario de mantenimiento",
          confirm: {
            description:
              "¿Estás seguro de que deseas realizar un restablecimiento de mantenimiento en la máquina <strong>{0}</strong>?",
            note: "Nota: Esta acción no se puede deshacer.",
            title: "Confirmar restablecimiento de mantenimiento"
          },
          description:
            "Esto enviará un comando de restablecimiento del mantenimiento a la máquina y funciona igual que un restablecimiento del mantenimiento con la herramienta de configuración Brokk.",
          disabled:
            "La función de restablecimiento del mantenimiento no está habilitada actualmente en esta máquina.",
          errors: {
            notAvailable:
              "Se puede realizar un restablecimiento de mantenimiento 100 horas antes del mantenimiento programado o cuando haya un mantenimiento anual pendiente.",
            offline:
              "La máquina debe estar en línea para que se restablezca un mantenimiento.",
            waitingForYearly:
              "El mantenimiento anual aún no se ha activado en la máquina. Puede tardar una hora hasta que esté activo. Espera y vuelve a intentarlo más tarde."
          },
          success: {
            description:
              "¡El mantenimiento en la máquina <strong>{0}</strong> se ha restablecido correctamente!",
            title: "Restablecimiento de mantenimiento correcto"
          },
          title: "Restablecimiento del mantenimiento"
        },
        schedule: {
          dataTable: {
            headers: {
              comment: "Comentario",
              loggedHours: "Horas registradas",
              scheduled: "Programado",
              service: "Mantenimiento",
              serviceDate: "Fecha de mantenimiento",
              status: "Estado"
            }
          },
          description:
            "El calendario de mantenimiento se basa en el tiempo de funcionamiento y la fecha del último mantenimiento de la máquina. Dado que las condiciones de trabajo pueden variar, puede sernecesario adaptar el intervalo de mantenimiento a las condiciones de trabajo correspondientes y al entorno.",
          received: "Recibido",
          title: "Calendario de mantenimiento"
        },
        update: "El mantenimiento se actualizó correctamente"
      },
      serviceAgreement: {
        actions: {
          add: null,
          edit: null
        },
        labels: {
          description: null,
          endDate: null,
          filename: null,
          fileuploadLabel: null,
          startDate: null,
          uploadFilePlaceholder: null
        },
        messages: {
          added: null,
          deleted: null,
          failedToDownload: null,
          updated: null
        },
        title: null,
        warnings: {
          isExpired: null
        }
      }
    },
    manuals: {
      actions: {
        downloadFile: "Descargar archivo"
      },
      dataTable: {
        headers: {
          fileName: "Nombre del archivo"
        }
      },
      messages: {
        failedToDownload: "Error al descargar {file}"
      }
    },
    measurements: {
      electricMotor: "Motor eléctrico",
      electronicUnit: "Unidad electrónica",
      engineHours: "Horas del motor",
      export: {
        csv: {
          columns: {
            category: "Categoría",
            description: "Descripción",
            parameterId: "ID de parámetro",
            timestamp: "Marca de tiempo",
            unit: "Unidad",
            value: "Valor"
          }
        }
      },
      gateway: "Puerta de enlace",
      highPressureTime: "Tiempo de alta presión",
      highTempTime: "Tiempo de alta temperatura",
      hydraulicFluid: "Fluido hidráulico",
      hydraulicFluidPressure: "Presión del fluido hidráulico",
      temperatures: "Temperaturas"
    },
    menuItems: {
      alarms: "Alarmas",
      dashboard: "Panel de control",
      info: "Información",
      location: "Ubicación",
      maintenance: "Mantenimiento",
      manuals: "Manuales",
      measurements: "Mediciones",
      parameters: "Parámetros",
      rental: "Alquiler",
      system: "Sistema"
    },
    messages: {
      applyChangesPending: "Esperando a que el dispositivo aplique los cambios",
      changesApplied: "Se han aplicado los cambios",
      contactToLearnMore: "Para obtener más información, ponte en contacto con",
      errorApplyingChanges: "Error al aplicar los cambios",
      fetchingMachineDetails: "Recuperando detalles de la máquina...",
      machineAdded: "Se ha añadido la máquina",
      machineDetailsAreBeeingFetched:
        "Se están recuperando los detalles de la máquina. Espera...",
      machineDoesNotLoadInfo:
        " Si la máquina no se carga, retrocede y actualiza manualmente la vista de las máquinas.",
      machineOffline: "La máquina está desconectada.",
      machineUpdated: "Se ha actualizado la máquina",
      unsavedChanges: "Cambios sin guardar"
    },
    missingMachineImage: "Falta imagen del producto",
    notFound: "Máquina no encontrada",
    notifications: {
      settings: {
        actions: {
          edit: "Editar ajustes de las notificaciones",
          remove: "Eliminar ajustes de las notificaciones",
          send: "Enviar notificación"
        },
        daysBeforeLeaseExpiration: "Días antes de que expire el arrendamiento",
        daysBeforeNextYearlyService:
          "Días antes del próximo mantenimiento anual",
        description:
          "Introduce las direcciones de correo electrónico de las personas que deberían recibir notificaciones por correo electrónico.",
        dialogs: {
          sendNotification: {
            confirm:
              "¿Deseas enviar una notificación por correo electrónico a {count} destinatario? | ¿Deseas enviar una notificación por correo electrónico a {count} destinatarios?",
            successMessage:
              "Se ha enviado una notificación por correo electrónico | Se han enviado notificaciones por correo electrónico"
          }
        },
        engineHoursBeforeLeaseExpires:
          "Horas del motor antes de que expire el arrendamiento",
        engineHoursBeforeNextService:
          "Horas del motor antes del próximo mantenimiento",
        maxRecipientsHint:
          "Se pueden añadir como máximo cinco direcciones de correo electrónico",
        messages: {
          notificationSettingsRemoved:
            "Se ha eliminado la configuración de notificaciones",
          notificationSettingsSaved:
            "Se ha guardado la configuración de notificaciones"
        },
        noLeaseAdded:
          "Para añadir notificaciones por correo electrónico, vuelva a la pestaña de arrendamiento y especifique un arrendamiento.",
        norecipientsAdded: "Aún no se añadieron destinatarios.",
        recipients: "Destinatarios",
        selectEmails:
          "Seleccione hasta 5 direcciones de correo electrónico que deben recibir notificaciones",
        sendHint:
          "Enviar un correo electrónico a todos los destinatarios ahora",
        threshold: "Umbral",
        title: "Ajustes de las notificaciones"
      }
    },
    parameters: {
      dataTable: {
        headers: {
          category: "Categoría",
          description: "Descripción",
          device: "Dispositivo",
          id: "Identificación",
          number: "Número",
          value: "Valor"
        }
      },
      dialogs: {
        editParameter: {
          category: "Categoría",
          messages: {
            cantBeEdited: "Este parámetro no se puede editar",
            changesNeedApproval:
              "Tus cambios necesitarán la aprobación de un operario antes de que surtan efecto."
          },
          parameterNo: "N.º de parámetro",
          updateinterval: "Intervalo de actualización"
        }
      }
    },
    props: {
      description: "Descripción",
      lastSeen: "Visto por última vez",
      name: "Nombre",
      product: "Producto",
      serialNo: "Número de serie",
      serialNumber: "Número de serie",
      serviceStatus: "Estado del mantenimiento",
      status: "Estado",
      tenant: "Inquilino"
    },
    rental: {
      lease: {
        notDefined: {
          description:
            "Para añadir un arrendamiento, pulse el botón de abajo.<br/>Esto le guiará a través de los pasos necesarios para utilizar la función de arrendamiento de su máquina.",
          title: "No se ha definido ningún arrendamiento"
        },
        notEnabled: {
          description:
            "El alquiler de la máquina se puede comprar como complemento.<br />Para obtener más información, ponte en contacto con",
          title:
            "Actualmente, la función de alquiler no está habilitada en esta máquina."
        },
        notSupportingDate:
          "Esta máquina solo admite arrendamiento por Horas del motor",
        settings: {
          actions: {
            add: "Añadir ajustes del arrendamiento",
            edit: "Editar los ajustes del arrendamiento",
            remove: "Eliminar los ajustes del arrendamiento"
          },
          configuredLeaseSettings: "Ajustes del arrendamiento configuradas.",
          confirm: {
            description:
              " ¿Estás seguro de que deseas aplicar el arrendamiento? Esto puede afectar a la capacidad de funcionamiento de la máquina. Asegúrate de que la máquina esté en una posición que no provoque ningún daño ni lesión a las personas ni al equipo.",
            title: "Confirmar actualización del arrendamiento"
          },
          description:
            " Imponer restricciones a la máquina después de que se hayan alcanzado las horas del motor especificadas.",
          engineHours: "Horas del motor",
          engineHoursHover:
            "Las últimas Horas de motor conocidas indicadas por la máquina",
          headers: {
            active: "Activo",
            requested: "Solicitado"
          },
          leaseEnd: "Finalización del arrendamiento",
          leaseSettingsReportedByMachine:
            "Ajustes del arrendamiento indicados por la máquina.",
          leaseStatusDescription: {
            applied: "Se han aplicado los cambios",
            notApplicable: "N/A",
            pending: "Esperando al dispositivo"
          },
          machineRestrictions: "Restricciones de la máquina",
          messages: {
            emailNotificationHasBeenSent:
              "Se ha enviado una notificación por correo electrónico",
            leaseUpdateRequestHasBeenSent:
              "Se ha enviado una solicitud de actualización del arrendamiento a la máquina. Se enviará una notificación cuando se haya aplicado."
          },
          restrictionTypes: {
            blinkLights: "Luces intermitentes",
            disableToolA: "Desactivar herramienta A",
            reduceMovementSpeedToLvl3:
              "Reducir la velocidad de movimiento al nivel de reducción de velocidad 3",
            stopEngineAfter: "Parar motor al cabo de"
          },
          sendEmailNotificationAboutLeaseChange:
            "¿Deseas enviar una notificación por correo electrónico ahora para informar al operario de que los ajustes del arrendamiento están a punto de cambiar?",
          title: "Ajustes del arrendamiento"
        }
      }
    },
    service: "Mantenimiento",
    system: {
      newVersionAvailable: "Puede descargar una versión nueva.",
      newVersionDetails: "Versión {version}, publicada {releaseDate}.",
      noDataDescription:
        "Esto es normal cuando la máquina es nueva y aún no ha informado de susvalores. Vuelve a verificarlo al cabo de un par de horas del motor."
    },
    tabHeaders: {
      alarms: "Alarmas",
      events: "Eventos",
      lease: "Arrendamiento",
      location: "Ubicación",
      measurements: "Mediciones",
      notifications: "Notificaciones",
      service: "Mantenimiento",
      serviceAgreement: null,
      settings: "Ajustes"
    },
    updated: "Actualizado {TIME}"
  }
}
