export default {
  home: {
    documents: {
      description: "La bibliothèque complète de tous nos modèles Brokk."
    },
    greeting: "Bienvenue sur My Brokk",
    machines: {
      description: "Consultez les informations relatives à vos engins Brokk.",
      register: "Enregistrer un engin"
    },
    webshop: {
      description:
        "Achetez des pièces de rechange sur notre boutique en ligne.",
      noAccess: {
        description:
          "Contactez votre administrateur local pour en savoir plus.",
        title: "L’accès à la boutique en ligne n’a pas été accordé"
      }
    }
  }
}
