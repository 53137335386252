<template>
  <v-container>
    <v-row>
      <!-- info window when rental is not enabled on the machine -->
      <v-overlay
        v-if="!isRentalEnabled"
        absolute
        color="#fff"
        opacity=".9"
        class="text--primary"
      >
        <v-col cols="10" offset="1">
          <div class="text-center">
            <v-icon color="grey darken-3">mdi-key-variant</v-icon>
          </div>
          <h1 class="title d-flex mb-5">
            <v-icon color="primary" class="mr-2"
              >mdi-information-outline</v-icon
            >
            {{ $t("machines.rental.lease.notEnabled.title") }}
          </h1>
          <p class="pl-8">
            <span v-html="$t('machines.rental.lease.notEnabled.description')">
            </span
            >&nbsp;
            <a :href="mailToLink">{{ brokkEmail }}</a>
          </p>
        </v-col>
      </v-overlay>

      <!-- Rental tabs -->
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          background-color="white"
          color="black accent-4"
          left
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="tab of tabs" :key="tab.href" :href="tab.href">
            <v-icon class="mr-2" small>{{ tab.icon }}</v-icon>
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <!-- LEASE -->
      <v-tab-item value="lease">
        <v-card flat outlined class="mt-2">
          <v-card-title>
            {{ $t("machines.rental.lease.settings.title") }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t("machines.rental.lease.settings.description") }}
          </v-card-subtitle>
          <v-card-text>
            <v-card flat outlined>
              <v-card-text class="black--text">
                <!-- info window when no lease has been defined yet -->
                <v-overlay
                  v-if="showLeaseOverlay"
                  absolute
                  color="#fff"
                  opacity=".9"
                  class="text--primary"
                >
                  <v-col cols="10" offset="1" class="justify-self-center">
                    <div class="text-center mb-2">
                      <v-icon color="grey darken-3">mdi-key-variant</v-icon>
                    </div>
                    <div class="text-center">
                      <h1 class="title d-flex justify-center mb-5">
                        <v-icon color="primary" class="mr-2"
                          >mdi-information-outline</v-icon
                        >
                        {{ $t("machines.rental.lease.notDefined.title") }}
                      </h1>
                      <span
                        v-html="
                          $t('machines.rental.lease.notDefined.description')
                        "
                      >
                      </span>
                    </div>
                    <div class="text-center pt-8">
                      <v-btn
                        @click="onEditLease"
                        :disabled="!canEditRental"
                        color="secondary accent--text"
                      >
                        <v-icon left>mdi-plus-circle</v-icon>
                        {{ $t("machines.rental.lease.settings.actions.add") }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-overlay>

                <v-row>
                  <v-col cols="6" md="6" class="align-self-center">
                    <span class="subtitle-1">{{
                      $t("machines.rental.lease.settings.machineRestrictions")
                    }}</span>
                  </v-col>
                  <v-col cols="6" md="6" class="d-flex justify-end">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-btn
                            icon
                            @click="refreshRental"
                            :loading="loadingRental"
                            :disabled="loadingRental || editLease"
                          >
                            <v-icon>mdi-cached</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <span>{{ $t("common.actions.refresh") }}</span>
                    </v-tooltip>

                    <v-tooltip top v-if="canEditRental">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-btn
                            icon
                            @click="onEditLease"
                            :disabled="loadingRental || editLease"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <span>
                        {{ $t("machines.rental.lease.settings.actions.edit") }}
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      v-if="
                        rental !== null &&
                          rental.lease !== null &&
                          canRemoveRental
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-btn
                            icon
                            @click.stop="removeLease = true"
                            :disabled="loadingRental || editLease"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <span>{{
                        $t("machines.rental.lease.settings.actions.remove")
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="3" class="pl-7">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" class="subtitle-2">
                          <span>{{
                            $t("machines.rental.lease.settings.headers.active")
                          }}</span>
                        </span>
                      </template>
                      <span>{{
                        $t(
                          "machines.rental.lease.settings.leaseSettingsReportedByMachine"
                        )
                      }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" class="subtitle-2">
                          <span>{{
                            $t(
                              "machines.rental.lease.settings.headers.requested"
                            )
                          }}</span>
                        </span>
                      </template>
                      <span>{{
                        $t(
                          "machines.rental.lease.settings.configuredLeaseSettings"
                        )
                      }}</span>
                    </v-tooltip></v-col
                  >

                  <v-col cols="6" md="3">
                    <span class="subtitle-2">
                      {{ $t("machines.dataTable.headers.description") }}
                    </span>
                  </v-col>
                </v-row>

                <v-row v-for="rType in restrictionTypes" :key="rType.Value">
                  <v-col cols="6" md="3" class="pl-7">
                    <v-checkbox
                      :disabled="true"
                      class="ma-0 pa-0"
                      hide-details
                      v-model="reportedRestrictions"
                      :value="rType.value"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-checkbox
                      :disabled="!editLease || loadingRental"
                      class="ma-0 pa-0"
                      hide-details
                      v-model="selectedRestrictions"
                      :value="rType.value"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" md="6">
                    <span class="body-2">{{ rType.text }}</span>
                  </v-col>
                  <v-col cols="12" class="d-md-none">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row>
                  <v-col cols="12" md="6" class="pb-0">
                    <span class="subtitle-1">{{
                      $t("machines.rental.lease.settings.leaseEnd")
                    }}</span>
                    <v-radio-group
                      :disabled="
                        !editLease || loadingRental || !isRentalDateSupported
                      "
                      v-model="leaseEndType"
                      row
                      mandatory
                      @change="onLeaseTypeChange"
                      class="mt-2 ml-0 pl-4"
                    >
                      <v-radio
                        key="1"
                        :label="
                          $t('machines.rental.lease.settings.engineHours')
                        "
                        value="engineHour"
                      ></v-radio>
                      <v-radio
                        v-if="isRentalDateSupported"
                        key="2"
                        label="Date"
                        value="date"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="pb-0 mt-2 align-self-center"
                    v-if="
                      !isRentalDateSupported &&
                        !showLeaseOverlay &&
                        isRentalEnabled
                    "
                  >
                    <div class="lease-info pa-2">
                      <v-icon color="accent" left>mdi-information</v-icon>
                      <span class="accent--text">{{
                        $t("machines.rental.lease.notSupportingDate")
                      }}</span>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="3" class="pl-7">
                    <span class="subtitle-2">
                      {{ $t("machines.rental.lease.settings.headers.active") }}
                    </span>
                  </v-col>
                  <v-col cols="6" md="3">
                    <span class="subtitle-2">
                      {{
                        $t("machines.rental.lease.settings.headers.requested")
                      }}
                    </span>
                  </v-col>
                </v-row>
                <div v-if="!isLeaseTypeDate">
                  <v-row>
                    <v-col cols="6" md="3" class="pt-0 pl-7">
                      <div class="mt-2">
                        {{ reportedLeaseEndSeconds | engineHourFilter }}
                      </div>
                    </v-col>
                    <v-col cols="6" md="3" class="pt-0">
                      <v-text-field
                        :disabled="!editLease || loadingRental"
                        v-model="leaseEndHours"
                        prepend-icon="mdi-engine"
                        placeholder="-"
                        dense
                        :rules="[validationRules.leaseHours]"
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <div
                        class="pa-2 align-self-center"
                        v-if="!showLeaseOverlay && isRentalEnabled"
                      >
                        <span class="accent--text">
                          {{
                            $t(
                              "machines.rental.lease.settings.engineHoursHover"
                            )
                          }}:
                          {{ machine.engineSeconds | engineHourFilter }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row>
                    <v-col cols="6" md="3" class="pt-0 pl-7">
                      <div class="mt-2">
                        {{ reportedLeaseEndDate }}
                      </div>
                    </v-col>
                    <v-col cols="6" md="3" class="pt-0">
                      <v-menu
                        ref="datePickerMenu"
                        :disabled="!editLease || loadingRental"
                        v-model="datePickerMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="leaseEndDate"
                            readonly
                            prepend-icon="mdi-calendar"
                            :placeholder="$t('machines.dataTable.headers.date')"
                            v-bind="attrs"
                            dense
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          no-title
                          v-model="leaseEndDate"
                          @input="datePickerMenu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions v-if="editLease">
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loadingRental"
                  :disabled="disableSaveLease"
                  @click="onSaveLease"
                  >{{ $t("common.actions.apply") }}
                </v-btn>
                <v-btn :disabled="loadingRental" @click="cancelEditLease">
                  {{ $t("common.actions.cancel") }}
                </v-btn>
              </v-card-actions>
            </v-card>
            <ConfirmRemoveDialog
              :itemName="leaseSettings"
              confirmRemoveEvent="confirmEvent"
              :show="removeLease"
              @close="removeLease = false"
              v-on:confirmEvent="onRemoveLease"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- NOTIFICATIONS -->
      <v-tab-item value="notifications">
        <NotificationSettings
          :disabled="!hasLeaseSettings"
          :disabledTitle="$t('machines.rental.lease.notDefined.title')"
          :disabledText="$t('machines.notifications.settings.noLeaseAdded')"
          :serialNo="machine.serialNo"
          :notification="notification"
          notificationType="lease-info"
          :canEditItem="canEditRental"
          :canRemoveItem="canRemoveRental"
          v-on:save="onSaveNotification"
          v-on:cancel="onCancelNotification"
          v-on:remove="onRemoveNotification"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
      v-model="snackbar"
      :timeout="1500"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <LeaseApplyConfirmDialog ref="leaseApplyConfirm" />
  </v-container>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import colors from "vuetify/lib/util/colors"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"
import LeaseApplyConfirmDialog from "@/dialogs/LeaseApplyConfirmDialog"
import NotificationSettings from "@/components/NotificationSettings"

let validationRules = null

export default {
  name: "Rental",
  props: ["machine"],
  components: {
    ConfirmRemoveDialog,
    NotificationSettings,
    LeaseApplyConfirmDialog
  },
  data() {
    return {
      tab: "lease",
      validationRules: validationRules,
      valid: false,
      // Lease
      editLease: false,
      removeLease: false,

      selectedRestrictions: [],
      leaseEndHours: "", // String, tied to the text input field
      leaseEndDate: "", // String, tied to the date selection

      datePickerMenu: false,
      leaseEndType: "engineHour",

      // Events
      rentalEvents: [],

      // Notification stuff
      notification: null,

      recipients: [],

      isSendingNotification: false,

      notificationHistory: [],

      snackbar: false,
      snackbarText: ""
    }
  },
  created() {
    this.fetchData()
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    tabs() {
      return [
        {
          href: "#lease",
          icon: "mdi-key-variant",
          title: this.$t("machines.tabHeaders.lease")
        },
        {
          href: "#notifications",
          icon: "mdi-bell-alert-outline",
          title: this.$t("machines.tabHeaders.notifications")
        }
      ]
    },
    restrictionTypes() {
      return [
        {
          text:
            this.$t(
              "machines.rental.lease.settings.restrictionTypes.stopEngineAfter"
            ) +
            " " +
            this.$tc("common.units.second", 30),
          value: "1"
        },
        {
          text:
            this.$t(
              "machines.rental.lease.settings.restrictionTypes.stopEngineAfter"
            ) +
            " " +
            this.$tc("common.units.second", 300),
          value: "2"
        },
        {
          text: this.$t(
            "machines.rental.lease.settings.restrictionTypes.disableToolA"
          ),
          value: "4"
        },
        {
          text: this.$t(
            "machines.rental.lease.settings.restrictionTypes.reduceMovementSpeedToLvl3"
          ),
          value: "8"
        },
        {
          text: this.$t(
            "machines.rental.lease.settings.restrictionTypes.blinkLights"
          ),
          value: "16"
        }
      ]
    },
    leaseSettings() {
      return this.$t("machines.rental.lease.settings.title")
    },
    brokkEmail() {
      return this.$store.getters.brokkEmail
    },
    mailToLink() {
      return `mailto:${this.brokkEmail}?subject=Brokk Connect: Rental for ${this.machine.productName} with serial number ${this.machine.serialNo}`
    },
    lease() {
      return {
        active: true,
        status: this.$t("machines.rental.lease.settings.headers.active")
      }
    },
    canEditRental() {
      return this.$store.getters.canEditRental
    },
    canRemoveRental() {
      return this.$store.getters.canRemoveRental
    },
    rental() {
      return this.$store.getters.rental
    },
    isRentalEnabled() {
      return this.rental !== null && this.rental.isEnabled
    },
    isRentalDateSupported() {
      return this.isRentalEnabled && this.rental.isDateSupported
    },
    isLeaseTypeDate() {
      return this.leaseEndType === "date"
    },
    hasLeaseSettings() {
      return this.rental !== null && this.rental.lease !== null
    },
    reportedRestrictions() {
      if (this.rental !== null && this.rental.reported !== null) {
        return this.rental.reported.restrictions
      }
      return []
    },
    reportedLeaseEndSeconds() {
      if (this.rental != null && this.rental.reported != null) {
        return this.rental.reported.endEngineHours * 3600
      }
      return 0
    },
    reportedLeaseEndDate() {
      if (this.rental != null && this.rental.reported != null) {
        if (this.rental.reported.endDate?.length > 0) {
          return this.rental.reported.endDate
        }
        return "-"
      }
      return "-"
    },
    leaseStatus() {
      var status = ""

      if (this.rental != null && this.rental.lease != null) {
        // There is a lease configured, let's compare with what the machine has reported
        if (
          this.rental.lease.endEngineHours !==
            this.rental.reported.endEngineHours ||
          this.rental.lease.restrictions.length !==
            this.rental.reported.restrictions.length
        ) {
          return "pending"
        } else {
          // Make a copy of the arrays so we can sort then without side effects
          var expectedRestrictions = this.rental.lease.restrictions
            .slice()
            .sort()
          var actualRestrictions = this.rental.reported.restrictions
            .slice()
            .sort()

          // Check that the arrays contain the same strings
          for (let ix = 0; ix < expectedRestrictions.length; ix++) {
            if (expectedRestrictions[ix] !== actualRestrictions[ix]) {
              return "pending"
            }

            // Still here. then all is good.
            return "applied"
          }
        }
      } else {
        status = "N/A"
      }

      return status
    },
    loadingRental() {
      return this.$store.getters.loadingRental
    },
    disableSaveLease() {
      const hasEndHour =
        this.leaseEndType !== "date" && this.leaseEndHours?.length > 0
      const hasEndDate =
        this.leaseEndType === "date" && this.leaseEndDate?.length > 0
      const hasEndValue = hasEndHour || hasEndDate

      return (
        this.loadingRental ||
        this.selectedRestrictions.length === 0 ||
        !hasEndValue
      )
    },
    showLeaseOverlay() {
      return this.isRentalEnabled && !this.hasLeaseSettings && !this.editLease
    }
  },
  watch: {
    rental() {
      if (this.rental.lease !== null) {
        this.leaseEndHours = this.rental.lease.endEngineHours
        this.leaseEndDate = this.rental.lease.endDate
        if (this.leaseEndDate !== undefined && this.leaseEndDate.length > 0) {
          this.leaseEndType = "date"
        } else {
          this.leaseEndType = "engineHour"
        }
        this.selectedRestrictions = this.rental.lease.restrictions
      } else {
        // Set default values
        this.leaseEndHours = ""
        this.leaseEndDate = ""
        this.leaseEndType = "engineHour"
        this.selectedRestrictions = []
      }
      if (this.rental.notification !== null) {
        this.recipients = this.rental.notification.recipients
      } else {
        // Set default values
        this.recipients = []
      }
    }
  },
  methods: {
    fetchData() {
      const params = {
        tenantUid: this.$route.params.tenantUid,
        serialNo: this.$route.params.serialNo
      }

      this.$store
        .dispatch("fetchRental", params)
        .then(() => {
          this.notification = this.$store.getters.rental.notification
        })
        .catch(() => {
          // Error already displayed by dispatched action.
        })
    },
    refreshRental() {
      this.fetchData()
    },
    getToolTip() {
      switch (this.leaseStatus) {
        case "applied":
          return this.$t(
            "machines.rental.lease.settings.leaseStatusDescription.applied"
          )
        case "pending":
          return this.$t(
            "machines.rental.lease.settings.leaseStatusDescription.pending"
          )
        default:
          return this.$t(
            "machines.rental.lease.settings.leaseStatusDescription.notApplicable"
          )
      }
    },
    getColor() {
      switch (this.leaseStatus) {
        case "applied":
          return colors.success
        case "pending":
          return colors.primary
        default:
          return ""
      }
    },
    getIcon() {
      switch (this.leaseStatus) {
        case "applied":
          return "mdi-check"
        case "pending":
          return "mdi-swap-vertical"
        //return "mdi-clock-alert"
        default:
          return ""
      }
    },
    onEditLease() {
      if (this.recipients.length > 0) {
        var dialog = {
          title: this.$t("machines.rental.lease.settings.actions.edit"),
          message: this.$t(
            "machines.rental.lease.settings.sendEmailNotificationAboutLeaseChange"
          ),
          icon: "mdi-help-circle",
          buttons: [
            {
              label: this.$t("common.actions.yes"),
              action: () => {
                this.editLease = true

                this.$store
                  .dispatch("sendEmailNotification", {
                    serialNo: this.machine.serialNo,
                    type: "lease-change"
                  })
                  .then(() => {
                    this.snackbarText = this.$t(
                      "machines.rental.lease.settings.messages.emailNotificationHasBeenSent"
                    )
                    this.snackbar = true
                  })
                  .catch(() => {
                    // Error already displayed by sendEmailNotification.
                  })
                  .finally(() => {
                    this.isSendingNotification = false
                  })
              }
            },
            {
              label: this.$t("common.actions.no"),
              action: () => {
                this.editLease = true
                return false
              }
            }
          ]
        }
        this.$store.dispatch("setGenericDialog", dialog)
      } else {
        this.editLease = true
      }
    },
    onLeaseTypeChange() {
      if (this.leaseEndType === "date") {
        this.leaseEndHours = 0
      }
      if (this.leaseEndType === "engineHour") {
        this.leaseEndDate = ""
      }
    },
    async onSaveLease() {
      const confirm = await this.$refs.leaseApplyConfirm.open()
      if (!confirm) {
        return
      }

      let rentalItem = {
        tenantUid: this.rental.tenantUid,
        serialNo: this.rental.serialNo,
        lease: {
          endEngineHours: Number.parseInt(this.leaseEndHours),
          endDate: this.leaseEndDate,
          restrictions: this.selectedRestrictions
        }
      }

      this.$store
        .dispatch("saveLease", rentalItem)
        .then(() => {
          var dialog = {
            message: this.$t(
              "machines.rental.lease.settings.messages.leaseUpdateRequestHasBeenSent"
            ),
            buttons: []
          }
          this.$store.dispatch("setGenericDialog", dialog)
        })
        .catch(() => {
          // Error already displayed by dispatched action.
        })
        .finally(() => {
          this.editLease = false // Hide the edit row
          this.refreshRental()
        })
    },
    cancelEditLease() {
      this.editLease = false
      this.refreshRental()
    },
    onRemoveLease() {
      this.$store
        .dispatch("removeLease", this.rental)
        .then(() => {
          var dialog = {
            message: this.$t(
              "machines.rental.lease.settings.messages.leaseUpdateRequestHasBeenSent"
            ),
            buttons: []
          }
          this.$store.dispatch("setGenericDialog", dialog)
        })
        .catch(() => {
          // Error already displayed by dispatched action.
        })
        .finally(() => {
          this.removeLease = false // Hide the delete button
          this.refreshRental()
        })
    },
    onSaveNotification(notifParams) {
      const params = {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo,
        notification: notifParams
      }

      this.$store
        .dispatch("saveRentalNotification", params)
        .then(() => {
          this.snackbarText = this.$t(
            "machines.notifications.settings.messages.notificationSettingsSaved"
          )
          this.snackbar = true
          this.fetchData()
        })
        .catch(() => {
          // Error already displayed by dispatched action.
        })
    },
    onCancelNotification() {
      this.refreshRental()
    },
    onRemoveNotification() {
      const params = {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo,
        notification: this.notification
      }

      this.$store
        .dispatch("removeRentalNotification", params)
        .then(() => {
          this.snackbarText = this.$t(
            "machines.notifications.settings.messages.notificationSettingsRemoved"
          )
          this.snackbar = true
          this.refreshRental()
        })
        .catch(() => {
          // Error already displayed by dispatched action.
        })
    }
  }
}
</script>
<style>
.lease-info {
  background-color: #ffcd00;
  border-color: #ffcd00;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  line-height: 20px;
  border-radius: 8px;
  height: auto;
}
.lease-info > span {
  margin: 4px;
}
</style>
