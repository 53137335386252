import fetchClient from "@/utils/fetchClient"

export default {
  state: {
    systemInfo: [],
    softwareInfo: {},
    loadingSystemInfo: false
  },
  mutations: {
    setSystemInfo(state, payload) {
      state.systemInfo = payload
    },
    setSoftwareInfo(state, payload) {
      state.softwareInfo = payload
    },
    setLoadingSystemInfo(state, payload) {
      state.loadingSystemInfo = payload
    }
  },
  actions: {
    fetchSystemInfo(context, payload) {
      context.commit("setLoadingSystemInfo", true)

      const { tenantUid, serialNo } = payload

      fetchClient(this.state.$i18n)
        .get(`/api/v1/tenants/${tenantUid}/machines/${serialNo}/system`)
        .then(data => {
          context.commit("setSystemInfo", data.components)
          context.commit("setSoftwareInfo", data.software)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoadingSystemInfo", false)
        })
    }
  },
  getters: {
    systemInfo(state) {
      return state.systemInfo
    },
    softwareInfo(state) {
      return state.softwareInfo
    },
    loadingSystemInfo(state) {
      return state.loadingSystemInfo
    }
  }
}
