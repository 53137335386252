import fetchClient from "@/utils/fetchClient"

export default {
  state: {
    maintenance: null,
    loadingMaintenance: false
  },
  mutations: {
    setMaintenance(state, payload) {
      state.maintenance = payload
    },
    setLoadingMaintenance(state, payload) {
      state.loadingMaintenance = payload
    }
  },
  actions: {
    registerService(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .post(
            `/api/v1/tenants/${data.tenantUid}/machines/${data.serialNo}/maintenance`,
            {
              step: parseInt(data.step),
              engineSeconds: parseInt(data.engineHours) * 3600,
              comment: data.comment,
              serviceDate: data.serviceDate
            }
          )
          .then(() => {
            resolve({})
          })
          .catch(error => {
            var errMsg = error.message

            if (error.causedBy) {
              errMsg = error.toString()
            }
            reject(errMsg)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },

    fetchMaintenance(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingMaintenance", true)
        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${params.tenantUid}/machines/${params.serialNo}/maintenance`
          )
          .then(data => {
            const maintenance = data
            maintenance.tenantUid = params.tenantUid
            maintenance.serialNo = params.serialNo
            context.commit("setMaintenance", maintenance)
            resolve(maintenance)
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingMaintenance", false)
          })
      })
    },
    saveMaintenanceNotification(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingMaintenance", true)

        fetchClient(this.state.$i18n)
          .put(
            `/api/v1/tenants/${params.tenantUid}/machines/${params.serialNo}/maintenance/notifications`,
            params.notification
          )
          .then(() => {
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingMaintenance", false)
          })
      })
    },
    removeMaintenanceNotification(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingMaintenance", true)

        // This removes the maintenance notification.
        fetchClient(this.state.$i18n)
          .delete(
            `/api/v1/tenants/${params.tenantUid}/machines/${params.serialNo}/maintenance/notifications`
          )
          .then(() => {
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoadingMaintenance", false)
          })
      })
    }
  },
  getters: {
    maintenance(state) {
      return state.maintenance
    },
    loadingMaintenance(state) {
      return state.loadingMaintenance
    }
  }
}
