<template>
  <v-dialog v-model="dialog" max-width="300" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-cogs</v-icon>
          <span class="title accent--text">{{ $t("machines.dialogs.editSettings.title") }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="onClose">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-1 pb-0">
        <v-form ref="editSettingsForm" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="6" class="pb-0"
                ><span class="caption">{{ $t("machines.dialogs.editSettings.id") }}</span>
              </v-col>
              <v-col cols="6" class="pb-0"
                ><span class="caption">{{ $t("machines.dialogs.editSettings.number") }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0"
                ><span class="body-1 black--text">{{
                  settingsDialog.item.parameterId
                }}</span>
              </v-col>
              <v-col cols="6" class="pt-0"
                ><span class="body-1 black--text">{{
                  settingsDialog.item.oldParameter
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0"
                ><span class="caption pb-0">{{ $t("machines.dialogs.editSettings.category") }} - {{ $t("machines.dialogs.editSettings.device") }}</span>
              </v-col>
              <v-col cols="12" class="pt-0"
                ><span class="body-1 black--text">{{
                  parameterDescription
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0"
                ><span class="caption">{{ $t("machines.dialogs.editSettings.description") }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0"
                ><span class="body-1 black--text">{{
                  settingsDialog.item.description
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  :disabled="isCustomSelectDisabled"
                  v-model="settingsDialog.item.updateInterval"
                  :items="intervalOptions"
                  :label="$t('machines.dialogs.editSettings.updateInterval')"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pl-6 pr-6">
        <v-container class="pa-0">
          <v-row v-if="comment" class="d-flex align-center">
            <v-col class="pl-6 pr-6" cols="9">
              <v-label class="pa-3">
                {{ comment }}
              </v-label>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <span
                v-for="button in settingsDialog.buttons"
                :key="button.label"
              >
                <v-btn
                  @click.stop="
                    onClose()
                    button.action()
                  "
                  class="ma-2"
                >
                  {{ button.label }}
                </v-btn>
              </span>
              <v-btn
                v-if="settingsDialog.buttons.length === 0"
                class="mr-2 mb-2"
                @click.stop="onClose"
              >
                OK
              </v-btn></v-col
            >
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditSettingsDialog",
  props: ["settingsDialog"],
  data() {
    return {
      valid: true,
      dialog: true
    }
  },
  computed: {
    intervalOptions() {
      var defaultOption = {
        text: `${this.$t("common.default")} (${this.settingsDialog.item.defaultInterval})`,
        value: this.settingsDialog.item.defaultInterval
      }

      var customOptions = [
        { text: this.$tc("common.units.second", 10), value: "10s" },
        { text: this.$tc("common.units.second", 15), value: "15s" },
        { text: this.$tc("common.units.second", 30), value: "30s" },
        { text: this.$tc("common.units.second", 60), value: "60s" }
      ]

      return [defaultOption, ...customOptions]
    },
    isCustomSelectDisabled() {
      // Cannot choose the custom option if edit mode forbids it.
      return this.settingsDialog.item.editMode === "never"
    },
    comment() {
      switch (this.settingsDialog.item.editMode) {
        case "never":
          return this.$t("machines.dialogs.editSettings.cantEdit")
        case "operatorConfirm":
          return this.$t("machines.dialogs.editSettings.operatorMustConfirm")
        case "specialMode":
        default:
          return ""
      }
    },
    parameterDescription() {
      let str = `${this.settingsDialog.item.category}`
      if (
        this.settingsDialog.item.device &&
        this.settingsDialog.item.device.length > 0
      ) {
        str += ` - ${this.settingsDialog.item.device}`
      }
      return str
    }
  },
  methods: {
    onClose() {
      this.dialog = false
    },
    onReset() {
      this.settingsDialog.item.updateInterval = this.settingsDialog.item.defaultInterval
    }
  },
  watch: {
    settingsDialog() {
      this.dialog = true
    }
  }
}
</script>
<style>
.v-select__selection--disabled,
.v-text-field__suffix,
.v-input--is-disabled input {
  color: black !important;
}
.v-label {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
