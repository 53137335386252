<template>
  <v-card flat :disabled="disabled">
    <v-card-text class="pl-0 pt-0">
      <CheckItemsFilterMenu
        :filterName="$t('machines.dataTable.headers.product')"
        :availableItems="availableProducts"
        :selectedItems.sync="products"
      />
    </v-card-text>
    <v-row>
      <v-col cols="12" v-if="hasFilter">
        Filters:
        <span>
          <v-chip
            v-if="productNamesFilterLabel.length > 0"
            class="ma-2"
            close
            color="primary"
            outlined
            @click:close="onRemoveProductsFilter"
          >
            {{ productNamesFilterLabel }}
          </v-chip>
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CheckItemsFilterMenu from "@/components/filters/CheckItemsFilterMenu"

export default {
  props: [
    "availableProducts",
    "selectedProducts",
    "disabled",
    "isFiltered",
    "clearFilter"
  ],
  components: {
    CheckItemsFilterMenu
  },
  data() {
    return {
      products: [],
      toggle: ""
    }
  },
  created() {
    this.products = this.selectedProducts
  },
  computed: {
    hasFilter() {
      const isFiltered =
        this.products.length > 0 || this.$emit("update:isFiltered", isFiltered)
      return isFiltered
    },
    productNamesFilterLabel() {
      const names = this.products?.map(p => p.label)
      if (names.length > 0) {
        return `${this.$t("machines.dataTable.headers.product")}: ${names.join(
          ", "
        )}`
      }
      return ""
    }
  },
  methods: {
    onRemoveProductsFilter() {
      this.products = []
      this.$emit("update:selectedProducts", this.products)
      this.$emit("filterChanged", {})
    }
  },
  watch: {
    products(newValue) {
      this.$emit("update:selectedProducts", newValue)
      this.$emit("filterChanged", {})
    },
    clearFilter(newValue) {
      if (newValue != this.toggle) {
        this.onRemoveProductsFilter()
        this.toggle = newValue
      }
    }
  }
}
</script>

<style></style>
