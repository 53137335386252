<template>
  <v-card>
    <v-card-title>
      <div class="text-body-1 font-weight-bold black--text text-uppercase">
        {{ appTitle }}
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <HomeKpi
            :title="$t('tenant.menuItems.machines')"
            :value="numMachines"
            :href="machinesLink"
            :loading="isLoading"
          />
        </v-col>
        <v-col>
          <HomeKpi
            :title="$t('machines.maintenance.service.overdue')"
            :value="numOverdue"
            :href="machinesLink"
            :loading="isLoading"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import HomeKpi from "@/components/home/HomeKpi"

export default {
  components: {
    HomeKpi
  },
  data() {
    return {}
  },
  computed: {
    isLoading() {
      return this.$store.getters.loadingMachines
    },
    appTitle() {
      return this.$store.getters.appTitle
    },
    tenantUid() {
      return this.$store.getters.tenantRoot?.uid
    },
    machinesLink() {
      return `/#/tenants/${this.tenantUid}/machines`
    },
    usersLink() {
      return `/#/tenants/${this.tenantUid}/users`
    },
    numMachines() {
      return this.$store.getters.machines?.length
    },
    numUsers() {
      return this.$store.getters.users?.length
    },
    numOverdue() {
      const machines = this.$store.getters.machines
      let cnt = 0

      for (let ix = 0; ix < machines?.length; ix++) {
        if (machines[ix].unprovisioned) {
          // Unprovisioned machines do not have any service status
          continue
        }
        const serviceStatus = machines[ix].serviceStatus
        for (let ss = 0; ss < serviceStatus?.length; ss++) {
          if (serviceStatus[ss] === "overdue") {
            cnt += 1
            break
          }
        }
      }
      return cnt
    }
  }
}
</script>

<style></style>
