<template>
  <v-card flat>
    <v-card-text>
      <div v-if="systemInfo.length == 0">
        <v-row align="center" justify="center">
          <v-col cols="11" sm="9">
            <div class="text-center"></div>
            <h1 class="title d-flex mb-5">
              <v-icon color="primary" class="mr-2"
                >mdi-information-outline</v-icon
              >
              {{ $t("$vuetify.noDataText") }}
            </h1>
            <p>
              {{ $t("machines.system.noDataDescription") }}
            </p>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col md="6" cols="12">
            <InfoBox title="Gateway" :info="systemInfoGateway"></InfoBox>
          </v-col>
          <v-col md="6" cols="12">
            <InfoBox
              title="GSM / LTE"
              :info="systemInfoGsmConnectivity"
            ></InfoBox>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <InfoBox title="PLC" :info="systemInfoPlc"></InfoBox>
          </v-col>
          <v-col md="6" cols="12"> </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import InfoBox from "../../components/widgets/InfoBox"

export default {
  name: "SystemInfo",
  props: ["machine"],
  components: { InfoBox },
  data() {
    return {}
  },
  computed: {
    systemInfo() {
      return this.$store.getters.systemInfo
    },
    systemInfoGateway() {
      for (let i = 0; i < this.systemInfo.length; i++) {
        const si = this.systemInfo[i]
        if (si.category.toLowerCase() === "electronics.gateway") {
          return si.values
        }
      }
      return []
    },
    systemInfoGsmConnectivity() {
      for (let i = 0; i < this.systemInfo.length; i++) {
        const si = this.systemInfo[i]
        if (si.category.toLowerCase() === "electronics.cellular") {
          return si.values
        }
      }
      return []
    },
    systemInfoPlc() {
      for (let i = 0; i < this.systemInfo.length; i++) {
        const si = this.systemInfo[i]
        if (si.category.toLowerCase() === "electronics.d1") {
          return si.values
        }
      }
      return []
    },
    gatewaySoftware() {
      return this.$store.getters.softwareInfo.gateway
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$store.dispatch("fetchSystemInfo", {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo
      })
    },
    isGatewayInfo(si) {
      return si.category.toLowerCase() === "electronics.gateway"
    }
  }
}
</script>
