import { store } from "../store"

export default parameterId => {
  let description = "-"

  if (
    parameterId !== undefined &&
    parameterId != null &&
    parameterId.length > 0
  ) {
    const eventMeta = store.getters.eventMetadata

    /* Default to the parameterId in case no description exists */
    description = parameterId

    if (eventMeta !== undefined && eventMeta !== null) {
      const info = eventMeta.find(item => item.parameterId === parameterId)

      if (info !== undefined) {
        description = `${info.category} — ${info.description}`
      }
    }
  }
  return description
}
