<template>
  <v-card>
    <v-progress-linear
      :active="isLoadingMap"
      :indeterminate="isLoadingMap"
      absolute
      top
    ></v-progress-linear>

    <v-alert
      v-if="error"
      :type="error.type"
      class="ma-0"
      elevation="2"
      border="left"
      colored-border
      dense
      dismissible
    >
      <div>{{ error.message }}</div>
      <div v-if="error.causedBy" class="subtitle">
        {{ error.causedBy }}
      </div>
    </v-alert>

    <v-container fluid>
      <v-row class="pt-3">
        <v-col xs="auto" class="mr-0">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            :label="$t('common.filters.filter')"
            hide-details
            class="ma-0 pa-0"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="mx-0 px-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="fetchMachineLocations"
                :loading="isLoadingMap"
                :disabled="isLoadingMap"
              >
                <v-icon>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="refreshLoader">
                    <v-icon>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>{{ $t("common.actions.refresh") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

    <div id="myMap"></div>
  </v-card>
</template>

<script>
import {
  createInfoWindow,
  createNoDataToDisplayWindow,
  initialize
} from "@/utils/googlemapsadapter"
import MarkerClusterer from "@google/markerclustererplus"
import InfoWindow from "@/components/InfoWindow"
import fetchClient from "@/utils/fetchClient"

export default {
  props: ["tenantUid"],
  data() {
    return {
      isLoadingMap: true,
      search: null,
      error: null,
      locations: []
    }
  },
  async created() {
    this.fetchMachineLocations()
  },
  computed: {
    selectedTenant() {
      return this.$store.getters.selectedTenant
    },
    filteredMachines() {
      if (this.search?.length > 0) {
        const fm = this.locations.filter(m => m.serialNo.includes(this.search))
        return fm
      }
      return this.locations
    }
  },
  watch: {
    filteredMachines() {
      this.configureMap()
    },
    selectedTenant() {
      this.fetchMachineLocations()
    }
  },
  methods: {
    async fetchMachineLocations() {
      if (this.selectedTenant === null) {
        return
      }

      const url = `/api/v1/tenants/${this.selectedTenant.uid}/machines/locations`

      try {
        this.locations = await fetchClient(this.$i18n).getDataOrThrow(url)
      } catch (error) {
        this.error = { type: "error", ...error }
      }
    },
    async configureMap() {
      this.isLoadingMap = true

      try {
        await initialize()
        const google = window.google

        // Default is to center the map at Brokk AB Headquarters.
        const defaultCenterPosition = new google.maps.LatLng(
          64.7448271,
          21.0342396
        )

        const mapOptions = {
          zoom: 8,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.RIGHT_TOP
          }
        }

        const map = new google.maps.Map(
          document.getElementById("myMap"),
          mapOptions
        )

        const machineInfoWindow = createInfoWindow(
          InfoWindow,
          {
            close: () => {
              machineInfoWindow.hide()
            },
            view: machine => {
              this.$router.push({
                name: "dashboard",
                params: {
                  tenantUid: machine.tenantUid,
                  serialNo: machine.serialNo,
                  sourceView: "map"
                }
              })
            }
          },
          this.$i18n
        )
        machineInfoWindow.setMap(map)

        var markers = []
        var firstMarkerPosition = null

        for (var i = 0; i < this.filteredMachines.length; i++) {
          const machine = this.filteredMachines[i]

          let machineProductName = machine.productName
          if (machine.location === undefined || machine.location === null) {
            // No location available for this machine, skip it.
            continue
          }

          const position = new google.maps.LatLng(
            machine.location.latitude,
            machine.location.longitude
          )

          if (firstMarkerPosition === null) {
            firstMarkerPosition = position
          }

          if (
            machine.properties != null &&
            machine.properties.tags != null &&
            machine.properties.tags.includes("smart_power_plus")
          ) {
            machineProductName += "⁺"
          }

          const toolTipText = `${machineProductName} / ${this.$t(
            "mapInfoWindow.machineSerialNo"
          )} ${machine.serialNo}`

          const marker = new google.maps.Marker({
            position: position,
            icon: `/marker.png?serialNo=${machine.serialNo}`,
            map: map,
            title: toolTipText
          })

          marker.addListener("click", function() {
            machineInfoWindow.show(position, { machine })
          })

          markers.push(marker)
        }

        if (markers.length >= 2) {
          // Got at least 2 markers, thus we will cluster them.
          var clusterOptions = {
            maxZoom: 16,
            zoomOnClick: true,
            minimumClusterSize: 2,
            imagePath: "/m"
          }

          var markerCluster = new MarkerClusterer(map, markers, clusterOptions)

          markerCluster.fitMapToMarkers()
        } else {
          // Zero or 1 marker.
          const centerPos =
            firstMarkerPosition === null
              ? defaultCenterPosition
              : firstMarkerPosition
          map.setCenter(centerPos)

          if (markers.length === 0) {
            map.setZoom(4)
            const msg = `<h3>${this.$t("tenant.noLocationData")}</h3>`
            const win = createNoDataToDisplayWindow(centerPos, msg)
            win.open(map)
          }
        }
      } catch (error) {
        this.error = { type: "error", ...error }
      }
      this.isLoadingMap = false
    }
  }
}
</script>

<style>
#myMap {
  /*width: 75%;*/
  height: 75vh;
}
</style>
