export default {
  documents: {
    ce: {
      subTitle: "CE-Dokumente für Brokk {productId}",
      title: "CE"
    },
    diagrams: {
      subTitle: "Diagramme für Brokk {productId}",
      title: "Diagramme"
    },
    manuals: {
      subTitle: "Handbücher für Brokk {productId}",
      title: "Handbücher"
    },
    menuItems: {
      returnform: "Rücksendeformular",
      serviceBulletins: "Service-Bulletins",
      serviceInstructions: "Serviceanweisungen",
      serviceManuals: "Servicehandbücher",
      serviceProtocols: "Protokolle der Servicearbeiten",
      spareParts: "Ersatzteilliste",
      warranty: "Gewährleistungsansprüche"
    },
    returnform: {
      description1:
        "Verwenden Sie dieses Formular, wenn Sie Produkte an Brokk AB zurücksenden.",
      description2:
        "Bitten Sie Brokk AB um Genehmigung, bevor Sie Produkte zurücksenden.",
      description3:
        "Produkte, die ohne Genehmigung bei Brokk AB eingehen, werden entsorgt.",
      ingress: "Rücksendeformular für Brokk-Produkte.",
      subtitle: "Rücksendeformular für Brokk-Produkte."
    },
    serialNoRange: "Seriennummer: {range}",
    serviceBulletins: {
      action: {
        add: "Service-Bulletins hinzufügen",
        addSerials: "Serien hinzufügen",
        edit: "Service-Bulletins bearbeiten"
      },
      description:
        "Informationen über Verbesserungen, Inspektionen, Reparaturen oder Modifikationen von Brokk-Maschinen und -Werkzeugen.",
      dialogs: {
        errors: {
          couldNotAddServiceBulletins:
            "Konnte keine Service-Bulletins hinzufügen {msg}",
          couldNotRemoveServiceBulletins:
            "Konnte Service-Bulletins nicht entfernen {msg}",
          couldNotUpdateServiceBulletins:
            "Konnte Service-Bulletins nicht aktualisieren {msg}"
        }
      },
      itemName: "Service-Bulletins",
      messages: {
        failedToDownload: "Download von {file} fehlgeschlagen",
        itemAdded: "Service-Bulletin wurde hinzugefügt.",
        itemUpdated: "Service-Bulletins wurden aktualisiert.",
        updateDateInfo: "Aktualisiert {date}",
        updateDocumentInfo: "Dokument {document} "
      },
      subtitle: "Dokumente für Service-Bulletins."
    },
    serviceDocuments: {
      action: {
        addSerials: "Serien hinzufügen"
      },
      addEditItemForm: {
        descriptionLabel: "Beschreibung",
        descriptionPlaceholder: "Beschreibung eingeben",
        fileuploadLabel: "Klicken Sie hier zur Auswahl einer pdf-Datei",
        machinesLabel: "Maschinen",
        partNumberLabel: "Artikelnummer",
        partNumberPlaceholder: "Teilenummer eingeben",
        serialLabel: "Serien",
        serialPlaceholder: "Seriennummern getrennt mit ',' eingeben",
        uploadFilePlaceholder: "Eine Datei hochladen"
      },
      datatable: {
        headers: {
          description: "Beschreibung",
          machines: "Maschinen",
          partNo: "Artikel-Nr.:",
          service: "Service"
        }
      },
      machineSerials: "Maschinen-Seriennummern"
    },
    serviceInstructions: {
      action: {
        add: "Serviceanweisungen hinzufügen",
        addSerials: "Serien hinzufügen",
        edit: "Serviceanweisungen bearbeiten"
      },
      datatable: {
        headers: {
          machines: "Maschinen",
          partNo: "Artikel-Nr.:",
          service: "Service"
        }
      },
      description:
        "Anweisungen zum Einbau, zur Wartung oder zum Einstellen verschiedener Teile und Kits.",
      dialogs: {
        errors: {
          couldNotAddServiceInstructions:
            "Konnte keine Serviceanweisungen hinzufügen {msg}",
          couldNotRemoveServiceInstructions:
            "Konnte keine Serviceanweisungen entfernen {msg}",
          couldNotUpdateServiceInstructions:
            "Konnte keine Serviceanweisungen aktualisieren {msg}"
        }
      },
      itemName: "Serviceanweisungen",
      machineSerials: "Maschinen-Seriennummern",
      messages: {
        failedToDownload: "Download von {file} fehlgeschlagen",
        itemAdded: "Serviceanweisungen wurden hinzugefügt.",
        itemUpdated: "Serviceanweisung wurde aktualisiert.",
        updateDateInfo: "Aktualisiert {date}",
        updateDocumentInfo: "Dokument {document} "
      },
      subtitle: "Dokumente für Serviceanweisungen."
    },
    serviceManuals: {
      description:
        "Handbücher für Service, Wartung und Reparatur. Nur erhältlich für Brokk 40, 90, 110, 150, 180, 250, 330 und SP+.",
      subtitle: "Dokumente für Servicehandbücher."
    },
    serviceProtocols: {
      description1:
        "Hier können Sie alle verfügbaren Protokolle von Servicearbeiten herunterladen.",
      description2:
        "Das Protokoll zu den Servicearbeiten wird zur Durchführung der regelmäßigen Wartung gemäß dem Wartungsplan im Brokk-Handbuch verwendet.",
      ingress: "Serviceprotokolle für Brokk-Geräte",
      subtitle: "Serviceprotokolle für Brokk-Maschinen."
    },
    spareParts: {
      description: "",
      subtitle: "Ersatzteile"
    },
    technicalData: {
      includedInManual:
        "Die technischen Daten sind im Handbuch enthalten. Bitte schauen Sie im Handbuch nach.",
      subTitle: "Technische Daten für Brokk {productId}",
      title: "Technische Daten"
    },
    troubleshooting: {
      includedInManual:
        "Die Hinweise zur Fehlersuche sind im Handbuch enthalten. Bitte schauen Sie im Handbuch nach.",
      subTitle: "Fehlersuche für Brokk {productId}",
      title: "Fehlersuche"
    },
    warranty: {
      description: "Gewährleistungsansprüche",
      ingress: "Gewährleistungsdokumente und Links.",
      subtitle: "Gewährleistungsdokumente und Links."
    }
  }
}
