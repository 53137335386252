<template>
  <v-card class="text-center">
    <v-card-title
      class="secondary d-flex align-center justify-center ma-0 pa-0 text-uppercase text-h6"
    >
      {{ $t("machines.dieselParticulateFilter.info.title") }}
    </v-card-title>
    <v-card-text class="black--text pb-0">
      <v-row no-gutters class="pt-2">
        <v-col cols="12" md="6">
          <DieselParticulateFilterIcon status="ok" size="sm" />
        </v-col>
        <v-col cols="12" md="6" class="d-flex align-center text-start">
          {{ $t("machines.dieselParticulateFilter.info.ok") }}
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="mt-2">
        <v-col cols="12" md="6">
          <DieselParticulateFilterIcon status="warning" size="sm" />
        </v-col>
        <v-col cols="12" md="6" class="d-flex align-center text-start">
          {{ $t("machines.dieselParticulateFilter.info.warning") }}
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="pb-0 mt-2">
        <v-col cols="12" md="6">
          <DieselParticulateFilterIcon status="error" size="sm" />
        </v-col>
        <v-col cols="12" md="6" class="d-flex align-center text-start">
          {{ $t("machines.dieselParticulateFilter.info.error") }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DieselParticulateFilterIcon from "@/components/widgets/DieselParticulateFilterIcon"

export default {
  components: {
    DieselParticulateFilterIcon
  },
  data: () => ({}),
  computed: {}
}
</script>

<style></style>
