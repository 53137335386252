export default {
  reports: {
    actions: {},
    dataTable: {
      headers: {
        modified: "Geändert",
        name: "Name",
        tenant: "Mieter"
      },
      noResultsText: "Keine passenden Berichte"
    },
    itemName: "Bericht"
  }
}
