export default {
  /**
   * Generates data series from measurements data
   * @param {Array} measurementsData
   * @param {Array} series
   */
  makeAreaSeries(measurementsData, series, valueFormatter) {
    // This value is used to detect time gaps between event occurrences
    const thresholdMilliSeconds = 30 * 60 * 1000 // 30 minutes, experimental value TODO: evaluate this

    let previousMeasurementTime = null

    series.forEach(serie => {
      measurementsData.forEach(measurement => {
        if (measurement.parameterId == serie.parameterId) {
          let measurementTime = Date.parse(measurement.receiveTime)

          // Set previous time to measurement time on first run
          if (previousMeasurementTime == null) {
            previousMeasurementTime = measurementTime
          }

          // check if machine probably was offline between measurement times
          let timeDiff = measurementTime - previousMeasurementTime
          if (timeDiff > thresholdMilliSeconds) {
            // Insert null value
            serie.data.push({
              x: previousMeasurementTime + 1,
              y: null
            })
          }
          serie.data.push({
            x: new Date(measurement.receiveTime).getTime(),
            y: valueFormatter(measurement.rawValue)
          })

          previousMeasurementTime = measurementTime
        }
      })
    })
    return series
  }
}
