<template>
  <v-container fluid class="grab-tenant-tree white">
    <v-row class="d-flex align-stretch">
      <v-col cols="auto" class="px-0 pt-0">
        <v-list dense class="pt-0">
          <v-list-item>
            <v-list-item-icon class="mr-2">
              <v-icon dense color="accent">mdi-home-city</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase subtitle-1">
                {{ $t("tenant.menuItems.tenants") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="px-0 mt-0">
            <v-treeview
              active-class="secondary font-weight-medium accent--text"
              class="grab-tenant-tree"
              :active.sync="active"
              :open.sync="open"
              :items="tenantTree"
              transition
              item-key="uid"
              activatable
              dense
              return-object
            >
              <!-- Context menu -->
              <template v-slot:append="{ item }">
                <v-menu
                  left
                  v-if="
                    isTenantActive(item) &&
                      (canAddItem || canEditItem || canRemoveItem)
                  "
                >
                  <template v-slot:activator="{ on: menu }">
                    <v-btn
                      icon
                      color="primary"
                      v-on="menu"
                      class="grab-tenant-action-menu"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <!-- Can add items -->
                    <v-list-item
                      v-if="canAddItem"
                      @click="addItem(item)"
                      class="grab-add-tenant"
                    >
                      <v-list-item-title class="body-2">
                        <v-icon small>mdi-plus</v-icon
                        >{{ $t("common.actions.add") }}
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Can edit items -->
                    <v-list-item v-if="canEditItem" @click="editItem(item)">
                      <v-list-item-title class="body-2">
                        <v-icon small>mdi-pencil</v-icon
                        >{{ $t("common.actions.edit") }}
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Can remove item -->
                    <v-list-item v-if="canRemoveItem" @click="removeItem(item)">
                      <v-list-item-title class="body-2">
                        <v-icon small>mdi-delete</v-icon
                        >{{ $t("common.actions.remove") }}
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Can change parent -->
                    <v-list-item
                      v-if="canEditItem && !item.children && isAdmin"
                      @click="changeParent(item)"
                    >
                      <v-list-item-title class="body-2">
                        <v-icon small>mdi-city-switch</v-icon
                        >{{ $t("common.actions.move") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-treeview>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Dialog to add a new or edit an existing tenant -->
    <TenantDialog
      :parent="parentItem"
      :tenant="tenantItem"
      :show="showTenantDialog"
      @save="onSave"
      @cancel="onCancel"
    />

    <TenantDialogChangeParent
      :parent="parentItem"
      :tenant="tenantItem"
      :show="showChangeParentDialog"
      @save="onSaveChangeParent"
      @cancel="onCancel"
    />

    <ConfirmRemoveDialog
      :itemName="$t('tenant.itemName')"
      :item="tenantItem"
      removeAction="removeTenant"
      :show="showRemoveDialog"
      @close="showRemoveDialog = false"
    />
  </v-container>
</template>

<script>
import TenantDialog from "@/dialogs/TenantDialog"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"
import TenantDialogChangeParent from "@/dialogs/TenantDialogChangeParent"

export default {
  components: {
    TenantDialog,
    ConfirmRemoveDialog,
    TenantDialogChangeParent
  },
  data() {
    return {
      active: [],
      open: [],

      parentItem: {},
      tenantItem: {},
      showTenantDialog: false,
      showRemoveDialog: false,
      showChangeParentDialog: false,
      snackbar: false,
      snackbarText: "",
    }
  },
  created() {
    if (this.$store.getters.tenantTree.length === 0) {
      this.$store
        .dispatch("loadTenantTree")
        .then(() => {
          this.$store.dispatch(
            "fetchMachines",
            this.$store.getters.tenantRoot.uid
          )
          this.$store
            .dispatch("setSelectedTenant", this.$store.getters.tenantTree[0])
            .then(() => {
              this.active = [this.tenantTree[0]]
              this.open = [this.tenantTree[0]]
            })
        })
        .catch(() => {
          // Error already displayed by setSelectedTenant.
        })
    } else if (this.selectedTenant !== null) {
      this.active = [this.selectedTenant]
      this.open = this.openItems
    } else {
      this.active = [this.tenantTree[0]]
      this.open = [this.tenantTree[0]]
    }
  },
  computed: {
    tenantTree() {
      return this.$store.getters.tenantTree
    },
    isTenantReadOnly() {
      if (this.selectedTenant !== null) {
        return this.selectedTenant.readonly
      }
      return true
    },
    selectedTenant() {
      return this.$store.getters.selectedTenant
    },
    canAddItem() {
      return this.$store.getters.canAddTenants
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    canEditItem() {
      return this.$store.getters.canEditTenants
    },
    canRemoveItem() {
      return !this.isTenantReadOnly && this.$store.getters.canRemoveTenants
    },
    openItems() {
      let items = []
      const tenants = this.$store.getters.tenantArray

      items.push(this.active[0])

      let parent = tenants.find(element => {
        return element.uid === this.active[0].parentUid
      })

      // Counter to stop at certain random depth
      let cn = 0
      while (cn < 10 && parent !== undefined) {
        items.push(parent)

        if (parent.parentUid === "0") {
          // Reached root node, no more parents
          break
        }
        parent = tenants.find(element => {
          return element.uid === parent.parentUid
        })
        cn += 1
      }

      items.reverse()
      return items
    }
  },
  methods: {
    isTenantActive(item) {
      if (item !== undefined && this.active.length > 0) {
        return this.active.find(t => t.uid === item.uid)
      }
      return false
    },
    addItem(item) {
      this.parentItem = Object.assign({}, item)

      this.tenantItem = {
        name: "",
        description: "",
        country: "",
        city: ""
      }
      this.showTenantDialog = true
    },
    editItem(item) {
      this.$router.push(`/tenant_details/${item.uid}`).catch(() => {
        // Error navigating to new route: err.message
      })
    },
    removeItem(item) {
      this.tenantItem = Object.assign({}, item)
      this.showRemoveDialog = true
    },
    changeParent(item) {
      this.tenantItem = Object.assign({}, item)
      this.showChangeParentDialog = true
      this.refreshItems()
    },
    onSave(info) {
      this.showTenantDialog = false
      this.snackbarText = info.wasAdded
        ? this.$t("tenant.dialogs.edit.messages.added")
        : this.$t("tenant.dialogs.edit.messages.updated")
      this.snackbar = true
      this.refreshItems()
    },
    async onSaveChangeParent(updatedTenant) {
      this.showChangeParentDialog = false
      this.snackbarText = this.$t("tenant.dialogs.edit.messages.parentChanged")
      this.snackbar = true

      // Close all opened branches - required for correct refresh 
      this.active = [this.tenantTree[0]]
      this.open = [this.tenantTree[0]]
      
      await this.refreshItems()
      
      // Open to updated tenant
      this.active = [updatedTenant]
      this.open = this.openItems
    },
    onCancel() {
      this.showTenantDialog = false
      this.showChangeParentDialog = false
      this.refreshItems()
    },
    async showViewForSelection(item) {
      let subView = "map"
      try {
        if (!this.$store.getters.userDetails) {
          // No user details, which might be the case when the page is reloaded.
          await this.$store
            .dispatch("fetchUserDetails", {
              tenantUid: this.$store.getters.loggedInUser.tenantUid,
              uid: this.$store.getters.loggedInUser.uid
            })
            .then(() => {
              subView =
                this.$store.getters.userDetails?.preferences?.initialView ||
                subView
            })
        } else {
          subView =
            this.$store.getters.userDetails?.preferences?.initialView || subView
        }
      } catch (error) {
        this.setError(error)
        subView = "map"
      }
      const ix = this.$route.path.lastIndexOf("/")
      if (ix > 0) {
        subView = this.$route.path.substring(ix + 1)
      }

      this.$router.push(`/tenants/${item.uid}/${subView}`).catch(() => {
        // Error navigating to new route: err.message
      })
    },
    async refreshItems() {
      this.refreshLoading = true
      await this.$store.dispatch("loadTenantTree", this.tenantUid)
      this.refreshLoading = false
    }
  },
  watch: {
    selectedTenant: function(selItem) {
      if (selItem !== null) {
        this.showViewForSelection(selItem)
      } else {
        this.$router.push("/")
      }
    },
    active: function(val) {
      if (val.length === 0) {
        // User deselected the selected node.
        this.active = [this.selectedTenant]
      } else {
        if (val[0] !== undefined) {
          this.$store.dispatch("setSelectedTenant", val[0])
        } else {
          this.$store.dispatch("setSelectedTenant", null)
        }
      }
    }
  }
}
</script>

<style>
.tenant-treeview {
  overflow: auto;
}
</style>
