<template>
  <v-card flat outlined class="ma-4">
    <v-card-title>
      {{ $t("tenant.customer.title") }}
      <v-spacer></v-spacer>

      <v-tooltip top v-if="canChangeItem && !canAddCustomerNo">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon :disabled="editCustomer" @click="editCustomer = true"
              >mdi-pencil</v-icon
            >
          </span>
        </template>
        <span>{{ $t("tenant.customer.actions.editCustomer") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="canChangeItem && !canAddCustomerNo">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon
              :disabled="editCustomer"
              @click.stop="showConfirmRemove = true"
              >mdi-delete</v-icon
            >
          </span>
        </template>
        <span>{{ $t("tenant.customer.actions.removeCustomer") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="canAddCustomerNo">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon :disabled="editCustomer" @click="onAddCustomerClick"
              >mdi-plus-circle</v-icon
            >
          </span>
        </template>
        <span>{{ $t("tenant.customer.actions.addCustomer") }}</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text class="pb-0">
      <v-form
        ref="tenantCustomerForm"
        v-model="valid"
        :disabled="!editCustomer"
      >
        <v-row v-if="isLoggedInUserAdmin">
          <v-col cols="12" class="pb-0">
            <div>
              {{ $t("tenant.customer.labels.customerType") }} :
              {{ customer.customerType }}
            </div>
          </v-col>
        </v-row>
        <div v-if="isDealer">
          <v-row>
            <v-col cols="12">
              <!-- Dealer only has one field: The Monitor customer number -->
              <v-text-field
                v-model="customer.customerNo"
                required
                :label="`${$t('tenant.customer.labels.customerNo')}*`"
                :placeholder="`${$t('tenant.customer.placeholders.customerNo')}*`"
                :rules="[validationRules.monitorCustomerNo]"
                autofocus
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p>
                {{ $t("tenant.customer.labels.dealerAddressDescription") }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="tenant.name"
                :label="`${$t('tenant.customer.labels.endCustomerName')}`"
                disabled
                persistent-hint
                :loading="isUpdating"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="countryLabel"
                :label="`${$t('tenant.dialogs.edit.country')}`"
                disabled
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="customer.customerNo"
                :label="`${$t('tenant.customer.labels.endCustomerNo')}`"
                disabled
                persistent-hint
                :loading="isUpdating"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="customer.priceListId"
                :label="`${$t('tenant.customer.labels.priceListId')}`"
                disabled
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="customer.discount"
                required
                :label="`${$t('tenant.customer.labels.discount')}*`"
                :placeholder="`${$t('tenant.customer.placeholders.discount')}*`"
                :rules="[validationRules.discount]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <TenantCustomerAddress
                :customer="customer"
                :disabled="!editCustomer"
              />
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-alert
              v-if="error"
              :type="error.type"
              class="ma-0"
              elevation="2"
              border="left"
              colored-border
              dense
              dismissible
            >
              <div>{{ error.message }}</div>
              <div v-if="error.causedBy" class="subtitle">
                {{ error.causedBy }}
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="editCustomer" class="pr-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        :disabled="!hasValidInput || isUpdating"
        :loading="isUpdating"
        @click.stop="onSaveClick"
      >
        {{ $t("common.actions.save") }}
      </v-btn>
      <v-btn :disabled="isUpdating" @click.stop="onCancelClick"
        >{{ $t("common.actions.cancel") }}
      </v-btn>
    </v-card-actions>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <CustomerTypeDialog
      :show="showCustomerTypeDialog"
      @save="onSelectedCustomerType"
      @cancel="showCustomerTypeDialog = false"
    />

    <ConfirmRemoveDialog
      :itemName="`${$t('tenant.customer.title')}`"
      confirmRemoveEvent="confirmEvent"
      :show="showConfirmRemove"
      @close="showConfirmRemove = false"
      v-on:confirmEvent="onRemoveClick"
    />
  </v-card>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"
import CustomerTypeDialog from "@/dialogs/CustomerTypeDialog"
import TenantCustomerAddress from "@/views/tenant/TenantCustomerAddress"
import fetchClient from "@/utils/fetchClient"

let validationRules = null

export default {
  data() {
    return {
      valid: false,
      validationRules: validationRules,
      editCustomer: false,
      isUpdating: false,
      isDealer: false,
      showCustomerTypeDialog: false,
      showConfirmRemove: false,
      showCountryWarning: false,
      error: null,
      customer: {},
      snackbar: false,
      snackbarText: ""
    }
  },
  components: {
    TenantCustomerAddress,
    ConfirmRemoveDialog,
    CustomerTypeDialog
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  mounted() {
    this.fetchCustomer()
  },
  computed: {
    canChangeItem() {
      // Must first of all have tenant permissions
      let allowed = this.$store.getters.canEditTenants
      // And must have web shop access granted
      allowed = allowed && this.$store.getters.hasWebshopAccess

      // If the customer to be changed is a dealer, then the logged in user must be an admin to change it
      if (this.customer.customerNo !== undefined && this.customer.isDealer) {
        allowed = allowed && this.isLoggedInUserAdmin
      }
      return allowed
    },
    canAddCustomerNo() {
      return this.customer.customerNo === undefined && this.canChangeItem
    },
    isLoggedInUserAdmin() {
      return this.$store.getters.isAdmin
    },
    tenant() {
      return this.$store.getters.tenant
    },
    hasValidInput() {
      if (this.isDealer) {
        return this.customer.customerNo?.length > 0
      }
      return (
        this.customer.priceListId?.length > 0 &&
        ("" + this.customer.discount).length > 0 &&
        this.customer.addresses?.length > 0
      )
    },
    countryLabel() {
      if (
        this.tenant.country?.length > 0 &&
        this.tenant.countryCode?.length > 0
      ) {
        return `${this.tenant.country} (${this.tenant.countryCode})`
      }
      return ""
    }
  },
  methods: {
    async fetchCustomer() {
      try {
        await this.$store.dispatch("fetchTenantCustomer", {
          tenantUid: this.$route.params.tenantUid
        })

        this.customer = Object.assign({}, this.$store.getters.tenantCustomer)
        this.customer.addresses = Object.assign(
          [],
          this.$store.getters.tenantCustomer.addresses
        )
        this.isDealer = this.customer.isDealer
      } catch (err) {
        this.customer = {}
      }
    },
    onSelectedCustomerType({ customerType }) {
      this.showCustomerTypeDialog = false
      this.customer.customerType = customerType
      this.isDealer = customerType.localeCompare("dealer") === 0
      if (!this.isDealer) {
        this.showEndCustomerDialog()
      } else {
        this.editCustomer = true
      }
    },
    showEndCustomerDialog() {
      var dialog = {
        title: this.$t("tenant.customer.actions.addCustomer"),
        message: this.$t("tenant.customer.labels.addDetailsQuestion"),
        icon: "mdi-help-circle",
        buttons: [
          {
            label: "Yes",
            action: async () => {
              this.isUpdating = true
              this.error = null

              const url = `/api/v1/tenants/${this.tenant.uid}/customer`
              try {
                await fetchClient(this.$i18n).postOrThrow(url, {})
                await this.fetchCustomer()
                this.editCustomer = true
              } catch (error) {
                console.error(error)
                this.error = { type: "error", ...error }
              } finally {
                this.isUpdating = false
              }
            }
          },
          {
            label: "No",
            action: () => {
              // Refresh customer details from the state store
              this.customer = Object.assign(
                {},
                this.$store.getters.tenantCustomer
              )
              return false
            }
          }
        ]
      }

      this.$store.dispatch("setGenericDialog", dialog)
    },
    onAddCustomerClick() {
      if (this.countryLabel.length === 0) {
        // Country has not been set.
        var dialog = {
          title: this.$t("tenant.customer.actions.addCustomer"),
          message: this.$t("tenant.customer.labels.missingCountry"),
          icon: "mdi-alert",
          buttons: []
        }
        this.$store.dispatch("setGenericDialog", dialog)
      } else {
        if (this.isLoggedInUserAdmin) {
          this.showCustomerTypeDialog = true
        } else {
          this.showEndCustomerDialog()
        }
      }
    },
    async saveDealer() {
      this.isUpdating = true
      this.error = null

      const url = `/api/v1/tenants/${this.tenant.uid}/dealer`
      const payload = {
        customerNo: this.customer.customerNo
      }
      try {
        if (
          this.customer.created === undefined ||
          this.customer.created === null
        ) {
          await fetchClient(this.$i18n).postOrThrow(url, payload)
        } else {
          await fetchClient(this.$i18n).putOrThrow(url, payload)
        }
        this.snackbarText = this.$t("tenant.customer.messages.saved")
        this.snackbar = true
        await this.fetchCustomer()
      } catch (error) {
        console.error(error)
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    },
    async saveCustomer() {
      this.isUpdating = true
      this.error = null

      const url = `/api/v1/tenants/${this.tenant.uid}/customer`
      const payload = {
        priceListId: this.customer.priceListId,
        discount: Number.parseInt(this.customer.discount),
        addresses: this.customer.addresses
      }
      try {
        await fetchClient(this.$i18n).putOrThrow(url, payload)
        this.snackbarText = this.$t("tenant.customer.messages.saved")
        this.snackbar = true
        await this.fetchCustomer()
      } catch (error) {
        console.error(error)
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    },
    async onSaveClick() {
      if (!this.hasValidInput) {
        return
      }

      this.editCustomer = false

      if (this.isDealer) {
        await this.saveDealer()
      } else {
        await this.saveCustomer()
      }
    },
    onCancelClick() {
      this.editCustomer = false
      this.customer = Object.assign({}, this.$store.getters.tenantCustomer)
      this.customer.addresses = Object.assign(
        [],
        this.$store.getters.tenantCustomer.addresses
      )
    },
    async onRemoveClick() {
      this.isUpdating = true
      this.error = null

      try {
        const url = `/api/v1/tenants/${this.tenant.uid}/customer`
        await fetchClient(this.$i18n).deleteOrThrow(url)
        this.snackbarText = this.$t("tenant.customer.messages.removed")
        this.snackbar = true
        await this.fetchCustomer()
        this.isUpdating = false
      } catch (error) {
        this.error = { type: "error", ...error }
      } finally {
        this.isUpdating = false
      }
    }
  }
}
</script>

<style></style>
